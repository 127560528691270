import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import React, { memo, useState } from "react";

import ChargebackUploadDialog from "~/modules/vendor/chargebackUploadModal";
import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import DeductionsSyncDialog from "~/modules/widgets/vendorProfitability/deductionsSyncDialog";
import { Grid } from "@material-ui/core";
import InfoAlert from "~/components/alert/infoAlert";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import PageBlock from "~/components/containers/sideNavPageBlock";
import SmallButton from "@components/buttons/smallButton";
import VendorProfitAndLoss from "~/modules/widgets/vendorProfitability/profitAndLoss";
import get from "lodash/get";
import { getCurrencyByCountryCode } from "mm-utils-frontend";
import moment from "moment-timezone";
import { useGetChargebackMetadataQuery } from "~/store/mystore/vendorProfitability.redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const ProfitabilityVendorStore = memo(function ProfitabilityVendorStore() {
  const currentStore = useTypedSelector(
    (state) => state.persistentAppSettings?.setting?.data?.currentStore
  );
  const { t } = useTranslation();

  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );
  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );

  const [chargebackUploadDialogOpen, setChargebackUploadDialogOpen] =
    useState(false);
  const [deductionUpdateDialogOpen, setDeductionUpdateDialogOpen] =
    useState(false);
  const [chargebackAlertOpen, setChargebackAlertOpen] = useState(true);

  const { hasChargebacks, maxChargebackDate, minChargebackDate } =
    useGetChargebackMetadataQuery(
      {
        mid: currentStore?.merchantId || "",
        marketplaceFilters: {
          marketplaceType: currentStore?.marketplace || "",
          marketplaceSubtype: currentStore?.marketplaceSubtype || "",
        },
        currentRange,
      },
      {
        skip: !currentStore,
        selectFromResult: ({ data }) => {
          return {
            hasChargebacks: data?.hasChargebacks || true,
            maxChargebackDate: data?.maxChargebackDate,
            minChargebackDate: data?.minChargebackDate,
          };
        },
      }
    );

  return (
    <PageBlock>
      {currentStore ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InfoAlert
                message={t("vendorProfitability.chargebackMissingAlertText", {
                  startDate: moment(minChargebackDate).isValid()
                    ? moment(minChargebackDate).format("DD MMM, YYYY")
                    : "-",
                  endDate: moment(maxChargebackDate).isValid()
                    ? moment(maxChargebackDate).format("DD MMM, YYYY")
                    : "-",
                })}
                typographyWeight="medium"
                isOpen={chargebackAlertOpen && !hasChargebacks}
                onClose={() => {
                  setChargebackAlertOpen(false);
                }}
                actions={
                  <SmallButton
                    onClick={() => {
                      setChargebackUploadDialogOpen(true);
                    }}
                    color="primary"
                    variant="contained"
                  >
                    {t("vendorProfitability.chargebackUploadTitle")}
                  </SmallButton>
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <VendorProfitAndLoss
                title={t("nav.storeProfitSellOut")}
                store={currentStore}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                setChargebackUploadDialogOpen={setChargebackUploadDialogOpen}
                setDeductionUpdateDialogOpen={setDeductionUpdateDialogOpen}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <VendorProfitAndLoss
                title={t("nav.storeProfitSellOut")}
                store={currentStore}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                isComparison
                setChargebackUploadDialogOpen={setChargebackUploadDialogOpen}
                setDeductionUpdateDialogOpen={setDeductionUpdateDialogOpen}
              />
            </Grid>
          </Grid>
          <ChargebackUploadDialog
            mid={currentStore.merchantId}
            marketplaceType={currentStore.marketplace}
            marketplaceSubtype={currentStore.marketplaceSubtype}
            countryCode={currentStore.marketplaceCountry}
            openModal={chargebackUploadDialogOpen}
            setOpenModal={setChargebackUploadDialogOpen}
            currentRange={currentRange}
          />
          <DeductionsSyncDialog
            store={currentStore}
            open={deductionUpdateDialogOpen}
            onClose={() => {
              setDeductionUpdateDialogOpen(false);
            }}
            timezone={currentRange.timezone}
            storeCurrency={
              getCurrencyByCountryCode[currentStore.marketplaceCountry]
            }
          />
        </>
      ) : (
        <LoadingIndicator />
      )}
    </PageBlock>
  );
});

export default ProfitabilityVendorStore;
