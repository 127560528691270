import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import {
  DEFAULT_CURRENCY,
  DEFAULT_FILTER,
} from "~/store/persistentAppSettings.redux";
import React, { memo, useMemo } from "react";
import {
  convertDateToTimezone,
  getEquivalentTimezone,
} from "mm-utils-frontend";

import ForecastingAndSalesChart from "~/modules/widgets/forecastingAndSalesChart";
import ForecastingCalendar from "~/components/forecasting/forecastingCalendar";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import PageBlock from "~/components/containers/sideNavPageBlock";
import get from "lodash/get";
import moment from "moment-timezone";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const ForecastsAndCosts = memo(function ForecastsAndCosts() {
  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );

  const userInfo = useTypedSelector((state) => state.user);
  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );
  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );

  const currentFilter = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentFilter") ||
      DEFAULT_FILTER
  );
  const includeTax = useTypedSelector((state) =>
    Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
  );

  const updatedCurrentRange = useMemo(() => {
    if (!store) return currentRange;

    const countryTz = getEquivalentTimezone(store.marketplaceCountry);

    if (!countryTz) return currentRange;

    const fromDate = convertDateToTimezone(
      currentRange.fromDate,
      currentRange.timezone,
      countryTz
    )
      .startOf("d")
      .unix();
    const toDate = convertDateToTimezone(
      currentRange.toDate,
      currentRange.timezone,
      countryTz
    )
      .endOf("d")
      .unix();
    const priorFromDate = convertDateToTimezone(
      currentRange.priorFromDate,
      currentRange.timezone,
      countryTz
    )
      .startOf("d")
      .unix();
    const priorToDate = convertDateToTimezone(
      currentRange.priorToDate,
      currentRange.timezone,
      countryTz
    )
      .endOf("d")
      .unix();

    return {
      fromDate,
      toDate,
      priorFromDate,
      priorToDate,
      timezone: countryTz.name,
      interval: currentRange.interval,
    };
  }, [currentRange, store]);

  return (
    <PageBlock>
      {store ? (
        <ForecastingCalendar
          currentRange={updatedCurrentRange}
          currentCurrency={currentCurrency}
          userInfo={userInfo}
          store={store}
          ChartComponent={() => (
            <ForecastingAndSalesChart
              currentRange={updatedCurrentRange}
              currentCompare={currentCompare}
              currentCurrency={currentCurrency}
              currentPeriod={currentPeriod}
              currentFilter={currentFilter}
              userInfo={userInfo}
              store={store}
              includeTax={includeTax}
            />
          )}
        />
      ) : (
        <LoadingIndicator />
      )}
    </PageBlock>
  );
});

export default ForecastsAndCosts;
