import { BaseDialog, BaseDialogTitle } from "~/components/dialogs/baseDialog";
import { Box, DialogContent, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import RaisedButton from "~/components/buttons/raisedButton.tsx";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const DialogContentGrid = styled(Grid)`
  padding: 8px 0;
`;

const OrderedList = styled.ol`
  margin: revert;
  padding: revert;
`;

const TemplateDialog = ({ mid, open, onClose }) => {
  const { t } = useTranslation();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    setButtonDisabled(false);
  }, [open]);

  const paramString = new URLSearchParams({
    mid,
  });

  return (
    <BaseDialog maxWidth="md" open={open} onClose={onClose}>
      <BaseDialogTitle onClose={onClose}>
        {t("profitability.downloadButtonLabel")}
      </BaseDialogTitle>
      <DialogContent>
        <DialogContentGrid container spacing={2}>
          <Grid item xs={12}>
            <Box mb={1}>
              <Typography variant="h2">
                {t("profitability.templateInstructionsTitle")}
              </Typography>
            </Box>
            <Typography variant="body1">
              {t("profitability.templateInstructionsIntroText")}
            </Typography>
            <Typography variant="body2">
              <OrderedList>
                <li>{t("profitability.templateInstructionsBullet1")}</li>
                <li>{t("profitability.templateInstructionsBullet2")}</li>
                <li>{t("profitability.templateInstructionsBullet3")}</li>
                <li>{t("profitability.templateInstructionsBullet4")}</li>
              </OrderedList>
            </Typography>
          </Grid>
        </DialogContentGrid>
        <Grid container justifyContent="center" item xs={12}>
          <RaisedButton
            disabled={buttonDisabled}
            href={`/api/myStores/profitability/directCosts?${paramString.toString()}`}
            variant="outlined"
            color="secondary"
            onClick={() => setButtonDisabled(true)}
          >
            {t("profitability.downloadButtonLabel")}
          </RaisedButton>
        </Grid>
      </DialogContent>
    </BaseDialog>
  );
};

TemplateDialog.propTypes = {
  mid: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TemplateDialog;
