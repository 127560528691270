import moment, { Moment } from "moment-timezone";

import { DateRange } from "~/typedef/date";

const midnight = (timezone: string) =>
  moment.tz(timezone).startOf("day").subtract(1, "s");

const WEEK = 7;
const MONTH30 = 30;

export const yearsAgo = (years: number, timezone: string) =>
  moment.tz(timezone).startOf("day").subtract(years, "y");

export const weeksAgo = (weeks: number, timezone: string) =>
  moment
    .tz(timezone)
    .startOf("day")
    .subtract(WEEK * weeks, "d");

const monthsAgo = (months: number, timezone: string, daysInMonth: number) =>
  moment
    .tz(timezone)
    .startOf("day")
    .subtract(daysInMonth * months, "d");

export type DATETIME_PERIODS_KEY = keyof typeof DATETIME_PERIODS;

export enum DATETIME_PERIODS {
  DAY = "TODAY",
  YESTERDAY = "YESTERDAY",
  WEEK = "WTD",
  WEEKMTS = "WTDMTS",
  LAST30 = "LAST30",
  MTD = "MTD",
  CURRENTMONTH = "CURRENTMONTH",
  LASTMONTH = "LASTMONTH",
  CURRENTQ = "CURRENTQ",
  LASTQ = "LASTQ",
  LAST12 = "LAST12",
  YEAR = "YTD",
  CURRENTYEAR = "CURRENTYEAR",
  CUSTOM = "CUSTOM",
}

export enum COMPARISON_PERIOD {
  THISYEAR = "THISYEAR",
  LASTYEAR = "LASTYEAR",
}

export enum INTERVAL {
  YEARS = "y",
  QUARTERS = "Q",
  MONTHS = "M",
  WEEKS = "w",
  DAYS = "d",
  HOURS = "h",
}

export const getIntervalString = (interval: INTERVAL) => {
  switch (interval) {
    case INTERVAL.YEARS:
      return "yearly";
    case INTERVAL.QUARTERS:
      return "quarterly";
    case INTERVAL.MONTHS:
      return "monthly";
    case INTERVAL.WEEKS:
      return "weekly";
    case INTERVAL.DAYS:
      return "daily";
    case INTERVAL.HOURS:
      return "hourly";
    default:
      return "hourly";
  }
};

// A list of chart intervals where only a restricted list of
// timezones is allowed
// due to continuous aggregate limitations
// Implementation note: defined as (string | undefined)[] to work
// around Typescript limitations - something like
// ['a','b'].includes(undefined) is a compile-time error
export const RESTRICTED_CHART_INTERVALS: (string | undefined)[] = [
  INTERVAL.MONTHS,
  INTERVAL.QUARTERS,
  INTERVAL.YEARS,
];

const getComparisonPeriod = (
  date: Moment,
  currentCompare: COMPARISON_PERIOD,
  diff: number,
  interval: INTERVAL
): number =>
  currentCompare === "LASTYEAR"
    ? date.clone().subtract(1, "years").unix()
    : date.clone().subtract(diff, interval).unix();

/** Handle a date range which isn't one of our presets in the getDatesFromPeriod
function. */
const getCustomDatesFromPeriod = (
  customFromDate: number, //unix timestamp
  customToDate: number, //unix timestamp
  currentCompare: COMPARISON_PERIOD,
  timezone: string,
  customPriorFromDate?: number,
  customPriorToDate?: number
) => {
  const toDateMoment = moment.unix(customToDate as number);
  const fromDateMoment = moment.unix(customFromDate as number);
  const diff = toDateMoment.diff(fromDateMoment, "days", true) || 1;
  const priorToDate = getComparisonPeriod(
    toDateMoment,
    currentCompare,
    diff,
    INTERVAL.DAYS
  );
  const priorFromDate = getComparisonPeriod(
    fromDateMoment,
    currentCompare,
    diff,
    INTERVAL.DAYS
  );
  let interval: INTERVAL = INTERVAL.HOURS;
  if (toDateMoment.isSame(fromDateMoment, "d")) {
    interval = INTERVAL.HOURS;
  } else if (diff <= 90) {
    // if selected dates are 90 days or less apart
    interval = INTERVAL.DAYS;
  } else {
    interval = INTERVAL.WEEKS;
  }

  return {
    fromDate: customFromDate,
    toDate: customToDate,
    priorFromDate: customPriorFromDate || priorFromDate,
    priorToDate: customPriorToDate || priorToDate,
    interval,
    timezone,
  };
};

export const getReportDatesFromPeriod = (
  currentPeriod: DATETIME_PERIODS,
  currentCompare: COMPARISON_PERIOD,
  timezone: string,
  fromDate: number, //unix timestamp
  toDate: number, //unix timestamp
  customPriorFromDate: number, //unix timestamp
  customPriorToDate: number, //unix timestamp
  customInterval?: INTERVAL
) => {
  const toDateMoment = timezone
    ? moment.unix(toDate).tz(timezone)
    : moment.unix(toDate);
  const fromDateMoment = timezone
    ? moment.unix(fromDate).tz(timezone)
    : moment.unix(fromDate);

  const diff = toDateMoment.diff(fromDateMoment, "days", true) || 1;
  let interval: INTERVAL = INTERVAL.HOURS;
  if (toDateMoment.isSame(fromDateMoment, "d")) {
    interval = INTERVAL.HOURS;
  } else if (diff <= 90) {
    // if selected dates are 90 days or less apart
    interval = INTERVAL.DAYS;
  } else {
    interval = INTERVAL.WEEKS;
  }

  let priorFromDate = customPriorFromDate;
  let priorToDate = customPriorToDate;

  if (
    [DATETIME_PERIODS.DAY, DATETIME_PERIODS.YESTERDAY].includes(currentPeriod)
  ) {
    priorFromDate = getComparisonPeriod(
      fromDateMoment,
      currentCompare,
      1,
      INTERVAL.DAYS
    );
    priorToDate = getComparisonPeriod(
      toDateMoment,
      currentCompare,
      1,
      INTERVAL.DAYS
    );
    interval = INTERVAL.HOURS;
  } else if (
    [DATETIME_PERIODS.WEEK, DATETIME_PERIODS.WEEKMTS].includes(currentPeriod)
  ) {
    priorFromDate = getComparisonPeriod(
      fromDateMoment,
      currentCompare,
      1,
      INTERVAL.WEEKS
    );
    priorToDate = getComparisonPeriod(
      toDateMoment,
      currentCompare,
      1,
      INTERVAL.WEEKS
    );
    interval = INTERVAL.DAYS;
  } else if (currentPeriod === DATETIME_PERIODS.MTD) {
    const subtract = currentCompare === "LASTYEAR" ? "year" : "month";
    priorFromDate = fromDateMoment
      .clone()
      .tz(timezone)
      .subtract(1, subtract)
      .startOf("month")
      .unix();
    priorToDate = toDateMoment
      .clone()
      .tz(timezone)
      .endOf("day")
      .subtract(1, subtract)
      .unix();
    interval = INTERVAL.DAYS;
  } else if (
    [DATETIME_PERIODS.CURRENTMONTH, DATETIME_PERIODS.LASTMONTH].includes(
      currentPeriod
    )
  ) {
    priorFromDate = getComparisonPeriod(
      fromDateMoment,
      currentCompare,
      1,
      INTERVAL.MONTHS
    );
    priorToDate = getComparisonPeriod(
      toDateMoment,
      currentCompare,
      1,
      INTERVAL.MONTHS
    );
    interval = INTERVAL.DAYS;
  } else if (
    [DATETIME_PERIODS.CURRENTQ, DATETIME_PERIODS.LASTQ].includes(currentPeriod)
  ) {
    priorFromDate = getComparisonPeriod(
      fromDateMoment,
      currentCompare,
      1,
      INTERVAL.QUARTERS
    );
    priorToDate = getComparisonPeriod(
      toDateMoment,
      currentCompare,
      1,
      INTERVAL.QUARTERS
    );
    interval = INTERVAL.WEEKS;
  } else if (currentPeriod === DATETIME_PERIODS.LAST30) {
    priorFromDate = getComparisonPeriod(
      fromDateMoment,
      currentCompare,
      30,
      INTERVAL.DAYS
    );
    priorToDate = getComparisonPeriod(
      toDateMoment,
      currentCompare,
      30,
      INTERVAL.DAYS
    );
    interval = INTERVAL.DAYS;
  } else if (currentPeriod === DATETIME_PERIODS.LAST12) {
    priorFromDate = getComparisonPeriod(
      fromDateMoment,
      currentCompare,
      12,
      INTERVAL.MONTHS
    );
    priorToDate = getComparisonPeriod(
      toDateMoment,
      currentCompare,
      12,
      INTERVAL.MONTHS
    );
    interval = INTERVAL.WEEKS;
  } else if (
    currentPeriod === DATETIME_PERIODS.YEAR ||
    currentPeriod === DATETIME_PERIODS.CURRENTYEAR
  ) {
    priorFromDate = getComparisonPeriod(
      fromDateMoment,
      currentCompare,
      1,
      INTERVAL.YEARS
    );
    priorToDate = getComparisonPeriod(
      toDateMoment,
      currentCompare,
      1,
      INTERVAL.YEARS
    );
    interval = INTERVAL.WEEKS;
  }

  return {
    fromDate,
    toDate,
    priorFromDate: priorFromDate,
    priorToDate: priorToDate,
    interval: customInterval ?? interval,
    timezone,
  };
};

export const getDatesFromPeriod = (
  currentPeriod: DATETIME_PERIODS,
  currentCompare: COMPARISON_PERIOD,
  timezone: string,
  customFromDate?: number, //unix timestamp
  customToDate?: number, //unix timestamp
  customPriorFromDate?: number, //unix timestamp
  customPriorToDate?: number, //unix timestamp
  interval?: INTERVAL
): DateRange => {
  if (currentPeriod === DATETIME_PERIODS.DAY) {
    const fromDate = moment.tz(timezone).startOf("d");
    // add one minute to prevent the final bucket from being excluded
    const toDate = moment.tz(timezone).startOf("h").add(1, "m");

    return {
      fromDate: fromDate.unix(),
      toDate: toDate.unix(),
      priorFromDate: getComparisonPeriod(
        fromDate,
        currentCompare,
        1,
        INTERVAL.DAYS
      ),
      priorToDate: getComparisonPeriod(
        toDate,
        currentCompare,
        1,
        INTERVAL.DAYS
      ),
      interval: interval ?? INTERVAL.HOURS,
      timezone,
    };
  }
  if (currentPeriod === DATETIME_PERIODS.YESTERDAY) {
    const fromDate = moment.tz(timezone).startOf("d").subtract(1, "d");
    const toDate = moment.tz(timezone).startOf("d").subtract(1, "m");

    return {
      fromDate: fromDate.unix(),
      toDate: toDate.unix(),
      priorFromDate: getComparisonPeriod(
        fromDate,
        currentCompare,
        1,
        INTERVAL.DAYS
      ),
      priorToDate: getComparisonPeriod(
        toDate,
        currentCompare,
        1,
        INTERVAL.DAYS
      ),
      interval: interval ?? INTERVAL.HOURS,
      timezone,
    };
  }
  if (currentPeriod === DATETIME_PERIODS.WEEK) {
    const fromDate = moment.tz(timezone).startOf("week").subtract(1, "week");
    const toDate = moment
      .tz(timezone)
      .startOf("week")
      .subtract(1, "week")
      .endOf("week");

    return {
      fromDate: fromDate.unix(),
      toDate: toDate.unix(),
      priorFromDate: getComparisonPeriod(
        fromDate,
        currentCompare,
        1,
        INTERVAL.WEEKS
      ),
      priorToDate: getComparisonPeriod(
        toDate,
        currentCompare,
        1,
        INTERVAL.WEEKS
      ),
      interval: interval ?? INTERVAL.DAYS,
      timezone,
    };
  }
  if (currentPeriod === DATETIME_PERIODS.WEEKMTS) {
    const fromDate = moment.tz(timezone).startOf("isoWeek").subtract(1, "week");
    const toDate = moment
      .tz(timezone)
      .startOf("isoWeek")
      .subtract(1, "week")
      .endOf("isoWeek");

    return {
      fromDate: fromDate.unix(),
      toDate: toDate.unix(),
      priorFromDate: getComparisonPeriod(
        fromDate,
        currentCompare,
        1,
        INTERVAL.WEEKS
      ),
      priorToDate: getComparisonPeriod(
        toDate,
        currentCompare,
        1,
        INTERVAL.WEEKS
      ),
      interval: interval ?? INTERVAL.DAYS,
      timezone,
    };
  }
  if (currentPeriod === DATETIME_PERIODS.MTD) {
    const fromDate = moment.tz(timezone).startOf("month");
    const toDate = moment.tz(timezone).endOf("day");
    const subtract = currentCompare === "LASTYEAR" ? "year" : "month";
    const priorFromDate = moment
      .tz(timezone)
      .subtract(1, subtract)
      .startOf("month");
    const priorToDate = moment.tz(timezone).endOf("day").subtract(1, subtract);
    return {
      fromDate: fromDate.unix(),
      toDate: toDate.unix(),
      priorFromDate: priorFromDate.unix(),
      priorToDate: priorToDate.unix(),
      interval: interval ?? INTERVAL.DAYS,
      timezone,
    };
  }
  if (currentPeriod === DATETIME_PERIODS.CURRENTMONTH) {
    const fromDate = moment.tz(timezone).startOf("month");
    const toDate = moment.tz(timezone).endOf("month");
    return {
      fromDate: fromDate.unix(),
      toDate: toDate.unix(),
      priorFromDate: getComparisonPeriod(
        fromDate,
        currentCompare,
        1,
        INTERVAL.MONTHS
      ),
      priorToDate: getComparisonPeriod(
        toDate,
        currentCompare,
        1,
        INTERVAL.MONTHS
      ),
      interval: interval ?? INTERVAL.DAYS,
      timezone,
    };
  }
  if (currentPeriod === DATETIME_PERIODS.LASTMONTH) {
    const fromDate = moment.tz(timezone).subtract(1, "month").startOf("month");
    const toDate = moment.tz(timezone).subtract(1, "month").endOf("month");

    return {
      fromDate: fromDate.unix(),
      toDate: toDate.unix(),
      priorFromDate: getComparisonPeriod(
        fromDate,
        currentCompare,
        1,
        INTERVAL.MONTHS
      ),
      priorToDate: getComparisonPeriod(
        toDate,
        currentCompare,
        1,
        INTERVAL.MONTHS
      ),
      interval: interval ?? INTERVAL.DAYS,
      timezone,
    };
  }
  if (currentPeriod === DATETIME_PERIODS.CURRENTQ) {
    const fromDate = moment.tz(timezone).startOf("quarter");
    const toDate = moment.tz(timezone).endOf("quarter");

    return {
      fromDate: fromDate.unix(),
      toDate: toDate.unix(),
      priorFromDate: getComparisonPeriod(
        fromDate,
        currentCompare,
        1,
        INTERVAL.QUARTERS
      ),
      priorToDate: getComparisonPeriod(
        toDate,
        currentCompare,
        1,
        INTERVAL.QUARTERS
      ),
      interval: interval ?? INTERVAL.WEEKS,
      timezone,
    };
  }
  if (currentPeriod === DATETIME_PERIODS.LASTQ) {
    const fromDate = moment
      .tz(timezone)
      .subtract(1, "quarter")
      .startOf("quarter");
    const toDate = moment.tz(timezone).subtract(1, "quarter").endOf("quarter");

    return {
      fromDate: fromDate.unix(),
      toDate: toDate.unix(),
      priorFromDate: getComparisonPeriod(
        fromDate,
        currentCompare,
        1,
        INTERVAL.QUARTERS
      ),
      priorToDate: getComparisonPeriod(
        toDate,
        currentCompare,
        1,
        INTERVAL.QUARTERS
      ),
      interval: interval ?? INTERVAL.WEEKS,
      timezone,
    };
  }
  if (currentPeriod === DATETIME_PERIODS.LAST30) {
    const fromDate = monthsAgo(1, timezone, MONTH30);
    const toDate = midnight(timezone);

    return {
      fromDate: fromDate.unix(),
      toDate: toDate.unix(),
      priorFromDate: getComparisonPeriod(
        fromDate,
        currentCompare,
        30,
        INTERVAL.DAYS
      ),
      priorToDate: getComparisonPeriod(
        toDate,
        currentCompare,
        30,
        INTERVAL.DAYS
      ),
      interval: interval ?? INTERVAL.DAYS,
      timezone,
    };
  }
  if (currentPeriod === DATETIME_PERIODS.LAST12) {
    const fromDate = yearsAgo(1, timezone);
    const toDate = midnight(timezone);

    // If we're using monthly etc buckets, align selected
    // date range to start and end of month to avoid
    // inconsistencies with charts. Otherwise, some charts
    // will have buckets beginning mid-month instead.
    if (RESTRICTED_CHART_INTERVALS.includes(interval)) {
      fromDate.startOf("month");
      toDate.endOf("month");
    }

    return {
      fromDate: fromDate.unix(),
      toDate: toDate.unix(),
      priorFromDate: getComparisonPeriod(
        fromDate,
        currentCompare,
        12,
        INTERVAL.MONTHS
      ),
      priorToDate: getComparisonPeriod(
        toDate,
        currentCompare,
        12,
        INTERVAL.MONTHS
      ),
      interval: interval ?? INTERVAL.WEEKS,
      timezone,
    };
  }
  if (currentPeriod === DATETIME_PERIODS.YEAR) {
    const fromDate = moment.tz(timezone).startOf("year");
    const toDate = midnight(timezone);

    return {
      fromDate: fromDate.unix(),
      toDate: toDate.unix(),
      priorFromDate: getComparisonPeriod(
        fromDate,
        currentCompare,
        1,
        INTERVAL.YEARS
      ),
      priorToDate: getComparisonPeriod(
        toDate,
        currentCompare,
        1,
        INTERVAL.YEARS
      ),
      interval: interval ?? INTERVAL.WEEKS,
      timezone,
    };
  }
  if (currentPeriod === DATETIME_PERIODS.CURRENTYEAR) {
    const fromDate = moment.tz(timezone).startOf("year");
    const toDate = midnight(timezone).endOf("year");

    return {
      fromDate: fromDate.unix(),
      toDate: toDate.unix(),
      priorFromDate: getComparisonPeriod(
        fromDate,
        currentCompare,
        1,
        INTERVAL.YEARS
      ),
      priorToDate: getComparisonPeriod(
        toDate,
        currentCompare,
        1,
        INTERVAL.YEARS
      ),
      interval: interval ?? INTERVAL.WEEKS,
      timezone,
    };
  }
  return {
    ...getCustomDatesFromPeriod(
      customFromDate!,
      customToDate!,
      currentCompare,
      timezone,
      customPriorFromDate,
      customPriorToDate
    ),
    ...(interval ? { interval } : {}),
  };
};
