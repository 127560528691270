import ActionCell, {
  ReactTableCell,
} from "~/components/table/cells/actionCell";
import ColumnSelect, { Column } from "~/components/adTable/columnSelect";
import { InlineBlockDiv, StyledTableCell } from "../widgets/salesByProduct";
import React, {
  ReactChild,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { formatCurrency, formatCurrencyRounded } from "~/utils/currencyUtils";

import { Box } from "@material-ui/core";
import { CategoryCell } from "~/components/table/cells/categoryCell";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import NoScrollTable from "~/components/table/table";
import { PaginationArgs } from "~/typedef/pagination";
import Panel from "~/components/panel/panel";
import PercentageAndGrowthCell from "~/components/table/cells/percentageAndGrowthCell";
import { Range } from "~/typedef/store";
import { ShowChart } from "@material-ui/icons";
import Table from "~/components/adTable/table";
import { TextCell } from "~/components/table/cells/textCell";
import TrafficAndConversionTableChart from "./trafficAndConversionTableChart";
import { ValueAndGrowthCell } from "~/components/table/cells/valueAndGrowthCell";
import { ValueCell } from "~/components/table/cells/valueCell";
import { getPercentageDifference } from "~/utils/salesUtils";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import { numberWithCommas } from "~/utils/utils";
import { useTrafficAndConversionQuery } from "~/store/mystore/trafficAndConversion.redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

export enum TrafficAndConversionTableSelect {
  PARENTS = "parents",
  SKUS = "skus",
}

export type TrafficAndConversionTableView =
  | typeof TrafficAndConversionTableSelect.PARENTS
  | typeof TrafficAndConversionTableSelect.SKUS;

const PARENTS_HIDDEN_COLUMNS = ["brand"];

interface TrafficAndConversionTableProps {
  marketplaceType: string;
  mid: string;
  currentRange: Range;
  currentPeriod: DATETIME_PERIODS;
  currentCurrency: string;
  searchText?: string;
  view: TrafficAndConversionTableView;
  actions?: ReactChild;
  pageSize: number;
  condensed?: boolean;
  report?: boolean;
  conditionalFormatting?: boolean;
  timezone: string;
}

const TrafficAndConversionTable = memo<TrafficAndConversionTableProps>(
  function TrafficAndConversionTable({
    marketplaceType,
    mid,
    currentRange,
    currentPeriod,
    currentCurrency,
    searchText,
    view,
    actions,
    pageSize,
    condensed,
    report,
    conditionalFormatting,
    timezone,
  }) {
    const { t } = useTranslation();

    const [paginationParams, setPaginationParams] = useState<PaginationArgs>({
      pageSize,
      pageIndex: 0,
      sortKey: "page_views",
      sortOrder: "desc",
    });
    const {
      trafficAndConversion,
      trafficAndConversionCurrency,
      trafficAndConversionCount,
      trafficAndConversionFetching,
    } = useTrafficAndConversionQuery(
      {
        mid,
        currentRange,
        searchText,
        view,
        ...paginationParams,
      },
      {
        selectFromResult: ({ data, isFetching }) => ({
          trafficAndConversion: data?.[view]?.data || [],
          trafficAndConversionCurrency: data?.currency || "",
          trafficAndConversionCount: data?.[view]?.count || 0,
          trafficAndConversionFetching: isFetching,
        }),
      }
    );

    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );

    const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
      setPaginationParams({
        sortKey: sortBy[0]?.id || "page_views",
        sortOrder: sortBy[0]?.id ? (sortBy[0]?.desc ? "desc" : "asc") : "desc",
        pageIndex,
        pageSize,
      });
    }, []);

    const contentRef = React.useRef<HTMLHeadingElement>(null);

    const renderTrafficAndConversionSubComponent = ({ row }: any) => {
      const tableRenderedWidth = Number(contentRef.current?.clientWidth) - 20;
      return (
        // eslint-disable-next-line no-magic-numbers
        <tr>
          <StyledTableCell
            style={{
              maxWidth: "0px",
              overflowX: "visible",
              position: "sticky",
              zIndex: 3,
              left: "0px",
            }}
          >
            <div
              style={{
                width: tableRenderedWidth,
              }}
            >
              <Box>
                <TrafficAndConversionTableChart
                  mid={mid}
                  currentRange={currentRange}
                  currentPeriod={currentPeriod}
                  view={view}
                  productId={row.original?.productId}
                  timezone={timezone}
                />
              </Box>
            </div>
          </StyledTableCell>
        </tr>
      );
    };

    const columns = useMemo(
      () => [
        {
          Header: t("myStoresWidget.salesByProduct.productColumn"),
          accessor: (row: any) => ({
            value: row.title,
            secondRowValue: `${
              marketplaceType === "amazon" ? "ASIN" : "LISTING ID"
            }: ${row.productSku}`,
            image: row.imageUrl,
            link: row.url,
            target: "_blank",
          }),
          id: "title",
          Cell: (props: any) => (
            <LinkAndImageCell {...props} colorVariant="external" />
          ),
          colSpan: 2,
          isVisible: true,
          sticky: "left",
          width: 450, // Only affects the positioning of the next sticky column
        },
        ...(condensed
          ? []
          : [
              {
                Header: t("trafficAndConversion.actionsColumn"),
                accessor: () => {},
                id: "actions",
                Cell: (cell: ReactTableCell) => {
                  const actions = [
                    {
                      icon: ShowChart,
                      text: cell.row.isExpanded
                        ? t("generic.hide")
                        : t("generic.show"),
                      action: () => {
                        const isExpanded = cell.row.isExpanded;
                        cell.toggleAllRowsExpanded(false);
                        const { onClick } =
                          cell.row.getToggleRowExpandedProps();
                        if (!isExpanded) onClick();
                      },
                    },
                  ];
                  return <ActionCell actions={actions} />;
                },
                isVisible: true,
                disableSortBy: true,
                sticky: "left",
              },
              ...(view === TrafficAndConversionTableSelect.PARENTS
                ? []
                : [
                    {
                      Header: t("myStoresWidget.salesByBrand.brandColumn"),
                      accessor: "brand",
                      id: "brand",
                      Cell: TextCell,
                      colSpan: 3,
                      isVisible: view === TrafficAndConversionTableSelect.SKUS,
                      disableSortBy: true,
                    },
                  ]),
              {
                Header: t("myStoresWidget.salesByCategory.categoryColumn"),
                accessor: (row: {
                  categoryLabel: string;
                  parentCategory: string;
                }) => ({
                  current: row.categoryLabel,
                  parent: row.parentCategory,
                }),
                id: "category",
                Cell: (props: any) => <CategoryCell {...props} />,
                isVisible: true,
                colSpan: 3,
                disableSortBy: true,
              },
            ]),
        {
          Header: t("trafficAndConversion.pageViews"),
          accessor: (row: { pageViews: number; priorPageViews: number }) => {
            return {
              value:
                row.pageViews !== undefined
                  ? numberWithCommas(row.pageViews.toFixed(0))
                  : "-",
              growth: row.priorPageViews
                ? getPercentageDifference(row.pageViews, row.priorPageViews)
                : "N/A",
              conditionalFormatting,
            };
          },
          id: "page_views",
          Cell: ValueAndGrowthCell,
          align: "right",
          isVisible: true,
        },
        {
          Header: t("trafficAndConversion.orderedRevenueTable"),
          accessor: (row: {
            orderedRevenue: number;
            priorOrderedRevenue: number;
          }) => {
            return {
              value:
                row.orderedRevenue !== undefined
                  ? formatCurrencyRounded(
                      row.orderedRevenue,
                      currencyRates,
                      trafficAndConversionCurrency,
                      currentCurrency
                    )
                  : "-",
              growth: row.priorOrderedRevenue
                ? getPercentageDifference(
                    row.orderedRevenue,
                    row.priorOrderedRevenue
                  )
                : "N/A",
              conditionalFormatting,
            };
          },
          id: "ordered_revenue",
          Cell: ValueAndGrowthCell,
          isVisible: true,
          align: "right",
        },
        {
          Header: t("trafficAndConversion.orderedUnits"),
          accessor: (row: {
            orderedUnits: number;
            priorOrderedUnits: number;
          }) => {
            return {
              value:
                row.orderedUnits !== undefined
                  ? numberWithCommas(row.orderedUnits.toFixed(0))
                  : "-",
              growth: row.priorOrderedUnits
                ? getPercentageDifference(
                    row.orderedUnits,
                    row.priorOrderedUnits
                  )
                : "N/A",
              conditionalFormatting,
            };
          },
          id: "ordered_units",
          Cell: ValueAndGrowthCell,
          align: "right",
          isVisible: true,
          hiddenDown: "md",
        },
        ...(condensed
          ? []
          : [
              {
                Header: t("trafficAndConversion.avgPriceTable"),
                accessor: (row: { price: number }) => {
                  return row.price !== undefined
                    ? formatCurrency(
                        row.price,
                        currencyRates,
                        trafficAndConversionCurrency,
                        currentCurrency
                      )
                    : "-";
                },
                id: "price",
                Cell: ValueCell,
                align: "right",
                isVisible: false,
              },
            ]),
        {
          Header: t("trafficAndConversion.unitsPerView"),
          accessor: (row: {
            unitsPerView: number;
            priorUnitsPerView: number;
          }) => {
            return {
              value:
                row.unitsPerView !== undefined
                  ? row.unitsPerView.toFixed(1)
                  : "-",
              growth: row.priorUnitsPerView
                ? (row.unitsPerView - row.priorUnitsPerView).toFixed(1)
                : "N/A",
              conditionalFormatting,
            };
          },
          id: "units_per_view",
          Cell: PercentageAndGrowthCell,
          align: "right",
          isVisible: true,
          hiddenDown: "md",
        },
        {
          Header: t("trafficAndConversion.buyboxWinPercentage"),
          accessor: (row: {
            buyboxWinPercentage: number;
            priorBuyboxWinPercentage: number;
          }) => {
            return {
              value:
                row.buyboxWinPercentage !== undefined
                  ? row.buyboxWinPercentage.toFixed(1)
                  : "-",
              growth: row.priorBuyboxWinPercentage
                ? (
                    row.buyboxWinPercentage - row.priorBuyboxWinPercentage
                  ).toFixed(1)
                : "N/A",
              conditionalFormatting,
            };
          },
          id: "buybox_win_percentage",
          Cell: PercentageAndGrowthCell,
          align: "right",
          isVisible: true,
          hiddenDown: "md",
        },
        ...(marketplaceType !== "amazon" || condensed
          ? []
          : [
              {
                Header: t("trafficAndConversion.sessions"),
                accessor: (row: {
                  sessions: number;
                  priorSessions: number;
                }) => {
                  return {
                    value:
                      row.sessions !== undefined
                        ? numberWithCommas(row.sessions.toFixed(0))
                        : "-",
                    growth: row.priorSessions
                      ? getPercentageDifference(row.sessions, row.priorSessions)
                      : "N/A",
                    conditionalFormatting,
                  };
                },
                id: "sessions",
                Cell: ValueAndGrowthCell,
                align: "right",
              },
              {
                Header: t("trafficAndConversion.mobileSessionsPercentage"),
                accessor: (row: {
                  mobileSessionsPercentage: number;
                  priorMobileSessionsPercentage: number;
                }) => {
                  return {
                    value:
                      row.mobileSessionsPercentage !== undefined
                        ? row.mobileSessionsPercentage.toFixed(1)
                        : "-",
                    growth: row.priorMobileSessionsPercentage
                      ? (
                          row.mobileSessionsPercentage -
                          row.priorMobileSessionsPercentage
                        ).toFixed(1)
                      : "N/A",
                    conditionalFormatting,
                  };
                },
                id: "mobile_sessions_percentage",
                Cell: PercentageAndGrowthCell,
                align: "right",
              },
            ]),
      ],
      [
        mid,
        currentRange,
        currentCurrency,
        currencyRates,
        trafficAndConversionCurrency,
        view,
      ]
    );

    const [myColumns, setMyColumns] = useState<Column[]>(columns);

    useEffect(() => {
      setMyColumns(
        columns.map((column, idx) => {
          if (
            myColumns.find((myColumn) => myColumn.id === column.id)
              ?.isVisible === false
          ) {
            return {
              ...column,
              isVisible: false,
            } as any;
          } else {
            return column;
          }
        })
      );
    }, [columns]);

    const filteredColumns =
      view === TrafficAndConversionTableSelect.PARENTS
        ? myColumns.filter(
            (col) => !col.id || !PARENTS_HIDDEN_COLUMNS.includes(col.id)
          )
        : myColumns;

    const footerLink =
      condensed && !report
        ? {
            url: marketplaceLink(marketplaceType, mid, "trafficandconversion"),
            label: t("generic.viewAllLink"),
          }
        : undefined;

    return (
      <Panel
        id="traffic-and-conversion-table"
        title={`${t("trafficAndConversion.mainTitle")}${
          report
            ? ` - ${
                view === TrafficAndConversionTableSelect.PARENTS
                  ? t("trafficAndConversion.parentView")
                  : t("trafficAndConversion.childView")
              }`
            : ""
        }`}
        tooltip={
          marketplaceType === "walmart"
            ? t("trafficAndConversion.walmartTooltip")
            : undefined
        }
        footerLink={footerLink}
        content={
          condensed ? (
            <>
              {/* Empty div to get the rendered width of the table by ref */}
              <InlineBlockDiv ref={contentRef} />
              <NoScrollTable
                {...{
                  columns,
                  data: trafficAndConversion,
                  pageSize,
                  loading: trafficAndConversionFetching,
                  isReport: report,
                }}
              />
            </>
          ) : (
            <>
              {/* Empty div to get the rendered width of the table by ref */}
              <InlineBlockDiv ref={contentRef} />
              <Table
                {...{
                  columns: filteredColumns,
                  data: trafficAndConversion,
                  fetchData,
                  loading: trafficAndConversionFetching,
                  sorting: true,
                  pagination: true,
                  pageCount: Math.ceil(
                    (trafficAndConversionCount || 0) / pageSize
                  ),
                  pageSize,
                  renderRowSubComponent: renderTrafficAndConversionSubComponent,
                }}
              />
            </>
          )
        }
        actions={
          <>
            {actions}
            {!report && (
              <ColumnSelect
                {...{ columns: myColumns, setColumns: setMyColumns }}
              />
            )}
          </>
        }
      />
    );
  }
);

export default TrafficAndConversionTable;
