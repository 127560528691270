import {
  FetchCohortTrendData,
  useCohortTrendQuery,
} from "~/store/mystore/customerLtv.redux";
import React, { ReactChild, memo, useCallback, useMemo, useState } from "react";
import {
  formatCurrency,
  formatCurrencyRounded,
  getCurrencyByCountryCode,
} from "~/utils/currencyUtils";

import { INTERVAL } from "~/store/utils/dateTimeUtils";
import Panel from "~/components/panel/panel";
import { Range } from "~/typedef/store";
import Table from "~/components/adTable/table";
import { TextCell } from "~/components/table/cells/textCell";
import moment from "moment-timezone";
import { numberWithCommas } from "~/utils/utils";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface CLVCohortPurchaseBehaviourProps {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  countryCode: string;
  currentCurrency: string;
  currentRange: Range;
  timezone: string;
  actions?: ReactChild;
}

export const DEFAULT_SORT_KEY = "cohort";
export const DEFAULT_SORT_ORDER = "desc";

const CLVCohortPurchaseBehaviour = memo<CLVCohortPurchaseBehaviourProps>(
  function CLVCohortPurchaseBehaviour({
    mid,
    marketplaceType,
    marketplaceSubtype,
    countryCode,
    currentCurrency,
    currentRange,
    timezone,
    actions,
  }) {
    const { t } = useTranslation();

    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );

    const homeCurrency = useMemo(
      () => getCurrencyByCountryCode[countryCode],
      [countryCode]
    );

    const [sortingOrder, setSortingOrder] =
      useState<string>(DEFAULT_SORT_ORDER);

    const columns = useMemo(
      () => [
        {
          Header: t("clvPurchaseBehaviour.cohortColumn"),
          align: "center",
          cellJustify: "center" as const,
          id: "cohort",
          accessor: (row: FetchCohortTrendData) => {
            if (
              currentRange.interval === INTERVAL.WEEKS ||
              currentRange.interval === INTERVAL.DAYS
            ) {
              return moment
                .unix(row.startTime)
                .tz(timezone)
                .format("DD-MM-YYYY");
            }

            return moment.unix(row.startTime).tz(timezone).format("MMM-YY");
          },
          Cell: TextCell,
          isVisible: true,
          divideRight: true,
        },
        {
          Header: t("clvPurchaseBehaviour.totalRevenueColumn"),
          align: "center",
          cellJustify: "center" as const,
          id: "totalRevenue",
          accessor: (row: FetchCohortTrendData) =>
            row.totalRevenue
              ? formatCurrencyRounded(
                  row.totalRevenue,
                  currencyRates,
                  homeCurrency,
                  currentCurrency
                )
              : "-",
          Cell: TextCell,
          disableSortBy: true,
          isVisible: true,
        },
        {
          Header: t("clvPurchaseBehaviour.totalRepeatRevenueColumn"),
          align: "center",
          cellJustify: "center" as const,
          id: "totalRepeatRevenue",
          accessor: (row: FetchCohortTrendData) =>
            row.totalRevenueRepeatCustomers
              ? formatCurrencyRounded(
                  row.totalRevenueRepeatCustomers,
                  currencyRates,
                  homeCurrency,
                  currentCurrency
                )
              : "-",
          Cell: TextCell,
          disableSortBy: true,
          isVisible: true,
        },
        {
          Header: t("clvPurchaseBehaviour.repeatRevenuePercentageColumn"),
          align: "center",
          cellJustify: "center" as const,
          id: "repeatRevenuePercentage",
          accessor: (row: FetchCohortTrendData) =>
            row.totalRevenueRepeatCustomers && row.totalRevenue > 0
              ? `${(
                  (row.totalRevenueRepeatCustomers / row.totalRevenue) *
                  100
                ).toFixed(1)}%`
              : "-",
          Cell: TextCell,
          divideRight: true,
          disableSortBy: true,
          isVisible: true,
        },
        {
          Header: t("clvPurchaseBehaviour.totalNumberOfCustomers"),
          align: "center",
          cellJustify: "center" as const,
          id: "totalNumberOfCustomers",
          accessor: (row: FetchCohortTrendData) =>
            numberWithCommas(row.distinctCustomersCount),
          Cell: TextCell,
          disableSortBy: true,
          isVisible: true,
        },
        {
          Header: t("clvPurchaseBehaviour.totalNumberOfRepeatCustomers"),
          align: "center",
          cellJustify: "center" as const,
          id: "totalNumberOfRepeatCustomers",
          accessor: (row: FetchCohortTrendData) =>
            numberWithCommas(row.distinctRepeatCustomersCount),
          Cell: TextCell,
          disableSortBy: true,
          isVisible: true,
        },
        {
          Header: t("clvPurchaseBehaviour.repeatCustomersPercentageColumn"),
          align: "center",
          cellJustify: "center" as const,
          id: "repeatCustomersPercentage",
          accessor: (row: FetchCohortTrendData) =>
            row.distinctRepeatCustomersCount && row.distinctCustomersCount > 0
              ? `${(
                  (row.distinctRepeatCustomersCount /
                    row.distinctCustomersCount) *
                  100
                ).toFixed(1)}%`
              : "-",
          Cell: TextCell,
          divideRight: true,
          disableSortBy: true,
          isVisible: true,
        },
        {
          Header: t("clvPurchaseBehaviour.averageLtvColumn"),
          align: "center",
          cellJustify: "center" as const,
          id: "averageLtv",
          accessor: (row: FetchCohortTrendData) =>
            row.averageLTV
              ? formatCurrency(
                  row.averageLTV,
                  currencyRates,
                  homeCurrency,
                  currentCurrency
                )
              : "-",
          Cell: TextCell,
          disableSortBy: true,
          isVisible: true,
        },
        {
          Header: t("clvPurchaseBehaviour.averageRepeatLtvColumn"),
          align: "center",
          cellJustify: "center" as const,
          id: "averageRepeatLtv",
          accessor: (row: FetchCohortTrendData) =>
            row.averageRepeatLTV
              ? formatCurrency(
                  row.averageRepeatLTV,
                  currencyRates,
                  homeCurrency,
                  currentCurrency
                )
              : "-",
          Cell: TextCell,
          divideRight: true,
          disableSortBy: true,
          isVisible: true,
        },
        {
          Header: t("clvPurchaseBehaviour.adSpendColumn"),
          align: "center",
          cellJustify: "center" as const,
          id: "adSpend",
          accessor: (row: FetchCohortTrendData) =>
            row.adSpend
              ? formatCurrency(
                  row.adSpend,
                  currencyRates,
                  homeCurrency,
                  currentCurrency
                )
              : "-",
          Cell: TextCell,
          disableSortBy: true,
          isVisible: true,
        },
        {
          Header: t("clvPurchaseBehaviour.cacColumn"),
          align: "center",
          cellJustify: "center" as const,
          id: "cac",
          accessor: (row: FetchCohortTrendData) =>
            row.cac
              ? formatCurrency(
                  row.cac,
                  currencyRates,
                  homeCurrency,
                  currentCurrency
                )
              : "-",
          Cell: TextCell,
          isVisible: true,
          disableSortBy: true,
        },
        {
          Header: t("clvPurchaseBehaviour.ltvCacRatio"),
          align: "center",
          cellJustify: "center" as const,
          id: "ltvCacRatio",
          accessor: (row: FetchCohortTrendData) =>
            !row.ltvCacRatio ? "-" : `${row.ltvCacRatio.toFixed(2)}x`,
          Cell: TextCell,
          disableSortBy: true,
          divideRight: true,
          isVisible: true,
        },
        {
          Header: t("clvPurchaseBehaviour.repeatPurchasesColumn"),
          align: "center",
          cellJustify: "center" as const,
          id: "repeatPurchases",
          accessor: (row: FetchCohortTrendData) =>
            numberWithCommas(row.repeatPurchases || 0),
          Cell: TextCell,
          disableSortBy: true,
          isVisible: true,
        },
        {
          Header: t("clvPurchaseBehaviour.purchaseFrequencyColumn"),
          align: "center",
          cellJustify: "center" as const,
          id: "purchaseFrequency",
          accessor: (row: FetchCohortTrendData) =>
            row.purchaseFrequency
              ? `${row.purchaseFrequency.toFixed(1)}x`
              : "-",
          Cell: TextCell,
          disableSortBy: true,
          isVisible: true,
        },
      ],
      [currencyRates, homeCurrency, currentRange.interval, currentCurrency]
    );

    const fetchData = useCallback(({ sortBy }) => {
      setSortingOrder(
        sortBy && sortBy.length > 0
          ? sortBy[0].desc === true
            ? "desc"
            : "asc"
          : DEFAULT_SORT_ORDER
      );
    }, []);

    const { isLoadingCohortTrend, chartData } = useCohortTrendQuery(
      {
        mid,
        marketplaceType,
        marketplaceSubtype,
        currentRange,
        sortKey: DEFAULT_SORT_KEY,
        sortOrder: sortingOrder,
      },
      {
        selectFromResult: ({ data, isFetching }) => {
          return {
            isLoadingCohortTrend: isFetching,
            chartData: data?.data || [],
          };
        },
      }
    );

    return (
      <Panel
        id="widget-clv-cohort-behaviour"
        title={t("clvWidgetCohortBehaviour.title")}
        tooltip={t("clvWidgetCohortBehaviour.mainTooltip")}
        actions={actions}
        content={
          <Table
            columns={columns}
            data={chartData}
            fetchData={fetchData}
            loading={isLoadingCohortTrend}
            pagination={false}
            sorting={true}
            pageCount={1}
            pageSize={1}
          />
        }
      />
    );
  }
);

export default CLVCohortPurchaseBehaviour;
