import { Box, Typography } from "@material-ui/core";
import {
  CustomGroupsMetrics,
  VendorGroupMetrics,
  useDeleteCustomGroupMutation,
  useUploadCustomGroupsFileMutation,
} from "~/store/customGroups/reducer.redux";
import React, { memo, useCallback, useEffect, useState } from "react";
import {
  getCurrencyByCountryCode,
  getExchangeRate,
} from "~/utils/currencyUtils";

import ColumnSelect from "~/components/adTable/columnSelect";
import ConfirmDialog from "~/components/dialogs/confirmDialog";
import { CustomGroup } from "@typedef/customGroups";
import CustomGroupsModal from "./customGroupsModal";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import { DemoTooltip } from "~/components/tooltip/demoTooltip";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import FlatTable from "~/components/table/table";
import Panel from "~/components/panel/panel";
import { Range } from "@typedef/store";
import SearchFilter from "~/components/adTable/searchFilter";
import SmallButton from "~/components/buttons/smallButton";
import SyncDialog from "./syncDialog";
import Table from "~/components/adTable/table";
import TemplateDialog from "./templateDialog";
import { isEmpty } from "lodash";
import { stripFilteredSuffix } from "~/utils/marketplaceUtils";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const StyledButton = styled(SmallButton)`
  margin-right: 1rem;
`;

type CustomGroupEntity = {
  groupId: number;
  groupName: string;
};

interface CustomGroupsTableProps {
  mid: string;
  marketplace: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  currentCurrency: string;
  selectedGroups: CustomGroup[];
  customGroupsMetrics: (CustomGroupsMetrics | VendorGroupMetrics)[];
  customGroupsMetricsFetching: boolean;
  customGroupsMetricsRowCount: number;
  searchText: string;
  setSearchText: (text: string) => void;
  fetchData: ({
    pageSize,
    pageIndex,
  }: {
    pageSize: number;
    pageIndex: number;
  }) => void;
  countryCode: string;
  shopName: string;
  report?: boolean;
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  markedForDelete: CustomGroupEntity | null;
  setMarkedForDelete: (value: CustomGroupEntity | null) => void;
  markedForEdit: CustomGroupEntity | null;
  setMarkedForEdit: (value: CustomGroupEntity | null) => void;
  tableColumns: any[];
  customTableActions?: JSX.Element;
  customReportTitle?: string;
  reportType?: string;
  reportPath?: string;
  ungroupedProductCount?: number;
  pageSize: number;
}

const CustomGroupsTable = memo<CustomGroupsTableProps>(
  function CustomGroupsTable({
    mid,
    marketplace,
    currentRange,
    currentCurrency,
    selectedGroups,
    customGroupsMetrics,
    customGroupsMetricsFetching,
    customGroupsMetricsRowCount,
    setSearchText,
    fetchData,
    countryCode,
    shopName,
    report,
    openModal,
    setOpenModal,
    markedForDelete,
    setMarkedForDelete,
    markedForEdit,
    setMarkedForEdit,
    tableColumns,
    customTableActions,
    customReportTitle,
    reportType,
    reportPath,
    ungroupedProductCount,
    pageSize,
  }) {
    const { t } = useTranslation();

    const userInfo = useTypedSelector((state) => state.user);
    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );
    const exchangeRate = getExchangeRate(
      currencyRates,
      getCurrencyByCountryCode[countryCode],
      currentCurrency
    );
    const includeTax = useTypedSelector((state) =>
      Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
    );

    const [syncDialogOpen, setSyncDialogOpen] = useState(false);
    const [templateDialogOpen, setTemplateDialogOpen] = useState(false);
    const [data, setData] = useState(customGroupsMetrics);
    const [columns, setColumns] = useState([] as any[]);

    const [
      uploadCustomGroupsFile,
      { customGroupsUploading, customGroupsUploadMissingProducts },
    ] = useUploadCustomGroupsFileMutation({
      selectFromResult: ({ data, isLoading }) => ({
        customGroupsUploading: isLoading,
        customGroupsUploadResponseStatus: data?.result.status,
        customGroupsUploadResponseMessage: data?.result.message,
        customGroupsUploadMissingProducts: data?.result.productsNotFound,
      }),
    });
    const customGroupsUploadHandler = useCallback(
      (mid: string, file: File) => {
        uploadCustomGroupsFile({
          mid,
          file,
          successMessage: t("customGroups.uploadSuccessMessage"),
          inProgressMessage: t("customGroups.uploadInProgressMessage"),
        });
      },
      [uploadCustomGroupsFile, t]
    );

    const [deleteCustomGroup] = useDeleteCustomGroupMutation();

    useEffect(() => {
      if (!isEmpty(selectedGroups) && !isEmpty(customGroupsMetrics)) {
        setData(customGroupsMetrics);
      } else if (!isEmpty(data)) {
        setData([]);
      }
    }, [selectedGroups, customGroupsMetrics]);

    useEffect(() => {
      setColumns(tableColumns);
    }, [tableColumns]);

    const onDelete = () => {
      if (markedForDelete) {
        deleteCustomGroup({
          mid,
          groupId: markedForDelete.groupId,
          successMessage: t("customGroups.deleteSuccessMessage"),
        });
        setMarkedForDelete(null);
      }
    };

    const onEdit = async () => {
      setMarkedForEdit(null);
    };

    const isVendorStore = stripFilteredSuffix(marketplace) === "amazon_vendor";

    return (
      <>
        <SyncDialog
          mid={mid}
          open={syncDialogOpen}
          onClose={() => {
            setSyncDialogOpen(false);
          }}
          onConfirm={customGroupsUploadHandler}
          loading={customGroupsUploading}
          unsyncedItems={customGroupsUploadMissingProducts}
          errorLabel={t("customGroups.productsNotFound")}
          warningLabel={t("customGroups.uploadWarning")}
        />
        <TemplateDialog
          mid={mid}
          open={templateDialogOpen}
          onClose={() => {
            setTemplateDialogOpen(false);
          }}
        />
        <Panel
          id="widget-custom-groups-table"
          title={
            customReportTitle
              ? customReportTitle
              : isVendorStore
              ? t("customGroups.productGroupsTable.mainTitle")
              : t("nav.salesByGroup")
          }
          subtitle={
            ungroupedProductCount && Number(ungroupedProductCount) != 0
              ? t("customGroups.ungroupedProductMessage", {
                  count: ungroupedProductCount,
                })
              : undefined
          }
          content={
            report ? (
              <FlatTable
                {...{
                  columns,
                  data,
                  fetchData,
                  loading: customGroupsMetricsFetching,
                  sorting: false,
                  pagination: false,
                  pageSize,
                  isReport: report,
                }}
              />
            ) : (
              <Table
                {...{
                  columns,
                  data,
                  fetchData,
                  loading: customGroupsMetricsFetching,
                  sorting: false,
                  pagination: true,
                  pageCount: Math.ceil(
                    (customGroupsMetricsRowCount || 0) / pageSize
                  ),
                  pageSize,
                }}
              />
            )
          }
          actions={
            report ? undefined : (
              <>
                {customTableActions ? (
                  customTableActions
                ) : (
                  <>
                    <StyledButton
                      variant="outlined"
                      color="info"
                      onClick={() => setTemplateDialogOpen(true)}
                    >
                      {t("customGroups.downloadButtonLabel")}
                    </StyledButton>
                    {userInfo.isDemoMode ? (
                      <DemoTooltip
                        arrow
                        placement="top"
                        open
                        title={`${t("generic.notAvailableDemoMode")}`}
                      >
                        <span>
                          <StyledButton
                            variant="contained"
                            color="info"
                            disabled={true}
                          >
                            {t("customGroups.uploadButtonLabel")}
                          </StyledButton>
                        </span>
                      </DemoTooltip>
                    ) : (
                      <StyledButton
                        variant="contained"
                        color="info"
                        onClick={() => setSyncDialogOpen(true)}
                      >
                        {t("customGroups.uploadButtonLabel")}
                      </StyledButton>
                    )}
                    <StyledButton
                      color="info"
                      onClick={() => setOpenModal(true)}
                    >
                      {t("customGroups.createGroup")}
                    </StyledButton>
                  </>
                )}
                <SearchFilter setSearchText={setSearchText} />
                <Box pl={2}>
                  <ColumnSelect
                    {...{ columns: columns, setColumns: setColumns }}
                  />
                </Box>
                <Box pl={1}>
                  <DownloadCsv
                    {...{
                      mid,
                      reportType: reportType || "customGroups",
                      path: reportPath || "/api/generic/customGroups",
                      params: {
                        ...currentRange,
                        includeTax,
                        selectedGroups,
                        shopName: shopName,
                        marketplaceName: marketplace,
                        countryCode: countryCode,
                        currentCurrency,
                        exchangeRate,
                        currencyRates,
                      },
                    }}
                  />
                </Box>
              </>
            )
          }
        />
        <CustomGroupsModal
          mid={mid}
          marketplace={marketplace}
          openModal={openModal}
          onEdit={onEdit}
          groupId={markedForEdit?.groupId}
          setOpenModal={setOpenModal}
          setMarkedForEdit={setMarkedForEdit}
        />
        <ConfirmDialog
          open={Boolean(markedForDelete)}
          title={t("generic.confirmTitle")}
          onClose={() => setMarkedForDelete(null)}
          onConfirm={onDelete}
          content={
            <>
              <Typography variant="body1">
                {t("customGroups.removeConfirmMessage", {
                  groupName: markedForDelete?.groupName,
                })}
              </Typography>
            </>
          }
        />
      </>
    );
  }
);

export default CustomGroupsTable;
