import {
  AppSettings,
  PersistentAppSetting,
  PersistentAppSettingState,
  UpsertPersistentAppSetting,
} from "~/typedef/store";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "./utils/dateTimeUtils";

import { Dispatch } from "redux";
import { User } from "~/typedef/user";
import axios from "axios";
import { baseUrl } from "~/configs";
import get from "lodash/get";
import { getNewRange } from "~/components/buttons/syncButton";
import isEmpty from "lodash/isEmpty";
import { isHttpResponseValid } from "./utils/httpsResponseCodes";
import moment from "moment-timezone";
import { setError } from "./globalToast.redux";

export const DEFAULT_CURRENCY = "USD";
export const DEFAULT_FILTER = {
  marketplaces: [],
  countries: [],
  tags: [],
};

export const DATETIME_PERIOD_LABELS = {
  TODAY: "dateRange.day",
  YESTERDAY: "dateRange.yesterday",
  WTD: "dateRange.week",
  WTDMTS: "dateRange.weekMts",
  LAST30: "dateRange.last30",
  MTD: "dateRange.monthToDate",
  CURRENTMONTH: "dateRange.currentMonth",
  LASTMONTH: "dateRange.lastMonth",
  CURRENTQ: "dateRange.currentQuarter",
  LASTQ: "dateRange.lastQuarter",
  LAST12: "dateRange.last12Months",
  YTD: "dateRange.year",
  CURRENTYEAR: "dateRange.currentYear",
  CUSTOM: "dateRange.custom",
};

export const COMPARISON_PERIOD_LABELS = {
  THISYEAR: "generic.comparisonPeriod.priorPeriod",
  LASTYEAR: "generic.comparisonPeriod.lastYear",
};

export const FETCH_PERSISTENT_APP_SETTING_FETCHING =
  "FETCH_PERSISTENT_APP_SETTING_FETCHING";

export const FETCH_PERSISTENT_APP_SETTING_FAILED =
  "FETCH_PERSISTENT_APP_SETTING_FAILED";

export const FETCH_PERSISTENT_APP_SETTING_SUCCESS =
  "FETCH_PERSISTENT_APP_SETTING_SUCCESS";

export const UPSERT_PERSISTENT_APP_SETTING_SUCCESS =
  "UPSERT_PERSISTENT_APP_SETTING_SUCCESS";

type FetchSettingFetchingAction = {
  type: typeof FETCH_PERSISTENT_APP_SETTING_FETCHING;
};

type FetchSettingFailedAction = {
  type: typeof FETCH_PERSISTENT_APP_SETTING_FAILED;
};

type FetchSettingSuccessfulAction = {
  type: typeof FETCH_PERSISTENT_APP_SETTING_SUCCESS;
  payload: {
    settings: AppSettings;
  };
};

type UpsertSettingSuccessfulAction = {
  type: typeof UPSERT_PERSISTENT_APP_SETTING_SUCCESS;
  payload: {
    settings: AppSettings;
  };
};

type PersistentAppSettingAction =
  | FetchSettingFetchingAction
  | FetchSettingFailedAction
  | FetchSettingSuccessfulAction
  | UpsertSettingSuccessfulAction;

const initState: PersistentAppSettingState = {
  all: { fetching: false, triggerRefetch: false },
  setting: {
    data: {
      includeTax: false,
      currentStore: {
        marketplace: "",
        merchantId: "",
        sourceSystemId: "",
        marketplaceCountry: "",
        storeName: "",
        isDemoMode: false,
      },
      currentFilter: DEFAULT_FILTER,
      currentRange: getDatesFromPeriod(
        DATETIME_PERIODS.LAST30,
        COMPARISON_PERIOD.THISYEAR,
        moment.tz.guess()
      ),
      currentPeriod: DATETIME_PERIODS.LAST30,
      currentCompare: COMPARISON_PERIOD.THISYEAR,
      timezone: moment.tz.guess(),
      currentCurrency: DEFAULT_CURRENCY,
    },
    fetching: false,
  },
};

export const persistentAppSettings = (
  state: PersistentAppSettingState = initState,
  action: PersistentAppSettingAction
): PersistentAppSettingState => {
  switch (action.type) {
    case FETCH_PERSISTENT_APP_SETTING_FETCHING:
      return {
        ...state,
        all: { ...state.all, fetching: true, triggerRefetch: false },
        setting: { ...state.setting, fetching: true },
      };
    case FETCH_PERSISTENT_APP_SETTING_FAILED:
      return {
        ...state,
        all: { ...state.all, fetching: false, triggerRefetch: false },
        setting: { ...state.setting, fetching: false },
      };
    case FETCH_PERSISTENT_APP_SETTING_SUCCESS:
      return {
        ...state,
        setting: {
          data: {
            ...state.setting.data,
            ...action.payload.settings,
          },
          fetching: false,
        },
      };
    case UPSERT_PERSISTENT_APP_SETTING_SUCCESS:
      return {
        ...state,
        setting: {
          data: {
            ...state.setting.data,
            ...action.payload.settings,
          },
          fetching: false,
        },
      };
    default:
      return state;
  }
};

export const fetchPersistentAppSettings =
  ({ user }: { user: User }) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_PERSISTENT_APP_SETTING_FETCHING });
    try {
      const { status, data } = await axios.get(
        `${baseUrl}/api/user-management-service/api/setting`,
        {
          params: {
            userId: user._id,
          },
        }
      );

      if (
        isHttpResponseValid(status) &&
        !isEmpty(data.persistentAppSetting?.settings)
      ) {
        const currentRange = get(
          data,
          "persistentAppSetting.settings.currentRange"
        );
        const currentPeriod =
          get(data, "persistentAppSetting.settings.currentPeriod") ||
          DATETIME_PERIODS.LAST30;

        const selectedTimezone =
          get(data, "persistentAppSetting.settings.timezone") ||
          moment.tz.guess();

        const currentCompare =
          get(data, "persistentAppSetting.settings.currentCompare") ||
          COMPARISON_PERIOD.THISYEAR;

        const newData = { ...data };
        if (
          !isEmpty(currentRange) &&
          !isEmpty(currentPeriod) &&
          !isEmpty(selectedTimezone) &&
          !isEmpty(currentCompare)
        ) {
          newData.persistentAppSetting.settings.currentRange = getNewRange({
            currentPeriod,
            currentCompare,
            selectedTimezone,
            currentRange,
          });
        }
        return dispatch({
          type: FETCH_PERSISTENT_APP_SETTING_SUCCESS,
          payload: newData.persistentAppSetting,
        });
      } //do nothing if there are no settings
    } catch (e) {
      dispatch({ type: FETCH_PERSISTENT_APP_SETTING_FAILED });
      const err = get(e, "response.data.message");
      const msg = get(err, "data.error");
      const statusText = get(err, "statusText");
      return setError(
        dispatch,
        msg || statusText,
        get(err, "status"),
        "fetchPersistentAppSettings"
      );
    }
  };

export const upsertPersistentAppSettings = (
  newPersistentAppSetting: UpsertPersistentAppSetting,
  saveSettings: boolean = true
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPSERT_PERSISTENT_APP_SETTING_SUCCESS,
      payload: newPersistentAppSetting,
    });

    if (saveSettings) {
      try {
        const { status, data } = await axios.post(
          `${baseUrl}/api/user-management-service/api/setting`,
          newPersistentAppSetting
        );

        if (!isHttpResponseValid(status)) {
          return setError(
            dispatch,
            data,
            status,
            "updatePersistentAppSettings"
          );
        }
      } catch (e) {
        const err = get(e, "response.data.message");
        const msg = get(err, "data.error");
        const statusText = get(err, "statusText");
        return setError(
          dispatch,
          msg || statusText,
          get(err, "status"),
          "updatePersistentAppSettings"
        );
      }
    }
  };
};
