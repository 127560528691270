import { Box, Grid } from "@material-ui/core";
import React, { memo } from "react";

import { COMPARISON_PERIOD } from "~/store/utils/dateTimeUtils";
import GenericTotal from "~/components/totals/genericTotal";
import GenericTotalMoney from "~/components/totals/genericTotalMoney";
import Panel from "~/components/panel/panel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import { Range } from "~/typedef/store";
import moment from "moment-timezone";
import { useSubscribeAndSaveSummaryQuery } from "~/store/mystore/subscribeAndSave.redux";
import { useTranslation } from "react-i18next";

interface SubscribeAndSaveSummaryProps {
  mid: string;
  marketplaceType: string;
  marketplaceSubType?: string;
  countryCode: string;
  currentRange: Range;
  currentCurrency: string;
  currentCompare: COMPARISON_PERIOD;
  conditionalFormatting?: boolean;
  report?: boolean;
  condensed?: boolean;
}

const SubscribeAndSaveSummary = memo<SubscribeAndSaveSummaryProps>(
  function SubscribeAndSaveTrendChart({
    mid,
    marketplaceType,
    marketplaceSubType,
    countryCode,
    currentRange,
    currentCurrency,
    currentCompare,
    conditionalFormatting,
    report,
  }) {
    const { t } = useTranslation();

    const { snsSummaryData, homeCurrency, loading } =
      useSubscribeAndSaveSummaryQuery(
        {
          currentRange,
          mid,
          marketplaceType,
          marketplaceSubType,
          countryCode,
        },
        {
          selectFromResult: ({ data, isFetching }) => ({
            snsSummaryData: data,
            homeCurrency: data?.currency || "",
            loading: isFetching,
          }),
        }
      );

    const { snsRevenue, snsSubscribers, subscriberUnits, shippedRevenue } =
      snsSummaryData || {};

    return (
      <Panel
        id="widget-sns-summary"
        title={t(`myStoresWidget.snsSummary.mainTitle`)}
        subtitle={
          snsSummaryData
            ? t("myStoresWidget.snsSummary.timePeriod", {
                fromDate: moment
                  .unix(snsSummaryData.startDate)
                  .format("DD MMM YY"),
                toDate: moment
                  .tz(
                    moment.unix(snsSummaryData.endDate),
                    currentRange.timezone
                  )
                  .format("DD MMM YY"),
              })
            : undefined
        }
        content={
          <Box p={2}>
            {loading ? (
              <PanelLoading />
            ) : (
              <Grid container spacing={3}>
                {snsRevenue ? (
                  <Grid item xs={12} md={report ? 4 : 6}>
                    <GenericTotalMoney
                      {...snsRevenue}
                      currency={snsSummaryData?.currency}
                      currentCurrency={currentCurrency}
                      title={t(`snsSummary.snsRevenue`)}
                      tooltip={t(`snsSummary.snsRevenueTooltip`)}
                      comparisonPeriod={currentCompare}
                      condensedComparison
                      condensed
                      rounded
                      conditionalFormatting={conditionalFormatting}
                    />
                  </Grid>
                ) : null}
                {snsSubscribers && snsRevenue ? (
                  <Grid item xs={12} md={report ? 4 : 6}>
                    <GenericTotalMoney
                      current={
                        snsSubscribers?.current
                          ? snsRevenue?.current / snsSubscribers?.current
                          : 0
                      }
                      prior={
                        snsSubscribers?.prior
                          ? snsRevenue?.prior / snsSubscribers?.prior
                          : 0
                      }
                      currency={homeCurrency}
                      currentCurrency={currentCurrency}
                      title={t(`snsSummary.subscriberRevenue`)}
                      tooltip={t(`snsSummary.subscriberRevenueTooltip`)}
                      comparisonPeriod={currentCompare}
                      condensedComparison
                      condensed
                      rounded
                      conditionalFormatting={conditionalFormatting}
                    />
                  </Grid>
                ) : null}
                {snsSubscribers ? (
                  <Grid item xs={12} md={report ? 4 : 6}>
                    <GenericTotal
                      number={snsSubscribers?.current}
                      comparison={snsSubscribers}
                      title={t(`snsSummary.subscribers`)}
                      tooltip={t(`snsSummary.subscribersTooltip`)}
                      condensedComparison
                      condensed
                      conditionalFormatting={conditionalFormatting}
                    />
                  </Grid>
                ) : null}
                {subscriberUnits ? (
                  <Grid item xs={12} md={report ? 4 : 6}>
                    <GenericTotal
                      number={subscriberUnits?.current}
                      comparison={subscriberUnits}
                      title={t(`snsSummary.subscriberUnits`)}
                      tooltip={t(`snsSummary.subscriberUnitsTooltip`)}
                      condensedComparison
                      condensed
                      conditionalFormatting={conditionalFormatting}
                    />
                  </Grid>
                ) : null}
                {shippedRevenue && snsRevenue ? (
                  <Grid item xs={12} md={report ? 4 : 6}>
                    <GenericTotal
                      number={
                        shippedRevenue?.current
                          ? (snsRevenue?.current / shippedRevenue?.current) *
                            100
                          : 0
                      }
                      comparison={
                        shippedRevenue?.prior
                          ? {
                              prior:
                                (snsRevenue?.prior / shippedRevenue?.prior) *
                                100,
                            }
                          : { prior: 0 }
                      }
                      title={t(`snsSummary.snsPenetration`)}
                      tooltip={t(`snsSummary.snsPenetrationTooltip`)}
                      condensedComparison
                      condensed
                      conditionalFormatting={conditionalFormatting}
                      percentage
                    />
                  </Grid>
                ) : null}
                <Grid item xs={12} md={report ? 4 : 6}></Grid>
              </Grid>
            )}
          </Box>
        }
      />
    );
  }
);

export default SubscribeAndSaveSummary;
