import { Box, CircularProgress, Grid, useTheme } from "@material-ui/core";
import { DATETIME_PERIODS, INTERVAL } from "~/store/utils/dateTimeUtils";
import { Filter, Range } from "~/typedef/store";
import React, { memo, useMemo, useState } from "react";
import {
  getConvertedValue,
  getCurrencyByCountryCode,
  roundFractionalDigits,
} from "~/utils/currencyUtils";
import {
  useMarketingAllCampaignsChartQuery,
  useOrganicSalesQuery,
} from "~/store/mystore/marketing.redux";

import { CHART_TITLES } from "~/components/charts/chartUtils/chartUtils";
import Panel from "~/components/panel/panel";
import StackedBarAndLineChart from "~/components/charts/stackedBarChart/stackedBarAndLineChart";
import TableFilter from "~/components/panel/panelActions/tableFilter";
import { User } from "~/typedef/user";
import get from "lodash/get";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

interface OverviewChartPanelProps {
  userInfo: User;
  mid: string;
  countryCode: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  currentCurrency: string;
  currentFilter: Filter;
  timezone: string;
  includeTax: boolean;
  footerLink?: {
    url: any;
    external?: boolean;
    label?: string;
  };
  report?: boolean;
  condensed?: boolean;
  selectedMetric?: string;
}

const OverviewChartPanel = memo<OverviewChartPanelProps>(
  function OverviewChartPanel({
    userInfo,
    mid,
    countryCode,
    marketplaceType,
    marketplaceSubtype,
    currentPeriod,
    currentRange,
    currentCurrency,
    currentFilter,
    timezone,
    report,
    condensed,
    includeTax,
    selectedMetric,
  }) {
    const theme = useTheme();
    const { t } = useTranslation();
    const homeCurrency = getCurrencyByCountryCode[countryCode];
    const currencyRates = useSelector((state) =>
      get(state, "globalVar.currencyRates")
    );
    const [metric, setMetric] = useState(selectedMetric || "acos");

    const { data: organicSales, isLoading: isLoadingOrganicSales } =
      useOrganicSalesQuery({
        mid,
        filter: currentFilter,
        currentPeriod,
        currentRange: {
          ...currentRange,
          interval:
            currentRange.interval === INTERVAL.HOURS
              ? INTERVAL.DAYS
              : currentRange.interval,
        },
        includeTax,
        includeVendor: true, // Always include tax for advertising chart
        currency: currentCurrency,
      });

    const { data = [], isLoading: isLoadingMarketingAllCampaignsChart } =
      useMarketingAllCampaignsChartQuery({
        customerId: userInfo._id,
        mid,
        marketplaceType,
        marketplaceSubtype,
        timezone: timezone,
        priorFromDate: currentRange.priorFromDate,
        priorToDate: currentRange.priorToDate,
        reportDateTo: currentRange.toDate,
        reportDateFrom: currentRange.fromDate,
        interval: currentRange.interval,
        countryCode,
      });

    const chartData = useMemo(
      () =>
        data.map((entry) => {
          const matchingSales = organicSales?.chartData?.find(({ current }) => {
            return (
              moment
                .unix(current?.startTime)
                // set to midday to avoid weird boundary issues with timezones
                .add(currentRange.interval === INTERVAL.WEEKS ? 12 : 0, "h")
                .tz(timezone)
                .isSame(
                  moment(get(entry, "current.report_date")).tz(timezone),
                  currentRange.interval === INTERVAL.WEEKS ? "w" : "d"
                )
            );
          })?.current?.sales;

          const adSales = getConvertedValue(
            currencyRates,
            homeCurrency,
            currentCurrency,
            get(entry, "current.attributed_sales", 0)
          );
          const adSpend = getConvertedValue(
            currencyRates,
            homeCurrency,
            currentCurrency,
            get(entry, "current.cost", 0)
          );
          const totalSales = getConvertedValue(
            currencyRates,
            homeCurrency,
            currentCurrency,
            matchingSales
          );

          const acos = roundFractionalDigits(get(entry, "current.acos") || 0);

          return {
            reportDate: moment(get(entry, "current.report_date")).unix(),
            adSales: adSales,
            adSpend: adSpend,
            acos: acos,
            roas: acos > 0 ? 1 / (acos / 100) : 0,
            organicSales: Boolean(totalSales > adSales)
              ? totalSales - adSales
              : 0,
            tacos: totalSales
              ? (100 * get(entry, "current.cost")) / totalSales
              : 0,
          };
        }),
      [data, organicSales, currentCurrency]
    );

    const footerLink = {
      url: marketplaceLink(marketplaceType, mid, "marketingOverview"),
      label: t("generic.viewAllLink"),
    };

    const metricOptions = useMemo(
      () => [
        {
          value: "acos",
          label: t("advertisingDashboardWidget.adTable.acosColumn"),
        },
        {
          value: "tacos",
          label: t("advertisingDashboardWidget.adTable.tacosColumn"),
        },
        {
          value: "adSpend",
          label: t("advertisingDashboardWidget.adTable.adspend"),
        },
        {
          value: "roas",
          label: t("advertisingDashboardWidget.adTable.roasColumn"),
        },
      ],
      []
    );
    const metricColours: Record<string, string> = useMemo(() => {
      return {
        acos: theme.palette.primary.main,
        tacos: theme.palette.primary.dark,
        adSpend: theme.palette.error.main,
        roas: theme.palette.success.main,
      };
    }, [theme]);

    return (
      <Panel
        id="widget-marketing-campaign-chart"
        title={t("advertisingDashboardWidget.performanceTrend.mainTitle")}
        tooltip={t("advertisingDashboardWidget.performanceTrend.mainTooltip")}
        footerLink={!report && condensed ? footerLink : undefined}
        content={
          <Box p={2}>
            {isLoadingMarketingAllCampaignsChart || isLoadingOrganicSales ? (
              <CircularProgress />
            ) : (
              <Grid container spacing={1}>
                <Grid container item xs={12}>
                  <StackedBarAndLineChart
                    title={t(CHART_TITLES[currentPeriod])}
                    interval={currentRange.interval}
                    currentPeriod={currentPeriod}
                    currentCurrency={currentCurrency}
                    chartData={chartData}
                    isLoading={isLoadingMarketingAllCampaignsChart}
                    lineUnit={
                      metric === "adSpend"
                        ? "currency"
                        : ["acos", "tacos"].includes(metric)
                        ? "%"
                        : ""
                    }
                    line={{
                      key: metric,
                      colour:
                        metricColours[metric] ?? theme.palette.success.dark,
                      toFixed: 2,
                    }}
                    topBar={{
                      key: `organicSales`,
                      colour: theme.palette.disabled.main,
                      toFixed: 2,
                    }}
                    bottomBar={{
                      key: "adSales",
                      colour: theme.palette.secondary.main,
                      toFixed: 2,
                    }}
                    xKey="reportDate"
                    report={report}
                    timezone={timezone}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        }
        actions={
          !report ? (
            <TableFilter
              {...{
                width: "105px",
                options: metricOptions,
                currentValue: metric,
                handleChange: setMetric,
              }}
            />
          ) : undefined
        }
      />
    );
  }
);

export default OverviewChartPanel;
