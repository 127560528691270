import { Box, FormControlLabel } from "@material-ui/core";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
} from "~/store/utils/dateTimeUtils";
import { CurrentStore, Filter } from "~/typedef/store";
import React, { useState } from "react";
import SalesPerformanceLineChart, {
  CHART_TYPE,
} from "~/modules/widgets/salesPerformanceLineChart";
import {
  SettingsMenuItem,
  includeTaxSwitch,
} from "~/components/appSettings/menu";

import { DateRange } from "~/typedef/date";
import { FOREACST_TOGGLE } from "../overview/salesPerformance";
import { User } from "~/typedef/user";
import { useTranslation } from "react-i18next";

interface ForecastingCalendarProps {
  currentRange: DateRange;
  currentCurrency: string;
  userInfo: User;
  store: CurrentStore;
  currentPeriod: DATETIME_PERIODS;
  currentFilter: Filter;
  includeTax: boolean;
  currentCompare: COMPARISON_PERIOD;
}

const ForecastingAndSalesChart: React.FC<ForecastingCalendarProps> = ({
  currentRange,
  currentCurrency,
  userInfo,
  store,
  currentPeriod,
  currentFilter,
  currentCompare,
  includeTax,
}) => {
  const { t } = useTranslation();

  const [forecast, setForecast] = useState<boolean>(false);

  const forecastToggle: SettingsMenuItem = {
    title: t("salesPerformance.forecastingToggle"),
    checked: forecast,
    setChecked: (newValue: boolean) => setForecast(newValue),
    tooltip: t("includeTax.forecastingTooltip"),
  };

  const actions = (
    <>
      <Box pl="12px">
        <FormControlLabel
          control={includeTaxSwitch(forecastToggle)}
          label={
            forecastToggle.checked
              ? FOREACST_TOGGLE.Forecast
              : FOREACST_TOGGLE.Budget
          }
        />
      </Box>
    </>
  );

  return (
    <SalesPerformanceLineChart
      userInfo={userInfo}
      mid={store.merchantId}
      currentPeriod={currentPeriod}
      currentRange={currentRange}
      currentCurrency={currentCurrency}
      currentFilter={currentFilter}
      chartType={{
        value: CHART_TYPE.Sales,
        label: t("dashboardWidget.salesPerformance.salesOption"),
      }}
      includeTax={includeTax}
      timezone={currentRange.timezone}
      isLineChart={false}
      actions={actions}
      isForecast={forecast}
      customTitle={t("forecasting.chartTitle")}
    />
  );
};

export default ForecastingAndSalesChart;
