import {
  Box,
  Grid,
  InputAdornment,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { memo, useEffect, useMemo, useState } from "react";
import {
  SalesDemandSignalLevel,
  useInventoryReplenishmentSettingsQuery,
  useSetInventoryReplenishmentSettingsMutation,
} from "~/store/mystore/inventoryReplenishment.redux";

import ForecastWeightingSetting from "./forecastWeigtingSetting";
import { InlineIconButton } from "~/icons/inlineIconButton";
import LoadingButton from "~/components/buttons/loadingButton";
import Panel from "~/components/panel/panel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import { isNaN } from "lodash";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

interface ReplenishmentSettingProps {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  marketplaceCountry: string;
  extraActions?: JSX.Element;
}

const StyledTypography = styled(Typography)`
  margin: 8px 10px 8px 0;
`;

const SaveButton = styled(LoadingButton)`
  min-width: 100px;
`;

const StyledTextField = styled(TextField)`
  max-width: 100px;
`;

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
`;

const StyledTypographyForEdit = styled(Typography)`
  text-decoration: underline;
  cursor: pointer;
  ${({ theme }) => `
    color: ${theme.palette.link.secondary};
  `}
`;

export const DEFAULT_WAREHOUSE_LEAD_TIME = 30;
export const DEFAULT_DAYS_COVER_TARGET = 90;
export const DEFAULT_MIN_RESTOCK_CASES = 12;
export const DEFAULT_SALES_DEMAND_SIGNAL_LEVEL = SalesDemandSignalLevel.SKU;
export const DAYS_COVER_ATTRIBUTION_WINDOW = {
  "7d": 0,
  "30d": 100,
  "60d": 0,
  "90d": 0,
};

type InventoryReplenBasicConfig = {
  warehouseLeadTime: number;
  daysCoverTarget: number;
  minRestockCases: number;
  salesDemandSignalLevel: SalesDemandSignalLevel;
};

const ReplenishmentSetting = memo<ReplenishmentSettingProps>(
  function ReplenishmentSetting({
    mid,
    marketplaceType,
    marketplaceSubtype,
    marketplaceCountry,
    extraActions,
  }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const [openModal, setOpenModal] = useState(false);

    const { settings, updatedAt, inventoryReplenishmentFetching } =
      useInventoryReplenishmentSettingsQuery(
        {
          mid,
          marketplaceType,
          marketplaceSubtype,
          marketplaceCountry,
        },
        {
          selectFromResult: ({ data, isFetching }) => ({
            settings: data?.settings,
            updatedAt: data?.updatedAt,
            inventoryReplenishmentFetching: isFetching,
          }),
        }
      );

    const [
      updateInventoryReplenishmentSettings,
      { isLoading: inventoryReplenishmentSaving },
    ] = useSetInventoryReplenishmentSettingsMutation();

    const [baseSettings, setBaseSettings] =
      useState<InventoryReplenBasicConfig>({
        warehouseLeadTime:
          settings?.warehouseLeadTime || DEFAULT_WAREHOUSE_LEAD_TIME,
        daysCoverTarget: settings?.daysCoverTarget || DEFAULT_DAYS_COVER_TARGET,
        minRestockCases: settings?.minRestockCases || DEFAULT_MIN_RESTOCK_CASES,
        salesDemandSignalLevel:
          settings?.salesDemandSignalLevel || DEFAULT_SALES_DEMAND_SIGNAL_LEVEL,
      });

    const [daysCoverAttributionWindow, setDaysCoverAttributionWindow] =
      useState(
        settings?.daysCoverAttributionWindow || DAYS_COVER_ATTRIBUTION_WINDOW
      );

    const [isUserEvent, setIsUserEvent] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);

    const handleBaseInputChange = (
      key: keyof InventoryReplenBasicConfig,
      value: string
    ) => {
      const numberValue = value !== "" ? parseFloat(value) : 0;
      if (!isNaN(numberValue)) {
        setIsUserEvent(true);
        setBaseSettings((prev) => ({
          ...prev,
          [key]: Math.abs(numberValue),
        }));
      }
    };

    const handleDemandSignalToggleChange = (
      _event: React.ChangeEvent<HTMLInputElement>,
      checked: boolean
    ) => {
      setIsUserEvent(true);
      setBaseSettings((prev) => ({
        ...prev,
        salesDemandSignalLevel: checked
          ? SalesDemandSignalLevel.ASIN
          : SalesDemandSignalLevel.SKU,
      }));
    };

    useEffect(() => {
      if (isUserEvent) {
        setHasChanges(true);
      }
    }, [baseSettings]);

    const saveSettings = () => {
      setOpenModal(false);
      updateInventoryReplenishmentSettings({
        mid,
        marketplaceType,
        marketplaceSubtype,
        marketplaceCountry,
        settings: {
          ...baseSettings,
          daysCoverAttributionWindow,
        },
        lastUpdatedAt: updatedAt,
      });
      setHasChanges(false);
    };

    useEffect(() => {
      if (!inventoryReplenishmentFetching && !isUserEvent) {
        setBaseSettings({
          warehouseLeadTime:
            settings?.warehouseLeadTime || DEFAULT_WAREHOUSE_LEAD_TIME,
          daysCoverTarget:
            settings?.daysCoverTarget || DEFAULT_DAYS_COVER_TARGET,
          minRestockCases:
            settings?.minRestockCases || DEFAULT_MIN_RESTOCK_CASES,
          salesDemandSignalLevel:
            settings?.salesDemandSignalLevel ||
            DEFAULT_SALES_DEMAND_SIGNAL_LEVEL,
        });
        setDaysCoverAttributionWindow(
          settings?.daysCoverAttributionWindow || DAYS_COVER_ATTRIBUTION_WINDOW
        );
      }
    }, [inventoryReplenishmentFetching]);

    const textInputProps = useMemo<React.CSSProperties>(
      () => ({
        textAlign: "right",
        width: "75px",
        ...theme.typography.body2,
      }),
      [theme.typography]
    );

    return (
      <>
        <Panel
          id="replenishment-setting"
          title={t(`replenishmentSetting.title`)}
          tooltip={undefined}
          content={
            <Box p={2}>
              {inventoryReplenishmentFetching ? (
                <PanelLoading />
              ) : (
                <Grid container spacing={3}>
                  <Grid item container xs={12} justifyContent="space-between">
                    <StyledBox p={0}>
                      <StyledTypography variant="body1" display="inline">
                        {t(`replenishmentSetting.warehouseLeadTime`)}
                      </StyledTypography>
                      <Tooltip
                        title={t(
                          "replenishmentSetting.warehouseLeadTimeTooltip"
                        )}
                      >
                        <InlineIconButton />
                      </Tooltip>
                    </StyledBox>
                    <StyledTextField
                      size="small"
                      autoFocus
                      type="number"
                      disabled={inventoryReplenishmentSaving}
                      value={baseSettings.warehouseLeadTime
                        .toString()
                        .replace(/^0+/, "")}
                      variant="outlined"
                      color="primary"
                      inputProps={{
                        style: textInputProps,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {t(`replenishmentSetting.days`)}
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        handleBaseInputChange(
                          "warehouseLeadTime",
                          e.target.value
                        );
                      }}
                    />
                  </Grid>
                  <Grid item container xs={12} justifyContent="space-between">
                    <StyledBox p={0}>
                      <StyledTypography variant="body1" display="inline">
                        {t(`replenishmentSetting.daysCoverTarget`)}
                      </StyledTypography>
                      <Tooltip
                        title={t("replenishmentSetting.daysCoverTargetTooltip")}
                      >
                        <InlineIconButton />
                      </Tooltip>
                    </StyledBox>
                    <StyledTextField
                      size="small"
                      type="number"
                      disabled={inventoryReplenishmentSaving}
                      value={baseSettings.daysCoverTarget
                        .toString()
                        .replace(/^0+/, "")}
                      variant="outlined"
                      color="primary"
                      inputProps={{
                        style: textInputProps,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {t(`replenishmentSetting.days`)}
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        handleBaseInputChange(
                          "daysCoverTarget",
                          e.target.value
                        );
                      }}
                    />
                  </Grid>
                  <Grid item container xs={12} justifyContent="space-between">
                    <StyledBox p={0}>
                      <StyledTypography variant="body1" display="inline">
                        {t(`replenishmentSetting.minRestock`)}
                      </StyledTypography>
                      <Tooltip
                        title={t("replenishmentSetting.minRestockTooltip")}
                      >
                        <InlineIconButton />
                      </Tooltip>
                    </StyledBox>
                    <StyledTextField
                      size="small"
                      type="number"
                      disabled={inventoryReplenishmentSaving}
                      value={baseSettings.minRestockCases
                        .toString()
                        .replace(/^0+/, "")}
                      variant="outlined"
                      color="primary"
                      inputProps={{
                        style: textInputProps,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {t(`replenishmentSetting.cases`)}
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        handleBaseInputChange(
                          "minRestockCases",
                          e.target.value
                        );
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={6} container alignItems="center">
                      <StyledTypography variant="body1">
                        {t(`replenishmentSetting.daysCoverAttributionWindow`)}
                      </StyledTypography>
                      <Tooltip
                        title={t(
                          "replenishmentSetting.daysCoverAttributionWindowTooltip"
                        )}
                      >
                        <InlineIconButton />
                      </Tooltip>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      container
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Switch
                        color="secondary"
                        checked={
                          baseSettings.salesDemandSignalLevel ===
                          SalesDemandSignalLevel.ASIN
                        }
                        onChange={handleDemandSignalToggleChange}
                      />
                      <StyledTypography variant="body2">
                        {t(
                          `replenishmentSetting.salesDemandSignal${baseSettings.salesDemandSignalLevel}`
                        )}
                      </StyledTypography>
                    </Grid>
                    <Grid item xs={3} container justifyContent="flex-end">
                      <StyledTypographyForEdit
                        onClick={() => setOpenModal(true)}
                      >
                        {t("replenishmentSetting.editWeights")}
                      </StyledTypographyForEdit>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent="flex-end"
                    spacing={1}
                  >
                    {extraActions}
                    <Grid item>
                      <SaveButton
                        onClick={saveSettings}
                        data-testid="setting-save-button"
                        name="saveButton"
                        color="primary"
                        loading={inventoryReplenishmentSaving}
                        disabled={!hasChanges}
                      >
                        {t("replenishmentSetting.save")}
                      </SaveButton>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Box>
          }
        />
        <ForecastWeightingSetting
          openModal={openModal}
          inventoryReplenishmentSaving={inventoryReplenishmentSaving}
          setDaysCoverAttributionWindow={setDaysCoverAttributionWindow}
          daysCoverAttributionWindow={daysCoverAttributionWindow}
          setOpenModal={setOpenModal}
          saveSettings={saveSettings}
        />
      </>
    );
  }
);

export default ReplenishmentSetting;
