import { Box, Grid, Theme } from "@material-ui/core";
import {
  Fetched,
  Summary,
  fetchSuppressedProductsSummary,
} from "~/store/mystore/suppressedProducts.redux";
import HorizontalBarChart, {
  HorizontalBarChartProps,
} from "~/components/charts/horizontalBarChart/horizontalBarChart";
import React, { memo, useEffect, useRef, useState } from "react";
import SuppressedProductsTable, {
  AmazonProductCell,
  CommonTableData,
  ProductCell,
  SuppressedProductsTableProps,
} from "~/modules/marketplaceListings/suppressedProducts/suppressedProductsTable";
import { TFunction, useTranslation } from "react-i18next";
import { marketplaceLink, stripFilteredSuffix } from "~/utils/marketplaceUtils";

import Bold from "~/components/typography/bold";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import DrawerPanel from "~/components/drawerPanel/drawerPanel";
import { IssueGroup } from "mm-utils-frontend";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import PageBlock from "~/components/containers/sideNavPageBlock";
import Panel from "~/components/panel/panel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import SidePanel from "~/components/drawerPanel/sidePanel";
import SmallButton from "~/components/buttons/smallButton";
import { StoreState } from "~/typedef/store";
import { StyledIcon } from "~/components/table/rows/disconnectedStore";
import { Subset } from "~/utils/typeUtils";
import SuppressedProductDetails from "~/modules/marketplaceListings/suppressedProducts/suppressedProductDetails";
import { SuppressedProductsMarketplaces } from "~/components/toolbars/sideNavigation/sideNavConstants";
import { SuppressedProductsStatus } from "~/modules/marketplaceListings/suppressedProducts/suppressedProductsStatus";
import get from "lodash/get";
import { getCurrencyByCountryCode } from "~/utils/currencyUtils";
import styled from "styled-components";
import { useCustomTheme } from "~/hooks/useCustomTheme";
import { useDispatch } from "react-redux";
import { useMarketplace } from "~/utils/navigationUtils";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const DrawerHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  padding-left: ${({ theme }) => theme.spacing(1)}px;
`;

const DrawerHeaderComponent: React.FC = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView();
    }
  }, [ref.current]);
  return <DrawerHeader ref={ref}>{props.children}</DrawerHeader>;
};

export type SuppressedProductsMarketplace = Subset<
  Marketplace,
  (typeof SuppressedProductsMarketplaces)[number]
>;

export const getIssueGroupMap = (
  theme: Theme
): Record<IssueGroup, { details: string; color: string }> => {
  return {
    Title: {
      details: "myStoresWidget.suppressedProducts.issueGroup.titleDetail",
      color: theme.palette.chart.lightBlue1,
    },
    Image: {
      details: "myStoresWidget.suppressedProducts.issueGroup.imageDetail",
      color: get(theme.palette.primary, "main") as unknown as string,
    },
    Attributes: {
      details: "myStoresWidget.suppressedProducts.issueGroup.attributeDetail",
      color: theme.palette.chart.lightBrown,
    },
    Description: {
      details: "myStoresWidget.suppressedProducts.issueGroup.descriptionDetail",
      color: theme.palette.chart.lightGreen1,
    },
    Other: {
      details: "myStoresWidget.suppressedProducts.issueGroup.otherDetail",
      color: theme.palette.chart.gray,
    },
  };
};

function buildBarProps(
  summary: Fetched<Summary>,
  t: TFunction<"translation">,
  theme: Theme
): HorizontalBarChartProps {
  const bars = [
    {
      title: t("myStoresWidget.suppressedProducts.barChart.titleGroup"),
      value:
        summary.issueGroups.find((i) => i.issueGroup === "Title")
          ?.defectCount ?? 0,
      color: getIssueGroupMap(theme)["Title"].color,
    },
    {
      title: t("myStoresWidget.suppressedProducts.barChart.imagesGroup"),
      value:
        summary.issueGroups.find((i) => i.issueGroup === "Image")
          ?.defectCount ?? 0,
      color: getIssueGroupMap(theme)["Image"].color,
    },
    {
      title: t("myStoresWidget.suppressedProducts.barChart.attributesGroup"),
      value:
        summary.issueGroups.find((i) => i.issueGroup === "Attributes")
          ?.defectCount ?? 0,
      color: getIssueGroupMap(theme)["Attributes"].color,
    },
    {
      title: t("myStoresWidget.suppressedProducts.barChart.descriptionGroup"),
      value:
        summary.issueGroups.find((i) => i.issueGroup === "Description")
          ?.defectCount ?? 0,
      color: getIssueGroupMap(theme)["Description"].color,
    },
  ];

  const other = summary.issueGroups.find((f) => f.issueGroup === "Other");
  if (other) {
    bars.push({
      title: t("myStoresWidget.suppressedProducts.barChart.otherGroup"),
      value: other.defectCount ?? 0,
      color: getIssueGroupMap(theme)["Other"].color,
    });
  }

  return {
    showPercent: true,
    chartTitle: t("myStoresWidget.suppressedProducts.barChart.title"),
    bars,
  };
}

export type DrawerContents = CommonTableData | null;

interface SuppressedProductsProps {
  fromDashboard?: boolean;
  showFooterLink?: boolean;
}

type FooterLink =
  | {
      url: any;
      label?: string;
    }
  | undefined;

const SuppressedProducts = memo<SuppressedProductsProps>(
  function SuppressedProducts({ fromDashboard, showFooterLink }) {
    const { t } = useTranslation();
    const theme = useCustomTheme();
    const store = useTypedSelector((state) =>
      get(state, "persistentAppSettings.setting.data.currentStore")
    );
    const [drawerOpen, setDrawerOpen] = useState(false);
    const marketplace = useMarketplace<SuppressedProductsMarketplace>();
    const [drawerContents, setDrawerContents] = useState<DrawerContents>(null);
    const [loadingProducts, setLoadingProducts] = useState(false);
    const [sortState, setSortState] = useState({
      sortKey: "lost_value",
      sortOrder: "desc",
    });

    const tableProps: SuppressedProductsTableProps = {
      setDrawerOpen,
      setDrawerContents,
      marketplace,
      setFetching: setLoadingProducts,
      setSortState,
    };

    const dispatch = useDispatch();
    useEffect(() => {
      if (store) {
        dispatch(
          fetchSuppressedProductsSummary({
            mid: store.merchantId,
            marketplace,
          })
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store?.merchantId, marketplace]);

    const summary = useTypedSelector(
      (state) => state.suppressedProducts.summary
    );

    const getSuppressedProductStatus = () => {
      return (
        <Grid item xs={12} md={6}>
          <Panel
            id="widget-suppressed-products-status"
            title={t("myStoresWidget-suppressedProducts-status-title")}
            tooltip={t("myStoresWidget-suppressedProducts-status-tooltip")}
            footerLink={footerLink}
            content={
              summary.fetched && store ? (
                <SuppressedProductsStatus
                  totalProducts={summary.suppressedProductCount}
                  totalProductsPercent={
                    summary.productCount > 0
                      ? (summary.suppressedProductCount /
                          summary.productCount) *
                        100
                      : 0
                  }
                  salesValue={{
                    amount: summary.lostValue,
                    currency:
                      getCurrencyByCountryCode[store.marketplaceCountry],
                  }}
                  inventory={summary.availableQuantity}
                />
              ) : (
                <PanelLoading />
              )
            }
          />
        </Grid>
      );
    };

    const footerLink: FooterLink =
      showFooterLink && store
        ? {
            url: marketplaceLink(
              marketplace,
              store?.merchantId,
              "suppressedproducts"
            ),
            label: t("generic.viewAllLink"),
          }
        : undefined;

    const getSuppressedIssueTypes = () => {
      return (
        <Grid item xs={12} md={6}>
          <Panel
            id="widget-suppressed-products-issue-types"
            title={t("myStoresWidget-suppressedProducts-issueTypes-title")}
            tooltip={t("myStoresWidget-suppressedProducts-issueTypes-tooltip")}
            content={
              summary.fetched ? (
                <Box p={2}>
                  <HorizontalBarChart {...buildBarProps(summary, t, theme)} />
                </Box>
              ) : (
                <PanelLoading />
              )
            }
          />
        </Grid>
      );
    };

    const getSuppressedProductsTable = () => {
      return store ? (
        <Grid item xs={12}>
          <DrawerPanel
            containerId="suppressed-drawer-container"
            open={drawerOpen}
            setOpen={setDrawerOpen}
            sidePanel={
              <SidePanel
                {...{
                  displayImage: false,
                  content: drawerContents ? (
                    <SuppressedProductDetails
                      marketplace={marketplace}
                      suppressedProductId={drawerContents.suppressedProductId}
                      mid={store.merchantId}
                    />
                  ) : (
                    <LoadingIndicator />
                  ),
                  handleClose: () => {
                    setDrawerOpen(false);
                    setDrawerContents(null);
                  },
                  headerComponent: drawerContents ? (
                    <DrawerHeaderComponent>
                      {stripFilteredSuffix(marketplace) === "amazon" ? (
                        <AmazonProductCell
                          cell={{ value: drawerContents!.product }}
                        />
                      ) : (
                        <ProductCell
                          cell={{ value: drawerContents!.product }}
                        />
                      )}
                      <a
                        href={
                          drawerContents.product.fixLink ??
                          drawerContents.product.link
                        }
                        target="_blank"
                      >
                        <SmallButton color="primary">
                          <Bold variant="body2">
                            {t("myStoresWidget.suppressedProducts.fix")}
                          </Bold>
                          <StyledIcon fontSize="small" />
                        </SmallButton>
                      </a>
                    </DrawerHeaderComponent>
                  ) : (
                    <LoadingIndicator />
                  ),
                }}
              />
            }
            mainPanel={
              <Panel
                id="widget-suppressed-products-table"
                title={t(
                  "myStoresWidget-suppressedProducts-suppressedProductsTable-title"
                )}
                tooltip={t(
                  "myStoresWidget-suppressedProducts-suppressedProductsTable-tooltip"
                )}
                content={<SuppressedProductsTable {...tableProps} />}
                actions={
                  <DownloadCsv
                    {...{
                      mid: store.merchantId,
                      reportType: "suppressedProducts",
                      path: "/api/generic/suppressedListings/products",
                      params: {
                        marketplace,
                        sortKey: sortState.sortKey,
                        sortOrder: sortState.sortOrder,
                        shopName: store.storeName,
                        countryCode: store.marketplaceCountry,
                      },
                    }}
                  />
                }
              />
            }
          />
        </Grid>
      ) : (
        <LoadingIndicator />
      );
    };
    return fromDashboard ? (
      summary.fetched && summary.suppressedProductCount > 0 ? (
        <Grid container spacing={2} style={{ margin: "0px 2px" }}>
          {getSuppressedProductStatus()}
          {getSuppressedIssueTypes()}
        </Grid>
      ) : null
    ) : (
      <PageBlock>
        <Grid container spacing={2}>
          {getSuppressedProductStatus()}
          {getSuppressedIssueTypes()}
          {getSuppressedProductsTable()}
        </Grid>
      </PageBlock>
    );
  }
);

export default SuppressedProducts;
