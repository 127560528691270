import { Box, CircularProgress, styled } from "@material-ui/core";
import ColumnSelect, { Column } from "~/components/adTable/columnSelect";
import { CurrentStore, Range } from "~/typedef/store";
import {
  ProductProfitabilityRow,
  useGetProductProfitabilityQuery,
  useUploadDirectCostFileMutation,
} from "~/store/mystore/vendorProfitability.redux";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  formatCurrency,
  getCurrencyByCountryCode,
  intFormatter,
} from "~/utils/currencyUtils";
import { get, isEmpty } from "lodash";

import AdTable from "~/components/adTable/table";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import { PaginationArgs } from "~/typedef/pagination";
import Panel from "@components/panel/panel";
import SearchFilter from "~/components/adTable/searchFilter";
import SmallButton from "~/components/buttons/smallButton";
import SyncDialog from "~/modules/profitLossProductTable/syncDialog";
import { TableCellProp } from "~/components/table/cellProps";
import TemplateDialog from "~/modules/widgets/vendorProfitability/templateDialog";
import { ValueCell } from "~/components/table/cells/valueCell";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface ProfitabilityProductProps {
  includeTax: boolean;
  currentCurrency: string;
  currentRange: Omit<Range, "priorToDate" | "priorFromDate">;
  store: CurrentStore;
  report?: boolean;
}

const CenteredBox = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  padding: "24px",
}));

const ActionButton = styled(SmallButton)(() => ({
  marginRight: "1rem",
}));

const ProfitabilityProduct = memo<ProfitabilityProductProps>(
  function ProfitabilityProduct({
    includeTax,
    currentCurrency,
    currentRange,
    store,
    report,
  }) {
    const { t } = useTranslation();
    const [uploadDirectCostFile, { isLoading }] =
      useUploadDirectCostFileMutation();

    const [templateDialogOpen, setTemplateDialogOpen] = useState(false);
    const [cogsUploadDialogOpen, setCogsUploadDialogOpen] = useState(false);

    const userInfo = useTypedSelector((state) => state.user);

    const homeCurrency = getCurrencyByCountryCode[store.marketplaceCountry];

    const currencyRates = useTypedSelector((state) =>
      get(state, "globalVar.currencyRates")
    );

    const currencyFormatter = useCallback(
      (value: number) =>
        isNaN(value) || value === 0.0
          ? "-"
          : isEmpty(currencyRates)
          ? `$${intFormatter.format(value)}` // only used in storybook
          : formatCurrency(value, currencyRates, homeCurrency, currentCurrency),
      [currencyRates, homeCurrency, currentCurrency]
    );

    const columns = useMemo(
      () => [
        {
          Header: t("myStoresWidget.profitabilityProduct.productColumn"),
          accessor: ({
            title,
            imageUrl,
            linkUrl,
            asin,
          }: ProductProfitabilityRow) => ({
            value: title,
            secondRowValue: `ASIN: ${asin}`,
            image: imageUrl,
            link: linkUrl,
            target: "_blank",
          }),
          id: "title",

          Cell: (
            props: TableCellProp<{
              value: string;
              secondRowValue: string;
              image: string;
              link: string;
              target: string;
            }>
          ) => <LinkAndImageCell {...props} colorVariant="external" />,
          isLocked: true,
          isVisible: true,
          sticky: "left",
          /* This must be in sync - The text wont truncate until ~500px so 
           setting this lower than 500 creates a shifting effect on the table when sorting */
          width: 510,
          customWidth: 510,
        },
        {
          Header: t("myStoresWidget.profitabilityProduct.shippedCogsColumn"),
          accessor: ({ shippedCogs }: ProductProfitabilityRow) =>
            currencyFormatter(shippedCogs),
          id: "shippedCogs",
          Cell: ValueCell,
          isVisible: true,
        },
        {
          Header: t(
            "myStoresWidget.profitabilityProduct.advertisingCostColumn"
          ),
          accessor: ({ advertisingCost }: ProductProfitabilityRow) =>
            currencyFormatter(advertisingCost),
          id: "advertisingCost",
          Cell: ValueCell,
          isVisible: true,
        },
        {
          Header: t("myStoresWidget.profitabilityProduct.cogs"),
          accessor: ({ cogs }: ProductProfitabilityRow) =>
            currencyFormatter(cogs),
          id: "cogs",
          Cell: ValueCell,
          isVisible: true,
        },
        {
          Header: t("myStoresWidget.profitabilityProduct.chargebacks"),
          accessor: ({ chargebacks }: ProductProfitabilityRow) =>
            currencyFormatter(chargebacks),
          id: "chargebacks",
          Cell: ValueCell,
          isVisible: true,
        },
        {
          Header: t("myStoresWidget.profitabilityProduct.coopDeductions"),
          accessor: ({ coopDeductions }: ProductProfitabilityRow) =>
            currencyFormatter(coopDeductions),
          id: "coopDeductions",
          Cell: ValueCell,
          isVisible: true,
        },
        {
          Header: t("myStoresWidget.profitabilityProduct.expenses"),
          accessor: ({ expenses }: ProductProfitabilityRow) =>
            currencyFormatter(expenses),
          id: "expenses",
          Cell: ValueCell,
          isVisible: true,
        },
        {
          Header: t("myStoresWidget.profitabilityProduct.profit"),
          accessor: ({ profit }: ProductProfitabilityRow) =>
            currencyFormatter(profit),
          id: "profit",
          Cell: ValueCell,
          isVisible: true,
        },
        {
          Header: t("myStoresWidget.profitabilityProduct.inventoryQuantity"),
          accessor: ({ inventoryQuantity }: ProductProfitabilityRow) =>
            inventoryQuantity,
          id: "inventoryQuantity",
          Cell: ValueCell,
          isVisible: true,
        },
        {
          Header: t("myStoresWidget.profitabilityProduct.unitsShipped"),
          accessor: ({ unitsShipped }: ProductProfitabilityRow) => unitsShipped,
          id: "unitsShipped",
          Cell: ValueCell,
          isVisible: true,
        },
        {
          Header: t("myStoresWidget.profitabilityProduct.snsUnits"),
          accessor: ({ snsUnits }: ProductProfitabilityRow) => snsUnits,
          id: "snsUnits",
          Cell: ValueCell,
          isVisible: true,
          disableSortBy: true,
        },
      ],
      [currentRange, currentCurrency]
    );

    const [searchText, setSearchText] = useState("");
    const [columnDef, setColumnDef] = useState<Column[]>(columns);

    useEffect(() => {
      setColumnDef((columnDef) => {
        return columns.map((column) => {
          if (
            columnDef.find((each) => each.id === column.id)?.isVisible === false
          ) {
            return {
              ...column,
              isVisible: false,
            };
          } else {
            return column;
          }
        });
      });
    }, [columns]);

    const [paginationParams, setPaginationParams] = useState<PaginationArgs>({
      pageSize: 5,
      pageIndex: 0,
      sortKey: "shippedCogs",
      sortOrder: "desc",
    });

    const setPage = useCallback(({ pageSize, pageIndex, sortBy }) => {
      setPaginationParams({
        pageIndex,
        pageSize,
        sortOrder:
          sortBy.length > 0 ? (sortBy[0].desc ? "desc" : "asc") : "desc",
        sortKey: sortBy.length > 0 ? sortBy[0].id : "shippedCogs",
      });
    }, []);

    const { products, loading } = useGetProductProfitabilityQuery(
      {
        mid: store.merchantId,
        marketplaceType: store.marketplace,
        marketplaceSubtype: store.marketplaceSubtype || store.marketplace,
        includeTax,
        countryCode: store.marketplaceCountry,
        currentRange,
        paginationParams,
        searchText,
      },
      {
        selectFromResult: ({ data, isFetching }) => ({
          products: data || { rows: [], count: 0 },
          loading: isFetching,
        }),
      }
    );

    const { rows, count } = products;

    return (
      <>
        <Panel
          id="vendor-product-profitability-table"
          title={t("myStoresWidget.vendor.productProfitability.title")}
          tooltip={t("myStoresWidget.vendor.productProfitability.tooltip")}
          content={
            !store ? (
              <CenteredBox>
                <CircularProgress />
              </CenteredBox>
            ) : (
              <AdTable
                columns={columnDef}
                data={rows}
                fetchData={setPage}
                loading={loading}
                sorting={true}
                pageSize={paginationParams.pageSize}
                pageCount={Math.ceil(count / paginationParams.pageSize)}
                pagination={true}
              />
            )
          }
          actions={
            !report ? (
              <>
                <ActionButton
                  style={{ marginRight: "1rem" }}
                  variant="outlined"
                  color="info"
                  onClick={() => setTemplateDialogOpen(true)}
                >
                  {t("profitability.downloadButtonLabel")}
                </ActionButton>
                <ActionButton
                  variant="outlined"
                  color="info"
                  onClick={() => setCogsUploadDialogOpen(true)}
                >
                  {t("profitability.uploadButtonLabel")}
                </ActionButton>
                <SearchFilter setSearchText={setSearchText} />
                <ColumnSelect
                  columns={columnDef}
                  setColumns={(columns) => setColumnDef(columns)}
                />
                <DownloadCsv
                  mid={store.merchantId}
                  reportType="profitabilityProducts"
                  path="/api/generic/vendorProfitability/products"
                  params={{
                    mid: store.merchantId,
                    customerId: userInfo._id,
                    marketplaceType: store.marketplace,
                    marketplaceSubtype:
                      store.marketplaceSubtype || store.marketplace,
                    shopName: store.storeName,
                    countryCode: store.marketplaceCountry,
                    currentCurrency,
                    includeTax,
                    currentRange,
                  }}
                />
              </>
            ) : undefined
          }
        />
        <TemplateDialog
          mid={store.merchantId}
          marketplaceType={store.marketplace}
          marketplaceSubtype={store.marketplaceSubtype || store.marketplace}
          open={templateDialogOpen}
          onClose={() => setTemplateDialogOpen(false)}
        />
        <SyncDialog
          open={cogsUploadDialogOpen}
          onClose={() => setCogsUploadDialogOpen(false)}
          onConfirm={(fromDate, file) => {
            uploadDirectCostFile({
              mid: store.merchantId,
              marketplaceType: store.marketplace,
              marketplaceSubtype: store.marketplaceSubtype || store.marketplace,
              countryCode: store.marketplaceCountry,
              fromDate: moment(fromDate).unix(),
              file,
              successMessage: t("vendorCogs.uploadSuccessMessage"),
            });
          }}
          isLoading={isLoading}
        />
      </>
    );
  }
);

export default ProfitabilityProduct;
