import React, { memo } from "react";
import { connect, useSelector } from "react-redux";

import AccountHealthComponent from "~/modules/overview/accountHealth";
import { Grid } from "@material-ui/core";
import PageBlock from "~/components/containers/sideNavPageBlock";
import PropTypes from "prop-types";
import get from "lodash/get";
import { useMarketplace } from "~/utils/navigationUtils";

const AccountHealth = memo(({ user }) => {
  const marketplace = useMarketplace();
  const store = useSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );

  return (
    <PageBlock>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <AccountHealthComponent market={marketplace} mid={store.merchantId} />
        </Grid>
      </Grid>
    </PageBlock>
  );
});

AccountHealth.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(AccountHealth);
