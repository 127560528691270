import { Box, Grid } from "@material-ui/core";
import React, { memo } from "react";

import { COMPARISON_PERIOD } from "~/store/utils/dateTimeUtils";
import GenericTotal from "~/components/totals/genericTotal";
import GenericTotalMoney from "~/components/totals/genericTotalMoney";
import Panel from "~/components/panel/panel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import { Range } from "~/typedef/store";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import { useTrafficAndConversionSummaryQuery } from "~/store/mystore/trafficAndConversion.redux";
import { useTranslation } from "react-i18next";

interface TrafficAndConversionTotalsProps {
  mid: string;
  marketplaceType: string;
  currentRange: Range;
  currentCurrency: string;
  currentCompare: COMPARISON_PERIOD;
  conditionalFormatting?: boolean;
  report?: boolean;
  condensed?: boolean;
}

const TrafficAndConversionTotals = memo(
  ({
    mid,
    marketplaceType,
    currentRange,
    currentCurrency,
    currentCompare,
    conditionalFormatting,
    report,
    condensed,
  }: TrafficAndConversionTotalsProps) => {
    const { t } = useTranslation();

    const { trafficAndConversionTotals, homeCurrency, loading } =
      useTrafficAndConversionSummaryQuery(
        {
          currentRange,
          mid,
        },
        {
          selectFromResult: ({ data, isFetching }) => ({
            trafficAndConversionTotals: data?.data,
            homeCurrency: data?.currency || "",
            loading: isFetching,
          }),
        }
      );

    const footerLink =
      condensed && !report
        ? {
            url: marketplaceLink(marketplaceType, mid, "trafficandconversion"),
            label: t("generic.viewAllLink"),
          }
        : undefined;

    return (
      <Panel
        id="widget-traffic-and-conversion-totals"
        title={t(`myStoresWidget.trafficAndConversionSummary`)}
        tooltip={
          marketplaceType === "walmart"
            ? t("trafficAndConversion.walmartTooltip")
            : undefined
        }
        footerLink={footerLink}
        content={
          <Box p={2}>
            {loading || !trafficAndConversionTotals ? (
              <PanelLoading />
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotal
                    number={trafficAndConversionTotals?.pageViews?.current}
                    comparison={{
                      prior: trafficAndConversionTotals?.pageViews?.prior,
                    }}
                    title={t(`trafficAndConversion.pageViews`)}
                    condensedComparison={true}
                    condensed={true}
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotalMoney
                    {...trafficAndConversionTotals?.orderedRevenue}
                    currency={homeCurrency}
                    currentCurrency={currentCurrency}
                    title={t(`trafficAndConversion.orderedRevenue`)}
                    tooltip={t(
                      `trafficAndConversion.orderedRevenueTooltip.${marketplaceType}`
                    )}
                    comparisonPeriod={currentCompare}
                    condensedComparison={true}
                    condensed={true}
                    rounded
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>

                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotal
                    number={trafficAndConversionTotals?.orderedUnits?.current}
                    comparison={{
                      prior: trafficAndConversionTotals?.orderedUnits?.prior,
                    }}
                    title={t(`trafficAndConversion.orderedUnits`)}
                    condensedComparison={true}
                    condensed={true}
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotalMoney
                    current={
                      trafficAndConversionTotals?.orderedRevenue?.current /
                      trafficAndConversionTotals?.orderedUnits?.current
                    }
                    prior={
                      trafficAndConversionTotals?.orderedRevenue?.prior /
                      trafficAndConversionTotals?.orderedUnits?.prior
                    }
                    currency={homeCurrency}
                    currentCurrency={currentCurrency}
                    title={t(`trafficAndConversion.avgPrice`)}
                    tooltip={t(
                      `trafficAndConversion.taxTooltip.${marketplaceType}`
                    )}
                    comparisonPeriod={currentCompare}
                    condensedComparison={true}
                    condensed={true}
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotal
                    number={
                      (trafficAndConversionTotals?.orderedUnits?.current /
                        trafficAndConversionTotals?.pageViews?.current) *
                      100
                    }
                    comparison={{
                      prior:
                        (trafficAndConversionTotals?.orderedUnits?.prior /
                          trafficAndConversionTotals?.pageViews?.prior) *
                        100,
                    }}
                    title={t(`trafficAndConversion.unitsPerView`)}
                    tooltip={t(`trafficAndConversion.unitsPerViewTooltip`)}
                    condensedComparison={true}
                    condensed={true}
                    percentage={true}
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
                <Grid item xs={12} md={report ? 4 : 6}></Grid>
              </Grid>
            )}
          </Box>
        }
      />
    );
  }
);

export default TrafficAndConversionTotals;
