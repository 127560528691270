import {
  TrafficAndConversionNormalVsB2BTotals,
  TrafficAndConversionParentsView,
  TrafficAndConversionProductTrend,
  TrafficAndConversionTotals,
  TrafficAndConversionTrend,
  TrafficAndConversionView,
  TrafficMixTrend,
} from "~/typedef/store";

import { Range } from "~/typedef/store";
import { api } from "@store/apiSlice";
import { baseUrl } from "~/configs";
import { globalQueryErrorHandler } from "@store/utils/errorHandlerUtils";

interface FetchTrafficAndConversionTrendArgs {
  mid: string;
  currentRange: Range;
}

interface FetchTrafficAndConversionTrendResponse {
  data: TrafficAndConversionTrend[];
  currency: string;
}

interface FetchTrafficAndConversionSummaryArgs {
  mid: string;
  currentRange: Range;
}

interface FetchTrafficAndConversionTotalsResponse {
  data: TrafficAndConversionTotals;
  currency: string;
}

interface FetchTrafficAndConversionArgs {
  mid: string;
  currentRange: Range;
  searchText?: string;
  sortKey: string;
  sortOrder: string;
  pageIndex: number;
  pageSize: number;
  view: "parents" | "skus";
}

interface FetchTrafficAndConversionResponse {
  parents: TrafficAndConversionParentsView;
  skus: TrafficAndConversionView;
  currency: string;
}

interface FetchTrafficAndConversionProductTrendArgs {
  mid: string;
  currentRange: Range;
  view: string;
  productId: number;
}

interface FetchTrafficAndConversionProductTrendResponse {
  data: TrafficAndConversionProductTrend[];
  currency: string;
}

interface FetchTrafficMixTrendArgs {
  mid: string;
  currentRange: Range;
}

interface FetchTrafficMixTrendResponse {
  data: TrafficMixTrend[];
  currency: string;
}

interface FetchTrafficAndConversionNormalVsB2BSummaryArgs {
  mid: string;
  currentRange: Range;
}

interface FetchTrafficAndConversionNormalVsB2BSummaryResponse {
  data: TrafficAndConversionNormalVsB2BTotals;
  currency: string;
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    trafficAndConversionTrend: build.query<
      FetchTrafficAndConversionTrendResponse,
      FetchTrafficAndConversionSummaryArgs
    >({
      query: (params) => {
        const { currentRange, mid } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/trafficAndConversion/trafficAndConversionTrend`,
          method: "POST",
          data: {
            mid,
            ...currentRange,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
      transformResponse: ({
        data,
        currency,
      }: FetchTrafficAndConversionTrendResponse) => ({ data, currency }),
    }),

    trafficAndConversionSummary: build.query<
      FetchTrafficAndConversionTotalsResponse,
      FetchTrafficAndConversionTrendArgs
    >({
      query: (params) => {
        const { currentRange, mid } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/trafficAndConversion/trafficAndConversionSummary`,
          method: "POST",
          data: {
            mid,
            ...currentRange,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
      transformResponse: ({
        data,
        currency,
      }: FetchTrafficAndConversionTotalsResponse) => ({ data, currency }),
    }),

    trafficAndConversion: build.query<
      FetchTrafficAndConversionResponse,
      FetchTrafficAndConversionArgs
    >({
      query: (params) => {
        const { currentRange, ...otherParams } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/trafficAndConversion`,
          method: "POST",
          data: {
            ...otherParams,
            ...currentRange,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
      transformResponse: ({
        parents,
        skus,
        currency,
      }: FetchTrafficAndConversionResponse) => ({ parents, skus, currency }),
    }),

    trafficAndConversionProductTrend: build.query<
      FetchTrafficAndConversionProductTrendResponse,
      FetchTrafficAndConversionProductTrendArgs
    >({
      query: (params) => {
        const { currentRange, ...otherParams } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/trafficAndConversion/trafficAndConversionProductTrend`,
          method: "POST",
          data: {
            ...otherParams,
            ...currentRange,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
      transformResponse: ({
        data,
        currency,
      }: FetchTrafficAndConversionProductTrendResponse) => ({ data, currency }),
    }),

    trafficMixTrend: build.query<
      FetchTrafficMixTrendResponse,
      FetchTrafficMixTrendArgs
    >({
      query: (params) => {
        const { currentRange, mid } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/trafficAndConversion/trafficAndConversionMixTrend`,
          method: "POST",
          data: {
            mid,
            ...currentRange,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
      transformResponse: ({
        data,
        currency,
      }: FetchTrafficMixTrendResponse) => ({ data, currency }),
    }),

    trafficAndConversionNormalVsB2BSummary: build.query<
      FetchTrafficAndConversionNormalVsB2BSummaryResponse,
      FetchTrafficAndConversionNormalVsB2BSummaryArgs
    >({
      query: (params) => {
        const { currentRange, mid } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/trafficAndConversion/trafficAndConversionNormalVsB2BSummary`,
          method: "POST",
          data: {
            mid,
            ...currentRange,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
      transformResponse: ({
        data,
        currency,
      }: FetchTrafficAndConversionNormalVsB2BSummaryResponse) => ({
        data,
        currency,
      }),
    }),
  }),
});

export const {
  useTrafficAndConversionTrendQuery,
  useTrafficAndConversionSummaryQuery,
  useTrafficAndConversionQuery,
  useTrafficAndConversionProductTrendQuery,
  useTrafficMixTrendQuery,
  useTrafficAndConversionNormalVsB2BSummaryQuery,
} = extendedApiSlice;
