import { Box, Grid, Typography } from "@material-ui/core";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import React, { memo, useEffect, useState } from "react";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import TrafficAndConversionTable, {
  TrafficAndConversionTableSelect,
  TrafficAndConversionTableView,
} from "~/modules/trafficAndConversion/trafficAndConversionTable";

import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import NeedToReconnectBanner from "~/components/alert/needToReconnect";
import PageBlock from "~/components/containers/sideNavPageBlock";
import SearchFilter from "~/components/adTable/searchFilter";
import { StoreState } from "~/typedef/store";
import TrafficAndConversionNormalVsB2BTotals from "~/modules/trafficAndConversion/trafficAndConversionNormalVsB2BTotals";
import TrafficAndConversionTotals from "~/modules/trafficAndConversion/trafficAndConversionTotals";
import TrafficAndConversionTrend from "~/modules/trafficAndConversion/trafficAndConversionTrend";
import TrafficMixTrend from "~/modules/trafficAndConversion/trafficMixTrend";
import get from "lodash/get";
import { getCurrencyByCountryCode } from "mm-utils-frontend";
import { getExchangeRate } from "~/utils/currencyUtils";
import moment from "moment-timezone";
import { useTrafficAndConversionQuery } from "~/store/mystore/trafficAndConversion.redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PAGE_SIZE = 20;

interface TrafficAndConversionProps {
  singleWidget?: "table" | "summary" | "trend" | "mixTrend" | "mixSummary";
  defaultView?: TrafficAndConversionTableView;
}

const TrafficAndConversion = memo(
  ({ singleWidget, defaultView }: TrafficAndConversionProps) => {
    const { t } = useTranslation();

    const store = useTypedSelector((state: StoreState) =>
      get(state, "persistentAppSettings.setting.data.currentStore")
    );
    const currentPeriod: DATETIME_PERIODS = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.currentPeriod") ||
        DATETIME_PERIODS.LAST30
    );
    const currentCompare: COMPARISON_PERIOD = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.currentCompare") ||
        COMPARISON_PERIOD.THISYEAR
    );
    const selectedTimezone: string = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.timezone") ||
        moment.tz.guess()
    );
    const currentRange = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.currentRange") ||
        getDatesFromPeriod(
          currentPeriod,
          currentCompare || COMPARISON_PERIOD.THISYEAR,
          selectedTimezone
        )
    );
    const currentCurrency = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.currentCurrency") ||
        DEFAULT_CURRENCY
    );
    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );
    const issues: string[] | undefined = useTypedSelector(
      (state: StoreState) => state?.issues?.data
    );

    const [canShowData, setCanShowData] = useState(true);
    const [searchText, setSearchText] = useState("");

    const [view, setView] = useState<TrafficAndConversionTableView>(
      defaultView || TrafficAndConversionTableSelect.SKUS
    );

    const { trafficAndConversionCount } = useTrafficAndConversionQuery(
      {
        mid: store?.merchantId || "", // it won't be undefined because of the skip
        currentRange,
        searchText,
        view: TrafficAndConversionTableSelect.SKUS,
        pageSize: PAGE_SIZE,
        pageIndex: 0,
        sortKey: "page_views",
        sortOrder: "desc",
      },
      {
        skip: !store,
        selectFromResult: ({ data }) => ({
          trafficAndConversionCount:
            data?.[TrafficAndConversionTableSelect.SKUS]?.count || 0,
        }),
      }
    );

    useEffect(() => {
      if (
        issues?.includes("noRetailAnalytics") &&
        trafficAndConversionCount > 0
      ) {
        setCanShowData(false);
      }
    }, [issues, trafficAndConversionCount]);

    if (!store) {
      return (
        <PageBlock>
          <LoadingIndicator />
        </PageBlock>
      );
    }

    const tableActions = (
      <>
        {store.marketplace === "amazon" && (
          <Box px={1}>
            <ToggleButtonGroup
              exclusive
              size={"small"}
              onChange={(_, v) => {
                if (v !== null) setView(v);
              }}
              value={view}
            >
              <ToggleButton value={TrafficAndConversionTableSelect.PARENTS}>
                <Typography variant="caption">
                  {t("trafficAndConversion.parentView")}
                </Typography>
              </ToggleButton>
              <ToggleButton value={TrafficAndConversionTableSelect.SKUS}>
                <Typography variant="caption">
                  {t("trafficAndConversion.childView")}
                </Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        )}
        <Box px={1}>
          <SearchFilter setSearchText={setSearchText} />
        </Box>
        <DownloadCsv
          {...{
            reportType: "trafficAndConversion",
            path: "/api/generic/trafficAndConversion",
            mid: store.merchantId,
            params: {
              fromDate: currentRange.fromDate,
              priorFromDate: currentRange.priorFromDate,
              toDate: currentRange.toDate,
              priorToDate: currentRange.priorToDate,
              timezone: currentRange.timezone,
              sortKey: "ordered_units",
              sortOrder: "desc",
              searchText,
              currentCurrency,
              exchangeRate: getExchangeRate(
                currencyRates,
                getCurrencyByCountryCode[store.marketplaceCountry],
                currentCurrency
              ),
              view,
            },
          }}
        />
      </>
    );

    if (singleWidget) {
      switch (singleWidget) {
        case "trend":
          return (
            <TrafficAndConversionTrend
              mid={store.merchantId}
              marketplaceType={store.marketplace}
              currentRange={currentRange}
              currentPeriod={currentPeriod}
              timezone={selectedTimezone}
              condensed={true}
            />
          );
        case "summary":
          return (
            <TrafficAndConversionTotals
              mid={store.merchantId}
              marketplaceType={store.marketplace}
              currentRange={currentRange}
              currentCompare={currentCompare}
              currentCurrency={currentCurrency}
              condensed={true}
            />
          );
        case "mixTrend":
          return (
            <TrafficMixTrend
              mid={store.merchantId}
              currentRange={currentRange}
              currentPeriod={currentPeriod}
              currentCurrency={currentCurrency}
              timezone={selectedTimezone}
            />
          );
        case "mixSummary":
          return (
            <TrafficAndConversionNormalVsB2BTotals
              mid={store.merchantId}
              currentRange={currentRange}
              currentCurrency={currentCurrency}
            />
          );
        case "table":
          return (
            <TrafficAndConversionTable
              mid={store.merchantId}
              marketplaceType={store.marketplace}
              currentRange={currentRange}
              currentPeriod={currentPeriod}
              currentCurrency={currentCurrency}
              searchText={searchText}
              view={view}
              pageSize={5}
              condensed={true}
              timezone={selectedTimezone}
            />
          );
        default:
          return <></>;
      }
    } else {
      return (
        <PageBlock>
          {canShowData ? (
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <TrafficAndConversionTrend
                  mid={store.merchantId}
                  marketplaceType={store.marketplace}
                  currentRange={currentRange}
                  currentPeriod={currentPeriod}
                  timezone={selectedTimezone}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TrafficAndConversionTotals
                  mid={store.merchantId}
                  marketplaceType={store.marketplace}
                  currentRange={currentRange}
                  currentCompare={currentCompare}
                  currentCurrency={currentCurrency}
                />
              </Grid>
              {store.marketplace === "amazon" && (
                <>
                  <Grid item xs={12} md={8}>
                    <TrafficMixTrend
                      mid={store.merchantId}
                      currentRange={currentRange}
                      currentPeriod={currentPeriod}
                      currentCurrency={currentCurrency}
                      timezone={selectedTimezone}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TrafficAndConversionNormalVsB2BTotals
                      mid={store.merchantId}
                      currentRange={currentRange}
                      currentCurrency={currentCurrency}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <TrafficAndConversionTable
                  mid={store.merchantId}
                  marketplaceType={store.marketplace}
                  currentRange={currentRange}
                  currentPeriod={currentPeriod}
                  currentCurrency={currentCurrency}
                  searchText={searchText}
                  view={view}
                  actions={tableActions}
                  pageSize={PAGE_SIZE}
                  timezone={selectedTimezone}
                />
              </Grid>
            </Grid>
          ) : (
            <NeedToReconnectBanner marketplace={store.marketplace} />
          )}
        </PageBlock>
      );
    }
  }
);

export default TrafficAndConversion;
