import { Box, Grid, useTheme } from "@material-ui/core";
import { Range, TrafficAndConversionProductTrend } from "~/typedef/store";
import React, { memo } from "react";

import { CHART_TITLES } from "~/components/charts/chartUtils/chartUtils";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import LineAndBarChart from "~/components/charts/comboChart/lineAndBarChart";
import { TrafficAndConversionTableView } from "./trafficAndConversionTable";
import { useTrafficAndConversionProductTrendQuery } from "~/store/mystore/trafficAndConversion.redux";
import { useTranslation } from "react-i18next";

interface TrafficAndConversionTableChartProps {
  mid: string;
  currentRange: Range;
  currentPeriod: DATETIME_PERIODS;
  view: TrafficAndConversionTableView;
  productId: number;
  timezone: string;
}

const formatTrafficAndConversionProductData = (
  trafficAndConversionProductData:
    | TrafficAndConversionProductTrend[]
    | undefined
) => {
  if (!trafficAndConversionProductData) {
    return [];
  }
  return trafficAndConversionProductData.map((data) => ({
    startTime: data.startTime,
    endTime: data.endTime,
    pageViews: Number.isFinite(data.pageViews)
      ? Math.round(data.pageViews)
      : data.pageViews,
    unitsPerView: Number.isFinite(data.unitsPerView)
      ? Math.min(Math.round(data.unitsPerView), 100.0)
      : data.unitsPerView,
    buyboxWinPercentage: Number.isFinite(data.buyboxWinPercentage)
      ? Math.round(data.buyboxWinPercentage)
      : data.buyboxWinPercentage,
  }));
};

const TrafficAndConversionTableChart = memo(
  ({
    mid,
    currentRange,
    currentPeriod,
    view,
    productId,
    timezone,
  }: TrafficAndConversionTableChartProps) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const { data, loading } = useTrafficAndConversionProductTrendQuery(
      {
        currentRange,
        mid,
        view,
        productId,
      },
      {
        selectFromResult: ({ data, isFetching }) => ({
          data: data?.data
            ? formatTrafficAndConversionProductData(data.data)
            : [],
          loading: isFetching,
        }),
      }
    );

    return (
      <Box p={1}>
        <Grid container spacing={1}>
          <Grid container item xs={12}>
            <LineAndBarChart
              title={t(CHART_TITLES[currentPeriod])}
              currentPeriod={currentPeriod}
              chartData={data}
              isLoading={loading}
              line={{
                key: "unitsPerView",
                colour: theme.palette.secondary.main,
                toFixed: 1,
              }}
              line2={{
                key: "buyboxWinPercentage",
                colour: theme.palette.chart.red,
                toFixed: 1,
              }}
              bar={{
                key: "pageViews",
                colour: theme.palette.primary.main,
              }}
              xKey="startTime"
              xKey2="endTime"
              report={true} // to make chart shorter
              timezone={timezone}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }
);

export default TrafficAndConversionTableChart;
