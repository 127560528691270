import { Box, IconButton, Paper, Switch, Typography } from "@material-ui/core";
import { ProfitAndLossData, ProfitAndLossTable } from "./profitAndLossTable";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  formatCurrency,
  getCurrencyByCountryCode,
  getExchangeRate,
} from "~/utils/currencyUtils";

import { CurrentStore } from "~/typedef/store";
import { DateRange } from "~/typedef/date";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import { StyledEditIcon } from "~/modules/buybox/editablePriceCell";
import { hasFilteredSuffix } from "~/utils/marketplaceUtils";
import moment from "moment";
import { numberWithCommas } from "~/utils/utils";
import styled from "styled-components";
import { useMarketplace } from "~/utils/navigationUtils";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import { useVendorChannelProfitAndLossQuery } from "~/store/mystore/vendorProfitability.redux";

interface VendorProfitAndLossProps {
  title: string;
  store: CurrentStore;
  currentRange: DateRange;
  currentCurrency: string;
  isComparison?: boolean;
  setChargebackUploadDialogOpen: (open: boolean) => void;
  setDeductionUpdateDialogOpen: (open: boolean) => void;
}

const PanelWrapper = styled(Paper)`
  overflow: hidden;
`;

const PanelHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
`;

const LoadingWrapper = styled(Box)`
  width: 100%;
  height: 200px;
`;

const FlexBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledIconButton = styled(IconButton)`
  margin-left: 0.5rem;
`;

interface MapToTableDataProps {
  data: Record<string, number>;
  total: number;
  units: number;
  currency: string;
  subCategory?: boolean;
  bold?: boolean;
  valueRow?: "percent" | "int";
  tooltip?: boolean | string;
  translate?: boolean;
  actions?: React.ReactNode;
}

const EditButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <StyledIconButton size="small" onClick={onClick}>
      <StyledEditIcon fontSize="small" />
    </StyledIconButton>
  );
};

const VendorProfitAndLoss = memo<VendorProfitAndLossProps>(
  function VendorProfitAndLoss({
    title,
    store,
    currentRange,
    currentCurrency,
    isComparison,
    setChargebackUploadDialogOpen,
    setDeductionUpdateDialogOpen,
  }) {
    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );

    const [showBreakdown, setShowBreakdown] = useState(false);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const userInfo = useTypedSelector((state) => state.user);

    const canUploadData =
      !store.isDemoMode && !hasFilteredSuffix(store.marketplace);

    const {
      income,
      expense,
      metrics,
      isFetchingVendorProfitAndLoss,
      currency,
      snsFromDate,
      snsToDate,
    } = useVendorChannelProfitAndLossQuery(
      {
        mid: store.merchantId,
        marketplaceType: store.marketplace,
        marketplaceSubtype: store.marketplaceSubtype || store.marketplace,
        countryCode: store.marketplaceCountry,
        currentRange,
        isComparison,
      },
      {
        selectFromResult: ({ data, isFetching }) => {
          return {
            ...data,
            currency: data?.currency || currentCurrency,
            isFetchingVendorProfitAndLoss: isFetching,
          };
        },
      }
    );

    useEffect(() => {
      // force artificial loading state to show loading indicator
      // when user toggles the detailed P&L, loading indicator makes the transition smoother
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 500); // 0.5 seconds
    }, [showBreakdown]);

    const mapToTableData = useCallback(
      ({
        data,
        total,
        units,
        currency,
        // subCategory - used to identify subcategory rows
        // subCategory rows has label right aligned
        subCategory,
        // bold - used to toggle bold font weight
        bold,
        // valueRow - used to identify rows with only the value column
        // without the percent income or value per unit columns
        // valueRow can be "percent" or "int"
        valueRow,
        // tooltip - used to add tooltip to the row's label
        // could be a string or boolean
        tooltip,
        // translate - used to toggle translation the row's label
        translate = true,
        // actions - used to add actions to the label column
        actions,
      }: MapToTableDataProps): ProfitAndLossData[] => {
        const commonMappings = {
          subCategory,
          bold,
          tooltip,
          translate,
          actions,
        };
        return Object.entries(data).map(([key, value]) => {
          if (valueRow) {
            return {
              key,
              value:
                valueRow === "int"
                  ? numberWithCommas(value)
                  : total
                  ? `${((value / total) * 100).toFixed(2)}%`
                  : "-",
              incomePercent: "",
              valuePerUnit: "",
              ...commonMappings,
            };
          }

          return value
            ? {
                key,
                value: formatCurrency(
                  value,
                  currencyRates,
                  currency,
                  currentCurrency
                ),
                incomePercent: total
                  ? `${((value / total) * 100).toFixed(2)}%`
                  : "-",
                valuePerUnit: units
                  ? formatCurrency(
                      value / units,
                      currencyRates,
                      currency,
                      currentCurrency
                    )
                  : "-",
                ...commonMappings,
              }
            : {
                key,
                value: "-",
                incomePercent: "-",
                valuePerUnit: "-",
                ...commonMappings,
              };
        });
      },
      [currencyRates, currentCurrency]
    );

    const formattedTableData = useMemo(() => {
      if (!income || !expense || !metrics) {
        return {
          income: [],
          expense: [],
          profit: [],
          metrics: [],
        };
      }
      const netRevenue = income?.shippedCogs || 0;
      const netDeductions = Object.values(expense?.deduction || {}).reduce(
        (acc, val) => acc + val,
        0
      );
      const netChargebacks = Object.values(expense?.chargeback || {}).reduce(
        (acc, val) => acc + val,
        0
      );
      const adCost = expense?.adCost || 0;
      const cogs = expense?.cogs?.cogs || 0;
      const netExpense = adCost + cogs + netDeductions + netChargebacks;
      const unitsShipped = metrics?.shippedUnits || 0;
      const profit = netRevenue - netExpense;
      const snsUnits = metrics?.snsUnits || 0;
      return {
        income: [
          ...mapToTableData({
            data: income,
            total: netRevenue,
            units: unitsShipped,
            currency,
            tooltip: true,
          }),
          ...mapToTableData({
            data: { netRevenue },
            total: netRevenue,
            units: unitsShipped,
            currency,
            bold: true,
          }),
        ],
        expense: [
          ...mapToTableData({
            data: { adCost },
            total: netRevenue,
            units: unitsShipped,
            currency,
            tooltip: true,
          }),
          ...mapToTableData({
            data: { netDeductions },
            total: netRevenue,
            units: unitsShipped,
            currency,
            tooltip: true,
            actions: canUploadData ? (
              <EditButton
                onClick={() => {
                  setDeductionUpdateDialogOpen(true);
                }}
              />
            ) : undefined,
          }),
          ...(showBreakdown
            ? mapToTableData({
                data: expense?.deduction || {},
                total: netRevenue,
                units: unitsShipped,
                currency,
                subCategory: true,
              })
            : []),
          ...mapToTableData({
            data: { cogs },
            total: netRevenue,
            units: unitsShipped,
            currency,
            tooltip: true,
          }),
          ...mapToTableData({
            data: { netChargebacks },
            total: netRevenue,
            units: unitsShipped,
            currency,
            tooltip: true,
            actions: canUploadData ? (
              <EditButton
                onClick={() => {
                  setChargebackUploadDialogOpen(true);
                }}
              />
            ) : undefined,
          }),
          ...(showBreakdown
            ? mapToTableData({
                data: expense?.chargeback || {},
                total: netRevenue,
                units: unitsShipped,
                currency,
                subCategory: true,
                translate: false,
              })
            : []),
          ...mapToTableData({
            data: { netExpense },
            total: netRevenue,
            units: unitsShipped,
            currency,
            bold: true,
          }),
        ],
        profit: [
          ...mapToTableData({
            data: { profit },
            total: netRevenue,
            units: unitsShipped,
            currency,
            bold: true,
          }),
          ...mapToTableData({
            data: { profitPercent: profit },
            total: netRevenue,
            units: unitsShipped,
            currency,
            valueRow: "percent",
          }),
        ],
        metrics: [
          ...mapToTableData({
            data: { tacos: adCost },
            total: netRevenue,
            units: unitsShipped,
            currency,
            valueRow: "percent",
          }),
          ...mapToTableData({
            data: { totalFees: adCost + netDeductions },
            total: netRevenue,
            units: unitsShipped,
            currency,
            valueRow: "percent",
          }),
          ...mapToTableData({
            data: { unitsShipped },
            total: unitsShipped,
            units: unitsShipped,
            currency,
            valueRow: "int",
          }),
          ...mapToTableData({
            data: { snsUnits },
            total: unitsShipped,
            units: unitsShipped,
            currency,
            valueRow: "int",
            tooltip:
              snsFromDate && snsToDate
                ? t("vendorProfitAndLoss.snsUnitsTooltip", {
                    from: moment.unix(snsFromDate).format("DD MMMM YYYY"),
                    to: moment.unix(snsToDate).format("DD MMMM YYYY"),
                  })
                : undefined,
          }),
          ...mapToTableData({
            data: { snsPen: snsUnits },
            total: unitsShipped,
            units: unitsShipped,
            currency,
            valueRow: "percent",
          }),
        ],
      };
    }, [
      income,
      expense,
      metrics,
      showBreakdown,
      currencyRates,
      currentCurrency,
    ]);

    return (
      <PanelWrapper elevation={2} className="break-before">
        <PanelHeader>
          <Typography variant="h3">{title}</Typography>
          <FlexBox>
            <DownloadCsv
              {...{
                reportType: "vendorChannelProfitability",
                path: "/api/generic/vendorProfitability/channel",
                mid: store?.merchantId,
                params: {
                  customerId: userInfo._id,
                  currentRange: {
                    ...currentRange,
                    fromDate: isComparison
                      ? currentRange.priorFromDate
                      : currentRange.fromDate,
                    toDate: isComparison
                      ? currentRange.priorToDate
                      : currentRange.toDate,
                  },
                  currentCurrency,
                  shopName: store?.storeName,
                  marketplaceName: useMarketplace(),
                  countryCode: store?.marketplaceCountry,
                  marketplaceType: store?.marketplace,
                  marketplaceSubtype:
                    store?.marketplaceSubtype || store?.marketplace,
                  exchangeRate: getExchangeRate(
                    currencyRates,
                    getCurrencyByCountryCode[store?.marketplaceCountry || ""],
                    currentCurrency
                  ),
                },
              }}
            />
            <Typography variant="subtitle1" color="textPrimary" noWrap>
              {t("profitability.expandProfitAndLoss")}
            </Typography>
            <Switch
              size="small"
              checked={showBreakdown}
              onClick={() => {
                setShowBreakdown(!showBreakdown);
              }}
            />
          </FlexBox>
        </PanelHeader>
        <Box>
          {loading || isFetchingVendorProfitAndLoss ? (
            <LoadingWrapper>
              <PanelLoading />
            </LoadingWrapper>
          ) : (
            <ProfitAndLossTable
              {...formattedTableData}
              currentCurrency={currentCurrency}
              isComparison={isComparison}
            />
          )}
        </Box>
      </PanelWrapper>
    );
  }
);

export default VendorProfitAndLoss;
