import React, { useEffect, useState } from "react";

import { Theme } from "@material-ui/core";
import { baseUrl } from "../../../configs";
import styled from "styled-components";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const iconHeight = "15px";
const largeIconHeight = "40px";

interface IconProps {
  $largeSize?: boolean;
  $adsOverlay?: boolean;
  theme: Theme;
}
const SmallIcon = styled.img`
  height: ${({ $largeSize }: IconProps) =>
    $largeSize ? largeIconHeight : iconHeight};
`;

const FakeIcon = styled.div`
  height: ${({ $largeSize }: IconProps) =>
    $largeSize ? largeIconHeight : iconHeight};
  width: ${({ $largeSize }: IconProps) =>
    $largeSize ? largeIconHeight : iconHeight};
  font-size: 7px;
  font-weight: bold;
  line-height: ${({ $largeSize }: IconProps) =>
    $largeSize ? largeIconHeight : iconHeight};
  color: ${({ theme }) => theme.palette?.info?.contrastText};
  text-transform: uppercase;
  text-align: center;
  background: ${({ theme }) => `linear-gradient(
    ${theme.palette?.info?.light} 0%,
    ${theme.palette?.info?.main} 100%
  );`};
`;

const IconWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $adsOverlay, $largeSize, theme }: IconProps) =>
    $adsOverlay &&
    `&:after {
  content: "ADS";
  z-index: 1;
  overflow: hidden;
  font-size: 7px;
  font-weight: bold;
  line-height: initial;
  color: ${theme.palette?.info?.contrastText};
  text-transform: uppercase;
  text-align: center;
  transform: rotate(45deg);
  width: ${$largeSize ? "50px" : "28px"};
  height: 9px;
  background: ${theme.palette?.info?.main};
  background: linear-gradient(${theme.palette?.info?.light} 0%, ${
      theme.palette?.info?.main
    } 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 1px;
  right: -8px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}`}
`;

interface MarketplaceIconProps {
  market: string;
  marketplaceSubtype?: string;
  mid?: string;
  largeSize?: boolean;
}
export const MarketplaceIcon = (props: MarketplaceIconProps) => {
  const { market, marketplaceSubtype, mid, largeSize } = props;
  const [iconSrc, setIconSrc] = useState<string | undefined>();

  const user = useTypedSelector((state) => state.user);

  const loadImage = (marketplace: string) => {
    const url = `${baseUrl}/static/icons/${marketplace}.jpg`;
    setIconSrc(url);
  };

  useEffect(() => {
    if (market && marketplaceSubtype && marketplaceSubtype === "advertising") {
      loadImage(market);
    } else if (market === "mirakl" && marketplaceSubtype) {
      loadImage(marketplaceSubtype);
    } else if (market) {
      loadImage(market);
    }
  }, [market, mid, user]);

  return (
    <IconWrapper
      $largeSize={largeSize}
      $adsOverlay={Boolean(
        marketplaceSubtype && marketplaceSubtype === "advertising"
      )}
    >
      {market === "advertising" ? (
        <FakeIcon $largeSize={largeSize}>ADS</FakeIcon>
      ) : (
        <SmallIcon {...props} src={iconSrc} $largeSize={largeSize} />
      )}
    </IconWrapper>
  );
};
