export interface AgencyConfig {
  agency: string;
  originalDomain: string;
  hideHelpCenter: boolean;
  hideLoginProviders: boolean;
  hideSignUp: boolean;
  signupPath: string;
}

interface AgencyConfigMapper {
  [key: string]: AgencyConfig;
}

export const baseUrl = new URL(document.location.href).origin;

export const AGENCY_CONFIG: AgencyConfigMapper = {
  avask: {
    agency: "avask",
    originalDomain: "avask.merchantspring.io",
    hideHelpCenter: false,
    hideLoginProviders: true,
    hideSignUp: false,
    signupPath: "",
  },
  myfbaprep: {
    agency: "myfbaprep",
    originalDomain: "analytics.myfbaprep.com",
    hideHelpCenter: false,
    hideLoginProviders: true,
    hideSignUp: true,
    signupPath: "/signup-ddc75d53-bdf1-47d4-9c38-abe17d1c9fcb",
  },
  sellerssociety: {
    agency: "sellerssociety",
    originalDomain: "app.sellerssociety.io",
    hideHelpCenter: true,
    hideLoginProviders: true,
    hideSignUp: true,
    signupPath: "/signup-73ea53e3-eeff-47b1-8aae-0505596ec1d0",
  },
  zonkeepers: {
    agency: "zonkeepers",
    originalDomain: "reporting.zonkeepers.com.au",
    hideHelpCenter: false,
    hideLoginProviders: true,
    hideSignUp: true,
    signupPath: "/signup-dc423b79-e7ea-464e-8ad8-d93d60c47dd8",
  },
};
