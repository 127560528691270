import { Box, Grid, Tooltip, Typography } from "@material-ui/core";
import React, { memo, useMemo, useRef } from "react";

import { COMPARISON_PERIOD } from "~/store/utils/dateTimeUtils";
import GenericTotal from "~/components/totals/genericTotal";
import GenericTotalMoney from "~/components/totals/genericTotalMoney";
import { InlineIconButton } from "~/icons/inlineIconButton";
import LargeNumber from "~/components/totals/largeNumber";
import Panel from "~/components/panel/panel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import { Range } from "~/typedef/store";
import { getCurrencyByCountryCode } from "~/utils/currencyUtils";
import styled from "styled-components";
import { useCohortSummaryQuery } from "~/store/mystore/customerLtv.redux";
import { useTranslation } from "react-i18next";

const TotalGridContainer = styled(Grid)`
  ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
      justify-content: center;
      align-items: center;
    }
  `}
`;

interface CLVCohortSummaryPanelProps {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  countryCode: string;
  currentCurrency: string;
  currentRange: Range;
  currentCompare: COMPARISON_PERIOD;
  conditionalFormatting?: boolean;
  report?: boolean;
}

const CLVCohortSummaryPanel = memo<CLVCohortSummaryPanelProps>(
  function CLVCohortSummaryPanel({
    mid,
    marketplaceType,
    marketplaceSubtype,
    countryCode,
    currentCurrency,
    currentRange,
    currentCompare,
    conditionalFormatting,
    report,
  }) {
    const { t } = useTranslation();

    const homeCurrency = useMemo(
      () => getCurrencyByCountryCode[countryCode],
      [countryCode]
    );

    const { isLoadingCohortSummary, current, prior } = useCohortSummaryQuery(
      {
        mid,
        marketplaceType,
        marketplaceSubtype,
        currentRange,
      },
      {
        selectFromResult: ({ isFetching, data }) => {
          return {
            isLoadingCohortSummary: isFetching,
            current: data?.current,
            prior: data?.prior,
          };
        },
      }
    );

    const ltvCacRatioHeadingContainerRef = useRef<HTMLDivElement>(null);

    return (
      <Panel
        id="widget-clv-cohort-summary"
        title={t("clv.cohortSummary.title")}
        tooltip={t("clv.cohortSummary.mainTooltip")}
        content={
          <Box p={2}>
            {isLoadingCohortSummary || !current ? (
              <PanelLoading />
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotal
                    number={current.distinctCustomersCount}
                    comparison={
                      prior
                        ? {
                            prior: prior.distinctCustomersCount,
                          }
                        : undefined
                    }
                    title={t("clvCohortSummaryWidget.uniqueCustomers")}
                    tooltip={t("clvCohortSummaryWidget.uniqueCustomersTooltip")}
                    condensedComparison={true}
                    condensed={true}
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotalMoney
                    current={current.averageLtv}
                    prior={prior?.averageLtv}
                    currency={homeCurrency}
                    currentCurrency={currentCurrency}
                    title={t(`clvCohortSummaryWidget.averageLtv`)}
                    tooltip={t("clvCohortSummaryWidget.averageLtvTooltip")}
                    comparisonPeriod={currentCompare}
                    condensedComparison={true}
                    condensed={true}
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotal
                    number={current.repeatCustomersCount}
                    comparison={
                      prior
                        ? {
                            prior: prior.repeatCustomersCount,
                          }
                        : undefined
                    }
                    title={t("clvCohortSummaryWidget.repeatCustomers")}
                    tooltip={t("clvCohortSummaryWidget.repeatCustomersTooltip")}
                    condensedComparison={true}
                    condensed={true}
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotalMoney
                    current={current.averageRepeatLtv}
                    prior={prior?.averageRepeatLtv}
                    currency={homeCurrency}
                    currentCurrency={currentCurrency}
                    title={t("clvCohortSummaryWidget.averageRepeatLtv")}
                    tooltip={t(
                      "clvCohortSummaryWidget.averageRepeatLtvTooltip"
                    )}
                    comparisonPeriod={currentCompare}
                    condensedComparison={true}
                    condensed={true}
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotal
                    number={
                      current.repeatCustomersPercentage
                        ? current.repeatCustomersPercentage * 100
                        : 0
                    }
                    comparison={
                      prior
                        ? {
                            prior: prior.repeatCustomersPercentage
                              ? prior.repeatCustomersPercentage * 100
                              : 0,
                          }
                        : undefined
                    }
                    title={t("clvCohortSummaryWidget.repeatCustomers")}
                    tooltip={t(
                      "clvCohortSummaryWidget.repeatCustomersTooltipPtc"
                    )}
                    condensedComparison={true}
                    condensed={true}
                    conditionalFormatting={conditionalFormatting}
                    percentage={true}
                  />
                </Grid>
                <TotalGridContainer container item spacing={1} xs={6}>
                  <Grid container item md={12} alignItems="center">
                    <Typography variant="h3">
                      {t("clvCohortSummaryWidget.ltvCacRatio")}
                    </Typography>
                    <Tooltip
                      title={t("clvCohortSummaryWidget.ltvCacRatioTooltip")}
                      id="cohort-summary-ltvCacRatiotooltip"
                    >
                      <InlineIconButton id="cohort-summary-ltvCacRatiotooltip-icon" />
                    </Tooltip>
                  </Grid>
                  <Grid
                    container
                    item
                    md={12}
                    wrap="wrap"
                    alignItems="center"
                    ref={ltvCacRatioHeadingContainerRef}
                  >
                    <LargeNumber
                      value={
                        current.ltvCacRatio
                          ? `${current.ltvCacRatio.toFixed(2)}x`
                          : "-"
                      }
                      headingContainerRef={ltvCacRatioHeadingContainerRef}
                      variant="h1"
                    />
                  </Grid>
                  <Grid item md={12}>
                    -
                  </Grid>
                </TotalGridContainer>
              </Grid>
            )}
          </Box>
        }
      />
    );
  }
);

export default CLVCohortSummaryPanel;
