import {
  Bar,
  BarChart,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
} from "recharts";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { DATETIME_PERIODS, INTERVAL } from "../../../store/utils/dateTimeUtils";
import MultiBarLineChartTooltip, {
  PlotProps,
  TooltipAttainment,
  TooltipPercentageChange,
} from "./multiBarLineChartTooltip";
import React, { memo, useCallback, useMemo } from "react";
import { find, isEmpty } from "lodash";
import {
  formatCurrencyRounded,
  intFormatterRounded,
} from "~/utils/currencyUtils";
import {
  getEvenTicks,
  getEvenTicksFromZero,
} from "../chartUtils/chartComponents";
import {
  getNearestHundredOrTen,
  getNearestHundredOrTenFloor,
  getNearestPowerOfTenFloor,
} from "../chartUtils/chartUtils";

import BarChartNoData from "../../../components/placeholders/barChartNoData";
import { CurrencyRate } from "~/typedef/store";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import MultiBarLineChartLegend from "./multiBarLineChartLegend";
import moment from "moment-timezone";
import { useLayoutProps } from "../chartUtils/chartComponents";

export interface MultiBarLineChartProps {
  title: string;
  currentPeriod: DATETIME_PERIODS;
  currentCurrency: string;
  currencyRates: CurrencyRate[];
  chartData: any[];
  isLoading: boolean;
  lines: PlotProps[];
  bars: PlotProps[];
  xKey: string;
  report?: boolean;
  timezone: string;
  interval: INTERVAL;
  tooltipPercentageChange?: TooltipPercentageChange[];
  tooltipAttainment?: TooltipAttainment[];
}

const Y_AXIS_WIDTH = 80;

const getXAxisProps = (
  currentPeriod: DATETIME_PERIODS,
  interval: INTERVAL,
  timezone: string,
  xKey: string
): XAxisProps => {
  let tickFormatter;
  if (interval === INTERVAL.MONTHS) {
    tickFormatter = (tick: number) =>
      moment.unix(tick).tz(timezone).add(12, "h").startOf("day").format("MMM");
  } else if (currentPeriod === DATETIME_PERIODS.DAY) {
    tickFormatter = (tick: number) =>
      moment.unix(tick).tz(timezone).format("HH");
  } else if (
    currentPeriod === DATETIME_PERIODS.WEEK ||
    currentPeriod === DATETIME_PERIODS.WEEKMTS
  ) {
    tickFormatter = (tick: number) =>
      moment.unix(tick).tz(timezone).format("ddd");
  } else {
    tickFormatter = (tick: number) =>
      moment.unix(tick).tz(timezone).format("D MMM");
  }

  return {
    dataKey: xKey,
    type: "category",
    interval: "preserveStartEnd",
    tickLine: false,
    tick: { fontSize: 13 },
    minTickGap: 25,
    tickFormatter,
  };
};

type AxisMinMax = {
  axisOneMin: number;
  axisOneMax: number;
  axisTwoMin: number;
  axisTwoMax: number;
};

const MultiBarLineChart = memo<MultiBarLineChartProps>(
  function MultiBarLineChart({
    title,
    currentPeriod,
    currentCurrency,
    currencyRates,
    chartData,
    isLoading,
    lines,
    bars,
    xKey,
    report,
    timezone,
    interval,
    tooltipPercentageChange,
    tooltipAttainment,
  }) {
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down("sm"));
    const { height, margin } = useLayoutProps("comboChart", report);

    const {
      axisOnePlot,
      axisOneMin,
      axisOneMax,
      axisTwoPlot,
      axisTwoMin,
      axisTwoMax,
    } = useMemo(() => {
      const axisOnePlot =
        find(bars, { axis: "1" }) || find(lines, { axis: "1" });
      const axisTwoPlot =
        find(bars, { axis: "2" }) || find(lines, { axis: "2" });
      // Getting the min and max values for the bars
      const barsMinMax = bars.reduce(
        (acc: AxisMinMax, bar) => {
          if (bar.axis === "1") {
            return {
              ...acc,
              axisOneMin: Math.min(
                acc.axisOneMin,
                Math.min.apply(
                  Math,
                  chartData.map((o) => o[bar.key])
                )
              ),
              axisOneMax: Math.max(
                acc.axisOneMax,
                Math.max.apply(
                  Math,
                  chartData.map((o) => o[bar.key])
                )
              ),
            };
          } else {
            return {
              ...acc,
              axisTwoMin: Math.min(
                acc.axisTwoMin,
                Math.min.apply(
                  Math,
                  chartData.map((o) => o[bar.key])
                )
              ),
              axisTwoMax: Math.max(
                acc.axisTwoMax,
                Math.max.apply(
                  Math,
                  chartData.map((o) => o[bar.key])
                )
              ),
            };
          }
        },
        {
          axisOneMin: Infinity,
          axisOneMax: -Infinity,
          axisTwoMin: Infinity,
          axisTwoMax: -Infinity,
        }
      );

      // Getting the min and max values for the lines
      const { axisOneMin, axisOneMax, axisTwoMin, axisTwoMax } = lines.reduce(
        (acc: AxisMinMax, line) => {
          if (line.axis === "1") {
            return {
              ...acc,
              axisOneMin: Math.min(
                acc.axisOneMin,
                Math.min.apply(
                  Math,
                  chartData.map((o) => o[line.key])
                )
              ),
              axisOneMax: Math.max(
                acc.axisOneMax,
                Math.max.apply(
                  Math,
                  chartData.map((o) => o[line.key])
                )
              ),
            };
          } else {
            return {
              ...acc,
              axisTwoMin: Math.min(
                acc.axisTwoMin,
                Math.min.apply(
                  Math,
                  chartData.map((o) => o[line.key])
                )
              ),
              axisTwoMax: Math.max(
                acc.axisTwoMax,
                Math.max.apply(
                  Math,
                  chartData.map((o) => o[line.key])
                )
              ),
            };
          }
        },
        {
          ...barsMinMax,
        }
      );

      return {
        axisOnePlot,
        axisTwoPlot,
        axisOneMin:
          axisOneMin === Infinity ? 0 : getNearestHundredOrTenFloor(axisOneMin),
        axisOneMax:
          axisOneMax === -Infinity ? 0 : getNearestHundredOrTen(axisOneMax),
        axisTwoMin:
          axisTwoMin === Infinity ? 0 : getNearestHundredOrTenFloor(axisTwoMin),
        axisTwoMax:
          axisTwoMax === -Infinity ? 0 : getNearestHundredOrTen(axisTwoMax),
      };
    }, [bars, lines]);

    const leftTicks = useMemo(() => {
      return axisOneMin < 0
        ? getEvenTicks(axisOneMin, axisOneMax, 5)
        : getEvenTicksFromZero(
            getNearestHundredOrTen(axisOneMax),
            5,
            Math.max(10, getNearestPowerOfTenFloor(axisOneMax / 100))
          );
    }, [axisOneMin, axisOneMax]);
    const yAxisLeftTickFormatter = useCallback(
      (tick: number) => {
        const isCurrency = axisOnePlot?.isCurrency ?? false;
        const unit = axisOnePlot?.unit ?? "";
        return isCurrency
          ? formatCurrencyRounded(
              tick,
              currencyRates,
              currentCurrency,
              currentCurrency
            )
          : intFormatterRounded.format(tick) + unit;
      },
      [axisOnePlot, currentCurrency]
    );

    const rightTicks = useMemo(() => {
      return axisTwoMin < 0
        ? getEvenTicks(axisTwoMin, axisTwoMax, 5)
        : getEvenTicksFromZero(
            getNearestHundredOrTen(axisTwoMax),
            5,
            Math.max(10, getNearestPowerOfTenFloor(axisTwoMax / 100))
          );
    }, [axisTwoMin, axisTwoMax]);
    const yAxisRightTickFormatter = useCallback(
      (tick: number) => {
        const isCurrency = axisTwoPlot?.isCurrency ?? false;
        const unit = axisTwoPlot?.unit ?? "";
        return isCurrency
          ? formatCurrencyRounded(
              tick,
              currencyRates,
              currentCurrency,
              currentCurrency
            )
          : intFormatterRounded.format(tick) + unit;
      },
      [axisTwoPlot, currentCurrency]
    );

    const xAxisProps = useMemo(() => {
      return getXAxisProps(currentPeriod, interval, timezone, xKey);
    }, [currentPeriod, xKey, timezone, interval]);

    const legendData = useMemo(() => {
      return [
        ...bars.map((bar) => ({
          name: bar.legendKey ?? `chartKeys.${bar.key}`,
          fillColor: bar.colour,
          shape: "bar",
        })),
        ...lines.map((line) => ({
          name: line.legendKey ?? `chartKeys.${line.key}`,
          fillColor: line.colour,
          shape: "line",
        })),
      ];
    }, [bars, lines]);

    const renderMultiChartContent = () => {
      return (
        <>
          <Tooltip
            content={
              <MultiBarLineChartTooltip
                currentPeriod={currentPeriod}
                currentCurrency={currentCurrency}
                currencyRates={currencyRates}
                interval={interval}
                timezone={timezone}
                bars={bars}
                lines={lines}
                percentageChange={tooltipPercentageChange}
                attainment={tooltipAttainment}
              />
            }
          />
          <Legend
            content={<MultiBarLineChartLegend legendItems={legendData} />}
            verticalAlign="top"
            align="left"
            wrapperStyle={{ top: -16, fontSize: "12px" }}
          />

          <XAxis {...xAxisProps} />

          {axisOnePlot && (
            <>
              <YAxis
                yAxisId="1"
                tickFormatter={yAxisLeftTickFormatter}
                tick={{ fontSize: 14 }}
                domain={[axisOneMin, axisOneMax]}
                ticks={leftTicks}
                width={Y_AXIS_WIDTH}
              />
              {axisOneMin < 0 && (
                <ReferenceLine yAxisId="1" y={0} strokeWidth={2} />
              )}
            </>
          )}

          {axisTwoPlot && (
            <YAxis
              yAxisId="2"
              orientation={axisOnePlot ? "right" : "left"}
              tickFormatter={yAxisRightTickFormatter}
              tick={{ fontSize: 14 }}
              domain={[axisTwoMin, axisTwoMax]}
              ticks={rightTicks}
              width={Y_AXIS_WIDTH}
            />
          )}

          {bars.map((bar) => {
            return (
              <Bar
                key={`bar-${bar.key}`}
                yAxisId={bar.axis}
                dataKey={bar.key}
                fill={bar.colour}
              />
            );
          })}
          {lines.map((line) => {
            return (
              <Line
                key={`line-${line.key}`}
                yAxisId={line.axis}
                dataKey={line.key}
                type={line.type ?? "linear"}
                stroke={line.colour}
                dot={false}
                strokeWidth={smDown ? 1 : 2}
              />
            );
          })}
        </>
      );
    };

    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid item xs={12}>
          <Box pb={2}>
            <Typography variant="h6">{title}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {isLoading ? (
            <LoadingIndicator />
          ) : isEmpty(chartData) ? (
            <BarChartNoData {...{ currentPeriod }} />
          ) : (
            <ResponsiveContainer width="100%" height={height}>
              {lines.length > 0 ? (
                <ComposedChart data={chartData} margin={margin} barGap={0}>
                  {renderMultiChartContent()}
                </ComposedChart>
              ) : (
                <BarChart data={chartData} margin={margin} barGap={0}>
                  {renderMultiChartContent()}
                </BarChart>
              )}
            </ResponsiveContainer>
          )}
        </Grid>
      </Grid>
    );
  }
);
export default MultiBarLineChart;
