import { Cell } from "./cell";
import React from "react";
import { Row } from "./tableData";
import { getCurrencySymbol } from "~/utils/currencyUtils";
import { useTranslation } from "react-i18next";

interface HeaderProps {
  currentCurrency: string;
  isComparison?: boolean;
}

export const Header = ({ currentCurrency, isComparison }: HeaderProps) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Cell
        startAlign
        value={
          isComparison
            ? t(`profitability.comparisonPeriodLabel`)
            : t(`profitability.currentPeriodLabel`)
        }
        width="35%"
        bold
        uppercase
      />
      <Cell value="$" width="25%" uppercase />
      <Cell
        value={t(`profitability.percentIncomeLabel`)}
        uppercase
        tooltip={t(`profitability.percentIncomeTooltip`)}
      />
      <Cell
        value={t("profitability.perUnitLabel", {
          currencySymbol:
            getCurrencySymbol[
              currentCurrency as keyof typeof getCurrencySymbol
            ],
        })}
        uppercase
        tooltip={t(`profitability.perUnitTooltip`)}
      />
    </Row>
  );
};
