import { Box, Grid, Switch, Typography } from "@material-ui/core";
import {
  FrequencyInterval,
  NotificationFrequency,
  NotificationSetting,
  ProductNotificationSetting,
} from "mm-utils-frontend/dist/typedef/userNotificationSetting";
import {
  ProductNotificationType,
  StoreNotificationType,
} from "mm-utils-frontend";
import React, { useCallback, useEffect, useState } from "react";
import SettingsRow, { SettingsRowProps } from "./notificationsSettingRow";
import { Store, StoreState } from "~/typedef/store";
import {
  applySettingToAllStores,
  fetchNotificationSettings,
  saveNotificationSettings,
  saveWatchList,
} from "~/store/notifications.redux";
import { groupBy, uniqBy } from "lodash";

import Alert from "~/components/alert/alertCard";
import PageBlock from "~/components/containers/sideNavPageBlock";
import Panel from "~/components/panel/panel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import { StoreSelector } from "~/components/toolbars/myStoresToolbar/storeSelector";
import get from "lodash/get";
import i18next from "i18next";
import { memo } from "react";
import moment from "moment-timezone";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

export const DAYS_COVER_UPPER_LIMIT = 90;

const HeaderRow = styled(Grid)`
  padding: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
`;

const StyledTypography = styled(Typography)`
  text-decoration: underline;
  cursor: pointer;
  ${({ theme }) => `
    color: ${theme.palette.link.secondary};
  `}
`;
export interface FrequencyValue {
  value: number;
  interval: string;
}

export declare type NotificationSettingOverride =
  Partial<NotificationSetting> & {
    blockToggle?: boolean;
    isConnectionIssue?: boolean;
  };
declare type StoreSettingsOverride = {
  [key in StoreNotificationType]: NotificationSettingOverride;
};

declare type ProductNotificationSettingOverride =
  Partial<ProductNotificationSetting> & {
    restrictedMarketplace?: string;
  };
declare type ProductSettingsOverride = {
  [key in ProductNotificationType]: ProductNotificationSettingOverride;
};

declare type SettingsOverrides = Partial<
  StoreSettingsOverride & ProductSettingsOverride
>;

export const settingsOverrides: SettingsOverrides = {
  [StoreNotificationType.STORE_MISSING_PERMISSIONS]: {
    isEnabled: true,
    blockToggle: true,
    isConnectionIssue: true,
  },
  [StoreNotificationType.STORE_CREDENTIAL_INVALIDATION_PRIMARY]: {
    isEnabled: true,
    blockToggle: true,
    isConnectionIssue: true,
  },
  [StoreNotificationType.STORE_CREDENTIAL_INVALIDATION_ADVERTISING]: {
    isEnabled: true,
    blockToggle: true,
    isConnectionIssue: true,
  },
  [StoreNotificationType.STORE_REPORT_LANGUAGE_UNSUPPORTED]: {
    isEnabled: true,
    blockToggle: true,
    isConnectionIssue: true,
  },
  [ProductNotificationType.LOW_INVENTORY]: {
    advancedConfig: {
      daysCoverLimit: DAYS_COVER_UPPER_LIMIT,
    },
  },
  [ProductNotificationType.SUPPRESSED_LISTING]: {
    restrictedMarketplace: "amazon",
  },
  [ProductNotificationType.LOST_BUYBOX]: {
    restrictedMarketplace: "amazon",
  },
};

export const isProductSettings = (
  settings: NotificationSettingOverride | ProductNotificationSettingOverride
): settings is ProductNotificationSettingOverride => {
  return (
    (settings as ProductNotificationSettingOverride).products !== undefined
  );
};

const NotificationSettings = memo(() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userInfo = useTypedSelector((state) => state.user);
  const currentStore = useTypedSelector((state: StoreState) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const existingSettings = useTypedSelector(
    (state) => state?.notifications?.setting?.data?.settings
  );
  const fetchingSettings = useTypedSelector(
    (state) => state?.notifications?.setting?.fetching
  );
  const lastUpdated = useTypedSelector(
    (state) => state?.notifications?.setting?.data?.updatedAt
  );
  const filteredStores = useTypedSelector(
    (state) => state.mystore.filteredStores
  );
  const timezone = useTypedSelector(
    (state) =>
      state?.persistentAppSettings?.setting?.data?.timezone || moment.tz.guess()
  );

  const getSettings = () => {
    const settings: SettingsOverrides = {};
    for (const type of Object.values(StoreNotificationType)) {
      settings[type] = {
        isEnabled: false,
        emailFrequency: {
          value: 0,
          interval: FrequencyInterval.IMMEDIATE,
        },
        ...(existingSettings ? existingSettings[type] : {}),
        ...settingsOverrides[type],
      };
    }
    if (store.marketplaceType === "amazon") {
      for (const type of Object.values(ProductNotificationType)) {
        settings[type] = {
          ...settingsOverrides[type],
          products: [] as string[],
          isEnabled: false,
          emailFrequency:
            existingSettings && existingSettings[type]?.emailFrequency
              ? existingSettings && existingSettings[type]?.emailFrequency
              : {
                  value: 0,
                  interval: FrequencyInterval.IMMEDIATE,
                },
          ...(existingSettings ? existingSettings[type] : {}),
          ...(existingSettings && existingSettings[type]?.advancedConfig
            ? { advancedConfig: existingSettings[type]?.advancedConfig }
            : settingsOverrides[type]?.advancedConfig
            ? { advancedConfig: settingsOverrides[type]?.advancedConfig }
            : {}),
        };
      }
    } else {
      const type = ProductNotificationType.LOW_INVENTORY;
      settings[type] = {
        ...settingsOverrides[type],
        products: [] as string[],
        isEnabled: false,
        emailFrequency:
          existingSettings && existingSettings[type]?.emailFrequency
            ? existingSettings && existingSettings[type]?.emailFrequency
            : {
                value: 0,
                interval: FrequencyInterval.IMMEDIATE,
              },
        ...(existingSettings ? existingSettings[type] : {}),
        ...(existingSettings && existingSettings[type]?.advancedConfig
          ? { advancedConfig: existingSettings[type]?.advancedConfig }
          : settingsOverrides[type]?.advancedConfig
          ? { advancedConfig: settingsOverrides[type]?.advancedConfig }
          : {}),
      };
    }
    return settings;
  };

  const [notificationType, setNotificationType] = useState<
    StoreNotificationType | ProductNotificationType | null
  >(null);
  const [store, setStore] = useState<Store>(filteredStores?.stores[0]);
  const [settings, setSettings] = useState(getSettings());

  const determineAllOff = () => {
    if (settings) {
      return !Object.keys(settings).some((key) => {
        const typeSettings = settings[key as keyof SettingsOverrides];
        if (typeSettings) {
          if (isProductSettings(typeSettings)) {
            return typeSettings?.isEnabled;
          } else {
            if (typeSettings?.blockToggle) {
              return false;
            } else {
              return typeSettings?.isEnabled;
            }
          }
        }
        return false;
      });
    }
    return true;
  };
  const [allOff, setAllOff] = useState(true);

  useEffect(() => {
    setSettings(getSettings());
  }, [existingSettings, store.marketplaceType]);

  useEffect(() => {
    setAllOff(determineAllOff());
  }, [settings]);

  useEffect(() => {
    if (!store) {
      setStore(filteredStores?.stores[0]);
    }
  }, [filteredStores]);

  const setToggle = (
    type: StoreNotificationType | ProductNotificationType,
    newValue: boolean
  ) => {
    setSettings({
      ...settings,
      [type]: {
        ...settings[type],
        isEnabled: newValue,
        emailFrequency: newValue
          ? {
              value: 0,
              interval: FrequencyInterval.IMMEDIATE,
            }
          : {
              value: 0,
              interval: FrequencyInterval.NEVER,
            },
      },
    });
  };

  const setFrequency = (
    type: StoreNotificationType | ProductNotificationType,
    newValue: FrequencyValue
  ) => {
    if (newValue.interval === FrequencyInterval.NEVER) {
      setSettings({
        ...settings,
        [type]: {
          ...settings[type],
          emailFrequency: newValue,
          isEnabled: false,
        },
      });
    } else {
      setSettings({
        ...settings,
        [type]: {
          ...settings[type],
          emailFrequency: newValue,
        },
      });
    }
  };

  const setProducts = (
    type: StoreNotificationType | ProductNotificationType,
    newValue: string[]
  ) => {
    setSettings({
      ...settings,
      [type]: {
        ...settings[type],
        products: newValue,
      },
    });
  };

  const getToggleSettings = () => {
    const toggleSettings: { [x: string]: any } = {};
    for (const type of Object.values(StoreNotificationType)) {
      if (!settings[type]?.blockToggle) {
        toggleSettings[type] = {
          title: t(`notificationSettingsToggle.${type}`),
          checked: settings[type]?.isEnabled,
          colour: "primary",
          setChecked: (newValue: boolean) => {
            setToggle(type, newValue);
            setNotificationType(type);
          },
          tooltip: t(`notificationSettingsToggleTooltip.${type}`),
          notificationType: type,
        };
      }
    }
    for (const type of Object.values(ProductNotificationType)) {
      toggleSettings[type] = {
        title: t(`notificationSettingsToggle.${type}`),
        checked: settings[type]?.isEnabled,
        colour: "primary",
        setChecked: (newValue: boolean) => {
          setToggle(type, newValue);
          setNotificationType(type);
        },
        tooltip: t(`notificationSettingsToggleTooltip.${type}`),
        notificationType: type,
      };
    }
    return toggleSettings;
  };
  const toggles = getToggleSettings();

  const changeStore = (
    market: string,
    mid: string = currentStore?.merchantId || ""
  ): void => {
    const store = filteredStores?.stores?.find(
      (store: Store) => store.merchantId === mid
    );
    if (store) {
      setStore(store);
    }
  };

  const setDaysCover = (
    type: StoreNotificationType | ProductNotificationType,
    daysCoverLimit: number
  ): void => {
    const newDaysCover: number = Number.isFinite(daysCoverLimit)
      ? Math.abs(daysCoverLimit) > DAYS_COVER_UPPER_LIMIT
        ? DAYS_COVER_UPPER_LIMIT
        : Math.abs(daysCoverLimit)
      : DAYS_COVER_UPPER_LIMIT;

    setSettings({
      ...settings,
      [type]: {
        ...settings[type],
        advancedConfig: {
          daysCoverLimit: newDaysCover,
        },
      },
    });
  };

  const saveNotificationSetting = useCallback(
    (
      isEnabled: boolean | undefined,
      emailFrequency: FrequencyValue,
      advancedConfig: any,
      type: string
    ) => {
      if (store) {
        dispatch(
          saveNotificationSettings({
            marketplaceType: store?.marketplaceSubtype,
            mid: store?.merchantId,
            userId: userInfo._id,
            isEnabled: isEnabled,
            notificationType: type,
            emailFrequency,
            advancedConfig,
            email: userInfo.email,
          })
        );
      }
    },
    [userInfo, store?.merchantId]
  );

  const saveProductsInNotificationSetting = useCallback(
    (products: any[]) => {
      if (notificationType && store) {
        dispatch(
          saveWatchList({
            marketplaceType: store?.marketplaceSubtype,
            mid: store?.merchantId,
            userId: userInfo._id,
            email: userInfo.email,
            notificationType,
            newWatchlist: products,
          })
        );
      }
    },
    [settings]
  );

  const applyToAll = (type: "emailFrequency" | "isEnabled") => {
    const typeMap = Object.keys(settings).map((notificationType) => ({
      notificationType,
      type,
      value: (
        settings[
          notificationType as keyof SettingsOverrides
        ] as NotificationSetting
      )[type],
    }));
    dispatch(
      applySettingToAllStores({
        typeMap,
      })
    );
  };

  useEffect(() => {
    if (
      store &&
      notificationType &&
      notificationType in ProductNotificationType
    ) {
      saveProductsInNotificationSetting(
        (settings[notificationType] as ProductNotificationSetting)?.products
      );
    }
  }, [saveProductsInNotificationSetting]);

  const fetchSettings = useCallback(() => {
    if (store) {
      dispatch(
        fetchNotificationSettings({
          mid: store?.merchantId,
          marketplaceType: store?.marketplaceSubtype,
        })
      );
    }
  }, [store?.merchantId, userInfo._id]);

  useEffect(() => {
    if (store && notificationType) {
      saveNotificationSetting(
        settings[notificationType]?.isEnabled,
        settings[notificationType]?.emailFrequency as NotificationFrequency,
        settings[notificationType]?.advancedConfig,
        notificationType
      );
    }
  }, [settings]);

  useEffect(() => {
    if (store) {
      fetchSettings();
    }
  }, [fetchSettings]);

  const toggleAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const onOff = event.target.checked;
    setAllOff(onOff);
    setNotificationType(null);
    const newSettings = { ...settings };
    for (const type of Object.values(StoreNotificationType)) {
      if (!newSettings[type]?.blockToggle) {
        newSettings[type] = {
          ...newSettings[type],
          isEnabled: onOff,
          emailFrequency: onOff
            ? {
                value: 0,
                interval: FrequencyInterval.IMMEDIATE,
              }
            : {
                value: 0,
                interval: FrequencyInterval.NEVER,
              },
        };
      }
    }
    for (const type of Object.values(ProductNotificationType)) {
      newSettings[type] = {
        ...newSettings[type],
        isEnabled: onOff,
        emailFrequency: onOff
          ? {
              value: 0,
              interval: FrequencyInterval.IMMEDIATE,
            }
          : {
              value: 0,
              interval: FrequencyInterval.NEVER,
            },
      };
    }

    setSettings(newSettings);
    saveNotificationSetting(
      onOff,
      onOff
        ? {
            value: 0,
            interval: FrequencyInterval.IMMEDIATE,
          }
        : {
            value: 0,
            interval: FrequencyInterval.NEVER,
          },
      undefined,
      "ALL"
    );
  };

  const renderSettingsPanel = () => {
    const rows = Object.keys(settings).map((notificationType) => {
      const notificationSettings:
        | NotificationSettingOverride
        | ProductNotificationSettingOverride
        | undefined =
        settings[
          notificationType as StoreNotificationType | ProductNotificationType
        ];
      if (
        notificationSettings &&
        !isProductSettings(notificationSettings) &&
        notificationSettings.isConnectionIssue
      ) {
        return {
          store,
          title: t(`notificationSettingRowTitle.connectionIssue`),
          description: t(`notificationSettingRowDescription.connectionIssue`),
          emailFrequency:
            notificationSettings.emailFrequency as NotificationFrequency,
          setFrequency,
          setProducts,
          toggle: toggles[notificationType],
          showAdvancedConfig: Boolean(notificationSettings.advancedConfig),
          showAlwaysOn: Boolean(notificationSettings.blockToggle),
          notificationType: "CONNECTION_ISSUE",
          setNotificationType,
          noProducts: true,
        } as SettingsRowProps;
      }
      if (
        notificationSettings &&
        isProductSettings(notificationSettings) &&
        (!notificationSettings.restrictedMarketplace ||
          notificationSettings.restrictedMarketplace === store?.marketplaceType)
      ) {
        return {
          store,
          title: t(`notificationSettingRowTitle.${notificationType}`),
          description: t(
            `notificationSettingRowDescription.${notificationType}`
          ),
          emailFrequency:
            notificationSettings.emailFrequency as NotificationFrequency,
          setFrequency,
          products: notificationSettings.products,
          setProducts,
          toggle: toggles[notificationType],
          showAdvancedConfig: Boolean(notificationSettings.advancedConfig),
          notificationType,
          setNotificationType,
          setDaysCover,
          daysCoverLimit: notificationSettings?.advancedConfig?.daysCoverLimit,
          noProducts: false,
        } as SettingsRowProps;
      }
      if (notificationSettings && !isProductSettings(notificationSettings)) {
        return {
          store,
          title: t(`notificationSettingRowTitle.${notificationType}`),
          description: t(
            `notificationSettingRowDescription.${notificationType}`
          ),
          emailFrequency:
            notificationSettings.emailFrequency as NotificationFrequency,
          setFrequency,
          setProducts,
          toggle: toggles[notificationType],
          showAdvancedConfig: Boolean(notificationSettings.advancedConfig),
          showAlwaysOn: Boolean(notificationSettings.blockToggle),
          notificationType,
          setNotificationType,
          noProducts: true,
        } as SettingsRowProps;
      }
    });
    const groups = groupBy(uniqBy(rows, "notificationType"), "noProducts");

    return (
      <Grid container spacing={0}>
        <HeaderRow
          item
          xs={12}
          container
          direction="row"
          alignItems="center"
          spacing={0}
        >
          <Grid
            container
            item
            xs={12}
            md={6}
            direction="row"
            alignItems="center"
          >
            <Typography variant="h3">
              {t("notifications.storeSettings")}
            </Typography>
          </Grid>
        </HeaderRow>

        {groups?.true?.map((group) => (
          <SettingsRow {...(group as SettingsRowProps)} />
        ))}
        <HeaderRow xs={12}>
          <Typography variant="h3">
            {t("notifications.productSettings")}
          </Typography>
        </HeaderRow>
        {groups?.false?.map((group) => (
          <SettingsRow {...(group as SettingsRowProps)} />
        ))}
        <HeaderRow
          item
          xs={12}
          container
          direction="row"
          alignItems="center"
          spacing={0}
        >
          <Grid container item xs={12} md={6} direction="column">
            <Typography variant="h3">
              {t("notifications.applyToAllMessage")}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {t("notifications.applyToAllSubtitle")}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={2}
            md={2}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <StyledTypography onClick={() => applyToAll("isEnabled")}>
              {t("notifications.applyToAll")}
            </StyledTypography>
            <Typography variant="subtitle2" color="textSecondary">
              {t("notifications.applyToAllIsEnabled")}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={5}
            md={2}
            direction="column"
            alignItems="center"
            justifyContent="flex-start"
          >
            <StyledTypography
              align="center"
              onClick={() => applyToAll("emailFrequency")}
            >
              {t("notifications.applyToAll")}
            </StyledTypography>
            <Typography
              align="center"
              variant="subtitle2"
              color="textSecondary"
            >
              {t("notifications.applyToAllEmailFrequency")}
            </Typography>
          </Grid>
          <Grid container item xs={12} md={2} />
        </HeaderRow>
      </Grid>
    );
  };

  return (
    <PageBlock>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Alert isOpen={true} type="plain">
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <StoreSelector
                changeStore={changeStore}
                currentStore={{
                  marketplace: store?.marketplaceType,
                  merchantId: store?.merchantId,
                  sourceSystemId: store?.merchantId.split(" @")[0],
                  marketplaceCountry: store?.marketplaceCountry,
                  storeName: store?.storeName,
                }}
                maxWidth={300}
                fullWidth={false}
              />
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Typography variant="body2">
                  {t("notifications.turnAllOff")}
                </Typography>
                <Box display="flex" alignItems="center">
                  <Switch
                    size="small"
                    color="primary"
                    checked={!allOff}
                    onChange={toggleAll}
                  />
                  <Typography variant="body2">
                    {allOff ? t("switch.off") : t("switch.on")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Alert>
        </Grid>
        <Grid item xs={12}>
          <Panel
            title={t("notifications.settingsPanelTitle")}
            subtitle={
              lastUpdated
                ? t("reportComments.externalCommentsSaved", {
                    date: moment
                      .utc(lastUpdated)
                      .tz(timezone)
                      .locale(i18next.language)
                      .format("ll LT"),
                  }) + ` (${timezone})`
                : undefined
            }
            id="notification-settings"
            content={
              fetchingSettings ? (
                <PanelLoading />
              ) : store ? (
                renderSettingsPanel()
              ) : (
                <Box
                  p={4}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography>{t("notificiations.selectAStore")}</Typography>
                </Box>
              )
            }
          />
        </Grid>
      </Grid>
    </PageBlock>
  );
});

export default NotificationSettings;
