import Bold from "~/components/typography/bold";
import { Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const Wrapper = styled(Box)`
  background-color: ${({ theme }) => theme.palette.table.header};
  color: ${({ theme }) => theme.palette.table.text.header};
  padding: 0.75rem 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
  &:last-child {
    border-bottom: 0;
  }
`;

const BoldTypography = styled(Bold)`
  text-transform: uppercase;
`;

interface SectionHeaderProps {
  title: string;
}

export const SectionHeader = ({ title }: SectionHeaderProps) => {
  return (
    <Wrapper>
      <BoldTypography variant="body1">{title}</BoldTypography>
    </Wrapper>
  );
};
