import React, { memo, useCallback, useMemo } from "react";

import { Box } from "@material-ui/core";
import { Header } from "./header";
import { SectionHeader } from "./sectionHeader";
import { TableData } from "./tableData";
import { useTranslation } from "react-i18next";

export interface ProfitAndLossData {
  key: string;
  value: string;
  incomePercent: string;
  valuePerUnit: string;
  bold?: boolean;
  subCategory?: boolean;
  tooltip?: boolean | string;
  translate?: boolean;
  actions?: React.ReactNode;
}

interface ProfitAndLossStatementProps {
  income: ProfitAndLossData[];
  profit: ProfitAndLossData[];
  expense: ProfitAndLossData[];
  metrics: ProfitAndLossData[];
  currentCurrency: string;
  isComparison?: boolean;
}

interface RowDefinition {
  header?: boolean;
  sectionHeader?: string;
  dataSection?: ProfitAndLossData[];
  key: string;
}

export const ProfitAndLossTable = memo<ProfitAndLossStatementProps>(
  function ProfitAndLossTable({
    income,
    profit,
    expense,
    metrics,
    currentCurrency,
    isComparison,
  }) {
    const { t } = useTranslation();

    const rows = useMemo<RowDefinition[]>(() => {
      return [
        { header: true, key: "header" },
        { sectionHeader: t("profitability.incomeLabel"), key: "incomeHeader" },
        { dataSection: income, key: "income" },
        {
          sectionHeader: t("profitability.expensesLabel"),
          key: "expenseHeader",
        },
        { dataSection: expense, key: "expense" },
        { dataSection: profit, key: "profit" },
        {
          sectionHeader: t("profitability.metricsLabel"),
          key: "metricsHeader",
        },
        { dataSection: metrics, key: "metrics" },
      ];
    }, [income, profit, expense, metrics]);

    const getRowComponent = useCallback((row: RowDefinition) => {
      if (row.header) {
        return (
          <Header
            currentCurrency={currentCurrency}
            isComparison={isComparison}
            key={row.key}
          />
        );
      }
      if (row.sectionHeader) {
        return <SectionHeader title={row.sectionHeader} key={row.key} />;
      }
      if (row.dataSection) {
        return <TableData data={row.dataSection} key={row.key} />;
      }

      return null;
    }, []);

    return <Box>{rows.map((row) => getRowComponent(row))}</Box>;
  }
);
