import { Box } from "@material-ui/core";
import { Cell } from "./cell";
import { ProfitAndLossData } from "./profitAndLossTable";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

interface TableDataProps {
  data: ProfitAndLossData[];
}

export const Row = styled(Box)`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  padding: 0.25rem 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
`;

export const TableData = ({ data }: TableDataProps) => {
  const { t } = useTranslation();

  return (
    <>
      {data.map(
        ({
          key,
          value,
          incomePercent,
          valuePerUnit,
          bold,
          subCategory,
          tooltip,
          translate,
          actions,
        }) => {
          return (
            <Row key={key}>
              <Cell
                key="label"
                value={translate ? t(`vendorProfitAndLoss.${key}Label`) : key}
                startAlign={!subCategory}
                tooltip={
                  typeof tooltip === "string"
                    ? tooltip
                    : tooltip
                    ? t(`vendorProfitAndLoss.${key}Tooltip`)
                    : undefined
                }
                width="35%"
                bold={bold}
                actions={actions}
              />
              <Cell key="value" value={value} width="25%" bold={bold} />
              <Cell key="incomePercent" value={incomePercent} bold={bold} />
              <Cell key="valuePerUnit" value={valuePerUnit} bold={bold} />
            </Row>
          );
        }
      )}
    </>
  );
};
