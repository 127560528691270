import { Grid, Link, Typography, withTheme } from "@material-ui/core";
import React, { memo } from "react";

import { LaunchRounded } from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

const CellLink = withTheme(styled(Link)`
  ${({ theme }) => `
    color: ${theme.palette.link.secondary};
    font-weight: 600;
    width: 100%;
  `}
`);

const ExternalCellLink = withTheme(styled(Link)`
  ${({ theme }) => `
    color: ${theme.palette.link.secondary};
    font-weight: 600;
    width: 100%;
  `}
`);

const ExternalCellLinkIcon = withTheme(styled(LaunchRounded)`
  font-size: 1rem;
  margin-left: 5px;
  ${({ theme }) => `
    color: ${theme.palette.link.secondary};
    font-weight: 600;
  `}
`);

const RowContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const ColumnContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
`;

/* Need a width specification to get text overflow to work correctly */
const ShopName = styled(Typography)`
  width: 100%;
`;

type LinkCellProps = {
  cell: Record<string, any>;
  colorVariant?: "internal" | "external";
};

export const LinkCell = memo<LinkCellProps>(function LinkCell({
  cell,
  colorVariant = "internal",
}) {
  const value = cell.value;
  const shop = value.value;
  const link = value.link;
  const subtitle = value.subtitle;

  return (
    <Grid container alignItems="flex-start">
      {colorVariant === "external" ? (
        <RowContainer>
          {link ? (
            <>
              <ExternalCellLink
                href={link}
                target="_blank"
                variant="body2"
                noWrap
              >
                {shop}
              </ExternalCellLink>
              <ExternalCellLinkIcon />
            </>
          ) : (
            <ShopName variant="body2" noWrap>
              {shop}
            </ShopName>
          )}
        </RowContainer>
      ) : (
        <ColumnContainer>
          {link ? (
            <CellLink to={link} component={RouterLink} variant="body2" noWrap>
              {shop}
            </CellLink>
          ) : (
            <Typography variant="body2" noWrap>
              {shop}
            </Typography>
          )}
          {subtitle && <Typography variant="subtitle2">{subtitle}</Typography>}
        </ColumnContainer>
      )}
    </Grid>
  );
});
