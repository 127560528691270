import { MarketplaceFilters, Range } from "~/typedef/store";

import { PaginationArgs } from "~/typedef/pagination";
import { api } from "@store/apiSlice";
import { baseUrl } from "~/configs";
import { globalQueryErrorHandler } from "@store/utils/errorHandlerUtils";

export interface RefundAnalyticsChartArgs {
  mid: string;
  marketplaceFilters: MarketplaceFilters;
  currentRange: Range;
}

interface RefundAnalyticsChartData {
  startTime: number;
  grossSales: number;
  refunds: number;
  netSales: number;
  refundRatePercentage: number;
}

export interface RefundAnalyticsChartResponse {
  chartData: {
    startTime: number;
    current: RefundAnalyticsChartData;
    prior: RefundAnalyticsChartData;
  }[];
  currency: string;
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    refundAnalyticsChart: build.query<
      RefundAnalyticsChartResponse,
      RefundAnalyticsChartArgs
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/api/generic-mws-service/api/refundAnalytics/refundsChart`,
          method: "POST",
          data: params,
        };
      },
      onQueryStarted: globalQueryErrorHandler("RefundAnalyticsChart"),
    }),
  }),
});

export const { useRefundAnalyticsChartQuery } = extendedApiSlice;
