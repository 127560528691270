/** An alternate version of the store dashboard page which allows
widgets to be arranged in an arbitrary order, and for only select
widgets to be displayed. Currently used for Seller Society,
with plans to expand this by allowing each user to choose their
dashboard layout. */
import { Box, Grid } from "@material-ui/core";
import { WidgetId, WidgetOption } from "~/store/customLayout.redux";
import {
  hasAnyAdvertisingStores,
  stripFilteredSuffix,
} from "~/utils/marketplaceUtils";

import AccountHealth from "~/modules/overview/accountHealth";
import AdSpendInvestmentVsBudget from "~/modules/overview/adSpendInvestmentVsBudget";
import BuyBoxSummary from "~/modules/widgets/buyboxSummary";
import CampaignPerformance from "~/modules/marketing/campaignPerformance";
import DispatchStatus from "~/modules/widgets/overview/dispatchStatus";
import GenericBuybox from "../pricing/genericBuyboxPerformance";
import ItemSpecifics from "~/modules/widgets/itemSpecifics/itemSpecifics";
import ListingQuality from "~/modules/widgets/listingQuality/listingQuality";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import MarketingAuthoriseUser from "~/modules/marketing/authoriseAdvertising";
import NotificationsPieChart from "~/components/charts/pieChart/notificationsPieChart";
import OverviewChartWrapper from "~/modules/marketing/charts/overviewChartWrapper";
import { Panel } from "~/components/panel/panel";
import ProfitAndLossStatement from "~/pages/singleChannel/profitability/profitAndLossStatement";
import ProfitabilitySplit from "~/modules/widgets/profitability/profitabilitySplit";
import React from "react";
import SalesByBrandTable from "~/modules/overview/salesByBrand";
import SalesByCategoryTable from "~/modules/overview/salesByCategory";
import SalesByDay from "~/modules/widgets/salesByDay";
import SalesByProduct from "~/modules/overview/salesByProduct";
import SalesPerformance from "~/modules/overview/salesPerformance";
import SalesPerformanceAgainstBudget from "~/modules/overview/salesPerformanceAgainstBudget";
import SalesPerformanceAgainstForecast from "~/modules/overview/salesPerformanceAgainstForecast";
import { StoreState } from "~/typedef/store";
import TrafficAndConversion from "~/pages/singleChannel/trafficAndConversion";
import get from "lodash/get";
import { healthDataConfig } from "~/utils/accountHealthUtils";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const FullHeightBox = styled(Box)`
  height: 100%;
`;

interface CustomStoreDashboardProps {
  isAdvertisingAuthorised: boolean;
  isReconnectAdvertising: boolean;
}

const CustomStoreDashboard = ({
  isAdvertisingAuthorised,
  isReconnectAdvertising,
}: CustomStoreDashboardProps) => {
  const { t } = useTranslation();
  const store = useTypedSelector((state: StoreState) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const widgets: WidgetOption[] | undefined = useTypedSelector(
    (state) => state.customLayout.layoutConfig?.myStores?.widgets
  );

  if (!widgets || !store) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <LoadingIndicator />
      </Box>
    );
  }

  const isAdvertising = hasAnyAdvertisingStores([store]);

  return (
    <>
      {[...widgets]
        .sort((a, b) => a.position - b.position)
        .map((widget) => {
          switch (widget.id) {
            case WidgetId.SALES_PERFORMANCE:
              return (
                <Grid item xs={12} md={8}>
                  <SalesPerformance market={store.marketplace} store={store} />
                </Grid>
              );
            case WidgetId.ACCOUNT_HEALTH:
              return (
                <Grid container item xs={12} md={4}>
                  <Grid item xs={12}>
                    <AccountHealth
                      market={
                        store.marketplace as keyof typeof healthDataConfig
                      }
                      mid={store.merchantId}
                      condensed
                    />
                  </Grid>
                </Grid>
              );
            case WidgetId.TOP_SELLERS:
              return (
                <Grid item xs={12} md={6}>
                  <SalesByProduct
                    mid={store.merchantId}
                    marketplace={store.marketplace}
                    marketplaceSubtype={
                      store.marketplaceSubtype ?? store.marketplace
                    }
                    countryCode={store.marketplaceCountry}
                    sellerId={store.sourceSystemId}
                    type="topSellers"
                    condensed={true}
                  />
                </Grid>
              );
            case WidgetId.SALES_BY_DAY:
              return (
                <Grid item xs={12} md={6}>
                  <SalesByDay
                    mid={store.merchantId}
                    market={store.marketplace}
                  />
                </Grid>
              );
            case WidgetId.ADVERTISING_PERFORMANCE:
              if (isAdvertisingAuthorised) {
                return (
                  <>
                    <Grid item xs={12} md={6}>
                      <OverviewChartWrapper
                        countryCode={store.marketplaceCountry}
                        marketplaceType={store.marketplace}
                        mid={store.merchantId}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CampaignPerformance
                        market={store.marketplace}
                        mid={store.merchantId}
                        countryCode={store.marketplaceCountry}
                      />
                    </Grid>
                  </>
                );
              } else if (store.marketplace === "amazon") {
                return (
                  <Grid item xs={12} md={6}>
                    <Panel
                      id="widget-marketing-campaign-chart"
                      title={t(
                        "advertisingDashboardWidget.authorise.performanceTrendCampaignPerformance"
                      )}
                      content={
                        <MarketingAuthoriseUser
                          condensed
                          isReconnect={isReconnectAdvertising}
                        />
                      }
                    />
                  </Grid>
                );
              } else {
                return null;
              }
            case WidgetId.SALES_BY_CATEGORY:
              return (
                <Grid item xs={12} md={6}>
                  <SalesByCategoryTable
                    market={store.marketplace}
                    mid={store.merchantId}
                    condensed
                  />
                </Grid>
              );
            case WidgetId.SALES_BY_BRAND:
              return (
                <Grid item xs={12} md={6}>
                  <SalesByBrandTable
                    market={store.marketplace}
                    mid={store.merchantId}
                    condensed
                  />
                </Grid>
              );
            case WidgetId.WORST_SELLERS:
              return (
                <Grid item xs={12} md={6}>
                  <SalesByProduct
                    mid={store.merchantId}
                    marketplace={store.marketplace}
                    marketplaceSubtype={
                      store.marketplaceSubtype ?? store.marketplace
                    }
                    countryCode={store.marketplaceCountry}
                    sellerId={store.sourceSystemId}
                    type="worstSellers"
                    condensed={true}
                  />
                </Grid>
              );
            case WidgetId.BUYBOX_PERFORMANCE:
              if (store.marketplace === "amazon") {
                return (
                  <>
                    <Grid item xs={12} md={6}>
                      <FullHeightBox>
                        <GenericBuybox singleWidget={"trend"} />
                      </FullHeightBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FullHeightBox>
                        <BuyBoxSummary
                          {...{
                            store: {
                              sourceSystemId: store.sourceSystemId,
                              marketplaceCountry: store.marketplaceCountry,
                              merchantId: store.merchantId,
                            },
                          }}
                        />
                      </FullHeightBox>
                    </Grid>
                  </>
                );
              } else {
                return null;
              }
            case WidgetId.DISPATCH_STATUS:
              return (
                <Grid item xs={12} md={6}>
                  <DispatchStatus
                    market={store.marketplace}
                    mid={store.merchantId}
                  />
                </Grid>
              );
            case WidgetId.ITEM_SPECIFICS:
              if (store.marketplace === "ebay") {
                return (
                  <>
                    <Grid item xs={12} md={8}>
                      <ItemSpecifics
                        {...{
                          market: store.marketplace,
                          mid: store.merchantId,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <ListingQuality
                        {...{
                          market: store.marketplace,
                          mid: store.merchantId,
                        }}
                      />
                    </Grid>
                  </>
                );
              } else {
                return null;
              }
            case WidgetId.AD_SPEND_AGAINST_BUDGET:
              if (isAdvertising) {
                return (
                  <Grid item xs={12} md={4}>
                    <AdSpendInvestmentVsBudget
                      mid={store.merchantId}
                      marketplaceType={store.marketplace}
                      countryCode={store.marketplaceCountry}
                      marketplaceSubtype={store.marketplaceSubtype}
                    />
                  </Grid>
                );
              } else {
                return null;
              }
            case WidgetId.SALES_AGAINST_BUDGET:
              return (
                <Grid item xs={12} md={4}>
                  <SalesPerformanceAgainstBudget
                    mid={store.merchantId}
                    marketplaceType={store.marketplace}
                  />
                </Grid>
              );
            case WidgetId.SALES_AGAINST_FORECAST:
              return (
                <Grid item xs={12} md={4}>
                  <SalesPerformanceAgainstForecast
                    mid={store.merchantId}
                    marketplaceType={store.marketplace}
                  />
                </Grid>
              );
            case WidgetId.TRAFFIC_AND_CONVERSION:
              if (stripFilteredSuffix(store.marketplace) === "amazon") {
                return (
                  <>
                    <Grid item xs={12} md={8}>
                      <TrafficAndConversion singleWidget={"trend"} />
                    </Grid>
                  </>
                );
              } else {
                return null;
              }
            case WidgetId.UNRESOLVED_NOTIFICATIONS:
              return (
                <Grid item xs={12} md={4}>
                  <NotificationsPieChart
                    title={t("notificationsWidget.unresolvedNotifications")}
                    inPanel={true}
                    store={store}
                    flexDirection="column"
                  />
                </Grid>
              );
            case WidgetId.STORE_PROFITABILITY:
              if (
                ["amazon", "walmart"].includes(
                  stripFilteredSuffix(store.marketplace) as string
                )
              ) {
                return (
                  <>
                    <Grid item xs={12} md={8}>
                      <ProfitAndLossStatement
                        title={t("nav.storeProfit")}
                        showComparison={true}
                        condensed={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <ProfitabilitySplit singleStore={true} condensed={true} />
                    </Grid>
                  </>
                );
              } else {
                return null;
              }
            case WidgetId.TOP_SELLERS_BSR:
              if (store.marketplace === "amazon") {
                return (
                  <Grid item xs={12} md={6}>
                    <SalesByProduct
                      mid={store.merchantId}
                      marketplace={store.marketplace}
                      marketplaceSubtype={
                        store.marketplaceSubtype ?? store.marketplace
                      }
                      countryCode={store.marketplaceCountry}
                      sellerId={store.sourceSystemId}
                      type="topBSR"
                      condensed={true}
                    />
                  </Grid>
                );
              } else {
                return null;
              }
          }
        })}
    </>
  );
};

export default CustomStoreDashboard;
