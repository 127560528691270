import { Box, Grid, useTheme } from "@material-ui/core";
import React, { ReactChild, memo } from "react";

import { CHART_TITLES } from "~/components/charts/chartUtils/chartUtils";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import LineAndBarChart from "~/components/charts/comboChart/lineAndBarChart";
import Panel from "~/components/panel/panel";
import { Range } from "~/typedef/store";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import { useTrafficAndConversionTrendQuery } from "~/store/mystore/trafficAndConversion.redux";
import { useTranslation } from "react-i18next";

interface TrafficAndConversionParams {
  mid: string;
  marketplaceType: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  actions?: ReactChild;
  report?: boolean;
  condensed?: boolean;
  timezone: string;
}

const TrafficAndConversionTrend = memo(
  ({
    mid,
    marketplaceType,
    currentPeriod,
    currentRange,
    actions,
    report,
    condensed,
    timezone,
  }: TrafficAndConversionParams) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const { data, loading } = useTrafficAndConversionTrendQuery(
      {
        currentRange,
        mid,
      },
      {
        selectFromResult: ({ data, isFetching }) => ({
          data: data?.data || [],
          loading: isFetching,
        }),
      }
    );

    const footerLink =
      condensed && !report
        ? {
            url: marketplaceLink(marketplaceType, mid, "trafficandconversion"),
            label: t("generic.viewAllLink"),
          }
        : undefined;

    return (
      <Panel
        id="traffic-conversion-trend"
        title={t("chartTitle.trafficAndConversionTrend")}
        tooltip={
          marketplaceType === "walmart"
            ? t("trafficAndConversion.walmartTooltip")
            : undefined
        }
        actions={actions}
        footerLink={footerLink}
        content={
          <Box p={2}>
            <Grid container spacing={1}>
              <Grid container item xs={12}>
                <LineAndBarChart
                  title={t(CHART_TITLES[currentPeriod])}
                  currentPeriod={currentPeriod}
                  chartData={data}
                  isLoading={loading}
                  line={{
                    key: "conversionRate",
                    colour: theme.palette.secondary.main,
                    toFixed: 1,
                  }}
                  bar={{
                    key: "pageViews",
                    colour: theme.palette.primary.main,
                  }}
                  xKey="startTime"
                  xKey2="endTime"
                  report={report}
                  timezone={timezone}
                  interval={currentRange?.interval}
                />
              </Grid>
            </Grid>
          </Box>
        }
      />
    );
  }
);

export default TrafficAndConversionTrend;
