import { Box, Tooltip, Typography } from "@material-ui/core";

import { LightInlineIconButton } from "~/icons/inlineIconButton";
import React from "react";
import styled from "styled-components";

interface CellProps {
  value: string;
  startAlign?: boolean;
  tooltip?: string;
  bold?: boolean;
  width?: string;
  uppercase?: boolean;
  actions?: React.ReactNode;
}

const Wrapper = styled(Box)<{ $startAlign?: boolean; $width?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0;
  width: ${({ $width }) => $width || "20%"};
  flex-grow: 0;
  flex-shrink: 0;
  ${({ $startAlign }) =>
    $startAlign
      ? `justify-content: flex-start;`
      : `justify-content: flex-end; text-align: right; `}
`;

const TextWrapper = styled(Typography)<{
  $bold?: boolean;
  $uppercase?: boolean;
}>`
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-weight: ${({ $bold }) => ($bold ? "bold" : "normal")};
  text-transform: ${({ $uppercase }) => ($uppercase ? "uppercase" : "none")};
`;

export const Cell = ({
  value,
  tooltip,
  startAlign,
  bold,
  width,
  uppercase,
  actions,
}: CellProps) => {
  return (
    <Wrapper $startAlign={startAlign} $width={width}>
      {tooltip && (
        <Tooltip title={tooltip}>
          <LightInlineIconButton />
        </Tooltip>
      )}
      <TextWrapper variant="body1" $bold={bold} $uppercase={uppercase}>
        {value}
      </TextWrapper>
      {actions}
    </Wrapper>
  );
};
