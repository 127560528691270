import ActionCell, {
  ReactTableCell,
} from "~/components/table/cells/actionCell";
import ColumnSelect, { Column } from "~/components/adTable/columnSelect";
import React, {
  ReactChild,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  fetchInventoryReplenishmentProducts,
  fetchInventoryReplenishmentRecords,
  useInventoryReplenishmentSettingsQuery,
} from "~/store/mystore/inventoryReplenishment.redux";
import {
  formatCurrency,
  getCurrencyByCountryCode,
} from "~/utils/currencyUtils";

import { AmazonCountry } from "mm-amazon-common/dist/typedef/mws";
import { AvailabilityCell } from "~/components/table/cells/availabilityCell";
import { Box } from "@material-ui/core";
import { DEFAULT_DAYS_COVER_TARGET } from "./replenishmentSetting";
import FlatTable from "~/components/table/table";
import { InventoryReplenishment } from "~/typedef/store";
import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import NoScrollTable from "~/components/table/table";
import Panel from "~/components/panel/panel";
import Table from "~/components/adTable/table";
import { TextCell } from "~/components/table/cells/textCell";
import { User } from "~/typedef/user";
import ValueAndTooltipCell from "~/components/table/cells/valueAndTooltipCell";
import { ValueCell } from "~/components/table/cells/valueCell";
import { amazonSites } from "mm-amazon-common/dist/mwsConstants";
import moment from "moment";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

export const InlineBlockDiv = styled.div`
  display: inline-block;
  @media print {
    display: none;
  }
`;

const PAGE_SIZE = 25;
const CONDENSED_ROWS = 5;

interface InventoryReplenishmentTableData
  extends Omit<InventoryReplenishment, "estimated-storage-cost-next-month"> {
  title: {
    value: string;
    secondRowValue: string;
    link: string;
    image: string;
    target: "_blank";
  };
  "estimated-storage-cost-next-month": string;
}

interface InventoryReplenishmentTableProps {
  user: User;
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  countryCode: string;
  currentCurrency: string;
  footerLink?: {
    url: any;
    external?: boolean;
    label?: string;
  };
  actions?: ReactChild;
  condensed?: boolean;
  report?: boolean;
  searchText?: string;
  pageSize?: number;
  selectedTimezone: string;
  activeProducts: boolean;
}

const VOLUME_UNIT: Record<string, string> = {
  "cubic meter": "m³",
  "cubic feet": "ft³",
};

const InventoryReplenishmentTable = memo<InventoryReplenishmentTableProps>(
  function InventoryReplenishmentTable({
    user,
    mid,
    marketplaceType,
    marketplaceSubtype,
    countryCode,
    currentCurrency,
    footerLink,
    actions,
    condensed,
    report,
    searchText,
    pageSize,
    selectedTimezone,
    activeProducts,
  }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const contentRef = React.useRef<HTMLHeadingElement>(null);

    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );
    const isLoading = useTypedSelector(
      (state) => state?.inventoryReplenishment?.table?.loading
    );
    const inventoryReplenishmentData = useTypedSelector(
      (state) => state?.inventoryReplenishment?.table?.data
    );
    const inventoryReplenishmentProducts = useTypedSelector(
      (state) => state?.inventoryReplenishment?.products?.data
    );

    const { daysCoverTarget, inventoryReplenishmentSettingsLastUpdated } =
      useInventoryReplenishmentSettingsQuery(
        {
          mid,
          marketplaceType,
          marketplaceSubtype,
          marketplaceCountry: countryCode,
        },
        {
          skip: report,
          selectFromResult: ({ data }) => ({
            daysCoverTarget:
              data?.settings.daysCoverTarget || DEFAULT_DAYS_COVER_TARGET,
            inventoryReplenishmentSettingsLastUpdated: data?.updatedAt,
          }),
        }
      );

    const caseConfigUploading = useTypedSelector(
      (state) => state?.inventoryReplenishment?.caseConfigUpload?.uploading
    );

    const [settingsLastUpdated, setSettingsLastUpdated] = useState<
      string | null
    >(null);
    const [pageIndexInState, setPageIndexInState] = useState(0);
    const [sortByInState, setSortByInState] = useState<{
      id: string;
      desc: boolean;
    } | null>(null);

    const [isInitialLoad, setIsInitialLoad] = useState(true);

    const createShipmentLink = useMemo(() => {
      return amazonSites[countryCode as AmazonCountry]?.retailHost
        ? `https://sellercentral.${
            amazonSites[countryCode as AmazonCountry]?.retailHost
          }/fba/sendtoamazon/confirm_shipping_step`
        : null;
    }, [countryCode]);

    const dispatchFetchInventoryReplenishmentRecords = useCallback(
      ({ pageIndex, pageSize, sortBy, settingsRefresh = false }) => {
        // To take into consideration when refreshing on settings update
        if (!settingsRefresh) {
          setPageIndexInState(pageIndex);
          sortBy && sortBy.length && setSortByInState(sortBy);
        }

        dispatch(
          fetchInventoryReplenishmentRecords({
            mid,
            marketplaceType,
            marketplaceSubtype,
            countryCode,
            ...(sortBy && sortBy.length
              ? {
                  sortKey: sortBy[0].id,
                  sortOrder: sortBy[0].desc ? "desc" : "asc",
                }
              : {
                  sortKey: "replenUnits",
                  sortOrder: "desc",
                }),
            pageIndex,
            pageSize,
            searchText,
            activeProducts,
          })
        );
        setIsInitialLoad(false);
      },
      [
        mid,
        marketplaceType,
        marketplaceSubtype,
        countryCode,
        searchText,
        activeProducts,
      ]
    );

    const fetchData = async () => {
      await dispatchFetchInventoryReplenishmentRecords({
        pageIndex: 0,
        pageSize: pageSize ?? PAGE_SIZE,
        sortBy: sortByInState ? sortByInState : undefined,
      });
    };

    useEffect(() => {
      if (mid) {
        fetchData();
      }
    }, [
      mid,
      marketplaceType,
      marketplaceSubtype,
      countryCode,
      searchText,
      activeProducts,
    ]);

    // The below is to make sure that the table is reloaded when the settings are updated
    // we only want to refresh when the settings change, not pageIndex or sortBy
    // table handles those
    useEffect(() => {
      if (
        settingsLastUpdated &&
        inventoryReplenishmentSettingsLastUpdated !== settingsLastUpdated
      ) {
        // Settings have been updated
        dispatchFetchInventoryReplenishmentRecords({
          pageIndex: pageIndexInState,
          pageSize: pageSize ?? PAGE_SIZE,
          sortBy: sortByInState ? sortByInState : undefined,
          settingsRefresh: true,
        });
      }
      if (inventoryReplenishmentSettingsLastUpdated) {
        setSettingsLastUpdated(inventoryReplenishmentSettingsLastUpdated);
      }
    }, [inventoryReplenishmentSettingsLastUpdated]);

    useEffect(() => {
      // when the case config has uploaded and its not the initial load
      if (!caseConfigUploading && !isInitialLoad) {
        dispatchFetchInventoryReplenishmentRecords({
          pageIndex: 0,
          pageSize: pageSize ?? PAGE_SIZE,
          sortBy: sortByInState ? sortByInState : undefined,
          settingsRefresh: false,
        });
      }
    }, [caseConfigUploading]);

    useEffect(() => {
      if (
        inventoryReplenishmentData &&
        inventoryReplenishmentData.rows &&
        inventoryReplenishmentData.rows.length > 0
      ) {
        dispatch(
          fetchInventoryReplenishmentProducts({
            user,
            mid,
            skus: inventoryReplenishmentData.rows.map(
              (row: InventoryReplenishment) => row.sku
            ),
          })
        );
      }
    }, [inventoryReplenishmentData]);

    const data = useMemo<InventoryReplenishmentTableData[]>(() => {
      const sourceCurrency = getCurrencyByCountryCode[countryCode] || "AUD";
      return inventoryReplenishmentData && inventoryReplenishmentData.rows
        ? inventoryReplenishmentData.rows.map((row: InventoryReplenishment) => {
            const matchedProduct = inventoryReplenishmentProducts?.find(
              (product) => product.sellerSku === row.sku
            );
            return {
              ...row,
              title: {
                value: row["product-name"],
                secondRowValue: `ASIN: ${row.asin} | SKU: ${row.sku}`,
                link: matchedProduct?.linkUrl ?? "",
                image: matchedProduct?.imageUrl ?? "",
                target: "_blank",
              },
              "estimated-storage-cost-next-month": !row[
                "estimated-storage-cost-next-month"
              ]
                ? "-"
                : formatCurrency(
                    row["estimated-storage-cost-next-month"],
                    currencyRates,
                    sourceCurrency,
                    currentCurrency
                  ),
            };
          })
        : [];
    }, [
      inventoryReplenishmentData,
      inventoryReplenishmentProducts,
      currencyRates,
      currentCurrency,
    ]);

    const columns = useMemo(
      () => [
        {
          Header: t("myStoresWidget.inventoryReplenishment.productColumn"),
          id: "product-name",
          accessor: (row: InventoryReplenishmentTableData) => ({
            value: row.title.value,
            secondRowValue: row.title.secondRowValue,
            image: row.title.image,
            link: row.title.link,
            target: "_blank",
          }),
          Cell: (props: any) => (
            <LinkAndImageCell {...props} colorVariant="external" />
          ),
          isVisible: true,
          sticky: "left",
          minWidth: report ? 300 : undefined,
        },
        {
          id: "storage-type",
          Header: t("myStoresWidget.inventoryReplenishment.storageType"),
          accessor: "storage-type",
          Cell: TextCell,
          isVisible: true,
          align: "left",
          hiddenDown: "xl",
          divideRight: true,
          whiteSpace: "pre",
        },
        {
          id: "daysCover",
          Header: t("myStoresWidget.inventoryReplenishment.daysCover"),
          accessor: (row: InventoryReplenishmentTableData) =>
            !row.daysCover ? "-" : row.daysCover,
          Cell: ValueCell,
          align: "right",
          isVisible: true,
          divideRight: true,
          whiteSpace: "pre",
        },
        {
          id: "inventory",
          Header: t("myStoresWidget.inventoryReplenishment.totalInventory"),
          accessor: (row: InventoryReplenishmentTableData) => {
            return {
              availableValue: row.inventory,
              compareValue: row.daysCover,
              threshold: daysCoverTarget,
              hideUnits: true,
            };
          },
          Cell: AvailabilityCell,
          align: "center",
          isVisible: true,
          whiteSpace: "pre",
        },
        {
          id: "available",
          Header: t("myStoresWidget.inventoryReplenishment.available"),
          accessor: (row: InventoryReplenishmentTableData) =>
            !row["available"] ? "-" : row["available"],
          Cell: ValueCell,
          align: "right",
          isVisible: true,
          hiddenDown: "xl",
          whiteSpace: "pre",
        },
        {
          id: "reserved-quantity",
          Header: t("myStoresWidget.inventoryReplenishment.reserved"),
          accessor: (row: InventoryReplenishmentTableData) =>
            !row["reserved-quantity"] ? "-" : row["reserved-quantity"],
          Cell: ValueCell,
          align: "right",
          isVisible: true,
          hiddenDown: "xl",
          whiteSpace: "pre",
        },
        {
          id: "inbound-quantity",
          Header: t("myStoresWidget.inventoryReplenishment.inbound"),
          accessor: (row: InventoryReplenishmentTableData) =>
            !row["inbound-quantity"] ? "-" : row["inbound-quantity"],
          Cell: ValueCell,
          align: "right",
          isVisible: true,
          whiteSpace: "pre",
        },
        {
          id: "unfulfillable-quantity",
          Header: t("myStoresWidget.inventoryReplenishment.unfulfillable"),
          accessor: (row: InventoryReplenishmentTableData) =>
            !row["unfulfillable-quantity"]
              ? "-"
              : row["unfulfillable-quantity"],
          Cell: ValueCell,
          align: "right",
          isVisible: true,
          hiddenDown: "xl",
          divideRight: true,
          whiteSpace: "pre",
        },
        {
          id: "storage-volume",
          Header: t("myStoresWidget.inventoryReplenishment.storageVolume"),
          accessor: (row: InventoryReplenishmentTableData) =>
            !row["storage-volume"]
              ? "-"
              : row["storage-volume"].toFixed(2) +
                `${
                  VOLUME_UNIT[row["volume-unit-measurement"]] ??
                  row["volume-unit-measurement"]
                }`,
          Cell: ValueCell,
          align: "right",
          isVisible: true,
          hiddenDown: "xl",
          whiteSpace: "pre",
        },
        {
          id: "estimated-storage-cost-next-month",
          Header: t(
            "myStoresWidget.inventoryReplenishment.monthlyEstStorageCost"
          ),
          accessor: "estimated-storage-cost-next-month",
          Cell: ValueCell,
          align: "right",
          isVisible: true,
          hiddenDown: "xl",
          divideRight: true,
          whiteSpace: "pre",
        },
        {
          id: "dailySales7",
          Header: t("myStoresWidget.inventoryReplenishment.dailySales7"),
          accessor: (row: InventoryReplenishmentTableData) =>
            !row["dailySales7"] ? "-" : row["dailySales7"].toFixed(1),
          Cell: ValueCell,
          align: "right",
          isVisible: true,
          whiteSpace: "pre",
          hiddenDown: "xl",
        },
        {
          id: "dailySales30",
          Header: t("myStoresWidget.inventoryReplenishment.dailySales30"),
          accessor: (row: InventoryReplenishmentTableData) =>
            !row["dailySales30"] ? "-" : row["dailySales30"].toFixed(1),
          Cell: ValueCell,
          align: "right",
          isVisible: true,
          whiteSpace: "pre",
          hiddenDown: "xl",
        },
        {
          id: "dailySales60",
          Header: t("myStoresWidget.inventoryReplenishment.dailySales60"),
          accessor: (row: InventoryReplenishmentTableData) =>
            !row["dailySales60"] ? "-" : row["dailySales60"].toFixed(1),
          Cell: ValueCell,
          align: "right",
          isVisible: true,
          whiteSpace: "pre",
          hiddenDown: "xl",
        },

        {
          id: "dailySales90",
          Header: t("myStoresWidget.inventoryReplenishment.dailySales90"),
          accessor: (row: InventoryReplenishmentTableData) =>
            !row["dailySales90"] ? "-" : row["dailySales90"].toFixed(1),
          Cell: ValueCell,
          align: "right",
          isVisible: true,
          whiteSpace: "pre",
          hiddenDown: "xl",
        },
        {
          id: "dailyWeightedUnits",
          Header: t("myStoresWidget.inventoryReplenishment.dailyWeightedUnits"),
          accessor: (row: InventoryReplenishmentTableData) =>
            !row["dailyWeightedUnits"]
              ? "-"
              : row["dailyWeightedUnits"].toFixed(1),
          Cell: ValueCell,
          align: "right",
          divideRight: true,
          isVisible: true,
          whiteSpace: "pre",
          hiddenDown: "xl",
        },
        {
          id: "replenUnits",
          Header: t("myStoresWidget.inventoryReplenishment.replenUnits"),
          accessor: (row: InventoryReplenishmentTableData) =>
            !row.replenUnits ? "-" : row.replenUnits,
          Cell: ValueCell,
          align: "right",
          isVisible: true,
          whiteSpace: "pre",
        },
        {
          id: "replenCases",
          Header: t("myStoresWidget.inventoryReplenishment.replenCases"),
          accessor: (row: InventoryReplenishmentTableData) =>
            !row.replenCases ? "-" : row.replenCases,
          Cell: ValueCell,
          align: "right",
          isVisible: true,
          whiteSpace: "pre",
        },
        {
          id: "unitsPerCase",
          Header: t("myStoresWidget.inventoryReplenishment.unitsPerCase"),
          accessor: (row: InventoryReplenishmentTableData) => {
            return !row.unitsPerCaseInt
              ? {
                  value: "-",
                }
              : {
                  value: row.unitsPerCaseInt,
                  tooltip:
                    row.unitsPerCase === "NOT_CONFIGURED"
                      ? t(
                          "myStoresWidget.inventoryReplenishment.unitsPerCaseTooltip"
                        )
                      : undefined,
                };
          },
          Cell: ValueAndTooltipCell,
          align: "right",
          isVisible: true,
          hiddenDown: "xl",
          whiteSpace: "pre",
        },
        {
          id: "oosDate",
          Header: t("myStoresWidget.inventoryReplenishment.oosDate"),
          accessor: (row: InventoryReplenishmentTableData) => {
            const momentDate = moment(row.oosDate);
            if (row.oosDate && momentDate.isValid()) {
              return momentDate.tz(selectedTimezone).format("ll");
            } else {
              return "-";
            }
          },
          Cell: TextCell,
          isVisible: false,
          hiddenDown: "xl",
          whiteSpace: "pre",
        },
        {
          id: "estimatedReorderDate",
          Header: t(
            "myStoresWidget.inventoryReplenishment.estimatedReorderDate"
          ),
          accessor: (row: InventoryReplenishmentTableData) => {
            const momentDate = moment(row.estimatedReorderDate);
            if (row.estimatedReorderDate && momentDate.isValid()) {
              return momentDate.tz(selectedTimezone).format("ll");
            } else {
              return "-";
            }
          },
          Cell: TextCell,
          isVisible: false,
          hiddenDown: "xl",
          whiteSpace: "pre",
        },
        {
          id: "action",
          Header: t("myStoresWidget.inventoryReplenishment.action"),
          accessor: "replenUnits",
          Cell: (cell: ReactTableCell) => {
            const actions =
              cell.value > 0
                ? [
                    {
                      text: t(
                        "myStoresWidget.inventoryReplenishment.createShippingPlan"
                      ),
                      action: () => {
                        createShipmentLink &&
                          window.open(createShipmentLink, "_blank");
                      },
                    },
                  ]
                : [];
            return <ActionCell actions={actions} />;
          },
          isVisible: true,
          align: "center",
          disableSortBy: true,
          hiddenDown: "xl",
        },
      ],
      []
    );

    const [myColumns, setMyColumns] = useState<Column[]>(columns);

    // To be used if a Condensed View is required
    // Default to display all columns
    const condensedColumns = useMemo(() => columns, []);

    return (
      <Panel
        id="widget-inventory-replenishment-table"
        title={
          report
            ? t("myStoresWidget.inventoryReplenishmentTable.reportTitle")
            : t("myStoresWidget.inventoryReplenishmentTable.mainTitle")
        }
        footerLink={footerLink}
        content={
          condensed ? (
            <>
              <InlineBlockDiv ref={contentRef} />
              <NoScrollTable
                {...{
                  columns: condensedColumns,
                  data: data,
                  pageSize: pageSize ?? CONDENSED_ROWS,
                  gridLayoutColumns: 6,
                  loading: isLoading || !mid,
                }}
              />
            </>
          ) : (
            <>
              <InlineBlockDiv ref={contentRef} />
              {report ? (
                <FlatTable
                  {...{
                    columns,
                    data,
                    fetchData: dispatchFetchInventoryReplenishmentRecords,
                    loading: isLoading || !mid,
                    sorting: false,
                    pagination: false,
                    pageSize: PAGE_SIZE,
                    isReport: report,
                  }}
                />
              ) : (
                <Table
                  {...{
                    columns: myColumns,
                    data: data,
                    fetchData: dispatchFetchInventoryReplenishmentRecords,
                    sorting: true,
                    pagination: true,
                    pageSize: pageSize ?? PAGE_SIZE,
                    pageCount: inventoryReplenishmentData?.count
                      ? Math.ceil(
                          inventoryReplenishmentData?.count /
                            (pageSize ?? PAGE_SIZE)
                        )
                      : 0,
                    loading: isLoading || !mid,
                  }}
                />
              )}
            </>
          )
        }
        actions={
          <>
            {actions}
            {report ? null : (
              <Box pl={2}>
                <ColumnSelect
                  {...{ columns: myColumns, setColumns: setMyColumns }}
                />
              </Box>
            )}
          </>
        }
      />
    );
  }
);

export default InventoryReplenishmentTable;
