import { DATETIME_PERIODS, INTERVAL } from "~/store/utils/dateTimeUtils";
import {
  hasAnyAmazonVendorStores,
  hasOnlyVendorStores,
  stripFilteredSuffix,
} from "~/utils/marketplaceUtils";

import CampaignCustomGroupsSelect from "~/modules/widgets/customGroups/advertising/campaignCustomGroupSelect";
import CustomGroupsSelect from "~/modules/widgets/customGroups/customGroupsSelect";
import { FC } from "react";
import { MenuItem } from "~/components/toolbars/sideNavigation/sideNavConstants";
import MetricSelect from "~/modules/widgets/customGroups/advertising/metricSelect";
import { Option } from "~/components/dragAndDrop/dragAndDrop";
import { Palette } from "@material-ui/core/styles/createPalette";
import ShippedRevenueMetricSelect from "~/modules/vendor/shippedRevenueMetricSelect";
import { Store } from "~/typedef/store";
import { TFunction } from "react-i18next";
import { User } from "@typedef/user";
import { findMenuItem } from "~/components/toolbars/sideNavigation/sideNavUtils";
import { isValidSnSCountry } from "mm-amazon-common/dist/shared/countryUtils";
import moment from "moment-timezone";
import { useSideNavConfig } from "~/hooks/useSideNavConfig";

export const getNextReportTime = (
  frequency: string | number,
  timezone: string,
  reportingDelay?: number
) => {
  const reportTime = moment.tz(timezone).set({
    hour: 8,
    minute: 0,
    second: 0,
  });
  switch (frequency) {
    case "d":
      if (reportTime.isBefore(moment())) {
        reportTime.add(1, "day");
      }
      if (reportingDelay) {
        reportTime.add(reportingDelay, "days");
      }
      return reportTime;
    case "w":
      reportTime.day("Monday");
      if (reportTime.isBefore(moment())) {
        reportTime.add(1, "week");
      }
      if (reportingDelay) {
        reportTime.add(reportingDelay, "days");
      }
      return reportTime;
    case "m":
      reportTime.date(5);
      if (reportTime.isBefore(moment())) {
        reportTime.add(1, "month");
      }
      if (reportingDelay) {
        reportTime.add(reportingDelay, "days");
      }
      return reportTime;
    case "y":
      reportTime.week(1);
      if (reportTime.isBefore(moment())) {
        reportTime.add(1, "year");
      }
      if (reportingDelay) {
        reportTime.add(reportingDelay, "days");
      }
      return reportTime;
    default:
      return moment();
  }
};

export const getReportDayAndTime = (
  frequency: string | number,
  timezone: string,
  reportingDelay?: number
) => {
  const reportTime = getNextReportTime(frequency, timezone, reportingDelay);
  const reportDay =
    frequency === "w"
      ? reportTime.format("ddd")
      : frequency === "m"
      ? reportTime.format("Do")
      : frequency === "y"
      ? reportTime.format("Do MMM")
      : "";

  return {
    reportDay,
    reportTime,
  };
};

export const getScheduledDescription = (
  frequency: string | number,
  t: TFunction<"translation">,
  timezone: string,
  reportingDelay?: number
) => {
  const { reportDay, reportTime } = getReportDayAndTime(
    frequency,
    timezone,
    reportingDelay
  );
  switch (frequency) {
    case "d":
      return t("createReport.sendDailyDescription", {
        reportTime: reportTime.calendar(),
        timezone,
      });
    case "w":
      return t("createReport.sendWeeklyDescription", {
        reportTime: reportTime.format("ll"),
        reportDay,
        timezone,
      });
    case "m":
      return t("createReport.sendMonthlyDescription", {
        reportTime: reportTime.format("ll"),
        reportDay,
        timezone,
      });
    case "y":
      return t("createReport.sendYearlyDescription", {
        reportTime: reportTime.format("ll"),
        reportDay,
        timezone,
      });
    default:
      return t("createReport.sendOnceDescription");
  }
};

export interface ReportOptionSection {
  title: string;
  items: ReportOption[];
}

interface PartialReportOptionSection {
  title: string;
  items: Omit<ReportOption, "position">[];
}

export interface ReportOption {
  id: string;
  text: string;
  navId?: string;
  position: number;
  color: keyof Palette;
  dropped?: boolean;
  extraConfiguration?: FC<any>;
  options?: { tag: string };
  // TODO: Remove these once we have a proper date range picker for each widget
  customRange?: DATETIME_PERIODS;
  customInterval?: INTERVAL;
}

const PaletteKey = {
  Primary: "primary" as keyof Palette,
  Secondary: "secondary" as keyof Palette,
};

const filterReportOption = (
  item: Omit<ReportOption, "position">,
  menuItems: MenuItem[],
  user: User,
  marketplaceType: Marketplace,
  marketplaceSubtype: Marketplace,
  marketplaceCountry: string
) => {
  if (!item.navId) return false;
  const menuItem = findMenuItem(item.navId, menuItems);
  if (!menuItem) return false;
  if (
    menuItem.featureFlagged &&
    !user?.[menuItem.featureFlagged as keyof User]
  ) {
    return false;
  }
  if (menuItem.disabledFor?.markets.includes(marketplaceType)) {
    const disabledForCountries =
      menuItem.disabledFor?.countries?.[marketplaceType] ?? [];
    if (
      disabledForCountries.length === 0 ||
      disabledForCountries.includes(marketplaceCountry)
    ) {
      return false;
    }
  }

  if (!menuItem.restricted) return true;
  return Boolean(menuItem.restricted.markets.includes(marketplaceSubtype));
};

export const getSingleStoreOptions = (
  t: TFunction,
  marketplaceType: Marketplace,
  marketplaceSubtype: Marketplace,
  marketplaceCountry: string,
  user: User
): ReportOptionSection[] => {
  const menuItems = useSideNavConfig(marketplaceSubtype);
  const trimmedMarketplaceSubtype = stripFilteredSuffix(marketplaceSubtype);
  const isVendorStore = trimmedMarketplaceSubtype === "amazon_vendor";
  const isAmazonSeller = trimmedMarketplaceSubtype === "amazon";
  const hasProfitability = ["amazon", "walmart"].includes(
    trimmedMarketplaceSubtype || ""
  );
  const shouldIncludeSnsProductTable = isValidSnSCountry(
    marketplaceType,
    marketplaceCountry
  );
  const advertisingOptions: PartialReportOptionSection[] = [
    {
      title: "nav.marketing",
      items: [
        {
          id: "advertisingPerformanceTrend",
          text: t("advertisingDashboardWidget.performanceTrend.mainTitle"),
          navId: "marketingOverview",
          color: PaletteKey.Primary,
          extraConfiguration: MetricSelect,
        },
        {
          id: "advertisingPerformanceSummary",
          text: t("advertisingDashboardWidget.performanceTotals.mainTitle"),
          navId: "marketingOverview",
          color: PaletteKey.Primary,
        },
        {
          id: "advertisingProducts",
          text: t("advertisingDashboardWidget.products.mainTitle"),
          navId: "marketingOverview",
          color: PaletteKey.Primary,
        },
        {
          id: "advertisingPerformanceByCampaignType",
          text: t("advertisingDashboardWidget.campaignTypes"),
          navId: "marketingOverview",
          color: PaletteKey.Primary,
        },
        {
          id: "campaignByGroup",
          text: t("customGroups.campaignGroupsTable.mainTitle"),
          color: PaletteKey.Primary,
          navId: "marketingCampaigns",
          extraConfiguration: CampaignCustomGroupsSelect,
        },
        {
          id: "advertisingCampaignPerformance",
          text: t("advertisingDashboardWidget.campaignPerformance"),
          navId: "marketingCampaigns",
          color: PaletteKey.Primary,
        },
        {
          id: "advertisingTopPerformingProductAds",
          text: t("advertisingDashboardWidget.topPerformingProductAds"),
          navId: "marketingProductAds",
          color: PaletteKey.Primary,
        },
        {
          id: "advertisingTopPerformingKeywords",
          text: t("advertisingDashboardWidget.topPerformingKeywords"),
          navId: "marketingKeywords",
          color: PaletteKey.Primary,
        },
      ].filter((extendedOption) => {
        return filterReportOption(
          extendedOption,
          menuItems,
          user,
          marketplaceType,
          marketplaceSubtype,
          marketplaceCountry
        );
      }),
    },
  ];
  const ecommerceOptions: PartialReportOptionSection[] = [
    {
      title: "nav.trafficAndConversion",
      items: [
        {
          id: "trafficAndConversionTrend",
          text: t("chartTitle.trafficAndConversionTrend"),
          navId: "trafficandconversion",
          color: PaletteKey.Primary,
        },
        {
          id: "trafficAndConversionTrendYTD",
          text: t("chartTitle.trafficAndConversionTrendYTD"),
          navId: "trafficandconversion",
          color: PaletteKey.Primary,
          customRange: DATETIME_PERIODS.YEAR,
        },
        {
          id: "trafficAndConversionTotals",
          text: t("myStoresWidget.trafficAndConversionSummary"),
          navId: "trafficandconversion",
          color: PaletteKey.Primary,
        },
        {
          id: "trafficMixTrend",
          text: t("chartTitle.trafficMixTrend"),
          navId: "trafficandconversion",
          color: PaletteKey.Primary,
        },
        {
          id: "trafficAndConversionTableParents",
          text: `${t("trafficAndConversion.mainTitle")} - ${t(
            "trafficAndConversion.parentView"
          )}`,
          navId: "trafficandconversion",
          color: PaletteKey.Primary,
        },
        {
          id: "trafficAndConversionTableSkus",
          text: `${t("trafficAndConversion.mainTitle")} - ${t(
            "trafficAndConversion.childView"
          )}`,
          navId: "trafficandconversion",
          color: PaletteKey.Primary,
        },
        {
          id: "trafficAndConversionNormalVsB2BTotals",
          text: `${t("myStoresWidget.B2BvsNormalSales")}`,
          navId: "trafficandconversion",
          color: PaletteKey.Primary,
        },
        {
          id: "trafficAndConversionTrend",
          text: t("chartTitle.trafficAndConversionTrend"),
          navId: "trafficandconversionwalmart",
          color: PaletteKey.Primary,
        },
        {
          id: "trafficAndConversionTotals",
          text: t("myStoresWidget.trafficAndConversionSummary"),
          navId: "trafficandconversionwalmart",
          color: PaletteKey.Primary,
        },
        {
          id: "trafficAndConversionTableSkus",
          text: `${t("trafficAndConversion.mainTitle")}`,
          navId: "trafficandconversionwalmart",
          color: PaletteKey.Primary,
        },
      ].filter((extendedOption) => {
        return filterReportOption(
          extendedOption,
          menuItems,
          user,
          marketplaceType,
          marketplaceSubtype,
          marketplaceCountry
        );
      }),
    },
    ...(shouldIncludeSnsProductTable
      ? [
          {
            title: "nav.subscribeAndSave",
            items: [
              {
                id: "subscribeAndSaveProductTable",
                text: t("myStoresWidget.snsProductTable.mainTitle"),
                navId: "subscribeAndSave",
                color: PaletteKey.Primary,
              },
            ],
          },
        ]
      : []),
    {
      title: "nav.brandAnalytics",
      items: [
        {
          id: "orderedRevenueChart",
          text: t("chartTitle.orderedRevenueTrend"),
          navId: "orderedrevenue",
          color: PaletteKey.Primary,
        },
        {
          id: "orderedRevenueTable",
          text: t("orderedRevenue.mainTitle"),
          navId: "orderedrevenue",
          color: PaletteKey.Primary,
        },
        {
          id: "orderedRevenueSummary",
          text: t("myStoresWidget.orderedRevenueSummary"),
          navId: "orderedrevenue",
          color: PaletteKey.Primary,
        },
        {
          id: "shippedRevenueChart",
          text: t("report.ShippedRevenueTrend"),
          navId: "shippedrevenue",
          color: PaletteKey.Primary,
          extraConfiguration: ShippedRevenueMetricSelect,
        },
        {
          id: "shippedRevenueChartYTD",
          text: t("chartTitle.ShippedRevenueTrendYTD"),
          navId: "shippedrevenue",
          color: PaletteKey.Primary,
          extraConfiguration: ShippedRevenueMetricSelect,
          customRange: DATETIME_PERIODS.YEAR,
        },
        {
          id: "shippedRevenueChartCurrentYear",
          text: t("chartTitle.ShippedRevenueTrendCurrentYear"),
          navId: "shippedrevenue",
          color: PaletteKey.Primary,
          extraConfiguration: ShippedRevenueMetricSelect,
          customRange: DATETIME_PERIODS.CURRENTYEAR,
          customInterval: INTERVAL.MONTHS,
        },
        {
          id: "shippedRevenueLineChartCurrentYear",
          text: t("chartTitle.ShippedRevenueTrendLineCurrentYear"),
          navId: "shippedrevenue",
          color: PaletteKey.Primary,
          extraConfiguration: ShippedRevenueMetricSelect,
          customRange: DATETIME_PERIODS.CURRENTYEAR,
          customInterval: INTERVAL.WEEKS,
        },
        {
          id: "shippedRevenueSummary",
          text: t("myStoresWidget.shippedRevenueSummary"),
          navId: "shippedrevenue",
          color: PaletteKey.Primary,
        },
        {
          id: "shippedRevenueSummaryYTD",
          text: t("myStoresWidget.shippedRevenueSummaryYTD"),
          navId: "shippedrevenue",
          color: PaletteKey.Primary,
          customRange: DATETIME_PERIODS.YEAR,
        },
        {
          id: "shippedRevenueTable",
          text: t("myStoresWidget.shippedRevenueTable"),
          navId: "shippedrevenue",
          color: PaletteKey.Primary,
        },
        {
          id: "vendorGroupAnalytics",
          text: t("customVendorGroups.productGroupsTable.mainTitle"),
          navId: "orderedrevenue",
          color: PaletteKey.Primary,
          extraConfiguration: CustomGroupsSelect,
        },
        {
          id: "missedShippedCogs",
          text: t("chartTitle.sourcingShareTrend"),
          navId: "sourcingshare",
          color: PaletteKey.Primary,
        },
        {
          id: "shippedUnitsSummary",
          text: t("sourcingShare.summaryTitle"),
          navId: "sourcingshare",
          color: PaletteKey.Primary,
        },
        {
          id: "sourcingShareByProduct",
          text: t("sourcingShare.mainTitle"),
          navId: "sourcingshare",
          color: PaletteKey.Primary,
        },
        {
          id: "inventoryHealthChart",
          text: t("inventoryHealthTrend.mainTitle"),
          navId: "inventoryhealth",
          color: PaletteKey.Primary,
        },
        {
          id: "inventoryHealthSummary",
          text: t("myStoresWidget.inventoryHealthSummary"),
          navId: "inventoryhealth",
          color: PaletteKey.Primary,
        },
        {
          id: "inventoryHealthTable",
          text: t("inventoryHealth.mainTitle"),
          navId: "inventoryhealth",
          color: PaletteKey.Primary,
        },
      ].filter((extendedOption) => {
        return filterReportOption(
          extendedOption,
          menuItems,
          user,
          marketplaceType,
          marketplaceSubtype,
          marketplaceCountry
        );
      }),
    },
    {
      title: isVendorStore ? "nav.POAnalysis" : "nav.salesInsights",
      items: [
        ...(isVendorStore
          ? [
              {
                id: "purchaseOrdersPerformance",
                text: t(`dashboardWidget.poTrend.mainTitle`),
                navId: "storeoverview",
                color: PaletteKey.Primary,
              },
              {
                id: "purchaseOrdersPerformanceLineChart",
                text: t(`dashboardWidget.poTrendLineChart.mainTitle`),
                navId: "storeoverview",
                color: PaletteKey.Primary,
              },

              {
                id: "purchaseOrdersPerformanceShipDate",
                text: t(`dashboardWidget.poTrendShipDate.mainTitle`),
                navId: "storeoverview",
                color: PaletteKey.Primary,
              },
              {
                id: "purchaseOrdersPerformanceLineChartShipDate",
                text: t(`dashboardWidget.poTrendLineChartShipDate.mainTitle`),
                navId: "storeoverview",
                color: PaletteKey.Primary,
              },
              {
                id: "purchaseOrdersStatusPieChart",
                text: t(`vendor.purchaseOrderStatus.title`),
                navId: "storeoverview",
                color: PaletteKey.Primary,
              },
            ]
          : [
              {
                id: "salesPerformance",
                text: t(`report.salesPerformance`),
                navId: "storeoverview",
                color: PaletteKey.Primary,
              },
              {
                id: "salesPerformanceWithSalesBudget",
                text: t(`report.salesPerformanceWithSalesBudget`),
                navId: "storeoverview",
                color: PaletteKey.Primary,
              },
              {
                id: "salesPerformanceLineChart",
                text: t(`report.salesPerformanceLineChart`),
                navId: "storeoverview",
                color: PaletteKey.Primary,
              },
              {
                id: "salesPerformanceLineChartWithSalesBudget",
                text: t(`report.salesPerformanceLineChartWithSalesBudget`),
                navId: "storeoverview",
                color: PaletteKey.Primary,
              },
              {
                id: "salesPerformanceCurrentYear",
                text: t(`report.salesPerformanceCurrentYear`),
                navId: "storeoverview",
                color: PaletteKey.Primary,
                customRange: DATETIME_PERIODS.CURRENTYEAR,
                customInterval: INTERVAL.MONTHS,
              },
              {
                id: "salesPerformanceCurrentYearLineChart",
                text: t(`report.salesPerformanceCurrentYearLineChart`),
                navId: "storeoverview",
                color: PaletteKey.Primary,
                customRange: DATETIME_PERIODS.CURRENTYEAR,
                customInterval: INTERVAL.WEEKS,
              },
              {
                id: "salesPerformanceAgainstForecast",
                text: t(`report.salesPerformanceAgainstForecast`),
                navId: "forecastsandcosts",
                color: PaletteKey.Primary,
              },
              {
                id: "salesPerformanceAgainstBudget",
                text: t(`report.salesPerformanceAgainstBudget`),
                navId: "forecastsandcosts",
                color: PaletteKey.Primary,
              },
              {
                id: "adSpendInvestmentVsBudget",
                text: t(`report.adSpendInvestmentVsBudget`),
                navId: "forecastsandcosts",
                color: PaletteKey.Primary,
              },
            ]),

        {
          id: "salesPerformanceYTD",
          text: t(
            isVendorStore ? "report.poTrendYTD" : "report.salesPerformanceYTD"
          ),
          navId: "storeoverview",
          color: PaletteKey.Primary,
        },
        {
          id: "salesByCategory",
          text: t(
            isVendorStore
              ? "myStoresWidget.poCategory.mainTitle"
              : "myStoresWidget.salesByCategory.mainTitle"
          ),
          navId: "salesbycategory",
          color: PaletteKey.Primary,
        },
        ...(isVendorStore
          ? []
          : [
              {
                id: "salesByCountry",
                text: t("salesbyCountryTable.mainTitle"),
                navId: "salesbycountry",
                color: PaletteKey.Primary,
              },
            ]),
        {
          id: "salesByBrand",
          text: t(
            `myStoresWidget.${
              isVendorStore ? "poBrand" : "salesByBrand"
            }.mainTitle`
          ),
          navId: "salesbybrand",
          color: PaletteKey.Primary,
        },
        {
          id: "salesByGroup",
          text: t(
            isVendorStore
              ? "customGroups.productGroupsTable.mainTitle"
              : "nav.salesByGroup"
          ),
          navId: "salesbygroup",
          color: PaletteKey.Primary,
          extraConfiguration: CustomGroupsSelect,
        },
        {
          id: "salesByParent",
          text: t("nav.salesByParent"),
          navId: "salesbyparent",
          color: PaletteKey.Primary,
        },
        ...(isAmazonSeller
          ? [
              {
                id: "topBSR",
                text: t(`myStoresWidget.topBSR.mainTitle`),
                navId: "salesbyproduct",
                color: PaletteKey.Primary,
              },
            ]
          : []),
        {
          id: "topSellers",
          text: t(
            isVendorStore
              ? `purchaseOrder.topSellers.mainTitle`
              : `myStoresWidget.topSellers.mainTitle`
          ),
          navId: "salesbyproduct",
          color: PaletteKey.Primary,
        },
        {
          id: "worstSellers",
          text: t(
            isVendorStore
              ? `purchaseOrder.worstSellers.mainTitle`
              : `myStoresWidget.worstSellers.mainTitle`
          ),
          navId: "salesbyproduct",
          color: PaletteKey.Primary,
        },
      ].filter((basicOption) => {
        return filterReportOption(
          basicOption,
          menuItems,
          user,
          marketplaceType,
          marketplaceSubtype,
          marketplaceCountry
        );
      }),
    },
    ...(hasProfitability
      ? [
          {
            title: "nav.profitability",
            items: [
              {
                id: "storeProfit",
                text: t("nav.storeProfit"),
                navId: "profitabilityStore",
                color: PaletteKey.Primary,
              },
              {
                id: "storeProfitAndLossByMonth",
                text: t("nav.monthlyProfit"),
                navId: "profitabilityMonthly",
                color: PaletteKey.Primary,
              },
              {
                id: "productProfitability",
                text: t("myStoresWidget.productProfit.mainTitle"),
                navId: "profitabilityProduct",
                color: PaletteKey.Primary,
              },
            ].filter((extendedOption) => {
              return filterReportOption(
                extendedOption,
                menuItems,
                user,
                marketplaceType,
                marketplaceSubtype,
                marketplaceCountry
              );
            }),
          },
        ]
      : []),
    {
      title: "nav.pricing",
      items: [
        {
          id: "buyBoxHistory",
          text: t("myStoresWidget.buyBoxTrend.mainTitle"),
          navId: "buyBoxPerformance",
          color: PaletteKey.Primary,
        },
        {
          id: "buyBoxSummary",
          text: t("myStoresWidget.buyBoxSummary.mainTitle"),
          navId: "buyBoxPerformance",
          color: PaletteKey.Primary,
        },
      ].filter((extendedOption) => {
        return filterReportOption(
          extendedOption,
          menuItems,
          user,
          marketplaceType,
          marketplaceSubtype,
          marketplaceCountry
        );
      }),
    },
    {
      title: "nav.operations",
      items: [
        {
          id: "accountHealth",
          text: t("dashboardWidget.accountHealth.mainTitle"),
          navId: "accounthealth",
          color: PaletteKey.Primary,
        },
        {
          id: "accountHealthExpanded",
          text: t("dashboardWidget.accountHealth.expanded"),
          navId: "accounthealth",
          color: PaletteKey.Primary,
        },
        {
          id: "reviewStatus",
          text: t("myStoresWidget.reviewStatus"),
          navId: "reviews",
          color: PaletteKey.Primary,
        },
      ].filter((basicOption) => {
        return filterReportOption(
          basicOption,
          menuItems,
          user,
          marketplaceType,
          marketplaceSubtype,
          marketplaceCountry
        );
      }),
    },
    ...(isAmazonSeller && marketplaceCountry !== "MEX"
      ? [
          {
            title: "nav.inventoryManagement",
            items: [
              {
                id: "inventoryReplenishment",
                text: t("myStoresWidget.inventoryReplenishmentTable.mainTitle"),
                navId: "inventoryReplenishment",
                color: PaletteKey.Primary,
              },
              {
                id: "fbaInventoryStatus",
                text: t("inventoryStatus.panelTitle"),
                navId: "inventoryReplenishment",
                color: PaletteKey.Primary,
              },
            ],
          },
        ]
      : []),
    ...advertisingOptions,
  ];

  const options = (marketplaceSubtype === "advertising"
    ? advertisingOptions
    : ecommerceOptions
  )
    .filter((option) => {
      return option.items.length !== 0;
    })
    .map((option, i) => ({
      ...option,
      position: i + 1,
    })) as unknown as ReportOptionSection[];

  return [
    ...options,
    {
      title: "reportComments.widgetTitle",
      items: [
        {
          id: "commentsOverview",
          text: t("reportComments.widgetTitleOverview"),
          position: options.length + 1,
          color: PaletteKey.Primary,
        },
        {
          id: "commentsCustomer",
          text: t("reportComments.widgetTitleCustomer"),
          position: options.length + 2,
          color: PaletteKey.Primary,
        },
        {
          id: "commentsSales",
          text: t("reportComments.widgetTitleSales"),
          position: options.length + 3,
          color: PaletteKey.Primary,
        },
        {
          id: "commentsProfitability",
          text: t("reportComments.widgetTitleProfitability"),
          position: options.length + 4,
          color: PaletteKey.Primary,
        },
        {
          id: "commentsMarketing",
          text: t("reportComments.widgetTitleMarketing"),
          position: options.length + 5,
          color: PaletteKey.Primary,
        },
        {
          id: "commentsOperations",
          text: t("reportComments.widgetTitleOperations"),
          position: options.length + 6,
          color: PaletteKey.Primary,
        },
        {
          id: "commentsOther",
          text: t("reportComments.widgetTitleOther"),
          position: options.length + 7,
          color: PaletteKey.Primary,
        },
      ],
    },
  ];
};

export const getMultiStoreOptions = (
  t: TFunction,
  stores: Store[],
  selectedTags: string[]
): ReportOptionSection[] => {
  const filterForVendor = hasOnlyVendorStores(stores);
  const hasVendor = hasAnyAmazonVendorStores(stores);
  const basicOptions = [
    {
      title: "nav.salesInsights",
      items: [
        {
          id: "salesPerformance",
          text: t(
            filterForVendor
              ? "chartTitle.orderedRevenueTrend"
              : "dashboardWidget.salesPerformance.mainTitle"
          ),
          navId: "storeoverview",
          color: PaletteKey.Secondary,
        },
        {
          id: "salesPerformanceLineChart",
          text: t(
            filterForVendor
              ? "dashboardWidget.orderedRevenueLineChart.mainTitle"
              : "dashboardWidget.salesPerformanceLineChart.mainTitle"
          ),
          navId: "storeoverview",
          color: PaletteKey.Secondary,
        },
        {
          id: "salesPerformanceYTD",
          text: t(
            `report.${
              filterForVendor
                ? "orderedRevenueTrendYTD"
                : "salesPerformanceLineChartYTD"
            }`
          ),
          navId: "storeoverview",
          color: PaletteKey.Secondary,
        },
        {
          id: "marketplaceOverview",
          text: t(
            `dashboardWidget.${
              filterForVendor
                ? "marketplaceOverviewVendor"
                : "marketplaceOverview"
            }.mainTitle`
          ),
          color: PaletteKey.Secondary,
        },
      ],
    },
    ...(filterForVendor
      ? []
      : [
          {
            title: "nav.profitability",
            items: [
              {
                id: "profitabilityAndCostTrends",
                text: t("myStoresWidget.productProfit.chartTitle"),
                color: PaletteKey.Secondary,
              },
              {
                id: "profitabilitySummary",
                text: t("profitSummary.title"),
                color: PaletteKey.Secondary,
              },
              {
                id: "profitAndLoss",
                text: t("dashboardWidget.profitAndLoss.mainTitle"),
                color: PaletteKey.Secondary,
              },
            ],
          },
        ]),
    ...(hasVendor
      ? [
          {
            title: "nav.vendorAnalytics",
            items: [
              {
                id: "purchaseOrdersPerformance",
                text: t(`dashboardWidget.poTrend.mainTitle`),
                color: PaletteKey.Secondary,
              },
              {
                id: "purchaseOrdersPerformanceLineChart",
                text: t(`dashboardWidget.poTrendLineChart.mainTitle`),
                color: PaletteKey.Secondary,
              },
              {
                id: "vendorOverview",
                text: t(`dashboardWidget.vendorOverview.mainTitle`),
                color: PaletteKey.Secondary,
              },
              {
                id: "shippedRevenueChart",
                color: PaletteKey.Secondary,
                text: t("report.ShippedRevenueTrend"),
                navId: "shippedrevenue",
                extraConfiguration: ShippedRevenueMetricSelect,
              },
              {
                id: "shippedRevenueChartYTD",
                text: t("chartTitle.ShippedRevenueTrendYTD"),
                color: PaletteKey.Secondary,
                navId: "shippedrevenue",
                extraConfiguration: ShippedRevenueMetricSelect,
                customRange: DATETIME_PERIODS.YEAR,
              },
              {
                id: "shippedRevenueChartCurrentYear",
                text: t("chartTitle.ShippedRevenueTrendCurrentYear"),
                navId: "shippedrevenue",
                color: PaletteKey.Secondary,
                extraConfiguration: ShippedRevenueMetricSelect,
                customRange: DATETIME_PERIODS.CURRENTYEAR,
                customInterval: INTERVAL.MONTHS,
              },
              {
                id: "shippedRevenueByStores",
                text: t("dashboardWidget.shippedRevenueByStores.mainTitle"),
                color: PaletteKey.Secondary,
              },
            ],
          },
        ]
      : []),
    {
      title: "nav.operations",
      items: [
        {
          id: "inventoryStatus",
          text: t(`dashboardWidget.inventoryStatus.mainTitle`),
          color: PaletteKey.Secondary,
        },
        {
          id: "topPerformingProducts",
          text: t(`dashboardWidget.topPerformingProducts.mainTitle`),
          color: PaletteKey.Secondary,
        },
      ],
    },
    {
      title: "nav.marketing",
      items: [
        {
          id: "marketingCampaignSummary",
          text: t("advertisingDashboardWidget.storePerformance"),
          color: PaletteKey.Secondary,
        },
        {
          id: "advertisingPerformanceTrendOverview",
          text: t("advertisingDashboardWidget.performanceTrend.mainTitle"),
          color: PaletteKey.Secondary,
        },
        {
          id: "advertisingPerformanceSummaryOverview",
          text: t("advertisingDashboardWidget.performanceTotals.mainTitle"),
          color: PaletteKey.Secondary,
        },
      ],
    },
    {
      title: "reportComments.widgetTitle",
      items: selectedTags.map((s) => ({
        id: `commentsMultichannel${s}`,
        text: `${t("reportComments.mainTitle")} - ${s}`,
        color: PaletteKey.Secondary,
        options: {
          tag: s,
        },
      })),
    },
  ].map((option, i) => ({
    ...option,
    position: i + 1,
  })) as unknown as ReportOptionSection[];

  return [...basicOptions];
};

export const getExtraConfiguration = (
  widget: Option,
  allWidgets: ReportOptionSection[]
): FC | undefined => {
  for (const itemArray of allWidgets) {
    const extraConfig = itemArray.items.filter((w) => w.id === widget.id);
    if (extraConfig && extraConfig.length > 0) {
      return extraConfig[0].extraConfiguration;
    }
  }
};
