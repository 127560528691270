import React, { memo, useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";

import AdvertisingDashboard from "./advertisingDashboard";
import AmazonVendorDashboard from "./amazonVendorDashboard";
import CustomStoreDashboard from "./customStoreDashboard";
import DefaultStoreDashboard from "./defaultStoreDashboard";
import { Grid } from "@material-ui/core";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import PageBlock from "~/components/containers/sideNavPageBlock";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import { StoreState } from "~/typedef/store";
import { fetchCustomLayoutConfig } from "~/store/customLayout.redux";
import get from "lodash/get";
import { stripFilteredSuffix } from "~/utils/marketplaceUtils";
import { useAdvertisingConnected } from "~/hooks/useAdvertisingConnected";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const StoreDashboard = memo(() => {
  const dispatch = useDispatch();
  const location = useLocation();
  const store = useTypedSelector((state: StoreState) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const isCustomLayoutEnabled =
    Boolean(
      useTypedSelector((state) => state.customLayout?.layoutConfig?.enabled)
    ) &&
    Boolean(
      useTypedSelector(
        (state) => state.customLayout?.layoutConfig?.myStores?.widgets
      )?.length
    );

  useEffect(() => {
    dispatch(fetchCustomLayoutConfig());
  }, []);

  if (store) {
    const { isAdvertisingAuthorised, isReconnectAdvertising } =
      useAdvertisingConnected(store);

    return (
      <PageBlock>
        <Grid container spacing={2} alignItems="stretch">
          {!store || !store.merchantId ? (
            <PanelLoading />
          ) : store?.marketplaceSubtype === "advertising" ? (
            <Redirect to={{ ...location, pathname: "marketingOverview" }} />
          ) : stripFilteredSuffix(store.marketplace) === "amazon_vendor" ? (
            <AmazonVendorDashboard
              isAdvertisingAuthorised={isAdvertisingAuthorised}
              isReconnectAdvertising={isReconnectAdvertising}
            />
          ) : isCustomLayoutEnabled ? (
            <CustomStoreDashboard
              isAdvertisingAuthorised={isAdvertisingAuthorised}
              isReconnectAdvertising={isReconnectAdvertising}
            />
          ) : (
            <DefaultStoreDashboard
              isAdvertisingAuthorised={isAdvertisingAuthorised}
              isReconnectAdvertising={isReconnectAdvertising}
            />
          )}
        </Grid>
      </PageBlock>
    );
  }
  return (
    <PageBlock>
      <LoadingIndicator />
    </PageBlock>
  );
});

export default StoreDashboard;
