import React, { ReactChild, memo, useCallback, useMemo, useState } from "react";
import {
  RepeatPurchaseByProductRow,
  useRepeatPurchaseByProductQuery,
} from "~/store/mystore/repeatPurchases.redux";
import {
  formatCurrency,
  formatCurrencyRounded,
  getCurrencyByCountryCode,
} from "~/utils/currencyUtils";

import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import { PaginationArgs } from "~/typedef/pagination";
import Panel from "~/components/panel/panel";
import { Range } from "~/typedef/store";
import Table from "~/components/adTable/table";
import { TableCellProp } from "~/components/table/cellProps";
import { TextCell } from "~/components/table/cells/textCell";
import { ValueAndGrowthCell } from "~/components/table/cells/valueAndGrowthCell";
import { getPercentageDifference } from "~/utils/salesUtils";
import { numberWithCommas } from "~/utils/utils";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface RepeatPurchaseByProductProps {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  countryCode: string;
  currentCurrency: string;
  currentRange: Range;
  includeTax: boolean;
  pageSize?: number;
  conditionalFormatting?: boolean;
  actions?: ReactChild;
}

const DEFAULT_PAGE_SIZE = 20;

const RepeatPurchaseByProduct = memo<RepeatPurchaseByProductProps>(
  function RepeatPurchaseByProduct({
    mid,
    marketplaceType,
    marketplaceSubtype,
    countryCode,
    currentCurrency,
    currentRange,
    includeTax,
    pageSize = DEFAULT_PAGE_SIZE,
    conditionalFormatting,
    actions,
  }) {
    const { t } = useTranslation();

    const homeCurrency = useMemo(
      () => getCurrencyByCountryCode[countryCode],
      [countryCode]
    );

    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );

    const [paginationParams, setPaginationParams] = useState<PaginationArgs>({
      pageSize,
      pageIndex: 0,
      sortKey: "repeatSalesPercentage",
      sortOrder: "desc",
    });

    const { isLoading, rows, count } = useRepeatPurchaseByProductQuery(
      {
        mid,
        marketplaceType,
        marketplaceSubtype,
        currentRange,
        includeTax,
        ...paginationParams,
      },
      {
        selectFromResult: ({ data, isFetching }) => {
          return {
            isLoading: isFetching,
            rows: data?.rows || [],
            count: data?.count || 0,
          };
        },
      }
    );

    const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
      setPaginationParams({
        sortKey: sortBy[0]?.id || "repeatSalesPercentage",
        sortOrder: sortBy[0]?.id ? (sortBy[0]?.desc ? "desc" : "asc") : "desc",
        pageIndex,
        pageSize,
      });
    }, []);

    const columns = useMemo(
      () => [
        {
          Header: t("repeatPurchaseByProduct.productColumn"),
          id: "title",
          accessor: (row: RepeatPurchaseByProductRow) => ({
            value: row.title,
            secondRowValue: `${
              marketplaceType === "amazon" ? "ASIN" : "LISTING ID"
            }: ${row.productSku}`,
            image: row.imageUrl,
            link: row.linkUrl,
            target: "_blank",
          }),
          Cell: (
            props: TableCellProp<{
              value: string;
              secondRowValue: string;
              image: string;
              link: string;
              target: string;
            }>
          ) => <LinkAndImageCell {...props} colorVariant="external" />,
          isVisible: true,
          divideRight: true,
          sticky: "left",
          colSpan: 2,
          width: 450, // Only affects the positioning of the next sticky column
        },
        /* Sales */
        {
          Header: t("repeatPurchaseByProduct.totalSalesColumn"),
          id: "totalSales",
          align: "center",
          cellJustify: "center" as const,
          accessor: (row: RepeatPurchaseByProductRow) => {
            return {
              value: row.totalSales.current
                ? formatCurrencyRounded(
                    row.totalSales.current,
                    currencyRates,
                    homeCurrency,
                    currentCurrency
                  )
                : "-",
              growth: row.totalSales.prior
                ? getPercentageDifference(
                    row.totalSales.current,
                    row.totalSales.prior
                  )
                : "N/A",
              conditionalFormatting,
            };
          },
          Cell: ValueAndGrowthCell,
          isVisible: true,
        },
        {
          Header: t("repeatPurchaseByProduct.repeatCustomerSalesColumn"),
          id: "repeatCustomerSales",
          align: "center",
          cellJustify: "center" as const,
          accessor: (row: RepeatPurchaseByProductRow) => {
            return {
              value: row.repeatCustomerSales.current
                ? formatCurrencyRounded(
                    row.repeatCustomerSales.current,
                    currencyRates,
                    homeCurrency,
                    currentCurrency
                  )
                : "-",
              growth: row.repeatCustomerSales.prior
                ? getPercentageDifference(
                    row.repeatCustomerSales.current,
                    row.repeatCustomerSales.prior
                  )
                : "N/A",
              conditionalFormatting,
            };
          },
          Cell: ValueAndGrowthCell,
          isVisible: true,
          whiteSpace: "pre",
        },
        {
          Header: t("repeatPurchaseByProduct.repeatSalesPercentageColumn"),
          id: "repeatSalesPercentage",
          align: "center",
          cellJustify: "center" as const,
          accessor: (row: RepeatPurchaseByProductRow) => {
            return {
              value:
                row.repeatSalesPercentage.current !== null
                  ? `${row.repeatSalesPercentage.current.toFixed(1)}%`
                  : "-",
              growth: getPercentageDifference(
                row.repeatSalesPercentage.current,
                row.repeatSalesPercentage.prior,
                true
              ),
              conditionalFormatting,
              customSuffix: "ppt",
            };
          },
          Cell: ValueAndGrowthCell,
          isVisible: true,
          divideRight: true,
        },
        /* Sales Ends Here */
        /* Customers */
        {
          Header: t("repeatPurchaseByProduct.totalCustomersColumn"),
          id: "totalCustomers",
          align: "center",
          cellJustify: "center" as const,
          accessor: (row: RepeatPurchaseByProductRow) => {
            return {
              value: numberWithCommas(row.totalCustomers.current),
              growth: row.totalCustomers.prior
                ? getPercentageDifference(
                    row.totalCustomers.current,
                    row.totalCustomers.prior
                  )
                : "N/A",
              conditionalFormatting,
            };
          },
          Cell: ValueAndGrowthCell,
          isVisible: true,
          whiteSpace: "pre",
        },
        {
          Header: t("repeatPurchaseByProduct.newCustomersColumn"),
          id: "newCustomers",
          align: "center",
          cellJustify: "center" as const,
          accessor: (row: RepeatPurchaseByProductRow) => {
            return {
              value: numberWithCommas(row.newCustomers.current),
              growth: row.newCustomers.prior
                ? getPercentageDifference(
                    row.newCustomers.current,
                    row.newCustomers.prior
                  )
                : "N/A",
              conditionalFormatting,
            };
          },
          Cell: ValueAndGrowthCell,
          isVisible: true,
          whiteSpace: "pre",
        },
        {
          Header: t("repeatPurchaseByProduct.repeatCustomersColumn"),
          id: "repeatCustomers",
          align: "center",
          cellJustify: "center" as const,
          accessor: (row: RepeatPurchaseByProductRow) => {
            return {
              value: numberWithCommas(row.repeatCustomers.current),
              growth: row.repeatCustomers.prior
                ? getPercentageDifference(
                    row.repeatCustomers.current,
                    row.repeatCustomers.prior
                  )
                : "N/A",
              conditionalFormatting,
            };
          },
          Cell: ValueAndGrowthCell,
          isVisible: true,
          whiteSpace: "pre",
        },
        {
          Header: t("repeatPurchaseByProduct.repeatCustomersPercentageColumn"),
          id: "repeatCustomersPercentage",
          align: "center",
          cellJustify: "center" as const,
          accessor: (row: RepeatPurchaseByProductRow) => {
            return {
              value:
                row.repeatCustomersPercentage.current !== null
                  ? `${row.repeatCustomersPercentage.current.toFixed(1)}%`
                  : "-",
              growth: getPercentageDifference(
                row.repeatCustomersPercentage.current,
                row.repeatCustomersPercentage.prior,
                true
              ),
              conditionalFormatting,
              customSuffix: "ppt",
            };
          },
          Cell: ValueAndGrowthCell,
          isVisible: true,
          divideRight: true,
        },
        /* Customers Ends Here */
        {
          Header: t("repeatPurchaseByProduct.salesPerCustomerColumn"),
          id: "salesPerCustomer",
          align: "center",
          cellJustify: "center" as const,
          accessor: (row: RepeatPurchaseByProductRow) => {
            return row.salesPerCustomer.current
              ? formatCurrency(
                  row.salesPerCustomer.current,
                  currencyRates,
                  homeCurrency,
                  currentCurrency
                )
              : "-";
          },
          Cell: TextCell,
          isVisible: true,
          whiteSpace: "pre",
        },
        {
          Header: t("repeatPurchaseByProduct.salesPerRepeatCustomerColumn"),
          id: "salesPerRepeatCustomer",
          align: "center",
          cellJustify: "center" as const,
          accessor: (row: RepeatPurchaseByProductRow) => {
            return row.salesPerRepeatCustomer.current
              ? formatCurrency(
                  row.salesPerRepeatCustomer.current,
                  currencyRates,
                  homeCurrency,
                  currentCurrency
                )
              : "-";
          },
          Cell: TextCell,
          isVisible: true,
          whiteSpace: "pre",
        },
      ],
      [currencyRates, homeCurrency, currentCurrency]
    );

    return (
      <Panel
        id="widget-repeat-purchase-product"
        title={t("myStoresWidget.repeatPurchaseByProduct")}
        tooltip={t("myStoresWidget.repeatPurchaseByProductTooltip")}
        content={
          <Table
            columns={columns}
            loading={isLoading}
            data={rows}
            fetchData={fetchData}
            sorting={true}
            pagination={true}
            pageSize={pageSize}
            pageCount={Math.ceil(count / pageSize)}
          />
        }
        actions={actions}
      />
    );
  }
);

export default RepeatPurchaseByProduct;
