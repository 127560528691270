import { Grid, Typography } from "@material-ui/core";
import React, { memo, useCallback, useMemo, useState } from "react";
import {
  ReportTemplateRows,
  useDeleteReportTempateMutation,
  useGetReportTemplateByOrgQuery,
} from "~/store/mystore/clientReport.redux";

import ConfirmDialog from "~/components/dialogs/confirmDialog";
import { DATETIME_PERIOD_LABELS } from "~/store/persistentAppSettings.redux";
import { PaginationArgs } from "~/typedef/pagination";
import Panel from "~/components/panel/panel";
import ReportCell from "~/components/table/cells/reportCell";
import Table from "~/components/adTable/table";
import { TextCell } from "~/components/table/cells/textCell";
import { getFrequency } from "./scheduledReportsTable";
import { getScheduledDescription } from "../../pages/reports/reportUtils";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PAGE_SIZE = 25;

interface ReportProps {
  reportId: string;
  reportName: string;
}

const ReportTemplateTable = memo(function ReportTemplateTable() {
  const { t } = useTranslation();
  const userInfo = useTypedSelector((state) => state.user);

  const [paginationParams, setPaginationParams] = useState<PaginationArgs>({
    pageSize: PAGE_SIZE,
    pageIndex: 0,
    sortKey: "createdAt",
    sortOrder: "desc",
  });

  const [markedForDelete, setMarkedForDelete] = useState<ReportProps | null>(
    null
  );

  const [deleteTemplate, { isLoading: deleteTemplateLoading }] =
    useDeleteReportTempateMutation();

  const deleteReport = async () => {
    if (markedForDelete) {
      deleteTemplate({
        reportId: markedForDelete.reportId,
      });
      setMarkedForDelete(null);
    }
  };

  const onMarkDeleted = ({ reportId, reportName }: ReportProps) => {
    setMarkedForDelete({ reportId, reportName });
  };

  const columns = useMemo(
    () => [
      {
        Header: t("reportTemplate.templateName"),
        accessor: (row: any) => ({
          reportId: row._id,
          reportName: row.reportConfig.name,
          storeCount: row.stores?.length,
        }),
        Cell: (props: any) => (
          <ReportCell
            {...props}
            report={props?.row}
            onDelete={onMarkDeleted}
            redirectUrl={`/reports/createReport?template=${props.value.reportId}`}
          />
        ),
        isVisible: true,
        align: "left",
      },
      {
        Header: t("createReport.type"),
        accessor: "reportConfig.reportType",
        Cell: TextCell,
        isVisible: true,
        align: "left",
      },
      {
        Header: t("createReport.preparedBy"),
        accessor: "preparedBy",
        Cell: TextCell,
        isVisible: true,
        align: "left",
      },
      {
        Header: t("createReport.createdAt"),
        id: "createdAt",
        accessor: "createdAt",
        Cell: TextCell,
        isVisible: true,
        align: "left",
      },
      {
        Header: t("createReport.selectDateRangeTitle"),
        accessor: (row: any) =>
          t(
            DATETIME_PERIOD_LABELS[
              row.reportConfig
                .period as unknown as keyof typeof DATETIME_PERIOD_LABELS
            ]
          ),
        Cell: TextCell,
        isVisible: true,
        align: "left",
      },
      {
        Header: t("createReport.reportingFrequency"),
        accessor: (row: ReportTemplateRows) =>
          getFrequency(row.reportConfig, t),
        Cell: TextCell,
        isVisible: true,
        align: "left",
      },
      {
        Header: t("createReport.reportingDay"),
        accessor: (row: ReportTemplateRows) =>
          getScheduledDescription(
            row.reportConfig.frequency,
            t,
            row.reportConfig.timezone,
            row.reportConfig.reportingDelay
          ),
        Cell: TextCell,
        isVisible: true,
        align: "left",
      },
    ],
    []
  );

  const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
    setPaginationParams({
      sortKey: sortBy[0]?.id || "createdAt",
      sortOrder: sortBy[0]?.id ? (sortBy[0]?.desc ? "desc" : "asc") : "desc",
      pageIndex,
      pageSize,
    });
  }, []);

  const { data, count, isLoading } = useGetReportTemplateByOrgQuery(
    {
      ...paginationParams,
    },
    {
      selectFromResult: ({ data, isFetching }) => {
        return {
          data: data?.reportRows || [],
          count: data?.count || 0,
          isLoading: isFetching,
        };
      },
    }
  );

  return (
    <Grid item xs={12}>
      <Panel
        id="report-template-table"
        title={t("createReport.reportTemplates")}
        tooltip={t("createReport.reportTemplatesTooltip")}
        content={
          <Table
            {...{
              columns: columns,
              data: data,
              fetchData,
              pagination: true,
              pageSize: PAGE_SIZE,
              pageCount: Math.ceil(count / PAGE_SIZE),
              loading: isLoading || deleteTemplateLoading,
            }}
          />
        }
        actions={
          <ConfirmDialog
            open={Boolean(markedForDelete)}
            title={t("generic.confirmTitle")}
            onClose={() => setMarkedForDelete(null)}
            onConfirm={deleteReport}
            content={
              <Typography variant="body1">
                {t("createReport.removeReportTemplateMessage", {
                  reportName: markedForDelete?.reportName,
                })}
              </Typography>
            }
          />
        }
      />
    </Grid>
  );
});

export default ReportTemplateTable;
