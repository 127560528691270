import { Grid, Link, Tooltip, Typography, withTheme } from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import LaunchIcon from "@material-ui/icons/Launch";
import { LinkCell } from "./linkCell";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { ScheduledReportType } from "~/modules/widgets/scheduledReportsTable";
import styled from "styled-components";

const ReviewContainer = styled(Grid)`
  min-width: 150px;
`;

const Divider = styled.div`
  height: 50%;
  width: 1px;
  background-color: ${({ theme }) => theme.palette.border.dark};
`;

const SecondRowGridContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  align-items: center;
`;

const StyledEditIcon = styled(EditIcon)`
  fill: ${({ theme }) => theme.palette.grey["500"]};
  width: 1rem;
  height: 1rem;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
`;

const StyledDeleteIcon = withTheme(styled(DeleteIcon)`
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
  fill: ${({ theme }) => theme.palette.grey["500"]};
`);

const StyledLaunchIcon = styled(LaunchIcon)`
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  margin: 4px 5px 0px 5px;
  fill: ${({ theme }) => theme.palette.grey["500"]};
`;

const FlexGridItem = styled(Grid)`
  display: flex;
  height: 100%;
  align-items: center;
`;

interface ReportCellProps {
  cell: { value: any };
  onDelete: ({
    reportId,
    reportName,
  }: {
    reportId: string;
    reportName: string;
  }) => void;
  onEdit: ({
    reportId,
    reportName,
    report,
  }: {
    reportId: string;
    reportName: string;

    report: ScheduledReportType;
  }) => void;
  redirectUrl?: string;
  report: ScheduledReportType;
}

const ReportCell = ({
  cell,
  onEdit,
  onDelete,
  redirectUrl,
  report,
}: ReportCellProps) => {
  const { reportId, reportName, storeCount, reportLink } = cell.value;

  return (
    <>
      <ReviewContainer container>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <LinkCell
              {...{
                colorVariant: "external",
                cell: {
                  value: {
                    value: reportName,
                    link: reportLink,
                  },
                },
              }}
            />
          </Grid>
          <SecondRowGridContainer item container xs={12}>
            <FlexGridItem item style={{ width: "5rem" }}>
              <Typography
                align="left"
                variant="subtitle1"
                color="textSecondary"
                noWrap
              >
                {`${storeCount} Channels`}
              </Typography>
            </FlexGridItem>
            <Divider />
            <FlexGridItem item>
              {onDelete && (
                <Tooltip title="Delete">
                  <StyledDeleteIcon
                    onClick={() => onDelete({ reportId, reportName })}
                  />
                </Tooltip>
              )}
            </FlexGridItem>
            <Divider />
            <FlexGridItem item>
              {onEdit && (
                <Tooltip title="Edit">
                  <StyledEditIcon
                    onClick={() => onEdit({ reportId, reportName, report })}
                  />
                </Tooltip>
              )}
            </FlexGridItem>
            {redirectUrl && (
              <>
                <Divider />
                <FlexGridItem item>
                  <Tooltip title="Edit or create template">
                    <Link
                      to={redirectUrl}
                      component={RouterLink}
                      variant="body2"
                    >
                      <StyledEditIcon />
                    </Link>
                  </Tooltip>
                </FlexGridItem>
              </>
            )}
          </SecondRowGridContainer>
        </Grid>
      </ReviewContainer>
    </>
  );
};

export default ReportCell;
