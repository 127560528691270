import { CurrentStore, Range } from "~/typedef/store";
import ProductTable, {
  ProductEventRow,
} from "~/modules/profitLossProductTable/productTable";
import React, { memo, useCallback, useEffect, useState } from "react";
import {
  fetchProfitabilityCategories,
  useFetchProductProfitabilityQuery,
} from "~/store/mystore/profitability.redux";

import ColumnSelect from "~/components/adTable/columnSelect";
import { DemoTooltip } from "~/components/tooltip/demoTooltip";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import DrawerPanel from "~/components/drawerPanel/drawerPanel";
import { Grid } from "@material-ui/core";
import { PaginationArgs } from "~/typedef/pagination";
import Panel from "~/components/panel/panel";
import ProfitLossTable from "~/modules/profitLossTable/table";
import SearchFilter from "~/components/adTable/searchFilter";
import SidePanel from "~/components/drawerPanel/sidePanel";
import SmallButton from "~/components/buttons/smallButton";
import TemplateDialog from "~/modules/profitLossProductTable/templateDialog";
import { User } from "~/typedef/user";
import get from "lodash/get";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useMarketplace } from "~/utils/navigationUtils";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const StyledButton = styled(SmallButton)`
  margin-right: 8px;
`;

const PAGE_SIZE = 25;

interface ProfitabilityProductProps {
  includeTax: boolean;
  currentCurrency: string;
  currentRange: Range;
  store: CurrentStore;
  exchangeRate: number;
  user: User;
  setSyncDialogOpen?: (open: boolean) => void;
  handleCogsEdit?: (product: ProductEventRow) => void;
  report?: boolean;
  pageSize?: number;
  missingCogs?: boolean;
  setMissingCogs?: (missingCogs: boolean) => void;
}

const DEFAULT_SORT_ORDER = "desc";
const DEFAULT_SORT_KEY = "totalSales";

const allHaveCogs = (rows: ProductEventRow[]) =>
  rows.every((row) => {
    const {
      data: { events },
    } = row;
    return (
      // a row should have either no Principal event, or have both Principal and COGS events
      !events.some((event) => event.label === "Principal") ||
      events.some((event) => event.label === "COGS")
    );
  });

const ProfitabilityProduct = memo<ProfitabilityProductProps>(
  function ProfitabilityProduct({
    includeTax,
    currentCurrency,
    currentRange,
    store,
    exchangeRate,
    user,
    setSyncDialogOpen,
    handleCogsEdit,
    report,
    pageSize,
    missingCogs,
    setMissingCogs,
  }) {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [myColumns, setMyColumns] = useState<any[]>([]);
    const marketplaceName = useMarketplace();
    const [selectedListing, setSelectedListing] = useState<any>();
    const [templateDialogOpen, setTemplateDialogOpen] = useState(false);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const categoriesData = useTypedSelector((state) =>
      get(state.profitability, "categories.data")
    );

    useEffect(() => {
      dispatch(
        fetchProfitabilityCategories({
          mid: store.merchantId,
          includeTax,
        })
      );
    }, [store.merchantId, includeTax]);

    const [paginationParams, setPaginationParams] = useState<PaginationArgs>({
      pageSize: pageSize ?? PAGE_SIZE,
      pageIndex: 0,
      sortOrder: DEFAULT_SORT_ORDER,
      sortKey: DEFAULT_SORT_KEY,
    });

    const { isLoading, data } = useFetchProductProfitabilityQuery(
      {
        mid: store.merchantId,
        includeTax,
        currentRange,
        searchText,
        ...paginationParams,
      },
      {
        selectFromResult: (result) => {
          return {
            isLoading: result.isFetching,
            data: result.data || {
              count: 0,
              rows: [],
            },
          };
        },
      }
    );

    useEffect(() => {
      const rows = data.rows;
      if (rows.length > 0 && setMissingCogs) {
        const newMissingCogs = !allHaveCogs(rows);
        if (missingCogs !== newMissingCogs) {
          setMissingCogs(newMissingCogs);
        }
      }
    }, [data]);

    const fetchProductData = useCallback(({ pageSize, pageIndex, sortBy }) => {
      setPaginationParams({
        pageSize,
        pageIndex,
        sortOrder:
          sortBy.length > 0
            ? sortBy[0].desc
              ? "desc"
              : "asc"
            : DEFAULT_SORT_ORDER,
        sortKey: sortBy.length > 0 ? sortBy[0].id : DEFAULT_SORT_KEY,
      });
    }, []);

    const handleListingSelect = useCallback(
      (listing) => {
        setSelectedListing(listing);
        setDrawerOpen(true);
      },
      [setDrawerOpen]
    );

    return (
      <DrawerPanel
        open={drawerOpen}
        setOpen={setDrawerOpen}
        containerId={"drawer-container"}
        sidePanel={
          selectedListing ? (
            <SidePanel
              title={selectedListing.title}
              displayImage={true}
              image={selectedListing.imageUrl}
              content={
                <ProfitLossTable
                  countryCode={store.marketplaceCountry}
                  currentRange={currentRange}
                  currentCurrency={currentCurrency}
                  categories={categoriesData}
                  data={get(selectedListing, "data", [])}
                  isProductReport={true}
                  shopName={store.storeName}
                  marketplaceName={marketplaceName}
                  report={!!report}
                />
              }
              handleClose={() => setDrawerOpen(false)}
            />
          ) : (
            <Grid />
          )
        }
        mainPanel={
          <Panel
            id="product-profitability-panel"
            title={t("myStoresWidget.productProfit.mainTitle")}
            tooltip={t("myStoresWidget.productProfit.mainTooltip")}
            minOpenHeight="1100px"
            drawerOpen={drawerOpen}
            content={
              <>
                <TemplateDialog
                  mid={store.merchantId}
                  open={templateDialogOpen}
                  onClose={() => {
                    setTemplateDialogOpen(false);
                  }}
                />
                <ProductTable
                  store={store}
                  currency={currentCurrency}
                  categories={categoriesData}
                  loading={isLoading}
                  data={data}
                  fetchData={fetchProductData}
                  pageSize={PAGE_SIZE}
                  columns={myColumns}
                  report={report}
                  setColumns={setMyColumns}
                  handleListingSelect={handleListingSelect}
                  handleCogsEdit={handleCogsEdit}
                />
              </>
            }
            actions={
              !report ? (
                <>
                  <StyledButton
                    variant="outlined"
                    color="info"
                    onClick={() => setTemplateDialogOpen(true)}
                  >
                    {t("profitability.downloadButtonLabel")}
                  </StyledButton>
                  {user.isDemoMode ? (
                    <DemoTooltip
                      arrow
                      placement="top"
                      open
                      title={t("generic.notAvailableDemoMode")}
                    >
                      <span>
                        <StyledButton
                          variant="contained"
                          color="info"
                          disabled={true}
                        >
                          {t("profitability.uploadButtonLabel")}
                        </StyledButton>
                      </span>
                    </DemoTooltip>
                  ) : (
                    setSyncDialogOpen && (
                      <StyledButton
                        variant="contained"
                        color="info"
                        onClick={() => setSyncDialogOpen(true)}
                      >
                        {t("profitability.uploadButtonLabel")}
                      </StyledButton>
                    )
                  )}
                  <SearchFilter setSearchText={setSearchText} />
                  <ColumnSelect
                    columns={myColumns}
                    setColumns={(columns) => {
                      setMyColumns(columns);
                    }}
                  />
                  <DownloadCsv
                    mid={store.merchantId}
                    reportType={"profitabilityProducts"}
                    path={"/api/generic/profitability/products"}
                    params={{
                      ...currentRange,
                      searchText,
                      includeTax,
                      shopName: store.storeName,
                      marketplaceName,
                      countryCode: store.marketplaceCountry,
                      currentCurrency,
                      exchangeRate,
                    }}
                  />
                </>
              ) : undefined
            }
          />
        }
      />
    );
  }
);

export default ProfitabilityProduct;
