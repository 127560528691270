import { Box, useMediaQuery } from "@material-ui/core";
import React, { memo, useCallback, useMemo } from "react";
import {
  formatCurrency,
  getCurrencyByCountryCode,
} from "~/utils/currencyUtils";
import { useDispatch, useSelector } from "react-redux";

import ACOSCell from "../../../components/table/cells/acosCell";
import { LinkCell } from "~/components/table/cells/linkCell";
import { Panel } from "~/components/panel/panel";
import { Range } from "~/typedef/store";
import StatusCell from "~/modules/marketing/statusCell";
import Table from "~/components/table/table";
import { User } from "~/typedef/user";
import { ValueCell } from "~/components/table/cells/valueCell";
import { WrappedTextCell } from "../../../components/table/cells/textCell";
import { fetchMarketingKeywords } from "~/store/mystore/marketing.redux";
import { formatMatchType } from "~/modules/marketing/commonColumns";
import get from "lodash/get";
import { useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const PAGE_SIZE = 5;

interface TopPerformingKeywordsProps {
  userInfo: User;
  mid: string;
  countryCode: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  currentPeriod: string;
  currentRange: Range;
  currentCurrency: string;
  footerLink?: {
    url: any;
    external?: boolean;
    label?: string;
  };
  report?: boolean;
  pageSize?: number;
  conditionalFormatting?: boolean;
  condensed?: boolean;
}

const TopPerformingKeywords = memo(
  ({
    userInfo,
    mid,
    countryCode,
    marketplaceType,
    marketplaceSubtype,
    currentRange,
    currentCurrency,
    footerLink,
    report,
    pageSize,
    conditionalFormatting,
    condensed,
  }: TopPerformingKeywordsProps) => {
    const { t } = useTranslation();
    const homeCurrency = getCurrencyByCountryCode[countryCode];
    const currencyRates = useSelector((state) =>
      get(state, "globalVar.currencyRates")
    );
    const marketing = useSelector((state) => get(state, "marketing"));
    const loading = useSelector((state) =>
      get(state, "marketing.keywords.loading", false)
    );
    const theme = useTheme();
    const onMobile = useMediaQuery(theme.breakpoints.down("xs"));

    const dispatch = useDispatch();

    const columns = useMemo(
      () => [
        ...(!onMobile
          ? [
              {
                Header: t("advertisingDashboardWidget.adTable.campaignColumn"),
                id: "campaign_name",
                accessor: "campaign_name",
                isVisible: true,
                Cell: WrappedTextCell,
                colSpan: 1,
                customWidth: 350,
              },
              {
                Header: t("advertisingDashboardWidget.adTable.adGroupColumn"),
                id: "ad_group_name",
                accessor: "ad_group_name",
                isVisible: true,
                Cell: WrappedTextCell,
                colSpan: 1,
                customWidth: 150,
              },
            ]
          : []),
        {
          Header: t("advertisingDashboardWidget.adTable.keywordColumn"),
          id: "keyword_text",
          accessor: (row: any) => ({
            value: row.keyword_text,
            subtitle: formatMatchType(row.match_type),
          }),
          Cell: (props: any) => <LinkCell {...props} />,
          isVisible: true,
          isLocked: true,
          customWidth: onMobile ? 450 : 180,
        },
        ...(!condensed
          ? [
              {
                Header: t("advertisingDashboardWidget.adTable.statusColumn"),
                id: "keyword_status",
                accessor: (row: any) => ({
                  value: row.keyword_status,
                }),
                Cell: (props: any) => <StatusCell {...props} />,
                isVisible: true,
                align: "center",
                colSpan: 1,
                customWidth: 100,
              },
            ]
          : []),
        {
          Header: t("advertisingDashboardWidget.adTable.salesColumn"),
          id: "attributed_sales",
          accessor: (row: any) =>
            row.attributed_sales
              ? formatCurrency(
                  row.attributed_sales,
                  currencyRates,
                  homeCurrency,
                  currentCurrency
                )
              : "-",
          align: "right",
          Cell: ValueCell,
          isVisible: true,
          width: 100,
        },
        ...(!onMobile
          ? [
              {
                Header: t("advertisingDashboardWidget.adTable.spendColumn"),
                id: "cost",
                accessor: (row: any) =>
                  row.cost
                    ? formatCurrency(
                        row.cost,
                        currencyRates,
                        homeCurrency,
                        currentCurrency
                      )
                    : "-",
                align: "right",
                Cell: ValueCell,
                isVisible: true,
                customWidth: 100,
              },
              {
                Header: t("advertisingDashboardWidget.adTable.acosColumn"),
                id: "acos",
                accessor: (row: any) => {
                  return {
                    value: row.acos ? `${row.acos.toFixed(1)}%` : "-",
                    conditionalFormatting: conditionalFormatting,
                  };
                },
                align: "right",
                Cell: ACOSCell,
                isVisible: true,
                sortDescFirst: true,
                customWidth: 100,
              },
            ]
          : []),
      ],
      [currencyRates, currentCurrency, homeCurrency, onMobile]
    );

    const EMPTY_SEARCH = "";
    const NO_ADGROUP_ID = null;

    const fetchData = useCallback(
      ({ pageSize, pageIndex, sortBy }) => {
        dispatch(
          fetchMarketingKeywords(
            userInfo._id,
            mid,
            countryCode,
            marketplaceType,
            marketplaceSubtype,
            EMPTY_SEARCH,
            currentRange.fromDate,
            currentRange.toDate,
            NO_ADGROUP_ID,
            sortBy.length > 0
              ? {
                  pageSize,
                  pageIndex,
                  sortKey: sortBy[0].id,
                  sortDesc: sortBy[0].desc,
                }
              : {
                  pageSize,
                  pageIndex,
                  sortKey: "attributed_sales",
                  sortDesc: true,
                }
          )
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [userInfo._id, mid, countryCode, marketplaceType, currentRange]
    );

    return (
      <Panel
        id="widget-marketing-keyword-summary"
        title={t("advertisingDashboardWidget.topPerformingKeywords")}
        tooltip={t("advertisingDashboardWidget.topPerformingKeywordsTooltip")}
        footerLink={footerLink}
        content={
          <Box>
            <Table
              {...{
                columns,
                data: get(marketing, "keywords.data", []),
                fetchData,
                loading,
                pageSize: pageSize ?? PAGE_SIZE,
                gridLayoutColumns: 6,
                isReport: report,
              }}
            />
          </Box>
        }
      />
    );
  }
);

export default TopPerformingKeywords;
