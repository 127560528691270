import React, { memo } from "react";

import AccountHealthWidget from "../widgets/accountHealth";
import { StoreState } from "~/typedef/store";
import get from "lodash/get";
import { healthDataConfig } from "~/utils/accountHealthUtils";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import moment from "moment-timezone";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface AccountHealthProps {
  mid: string;
  market: keyof typeof healthDataConfig;
  marketplaceSubtype?: keyof typeof healthDataConfig;
  condensed?: boolean;
}

const AccountHealth = memo(
  ({ market, marketplaceSubtype, mid, condensed }: AccountHealthProps) => {
    const userInfo = useTypedSelector((state) => state.user);

    const currentFilter = useTypedSelector((state: StoreState) =>
      get(state, "persistentAppSettings.setting.data.currentFilter")
    );
    const selectedTimezone = useTypedSelector(
      (state: StoreState) =>
        get(state, "persistentAppSettings.setting.data.timezone") ||
        moment.tz.guess()
    );
    const marketConfig = healthDataConfig[market];
    const footerLink =
      condensed && marketConfig
        ? {
            url: marketplaceLink(market, mid, "accounthealth"),
            label: "View all >>",
          }
        : undefined;

    return (
      <AccountHealthWidget
        userInfo={userInfo}
        mid={mid}
        market={market}
        marketplaceSubtype={marketplaceSubtype}
        currentFilter={currentFilter}
        footerLink={footerLink}
        condensed={condensed}
        timezone={selectedTimezone}
      />
    );
  }
);

export default AccountHealth;
