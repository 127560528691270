import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import React, { memo, useState } from "react";
import SalesByCustomerType, {
  CHART_TYPE,
  ChartOption,
} from "~/modules/repeatPurchases/salesByCustomerType";
import {
  getCurrencyByCountryCode,
  getExchangeRate,
} from "~/utils/currencyUtils";

import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import { Grid } from "@material-ui/core";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import PageBlock from "~/components/containers/sideNavPageBlock";
import RepeatPurchaseByProduct from "~/modules/repeatPurchases/repeatPurchaseByProduct";
import RepeatPurchaseSummary from "~/modules/repeatPurchases/repeatPurchaseSummary";
import TableFilter from "~/components/panel/panelActions/tableFilter";
import get from "lodash/get";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const RepeatPurchases = memo(function RepeatPurchases() {
  const { t } = useTranslation();

  const userInfo = useTypedSelector((state) => state.user);

  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );

  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );

  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );

  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );

  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );

  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );

  const currencyRates = useTypedSelector((state) =>
    get(state, "globalVar.currencyRates")
  );

  const exchangeRate = getExchangeRate(
    currencyRates,
    getCurrencyByCountryCode[store?.marketplaceCountry || ""],
    currentCurrency
  );

  const includeTax = useTypedSelector((state) =>
    Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
  );

  const CHART_OPTIONS: ChartOption[] = [
    {
      value: CHART_TYPE.Customers,
      label: t("myStoresWidget.salesByCustomerType.customers"),
    },
    {
      value: CHART_TYPE.Revenue,
      label: t("myStoresWidget.salesByCustomerType.revenue"),
    },
    {
      value: CHART_TYPE.Orders,
      label: t("myStoresWidget.salesByCustomerType.orders"),
    },
  ];
  const [chartType, setChartType] = useState<ChartOption>(CHART_OPTIONS[0]);

  const switchChartType = (value: string) => {
    const option = CHART_OPTIONS.find(
      (chartOption) => chartOption.value === value
    );
    if (option) {
      setChartType(option);
    }
  };

  if (!store) {
    return (
      <PageBlock>
        <LoadingIndicator />
      </PageBlock>
    );
  }

  const chartActions = (
    <>
      <TableFilter
        width="165px"
        options={CHART_OPTIONS}
        currentValue={chartType.label}
        handleChange={switchChartType}
      />
      <DownloadCsv
        reportType="customerTypeTrend"
        path="/api/generic/salesByCustomerType"
        mid={store?.merchantId}
        params={{
          mid: store.merchantId,
          marketplaceType: store.marketplace,
          marketplaceSubtype: store.marketplaceSubtype || store.marketplace,
          currentRange,
          includeTax,
          currency: currentCurrency,
          exchangeRate,
        }}
      />
    </>
  );

  const tableActions = (
    <DownloadCsv
      reportType="repeatPurchaseByProduct"
      path="/api/generic/repeatPurchaseByProduct"
      mid={store.merchantId}
      params={{
        mid: store.merchantId,
        marketplaceType: store.marketplace,
        marketplaceSubtype: store.marketplaceSubtype || store.marketplace,
        currentRange,
        includeTax,
        currency: currentCurrency,
        exchangeRate,
      }}
    />
  );

  return (
    <PageBlock>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <SalesByCustomerType
            mid={store.merchantId}
            marketplaceType={store.marketplace}
            marketplaceSubtype={store.marketplaceSubtype || store.marketplace}
            countryCode={store.marketplaceCountry}
            currentCurrency={currentCurrency}
            currentPeriod={currentPeriod}
            currentRange={currentRange}
            timezone={selectedTimezone}
            includeTax={includeTax}
            chartType={chartType}
            actions={chartActions}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <RepeatPurchaseSummary
            mid={store.merchantId}
            marketplaceType={store.marketplace}
            marketplaceSubtype={store.marketplaceSubtype || store.marketplace}
            countryCode={store.marketplaceCountry}
            currentCurrency={currentCurrency}
            currentRange={currentRange}
            currentCompare={currentCompare}
            includeTax={includeTax}
          />
        </Grid>
        <Grid item xs={12}>
          <RepeatPurchaseByProduct
            mid={store.merchantId}
            marketplaceType={store.marketplace}
            marketplaceSubtype={store.marketplaceSubtype || store.marketplace}
            countryCode={store.marketplaceCountry}
            currentCurrency={currentCurrency}
            currentRange={currentRange}
            includeTax={includeTax}
            actions={tableActions}
          />
        </Grid>
      </Grid>
    </PageBlock>
  );
});

export default RepeatPurchases;
