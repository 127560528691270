import {
  BuildRounded,
  DescriptionRounded,
  GpsFixedRounded,
  InsertChartRounded,
  LocalOfferRounded,
  MonetizationOnRounded,
  Person,
  RssFeedRounded,
  ShoppingCartRounded,
  SvgIconComponent,
} from "@material-ui/icons";

import SalesInsightsIcon from "~/icons/salesInsightsIcon";
import { hasFilteredSuffix } from "~/utils/marketplaceUtils";

const AdvertisingMarketplaces: Marketplace[] = [
  "amazon",
  "amazon_filtered",
  "mercadolibre",
  "walmart",
  "advertising",
  "bol",
];

export const ListingQualityMarketplaces = ["ebay", "walmart"] as const;
export const ItemSpecificsMarketplaces = ["ebay"] as const;
const AccountHealthMarketplaces = [
  "amazon",
  "bol",
  "ebay",
  "iconic",
  "mercadolibre",
  "mirakl",
] as const;
export const SuppressedProductsMarketplaces = [
  "amazon",
  "amazon_filtered",
] as const;

const MarketplaceListingsMarketplaces = Array.from<Marketplace>(
  new Set<Marketplace>([
    ...ListingQualityMarketplaces,
    ...ItemSpecificsMarketplaces,
    ...SuppressedProductsMarketplaces,
  ])
);

export interface MenuItem {
  title: string;
  link?: string;
  id?: string;
  icon: SvgIconComponent;
  featureFlagged?: string;
  hideIfRestrictedAccess?: boolean;
  restricted?: {
    markets: Readonly<Marketplace[]>;
  };
  disabledFor?: {
    markets: Readonly<Marketplace[]>;
    countries?: Readonly<{
      [marketplace in Marketplace]?: string[];
    }>;
  };
  subMenus?: SubMenuItem[];
  disableDateFilter?: boolean;
  disableTimezoneFilter?: boolean;
  isNew?: boolean;
  isBeta?: boolean;
  hideSearchParams?: boolean;
}

export type SubMenuItem = Omit<MenuItem, "icon"> & {
  customMainTitle?: string;
};

export const amazonVendorMenuItems: MenuItem[] = [
  {
    title: "nav.vendorOverview",
    icon: InsertChartRounded,
    link: "storeoverview",
    id: "marketplace-overview",
  },
  {
    title: "nav.brandAnalytics",
    icon: ShoppingCartRounded,
    id: "brand-analytics",
    subMenus: [
      {
        title: "nav.orderedRevenue",
        link: "orderedrevenue",
        featureFlagged: "brandAnalyticsOn",
      },
      {
        title: "nav.shippedRevenue",
        link: "shippedrevenue",
        featureFlagged: "brandAnalyticsOn",
      },
      {
        title: "nav.vendorGroupAnalytics",
        customMainTitle: "nav.vendorRetailAnalyticsTitle",
        link: "grouprevenue",
        isBeta: true,
      },
      {
        title: "nav.sourcingShare",
        link: "sourcingshare",
        featureFlagged: "brandAnalyticsOn",
      },
      {
        title: "nav.inventoryHealth",
        link: "inventoryhealth",
        featureFlagged: "brandAnalyticsOn",
      },
      {
        title: "nav.subscribeAndSave",
        link: "subscribeandsave",
        id: "subscribeAndSave",
        isBeta: true,
      },
    ],
  },
  {
    title: "nav.purchaseOrders",
    icon: SalesInsightsIcon,
    id: "sales-insights",
    subMenus: [
      {
        title: "nav.purchaseOrders",
        link: "purchaseorders",
        featureFlagged: "brandAnalyticsOn",
      },
      {
        title: "nav.vendorCategory",
        link: "salesbycategory",
        featureFlagged: "brandAnalyticsOn",
      },
      {
        title: "nav.vendorBrand",
        link: "salesbybrand",
        featureFlagged: "brandAnalyticsOn",
      },
      {
        title: "nav.vendorGroup",
        link: "salesbygroup",
        featureFlagged: "brandAnalyticsOn",
      },
      {
        title: "nav.vendorProduct",
        link: "salesbyproduct",
        featureFlagged: "brandAnalyticsOn",
      },
      {
        title: "nav.vendorTopSellers",
        link: "topsellers",
        featureFlagged: "brandAnalyticsOn",
      },
      {
        title: "nav.vendorWorstSellers",
        link: "worstsellers",
        featureFlagged: "brandAnalyticsOn",
      },
    ],
  },
  {
    title: "nav.profitability",
    id: "profitability",
    icon: MonetizationOnRounded,
    featureFlagged: "vendorProfitabilityOn",
    subMenus: [
      {
        title: "nav.vendorStoreProfit",
        link: "profitabilityStoreVendor",
        isBeta: true,
      },
      {
        title: "nav.vendorProductProfit",
        link: "profitabilityProductVendor",
        isBeta: true,
      },
    ],
  },
  {
    title: "nav.marketing",
    id: "marketing",
    icon: RssFeedRounded,
    restricted: {
      markets: ["amazon_vendor", "amazon_vendor_filtered"],
    },
    subMenus: [
      {
        title: "nav.marketingOverview",
        link: "marketingOverview",
      },
      {
        title: "nav.campaignsByGroup",
        link: "campaignGroups",
        isNew: true,
        restricted: {
          // MBS-2799: campaign by groups doesn't work for filtered channels
          markets: ["amazon_vendor"] as Marketplace[],
        },
      },
      {
        title: "nav.campaigns",
        link: "marketingCampaigns",
        subMenus: [
          {
            title: "nav.campaignPerformance",
            link: "marketingCampaignAdGroups",
          },
          {
            title: "nav.campaignPerformance",
            link: "marketingCampaignNegatives",
          },
          {
            title: "nav.adGroups",
            link: "marketingCampaignAdGroupKeywords",
          },
          {
            title: "nav.adGroups",
            link: "marketingCampaignAdGroupNegatives",
          },
          {
            title: "nav.adGroups",
            link: "marketingCampaignAdGroupProductAds",
          },
        ],
      },
      {
        title: "nav.adGroups",
        link: "marketingAdGroups",
        subMenus: [
          {
            title: "nav.adGroupPerformance",
            link: "marketingAdGroupKeywords",
          },
          {
            title: "nav.adGroupPerformance",
            link: "marketingAdGroupNegatives",
          },
          {
            title: "nav.adGroupPerformance",
            link: "marketingAdGroupProductAds",
          },
        ],
      },
      {
        title: "nav.productPerformanceByProducts",
        link: "marketingProducts",
      },
      {
        title: "nav.productPerformance",
        link: "marketingProductAds",
      },
      {
        title: "nav.keywords",
        link: "marketingKeywords",
        disabledFor: { markets: ["dsp"] },
      },
    ],
  },
];

export const getAdvertisingWidgets = (): MenuItem => {
  return {
    title: "nav.marketing",
    id: "marketing",
    icon: RssFeedRounded,
    restricted: {
      markets: AdvertisingMarketplaces,
    },
    disabledFor: {
      markets: ["walmart"],
      countries: {
        walmart: ["MEX"],
      },
    },
    subMenus: [
      {
        title: "nav.marketingOverview",
        link: "marketingOverview",
        restricted: { markets: AdvertisingMarketplaces },
      },
      {
        title: "nav.campaignsByGroup",
        link: "campaignGroups",
        restricted: {
          markets: AdvertisingMarketplaces.filter(
            // MBS-2799: campaign by groups doesn't work for filtered channels
            (marketplace) => !hasFilteredSuffix(marketplace)
          ),
        },
        isNew: true,
      },
      {
        title: "nav.campaigns",
        link: "marketingCampaigns",
        restricted: { markets: AdvertisingMarketplaces },
        subMenus: [
          {
            title: "nav.campaignPerformance",
            link: "marketingCampaignAdGroups",
            restricted: { markets: AdvertisingMarketplaces },
          },
          {
            title: "nav.campaignPerformance",
            link: "marketingCampaignNegatives",
            restricted: { markets: AdvertisingMarketplaces },
          },
          {
            title: "nav.adGroups",
            link: "marketingCampaignAdGroupKeywords",
            restricted: { markets: AdvertisingMarketplaces },
          },
          {
            title: "nav.adGroups",
            link: "marketingCampaignAdGroupNegatives",
            restricted: { markets: AdvertisingMarketplaces },
          },
          {
            title: "nav.adGroups",
            link: "marketingCampaignAdGroupProductAds",
            restricted: { markets: AdvertisingMarketplaces },
          },
        ],
      },
      {
        title: "nav.adGroups",
        link: "marketingAdGroups",
        restricted: { markets: AdvertisingMarketplaces },
        subMenus: [
          {
            title: "nav.adGroupPerformance",
            link: "marketingAdGroupKeywords",
            restricted: { markets: AdvertisingMarketplaces },
          },
          {
            title: "nav.adGroupPerformance",
            link: "marketingAdGroupNegatives",
            restricted: { markets: AdvertisingMarketplaces },
          },
          {
            title: "nav.adGroupPerformance",
            link: "marketingAdGroupProductAds",
            restricted: { markets: AdvertisingMarketplaces },
          },
        ],
      },
      {
        title: "nav.productPerformanceByProducts",
        link: "marketingProducts",
        isNew: true,
        restricted: { markets: AdvertisingMarketplaces },
      },
      {
        title: "nav.productPerformance",
        link: "marketingProductAds",
        restricted: { markets: AdvertisingMarketplaces },
      },
      {
        title: "nav.keywords",
        link: "marketingKeywords",
        restricted: { markets: AdvertisingMarketplaces },
        disabledFor: { markets: ["dsp", "tiktok"] },
      },
    ],
  };
};

export const getAdvertisingMenuItems = (): MenuItem[] => {
  const items: MenuItem[] = [getAdvertisingWidgets()];
  return items;
};

export const getMenuItems = (): MenuItem[] => {
  return [
    {
      title: "nav.marketplaceOverview",
      icon: InsertChartRounded,
      link: "storeoverview",
      id: "marketplace-overview",
    },
    {
      title: "nav.customers",
      icon: Person,
      id: "customers",
      subMenus: [
        {
          title: "nav.trafficAndConversion",
          link: "trafficandconversion",
          id: "traffic-and-conversion",
          restricted: {
            markets: ["amazon", "amazon_filtered"],
          },
        },
        {
          title: "nav.trafficAndConversion",
          link: "trafficandconversionwalmart",
          id: "traffic-and-conversion-walmart",
          restricted: {
            markets: ["walmart"],
          },
          featureFlagged: "walmartTrafficOn",
        },
        {
          title: "nav.customerLTV",
          link: "customerltv",
          id: "customer-ltv",
          restricted: {
            markets: [
              "amazon" as const,
              "walmart" as const,
              "ebay" as const,
              "shopify" as const,
            ],
          },
          featureFlagged: "customerLTVOn",
          hideIfRestrictedAccess: true,
          isBeta: true,
        },
        {
          title: "nav.repeatpurchases",
          link: "repeatpurchases",
          id: "repeatpurchases",
          restricted: {
            markets: [
              "amazon" as const,
              "walmart" as const,
              "ebay" as const,
              "shopify" as const,
            ],
          },
          featureFlagged: "repeatPurchaseOn",
          hideIfRestrictedAccess: true,
          isBeta: true,
        },
        {
          title: "nav.subscribeAndSave",
          link: "subscribeandsave",
          id: "subscribeAndSave",
          restricted: {
            markets: ["amazon" as const],
          },
          hideIfRestrictedAccess: true,
          isBeta: true,
        },
        {
          title: "nav.refundAnalytics",
          link: "refundanalytics",
          id: "refundAnalytics",
          featureFlagged: "refundAnalyticsOn",
          hideIfRestrictedAccess: true,
          isBeta: true,
        },
      ],
    },
    {
      title: "nav.salesInsights",
      icon: SalesInsightsIcon,
      id: "sales-insights",
      subMenus: [
        { title: "nav.salesByCategory", link: "salesbycategory" },
        {
          title: "nav.salesByCountry",
          link: "salesbycountry",
        },
        { title: "nav.salesByBrand", link: "salesbybrand" },
        {
          title: "nav.salesByGroup",
          link: "salesbygroup",
        },
        { title: "nav.salesByProduct", link: "salesbyproduct" },
        {
          title: "nav.salesByParent",
          link: "salesbyparent",
          restricted: {
            markets: ["amazon", "amazon_filtered", "mercadolibre"],
          },
        },
        {
          title: "nav.salesByInterval",
          link: "salesbyinterval",
        },
        { title: "nav.topSellers", link: "topsellers" },
        {
          title: "nav.worstSellers",
          link: "worstsellers",
        },
        {
          title: "nav.forecastsAndCosts",
          link: "forecastsandcosts",
          disableTimezoneFilter: true,
          isNew: true,
        },
      ].filter((e) => Boolean(e)) as SubMenuItem[],
    },
    {
      title: "nav.profitability",
      id: "profitability-walmart",
      icon: MonetizationOnRounded,
      restricted: { markets: ["walmart", "walmart_filtered"] },
      subMenus: [
        {
          title: "nav.storeProfit",
          link: "profitabilityStore",
        },
        {
          title: "nav.monthlyProfit",
          link: "profitabilityMonthly",
          restricted: { markets: ["walmart"] },
        },
        {
          title: "nav.productProfit",
          link: "profitabilityProduct",
        },
      ],
    },
    {
      title: "nav.profitability",
      id: "profitability",
      icon: MonetizationOnRounded,
      restricted: { markets: ["amazon", "amazon_filtered"] },
      subMenus: [
        {
          title: "nav.storeProfit",
          link: "profitabilityStore",
          restricted: { markets: ["amazon", "amazon_filtered"] },
        },
        {
          title: "nav.monthlyProfit",
          link: "profitabilityMonthly",
          restricted: { markets: ["amazon"] },
        },
        {
          title: "nav.productProfit",
          link: "profitabilityProduct",
          restricted: { markets: ["amazon", "amazon_filtered"] },
        },
      ],
    },
    {
      title: "nav.marketplaceListings",
      id: "marketplace-listings",
      icon: GpsFixedRounded,
      restricted: { markets: MarketplaceListingsMarketplaces },
      disabledFor: {
        markets: ["walmart"],
        countries: {
          walmart: ["MEX"],
        },
      },
      subMenus: [
        {
          title: "nav.listingQuality",
          link: "listingquality",
          restricted: { markets: ListingQualityMarketplaces },
          disableDateFilter: true,
        },
        {
          title: "nav.itemSpecifics",
          link: "itemspecifics",
          id: "item-specifics",
          restricted: { markets: ItemSpecificsMarketplaces },
          disableDateFilter: true,
        },
        {
          title: "nav.suppressedProducts",
          link: "suppressedproducts",
          restricted: { markets: SuppressedProductsMarketplaces },
          disableDateFilter: true,
        },
      ],
    },
    {
      title: "nav.pricing",
      id: "pricing",
      icon: LocalOfferRounded,
      restricted: { markets: ["amazon"] },
      subMenus: [
        {
          title: "nav.buyBox",
          link: "buyBoxPerformance",
          restricted: { markets: ["amazon"] },
        },
      ],
    },
    {
      title: "nav.operations",
      id: "operations",
      icon: BuildRounded,
      subMenus: [
        {
          title: "nav.accountHealth",
          link: "accounthealth",
          disableDateFilter: true,
          restricted: { markets: AccountHealthMarketplaces },
        },
        { title: "nav.overdueOrders", link: "overdueorders" },
        {
          title: "nav.reviewManagement",
          link: "reviews",
          restricted: { markets: ["amazon"] },
        },
        {
          title: "nav.outOfStocks",
          link: "outofstock",
        },
        {
          title: "nav.inventoryReplenishment",
          link: "inventoryreplenishment",
          restricted: { markets: ["amazon"] },
          disableDateFilter: true,
        },
      ],
    },
    getAdvertisingWidgets(),
  ];
};

export const drawerWidthOpen = 250;
export const drawerWidthClosed = 66;

// Menu items for the client reports pages
export const reportMenuItems: MenuItem[] = [
  {
    title: "nav.reports",
    id: "reports",
    icon: DescriptionRounded,
    featureFlagged: "reportsOn",
    subMenus: [
      {
        title: "nav.createReport",
        link: "createReport",
        disableDateFilter: true,
        hideSearchParams: true,
      },
      {
        title: "createReport.reportsSent",
        link: "reportsSent",
        disableDateFilter: true,
        hideSearchParams: true,
      },
      {
        title: "createReport.scheduledReports",
        link: "scheduledReports",
        disableDateFilter: true,
        hideSearchParams: true,
      },
      {
        title: "createReport.reportTemplates",
        link: "reportTemplates",
        isNew: true,
        disableDateFilter: true,
        hideSearchParams: true,
      },
    ],
  },
];

// Menu items for the notifications pages
export const notificationMenuItems: MenuItem[] = [
  {
    title: "nav.notifications",
    id: "notifications",
    icon: DescriptionRounded,
    subMenus: [
      {
        title: "nav.notificationList",
        link: "list",
        disableDateFilter: true,
      },
      {
        title: "nav.notificationSettings",
        link: "settings",
        disableDateFilter: true,
      },
    ],
  },
];
