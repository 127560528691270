import {
  Box,
  BoxProps,
  Link,
  Paper,
  Switch,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  ChildValueAndGrowth,
  ProfitAndLossGrowthCell,
  ValueAndGrowth,
} from "./cells/profitAndLossGrowthCell";
import { CurrentStore, Filter, Range } from "~/typedef/store";
import { DownArrow, UpArrow } from "~/icons/percentageChangeArrows";
import {
  InlineIconButton,
  LightInlineIconButton,
} from "~/icons/inlineIconButton";
import {
  ProfitAndLossBreakdownResponse,
  ProfitBreakdown,
  ProfitabilityEventGroup,
  fetchProfitAndLoss,
  useFetchProfitAndLossBreakdownQuery,
} from "~/store/mystore/profitability.redux";
import React, { ReactElement, memo, useEffect, useMemo, useState } from "react";
import _, { isEmpty } from "lodash";
import {
  formatCurrency,
  formatCurrencyRounded,
  getCurrencySymbol,
} from "~/utils/currencyUtils";
import {
  getStatusFromProfit,
  percentageFormatter,
} from "~/modules/profitLossTable/categoryUtils";
import styled, { DefaultTheme, ThemeProps } from "styled-components";

import Bold from "~/components/typography/bold";
import Cell from "./cells/cell";
import { CustomBadge } from "~/components/toolbars/sideNavigation/drawerMenuItem";
import DoubleCell from "./cells/doubleCell";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import ProfitAndLossCategoryLabelCell from "./cells/categoryLabelCell";
import { ProfitAndLossExpandableCell } from "./cells/profitAndLossExpandableCell";
import { Link as RouterLink } from "react-router-dom";
import StatusText from "~/components/typography/status";
import { getGrowthStatus } from "~/components/table/cells/percentageAndGrowthCell";
import { getPercentageDifference } from "~/utils/salesUtils";
import { getGrowthStatus as getProfitGrowthStatus } from "~/components/table/cells/profitCell";
import moment from "moment-timezone";
import { numberWithCommas } from "~/utils/utils";
import { useDispatch } from "react-redux";
import { useMarketplace } from "~/utils/navigationUtils";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const MIN_COLUMNS = 3;

const PanelWrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: stretch;
  justify-content: stretch;
`;

const PanelContent = styled(Box)`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  align-items: stretch;
  justify-content: stretch;
`;

const ScrollArea = styled(Box)`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  align-items: stretch;
  justify-content: stretch;
  overflow-x: auto;
`;

const NewBadge = styled(CustomBadge)`
  margin-right: 5px;
  border-radius: unset;
`;

type HeadingGridProps = {
  $condensed?: boolean;
  $justify?: string;
} & ThemeProps<DefaultTheme> &
  BoxProps;

export const HeadingGrid = styled(Box)`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
  ${({ $condensed, theme }: HeadingGridProps) =>
    $condensed
      ? `background-color: ${theme.palette.table.header};
  color: ${theme.palette.table.text.header};`
      : ``};
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.75rem;
  max-height: ${({ $condensed }: HeadingGridProps) =>
    $condensed ? "28px" : "30px"};
  min-height: ${({ $condensed }: HeadingGridProps) =>
    $condensed ? "28px" : "30px"};
  align-items: center;
  justify-content: ${({ $justify }: HeadingGridProps) =>
    $justify ? $justify : "flex-end"};
  padding: 0.5rem 1rem;
`;

export const CategoryHeading = styled(Bold)`
  text-transform: uppercase;
`;

const PanelHeader = styled(Box)`
  padding: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SectionHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.palette.table.header};
  color: ${({ theme }) => theme.palette.table.text.header};
  justify-content: ${({
    $startAlign,
  }: {
    $startAlign?: boolean;
    $condensed?: boolean;
  }) => ($startAlign ? "flex-start" : "flex-end")};
  max-height: 30px;
  min-height: ${({
    $condensed,
  }: {
    $startAlign?: boolean;
    $condensed?: boolean;
  }) => ($condensed ? "none" : "30px")};
  align-items: center;
  padding: 0.5rem 1rem;
`;

const PanelFooter = styled(Box)`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  min-height: 35px;
`;

const Column = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1 1 25%;
  border-right: ${({
    theme,
    $sticky,
  }: {
    theme: DefaultTheme;
    $sticky?: boolean;
  }) => ($sticky ? `1px solid ${theme.palette.border.main}` : `none`)};
  max-width: ${({ $sticky }: { $sticky?: boolean }) =>
    $sticky ? `120px` : `none`};
`;

const FirstColumn = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1 1 60%;
  max-width: ${({ $thin }: { $thin?: boolean }) => ($thin ? `175px` : `35%`)};
`;

const DoubleCellProfit = styled(Box)`
  display: flex;
  flex-direction: row;
  min-width: 120px;
  align-items: center;
  justify-content: flex-end;
  min-height: 20px;
  max-height: 20px;
`;

interface ProfitAndLossProps {
  title: string;
  store?: CurrentStore;
  currentRange: Range;
  currentCurrency: string;
  currentFilter?: Filter;
  includeTax: boolean;
  groupByMonth: boolean;
  report?: boolean;
  showComparison?: boolean;
  isComparison?: boolean;
  condensed?: boolean;
  footerLink?: {
    url: any;
    label?: string;
  };
}

const CONDENSED_KEYS = [
  "productSales",
  "sellingFees",
  "fulfilmentAndShipping",
  "units",
  "unitsRefunded",
];

const TOTAL_KEYS = ["totalRevenue", "totalExpenses"];

interface ProfitAndLossIncomeProps {
  productSales: number;
  productSalesTax: number | undefined;
  refundedSales: number;
  reimbursements: number;
  promotions: number;
  otherIncome: number;
}

interface ProfitAndLossExpenseProps {
  advertising: number;
  sellingFees: number;
  fulfilmentAndShipping: number;
  refundsAndReturns: number;
  costOfGoods: number;
  otherExpenses: number;
}

interface ProfitAndLossDataProps {
  dates: (string | undefined)[];
  income: (ProfitAndLossIncomeProps & {
    totalRevenue: number;
  })[];
  expenses: (ProfitAndLossExpenseProps & {
    totalExpenses: number;
  })[];
  profit: number[];
  meta: {
    estimatedPayout: number;
    tacos: number;
    totalFees: number;
    refunds: number;
    orders: number;
    units: number;
    unitsRefunded: number;
  }[];
  currency: string;
}

interface IncomeGrowthProps {
  productSales: ValueAndGrowth;
  productSalesTax: ValueAndGrowth;
  refundedSales: ValueAndGrowth;
  reimbursements: ValueAndGrowth;
  promotions: ValueAndGrowth;
  otherIncome: ValueAndGrowth;
}
interface ExpenseGrowthProps {
  advertising: ValueAndGrowth;
  sellingFees: ValueAndGrowth;
  fulfilmentAndShipping: ValueAndGrowth;
  refundsAndReturns: ValueAndGrowth;
  costOfGoods: ValueAndGrowth;
  otherExpenses: ValueAndGrowth;
}
type IncomeGrowthPropsWithTotal = IncomeGrowthProps & {
  totalRevenue: ValueAndGrowth;
};

type ExpenseGrowthPropsWithTotal = ExpenseGrowthProps & {
  totalExpenses: ValueAndGrowth;
};

interface ProfitAndLossDataWithGrowthProps {
  dates: (string | undefined)[];
  income: IncomeGrowthPropsWithTotal[];
  expenses: ExpenseGrowthPropsWithTotal[];
  profit: ValueAndGrowth[];
  meta: {
    estimatedPayout: ValueAndGrowth;
    tacos: ValueAndGrowth;
    totalFees: ValueAndGrowth;
    refunds: ValueAndGrowth;
    orders: ValueAndGrowth;
    units: ValueAndGrowth;
    unitsRefunded: ValueAndGrowth;
  }[];
  currency: string;
}
interface ProfitAndLossStatementProps {
  groupByMonth: boolean;
  currentCurrency: string;
  current: ProfitAndLossDataProps;
  prior: ProfitAndLossDataProps;
  total: ProfitAndLossDataProps;
  expandRows?: boolean;
  breakdown?: ProfitAndLossBreakdownResponse;
  totalBreakdown?: ProfitBreakdown;
  report?: boolean;
  condensed?: boolean;
  showComparison?: boolean;
  isComparison?: boolean;
}

interface ProfitAndLossColumnProps {
  groupByMonth: boolean;
  currentCurrency: string;
  report?: boolean;
  condensed?: boolean;
  data: ProfitAndLossDataProps;
  breakdown?: Record<
    keyof (ProfitAndLossIncomeProps & ProfitAndLossExpenseProps),
    ProfitabilityEventGroup[] | undefined
  >[];
  expandRows?: boolean;
  title?: string;
  sticky?: boolean;
}

interface ProfitAndLossColumnWithGrowthProps {
  currentCurrency: string;
  report?: boolean;
  condensed?: boolean;
  expandRows?: boolean;
  data: ProfitAndLossDataWithGrowthProps;
  breakdown?: Record<
    keyof ProfitAndLossIncomeProps | keyof ProfitAndLossExpenseProps,
    ChildValueAndGrowth[]
  >;
  title?: string;
}

type IncomeDataKey = keyof ProfitAndLossIncomeProps | "totalRevenue";
type ExpenseDataKey = keyof ProfitAndLossExpenseProps | "totalExpenses";

const DifferenceDataColumn = ({
  current,
  prior,
  condensed,
  title,
  currentCurrency,
  report,
}: {
  current: ProfitAndLossDataProps;
  prior: ProfitAndLossDataProps;
  condensed?: boolean;
  title?: string;
  currentCurrency: string;
  report?: boolean;
}) => {
  const { dates, income, expenses, profit, currency, meta } = current;

  const incomeDiff = income.map((incomeItem, i) => {
    const priorIncomeItem = prior.income[i];
    return _.mapValues(
      incomeItem,
      (value, key: IncomeDataKey) =>
        ({
          value: (value ?? 0) - (priorIncomeItem[key] ?? 0),
          growth: getPercentageDifference(
            value,
            priorIncomeItem[key] as number
          ),
          status: getGrowthStatus((value ?? 0) - (priorIncomeItem[key] ?? 0)),
        } as ValueAndGrowth)
    ) as unknown as IncomeGrowthPropsWithTotal;
  });

  const expensesDiff = expenses.map((expenseItem, i) => {
    const priorExpenseItem = prior.expenses[i];
    return _.mapValues(expenseItem, (value, key: ExpenseDataKey) => ({
      value: (value as number) - (priorExpenseItem[key] as number),
      growth: getPercentageDifference(value, priorExpenseItem[key] as number),
      status: getGrowthStatus((value as number) - priorExpenseItem[key], true),
    })) as unknown as ExpenseGrowthPropsWithTotal;
  });

  const profitDiff = profit.map((profitItem, i) => {
    const priorProfit = prior.profit[i];
    return {
      value: profitItem - priorProfit,
      growth: getPercentageDifference(profitItem, priorProfit),
      status: getProfitGrowthStatus(profitItem - priorProfit) as
        | "success"
        | "error"
        | undefined,
    };
  });

  const metaDiff = meta.map((metaItem, i) => {
    const priorMetaItem = prior.meta[i];
    return {
      estimatedPayout: {
        value: metaItem.estimatedPayout - priorMetaItem.estimatedPayout,
        growth: getPercentageDifference(
          metaItem.estimatedPayout,
          priorMetaItem.estimatedPayout
        ),
        status: getGrowthStatus(
          metaItem.estimatedPayout - priorMetaItem.estimatedPayout
        ),
      },
      tacos: {
        value: metaItem.tacos - priorMetaItem.tacos,
        growth: getPercentageDifference(metaItem.tacos, priorMetaItem.tacos),
        status: getGrowthStatus(metaItem.tacos - priorMetaItem.tacos),
      },
      totalFees: {
        value: metaItem.totalFees - priorMetaItem.totalFees,
        growth: getPercentageDifference(
          metaItem.totalFees,
          priorMetaItem.totalFees
        ),
        status: getGrowthStatus(metaItem.totalFees - priorMetaItem.totalFees),
      },
      refunds: {
        value: metaItem.refunds - priorMetaItem.refunds,
        growth: getPercentageDifference(
          metaItem.refunds,
          priorMetaItem.refunds
        ),
        status: getGrowthStatus(metaItem.refunds - priorMetaItem.refunds),
      },
      orders: {
        value: metaItem.orders - priorMetaItem.orders,
        growth: getPercentageDifference(metaItem.orders, priorMetaItem.orders),
        status: getGrowthStatus(metaItem.orders - priorMetaItem.orders),
      },
      units: {
        value: metaItem.units - priorMetaItem.units,
        growth: getPercentageDifference(metaItem.units, priorMetaItem.units),
        status: getGrowthStatus(metaItem.units - priorMetaItem.units),
      },
      unitsRefunded: {
        value: metaItem.unitsRefunded - priorMetaItem.unitsRefunded,
        growth: getPercentageDifference(
          metaItem.unitsRefunded,
          priorMetaItem.unitsRefunded
        ),
        status: getGrowthStatus(
          metaItem.unitsRefunded - priorMetaItem.unitsRefunded
        ),
      },
    };
  });

  return (
    <ProfitAndLossDataColumnWithGrowth
      data={{
        dates,
        currency,
        income: incomeDiff,
        expenses: expensesDiff,
        profit: profitDiff,
        meta: metaDiff,
      }}
      currentCurrency={currentCurrency}
      condensed={condensed}
      report={report}
      title={title}
    />
  );
};

const ProfitAndLossDataColumnWithGrowth = ({
  data,
  currentCurrency,
  condensed,
  title,
}: ProfitAndLossColumnWithGrowthProps) => {
  const { income, expenses, profit, currency, meta } = data;

  const currencyRates = useTypedSelector(
    (state) => state.globalVar.currencyRates
  );

  const currencyFormatter = condensed ? formatCurrencyRounded : formatCurrency;

  const columns: ReactElement[] = [];
  for (let i = 0; i < income.length; i++) {
    columns.push(
      <Column key={i}>
        <HeadingGrid
          $condensed={condensed}
          $justify="center"
          className="profit-headings"
        >
          <CategoryHeading variant="body1">{title}</CategoryHeading>
        </HeadingGrid>
        {!condensed && <SectionHeader></SectionHeader>}
        {income[i] &&
          Object.values(income[i]).map((value, j) => {
            const key = Object.keys(income[i])[j];
            const bold = TOTAL_KEYS.includes(key);
            if (!condensed || CONDENSED_KEYS.includes(key)) {
              return (
                <ProfitAndLossGrowthCell
                  value={value}
                  key={`${i}-inc-${key}`}
                  condensed={condensed}
                  currencyRates={currencyRates}
                  currency={currency}
                  currentCurrency={currentCurrency}
                  bold={bold}
                />
              );
            }
            return null;
          })}
        {!condensed && <SectionHeader></SectionHeader>}
        {expenses[i] &&
          Object.values(expenses[i]).map((value, j) => {
            const key = Object.keys(expenses[i])[j];
            const bold = TOTAL_KEYS.includes(key);
            if (!condensed || CONDENSED_KEYS.includes(key)) {
              return (
                <ProfitAndLossGrowthCell
                  value={value}
                  key={`${i}-exp-${key}`}
                  condensed={condensed}
                  currencyRates={currencyRates}
                  currency={currency}
                  currentCurrency={currentCurrency}
                  bold={bold}
                />
              );
            }
            return null;
          })}
        <HeadingGrid>
          <DoubleCellProfit>
            <CategoryHeading variant="body1">
              {profit[i].value
                ? currencyFormatter(
                    profit[i].value,
                    currencyRates,
                    currency,
                    currentCurrency
                  )
                : "-"}
            </CategoryHeading>
            <Box width="14px" />
            <StatusText
              status={profit[i].status}
              variant="body2"
              color="textSecondary"
            >
              {profit[i].status === "success" && (
                <UpArrow fontSize="inherit" status="success" />
              )}
              {profit[i].status === "error" && (
                <DownArrow fontSize="inherit" status="error" />
              )}
              {profit[i].growth ? `${profit[i].growth}%` : "-"}
            </StatusText>
          </DoubleCellProfit>
        </HeadingGrid>
        <DoubleCell></DoubleCell>
        {!condensed && <SectionHeader></SectionHeader>}
        {meta[i] &&
          Object.values(meta[i]).map((value, j) => {
            const key = Object.keys(meta[i])[j];
            const Text = TOTAL_KEYS.includes(key) ? Bold : Typography;
            if (j == 0) {
              if (!condensed || CONDENSED_KEYS.includes(key)) {
                return (
                  <HeadingGrid $condensed={condensed} key={`${i}-meta-${key}`}>
                    <DoubleCell>
                      <Text variant="body1">
                        {value.value
                          ? currencyFormatter(
                              value.value,
                              currencyRates,
                              currency,
                              currentCurrency
                            )
                          : "-"}
                      </Text>
                      <Box width="14px" />
                      <StatusText
                        status={value.status}
                        variant="body2"
                        color="textSecondary"
                      >
                        {value.status === "success" && (
                          <UpArrow fontSize="inherit" status="success" />
                        )}
                        {value.status === "error" && (
                          <DownArrow fontSize="inherit" status="error" />
                        )}
                        {value.growth ? `${value.growth}%` : "-"}
                      </StatusText>
                    </DoubleCell>
                  </HeadingGrid>
                );
              }
              return null;
            } else {
              const Text = TOTAL_KEYS.includes(key) ? Bold : Typography;
              if (!condensed || CONDENSED_KEYS.includes(key)) {
                return (
                  <DoubleCell key={`${i}-meta-${key}`}>
                    <Text variant="body1">
                      {value.value
                        ? j < 4
                          ? `${percentageFormatter.format(100 * value.value)}%`
                          : numberWithCommas(value.value)
                        : "-"}
                    </Text>
                    <Box width="14px" />
                    <StatusText
                      status={value.status}
                      variant="body2"
                      color="textSecondary"
                    >
                      {value.status === "success" && (
                        <UpArrow fontSize="inherit" status="success" />
                      )}
                      {value.status === "error" && (
                        <DownArrow fontSize="inherit" status="error" />
                      )}
                      {value.growth ? `${value.growth}%` : "-"}
                    </StatusText>
                  </DoubleCell>
                );
              }
              return null;
            }
          })}
      </Column>
    );
  }
  return <>{columns}</>;
};

const ProfitAndLossDataColumn = ({
  data,
  breakdown,
  currentCurrency,
  groupByMonth,
  expandRows,
  condensed,
  title,
  sticky,
  report,
}: ProfitAndLossColumnProps) => {
  const { dates, income, expenses, profit, currency, meta } = data;

  const currencyRates = useTypedSelector(
    (state) => state.globalVar.currencyRates
  );

  const currencyFormatter = condensed
    ? (v?: number) =>
        v
          ? formatCurrencyRounded(v, currencyRates, currency, currentCurrency)
          : "-"
    : (v?: number) =>
        v ? formatCurrency(v, currencyRates, currency, currentCurrency) : "-";

  const columns: ReactElement[] = [];
  for (let i = 0; i < income.length; i++) {
    const { totalRevenue } = income[i] || { totalRevenue: 0 };

    const getTitle = () => {
      if (title) {
        return title;
      }
      if (groupByMonth) {
        if (dates[i]) {
          const datePart = dates[i]!.split("T")[0];
          return moment(datePart).format("MMM 'YY");
        } else {
          return "";
        }
      }
      return getCurrencySymbol[
        currentCurrency as keyof typeof getCurrencySymbol
      ];
    };
    columns.push(
      <Column key={i} $sticky={sticky}>
        <HeadingGrid $condensed={condensed} className="profit-headings">
          <CategoryHeading variant="body1">{getTitle()}</CategoryHeading>
        </HeadingGrid>
        {!condensed && <SectionHeader></SectionHeader>}
        {income[i] &&
          Object.values(income[i]).map((value, j) => {
            const key = Object.keys(income[i])[j];
            const bold = TOTAL_KEYS.includes(key);
            if (!condensed || CONDENSED_KEYS.includes(key)) {
              return (
                <ProfitAndLossExpandableCell
                  value={currencyFormatter(value)}
                  breakdown={breakdown?.[i]?.[
                    key as keyof ProfitAndLossIncomeProps
                  ]?.map((s) => currencyFormatter(s.eventValue))}
                  key={`${i}-inc-${key}`}
                  cellKey={`${i}-inc-${key}`}
                  expandRows={expandRows}
                  bold={bold}
                />
              );
            }
            return null;
          })}
        {!condensed && <SectionHeader></SectionHeader>}
        {expenses[i] &&
          Object.values(expenses[i]).map((value, j) => {
            const key = Object.keys(expenses[i])[j];
            const bold = TOTAL_KEYS.includes(key);
            if (!condensed || CONDENSED_KEYS.includes(key)) {
              return (
                <ProfitAndLossExpandableCell
                  value={currencyFormatter(value)}
                  breakdown={breakdown?.[i]?.[
                    key as keyof ProfitAndLossExpenseProps
                  ]?.map((s) => currencyFormatter(s.eventValue))}
                  key={`${i}-exp-${key}`}
                  cellKey={`${i}-exp-${key}`}
                  expandRows={expandRows}
                  bold={bold}
                />
              );
            }
            return null;
          })}
        <HeadingGrid>
          <CategoryHeading variant="body1">
            {currencyFormatter(profit[i])}
          </CategoryHeading>
        </HeadingGrid>
        <Cell>
          <StatusText
            variant="body1"
            status={getStatusFromProfit(
              totalRevenue > 0 ? (100 * profit[i]) / totalRevenue : null
            )}
          >
            {totalRevenue > 0
              ? `${percentageFormatter.format(
                  (100 * profit[i]) / totalRevenue
                )}%`
              : "-"}
          </StatusText>
        </Cell>
        {!condensed && <SectionHeader></SectionHeader>}
        {meta[i] &&
          Object.values(meta[i]).map((value, j) => {
            const key = Object.keys(meta[i])[j];
            const Text = TOTAL_KEYS.includes(key) ? Bold : Typography;
            if (j == 0) {
              if (!condensed || CONDENSED_KEYS.includes(key)) {
                return (
                  <HeadingGrid key={`${i}-meta-${key}`} $condensed={condensed}>
                    <Text variant="body1">{currencyFormatter(value)}</Text>
                  </HeadingGrid>
                );
              }
              return null;
            } else {
              const Text = TOTAL_KEYS.includes(key) ? Bold : Typography;
              if (!condensed || CONDENSED_KEYS.includes(key)) {
                return (
                  <Cell key={`${i}-meta-${key}`}>
                    <Text variant="body1">
                      {value
                        ? j < 4
                          ? `${percentageFormatter.format(100 * value)}%`
                          : numberWithCommas(value)
                        : "-"}
                    </Text>
                  </Cell>
                );
              }
              return null;
            }
          })}
      </Column>
    );
  }
  if (groupByMonth && !sticky) {
    for (let i = income.length; i < MIN_COLUMNS; i++) {
      columns.push(
        <PlaceholderColumn
          key={i}
          currentCurrency={currentCurrency}
          data={data}
          breakdown={breakdown}
          expandRows={expandRows}
          groupByMonth={groupByMonth}
          condensed={condensed}
          report={report}
          empty={true}
        />
      );
    }
  }
  return <>{columns}</>;
};

const ProfitAndLossPercentageColumn = ({
  currentCurrency,
  data,
  breakdown,
  expandRows,
  groupByMonth,
  condensed,
  report,
}: ProfitAndLossColumnProps) => {
  const { t } = useTranslation();
  const { income, expenses, profit, currency, meta } = data;

  const currencyRates = useTypedSelector(
    (state) => state.globalVar.currencyRates
  );

  const currencyFormatter = condensed ? formatCurrencyRounded : formatCurrency;

  const columns: ReactElement[] = [];

  if (!groupByMonth) {
    const { totalRevenue } = income[0] || { totalRevenue: 0 };
    const { units } = meta[0] || { units: 0 };
    const revenuePercentageFormatter = (v?: number) =>
      totalRevenue > 0 && v != null && v !== 0
        ? `${percentageFormatter.format((100 * v) / totalRevenue)}%`
        : "-";
    const avgValueFormatter = (v?: number) => {
      return units > 0 && v != null && v !== 0
        ? currencyFormatter(v / units, currencyRates, currency, currentCurrency)
        : "-";
    };

    columns.push(
      <Column key="income">
        <HeadingGrid $condensed={condensed} className="profit-headings">
          {!report && !condensed && (
            <Tooltip title={t("profitability.percentIncomeTooltip") as string}>
              <LightInlineIconButton />
            </Tooltip>
          )}
          <CategoryHeading variant="body1">
            {t("profitability.percentIncomeLabel")}
          </CategoryHeading>
        </HeadingGrid>
        {!condensed && <SectionHeader></SectionHeader>}
        {income[0] &&
          Object.values(income[0]).map((value, j) => {
            const key = Object.keys(income[0])[j];
            const bold = TOTAL_KEYS.includes(key);
            if (!condensed || CONDENSED_KEYS.includes(key)) {
              return (
                <ProfitAndLossExpandableCell
                  value={revenuePercentageFormatter(value)}
                  breakdown={breakdown?.[0]?.[
                    key as keyof ProfitAndLossIncomeProps
                  ]?.map((s) => revenuePercentageFormatter(s.eventValue))}
                  key={`revenue-${key}`}
                  cellKey={`revenue-${key}`}
                  expandRows={expandRows}
                  bold={bold}
                />
              );
            }
            return null;
          })}
        {!condensed && <SectionHeader></SectionHeader>}
        {expenses[0] &&
          Object.values(expenses[0]).map((value, j) => {
            const key = Object.keys(expenses[0])[j];
            const bold = TOTAL_KEYS.includes(key);
            if (!condensed || CONDENSED_KEYS.includes(key)) {
              return (
                <ProfitAndLossExpandableCell
                  value={revenuePercentageFormatter(value)}
                  breakdown={breakdown?.[0]?.[
                    key as keyof ProfitAndLossExpenseProps
                  ]?.map((s) => revenuePercentageFormatter(s.eventValue))}
                  key={`expenses-${key}`}
                  cellKey={`expenses-${key}`}
                  expandRows={expandRows}
                  bold={bold}
                />
              );
            }
            return null;
          })}
        <HeadingGrid>
          <CategoryHeading variant="body1">
            {totalRevenue > 0 && profit[0] > 0
              ? `${percentageFormatter.format(
                  (100 * profit[0]) / totalRevenue
                )}%`
              : "-"}
          </CategoryHeading>
        </HeadingGrid>
        <Cell></Cell>
        {!condensed && <SectionHeader></SectionHeader>}
        {meta[0] &&
          Object.values(meta[0]).map((_value, i) => {
            const key = Object.keys(meta)[i];
            if (!condensed || CONDENSED_KEYS.includes(key)) {
              return i === 0 ? (
                <HeadingGrid
                  key={`meta-${i}`}
                  $condensed={condensed}
                ></HeadingGrid>
              ) : (
                <Cell key={`meta-${i}`}></Cell>
              );
            }
            return null;
          })}
      </Column>
    );

    columns.push(
      <Column key="perUnit">
        <HeadingGrid $condensed={condensed} className="profit-headings">
          {!report && !condensed && (
            <Tooltip title={t("profitability.perUnitTooltip") as string}>
              <LightInlineIconButton />
            </Tooltip>
          )}
          <CategoryHeading variant="body1">
            {t("profitability.perUnitLabel", {
              currencySymbol:
                getCurrencySymbol[
                  currentCurrency as keyof typeof getCurrencySymbol
                ],
            })}
          </CategoryHeading>
        </HeadingGrid>
        <SectionHeader $condensed={condensed}></SectionHeader>
        {income[0] &&
          Object.values(income[0]).map((value, j) => {
            const key = Object.keys(income[0])[j];
            const bold = TOTAL_KEYS.includes(key);
            return (
              <ProfitAndLossExpandableCell
                value={avgValueFormatter(value)}
                breakdown={breakdown?.[0]?.[
                  key as keyof ProfitAndLossIncomeProps
                ]?.map((s) => avgValueFormatter(s.eventValue))}
                key={key}
                cellKey={key}
                expandRows={expandRows}
                bold={bold}
              />
            );
          })}
        <SectionHeader $condensed={condensed}></SectionHeader>
        {expenses[0] &&
          Object.values(expenses[0]).map((value, j) => {
            const key = Object.keys(expenses[0])[j];
            const bold = TOTAL_KEYS.includes(key);
            return (
              <ProfitAndLossExpandableCell
                value={avgValueFormatter(value)}
                breakdown={breakdown?.[0]?.[
                  key as keyof ProfitAndLossExpenseProps
                ]?.map((s) => avgValueFormatter(s.eventValue))}
                key={key}
                cellKey={key}
                expandRows={expandRows}
                bold={bold}
              />
            );
          })}
        <HeadingGrid>
          <CategoryHeading variant="body1">
            {units > 0 && profit[0] > 0
              ? currencyFormatter(
                  profit[0] / units,
                  currencyRates,
                  currency,
                  currentCurrency
                )
              : "-"}
          </CategoryHeading>
        </HeadingGrid>
        <Cell></Cell>
        {!condensed && <SectionHeader></SectionHeader>}
        {meta[0] &&
          Object.values(meta[0]).map((value, i) =>
            i === 0 ? (
              <HeadingGrid key={i} $condensed={condensed}>
                <Typography variant="body1">
                  {units > 0 && value !== 0
                    ? currencyFormatter(
                        value / units,
                        currencyRates,
                        currency,
                        currentCurrency
                      )
                    : "-"}
                </Typography>
              </HeadingGrid>
            ) : (
              <Cell key={i}></Cell>
            )
          )}
      </Column>
    );
  }

  return <>{columns}</>;
};

const PlaceholderColumn = ({
  data,
  breakdown,
  groupByMonth,
  expandRows,
  condensed,
  report,
  empty,
  isComparison,
}: ProfitAndLossColumnProps & { empty?: boolean; isComparison?: boolean }) => {
  const { t } = useTranslation();
  const { income, expenses, meta } = data;

  return (
    <FirstColumn $thin={groupByMonth}>
      <HeadingGrid
        $condensed={condensed}
        $justify="flex-start"
        className="profit-headings"
      >
        <CategoryHeading variant="body1">
          {!empty &&
            (groupByMonth
              ? t(`profitability.period`)
              : isComparison
              ? t(`profitability.comparisonPeriodLabel`)
              : t(`profitability.currentPeriodLabel`))}
        </CategoryHeading>
      </HeadingGrid>
      {!condensed && (
        <SectionHeader $startAlign={true}>
          <CategoryHeading variant="body1">
            {!empty && t(`profitability.incomeLabel`)}
          </CategoryHeading>
        </SectionHeader>
      )}
      {income[0] &&
        Object.keys(income[0]).map((key) => {
          const bold = TOTAL_KEYS.includes(key);
          if (!condensed || CONDENSED_KEYS.includes(key)) {
            return (
              <ProfitAndLossCategoryLabelCell
                key={key}
                cellKey={key}
                breakdown={breakdown?.[0]?.[
                  key as keyof ProfitAndLossIncomeProps
                ]?.map((s) => s.eventType)}
                expandRows={expandRows}
                shouldDisplayTooltip={
                  !report && !condensed && !TOTAL_KEYS.includes(key)
                }
                empty={empty}
                bold={bold}
              />
            );
          }
          return null;
        })}
      {!condensed && (
        <SectionHeader $startAlign={true}>
          <CategoryHeading variant="body1">
            {!empty && t(`profitability.expensesLabel`)}
          </CategoryHeading>
        </SectionHeader>
      )}
      {expenses[0] &&
        Object.keys(expenses[0]).map((key) => {
          const bold = TOTAL_KEYS.includes(key);
          if (!condensed || CONDENSED_KEYS.includes(key)) {
            return (
              <ProfitAndLossCategoryLabelCell
                key={key}
                cellKey={key}
                breakdown={breakdown?.[0]?.[
                  key as keyof ProfitAndLossExpenseProps
                ]?.map((s) => s.eventType)}
                expandRows={expandRows}
                shouldDisplayTooltip={
                  !report && !condensed && !TOTAL_KEYS.includes(key)
                }
                empty={empty}
                bold={bold}
              />
            );
          }
          return null;
        })}
      <HeadingGrid $justify="flex-start">
        {!empty && !report && !condensed && (
          <Tooltip title={t(`profitability.profitTooltip`) as string}>
            <LightInlineIconButton />
          </Tooltip>
        )}
        <CategoryHeading variant="body1">
          {!empty && t(`profitability.profitLabel`)}
        </CategoryHeading>
      </HeadingGrid>
      <Cell $startAlign={true}>
        {!empty && (
          <>
            {!report && !condensed && (
              <Tooltip title={t(`profitability.grossMarginTooltip`) as string}>
                <LightInlineIconButton />
              </Tooltip>
            )}
            <Typography variant="body1">
              {t(`profitability.grossMarginLabel`)}
            </Typography>
          </>
        )}
      </Cell>
      {!condensed && (
        <SectionHeader $startAlign={true}>
          <CategoryHeading variant="body1">
            {!empty && t(`profitability.metaLabel`)}
          </CategoryHeading>
        </SectionHeader>
      )}
      {meta[0] &&
        Object.keys(meta[0]).map((key, i) => {
          const Text = TOTAL_KEYS.includes(key) ? Bold : Typography;
          if (i === 0) {
            if (!condensed || CONDENSED_KEYS.includes(key)) {
              return (
                <HeadingGrid
                  key={key}
                  $condensed={condensed}
                  $justify="flex-start"
                >
                  {!empty && (
                    <>
                      {!report && !condensed && (
                        <Tooltip
                          title={
                            t(`profitability.estimatedPayoutTooltip`) as string
                          }
                        >
                          <LightInlineIconButton />
                        </Tooltip>
                      )}
                      <Text variant="body1">
                        {t(`profitability.estimatedPayoutLabel`)}
                      </Text>
                    </>
                  )}
                </HeadingGrid>
              );
            }
            return null;
          } else {
            if (!condensed || CONDENSED_KEYS.includes(key)) {
              return (
                <Cell key={key} $startAlign={true}>
                  {!empty && (
                    <>
                      {!report && !condensed && (
                        <Tooltip
                          title={t(`profitability.${key}Tooltip`) as string}
                        >
                          <LightInlineIconButton />
                        </Tooltip>
                      )}
                      <Text variant="body1">
                        {t(`profitability.${key}Label`)}
                      </Text>
                    </>
                  )}
                </Cell>
              );
            }
            return null;
          }
        })}
    </FirstColumn>
  );
};

export const ProfitAndLossTable = memo<ProfitAndLossStatementProps>(
  function ProfitAndLossTable({
    groupByMonth,
    current,
    prior,
    total,
    breakdown,
    totalBreakdown,
    report,
    condensed,
    expandRows,
    showComparison,
    currentCurrency,
    isComparison,
  }) {
    const { t } = useTranslation();

    return (
      <>
        <PlaceholderColumn
          currentCurrency={currentCurrency}
          data={isComparison ? prior : current}
          breakdown={isComparison ? breakdown?.prior : breakdown?.current}
          groupByMonth={groupByMonth}
          condensed={condensed}
          expandRows={expandRows}
          report={report}
          isComparison={isComparison}
        />
        {groupByMonth && total && (expandRows ? totalBreakdown : true) && (
          <ProfitAndLossDataColumn
            currentCurrency={currentCurrency}
            data={total}
            groupByMonth={groupByMonth}
            condensed={condensed}
            breakdown={totalBreakdown ? [totalBreakdown] : undefined}
            report={report}
            expandRows={expandRows}
            title={t("generic.total")}
            sticky={true}
          />
        )}
        <ScrollArea>
          <>
            {
              <ProfitAndLossDataColumn
                currentCurrency={currentCurrency}
                data={isComparison ? prior : current}
                breakdown={isComparison ? breakdown?.prior : breakdown?.current}
                groupByMonth={groupByMonth}
                expandRows={expandRows}
                condensed={condensed}
                report={report}
                title={showComparison ? t(`generic.current`) : undefined}
              />
            }
            {!condensed && (
              <ProfitAndLossPercentageColumn
                currentCurrency={currentCurrency}
                data={isComparison ? prior : current}
                breakdown={isComparison ? breakdown?.prior : breakdown?.current}
                expandRows={expandRows}
                groupByMonth={groupByMonth}
                condensed={condensed}
                report={report}
              />
            )}
            {showComparison && prior && !isEmpty(prior) && (
              <ProfitAndLossDataColumn
                currentCurrency={currentCurrency}
                data={prior}
                groupByMonth={groupByMonth}
                condensed={condensed}
                report={report}
                title={showComparison ? t(`generic.comparison`) : undefined}
              />
            )}
            {showComparison && prior && !isEmpty(prior) && (
              <DifferenceDataColumn
                current={isComparison ? prior : current}
                prior={prior}
                currentCurrency={currentCurrency}
                condensed={condensed}
                report={report}
                title={t(`profitability.diff`)}
              />
            )}
          </>
        </ScrollArea>
      </>
    );
  }
);

const ProfitAndLoss = memo<ProfitAndLossProps>(function ProfitAndLoss({
  title,
  store,
  currentRange,
  currentCurrency,
  currentFilter,
  includeTax,
  groupByMonth,
  showComparison,
  footerLink,
  condensed,
  report,
  isComparison,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const profitAndLoss = useTypedSelector(
    (state) =>
      state?.profitability?.profitAndLoss?.[store?.merchantId || "ALL"]?.[
        groupByMonth ? "monthly" : "summary"
      ]?.data?.current
  );

  const profitAndLossPrior = useTypedSelector(
    (state) =>
      state?.profitability?.profitAndLoss?.[store?.merchantId || "ALL"]?.[
        groupByMonth ? "monthly" : "summary"
      ]?.data?.prior
  );

  const profitAndLossTotal = useTypedSelector(
    (state) =>
      state?.profitability?.profitAndLoss?.[store?.merchantId || "ALL"]?.[
        groupByMonth ? "monthly" : "summary"
      ]?.data?.total
  );

  const lastReportDate = useTypedSelector(
    (state) =>
      state?.profitability?.profitAndLoss?.[store?.merchantId || "ALL"]?.[
        groupByMonth ? "monthly" : "summary"
      ]?.data?.lastReportDate
  );

  const lastUpdatedAt = useTypedSelector(
    (state) =>
      state?.profitability?.profitAndLoss?.[store?.merchantId || "ALL"]?.[
        groupByMonth ? "monthly" : "summary"
      ]?.data?.lastUpdatedAt
  );

  const fetching = useTypedSelector(
    (state) =>
      state?.profitability?.profitAndLoss?.[store?.merchantId || "ALL"]?.[
        groupByMonth ? "monthly" : "summary"
      ]?.fetching
  );

  const currencyRates = useTypedSelector(
    (state) => state.globalVar.currencyRates
  );

  const [expandRows, setExpandRows] = useState(false);

  useEffect(() => {
    dispatch(
      fetchProfitAndLoss({
        mid: store?.merchantId,
        includeTax,
        currentRange,
        currentFilter,
        groupByMonth,
        showComparison: showComparison || isComparison,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store?.merchantId, includeTax, currentRange, currentFilter]);

  const { expandedProfitIsLoading, data: breakdown } =
    useFetchProfitAndLossBreakdownQuery(
      {
        mid: store?.merchantId,
        ...(store
          ? {
              marketplaceFilters: {
                marketplaceType: store.marketplace,
                marketplaceSubtype:
                  store.marketplaceSubtype ?? store.marketplace,
              },
            }
          : {}),
        includeTax,
        currentRange,
        currentFilter,
        groupByMonth,
        // we need to fetch prior period data even if we're not
        // showing a comparison, to allow us to properly
        // align current and prior period rows on the
        // single channel profit and loss statement
        showComparison:
          showComparison || isComparison || !(groupByMonth || report),
      },
      {
        skip: !expandRows,
        selectFromResult: (result) => {
          return {
            expandedProfitIsLoading: result.isFetching,
            data: result.data,
          };
        },
      }
    );

  const totalBreakdown = useMemo(() => {
    return breakdown?.current.reduce(
      (total, val) => {
        for (const category in val) {
          const currentEvents = val[category as keyof ProfitBreakdown];
          const currentTotal = total[category as keyof ProfitBreakdown];
          for (const event of currentEvents ?? []) {
            const existing = total[category as keyof ProfitBreakdown]?.find(
              (e) => e.eventType === event.eventType
            );
            if (existing) {
              existing.eventValue += event.eventValue;
            } else {
              currentTotal?.push({
                eventType: event.eventType,
                eventValue: event.eventValue,
              });
            }
          }
        }
        return total;
      },
      {
        productSales: [],
        productSalesTax: [],
        refundedSales: [],
        reimbursements: [],
        promotions: [],
        otherIncome: [],
        advertising: [],
        sellingFees: [],
        fulfilmentAndShipping: [],
        refundsAndReturns: [],
        costOfGoods: [],
        otherExpenses: [],
      }
    );
  }, [breakdown]);

  return (
    <PanelWrapper elevation={2} className="break-before">
      <PanelHeader>
        <Box display="flex" alignItems="center">
          <Box display="flex" flexDirection="column">
            <Typography variant="h3">{title}</Typography>

            {lastReportDate && lastUpdatedAt && (
              <Typography variant="subtitle2" color="textSecondary">
                {t("retailAnalytics.updated", {
                  lastReportDate: moment(lastReportDate).format("ll"),
                  lastUpdatedAt: moment(lastUpdatedAt).format("ll"),
                })}
              </Typography>
            )}
          </Box>
          <Tooltip
            title={t("profitAndLoss.mainTooltip") as string}
            id="panel-card-tooltip"
          >
            <InlineIconButton id="panel-card-tooltip-icon" />
          </Tooltip>
        </Box>
        {!condensed && !report && (
          <Box display="flex" flexDirection="row">
            {
              <Box display="flex" justifyContent="center" height="50%">
                <NewBadge>{t("generic.new")}</NewBadge>
                <Typography variant="subtitle1" color="textPrimary" noWrap>
                  {t("profitability.expandProfitAndLoss")}
                </Typography>
                <Switch
                  size="small"
                  checked={expandRows}
                  onClick={() => {
                    setExpandRows(!expandRows);
                  }}
                />
              </Box>
            }
            <DownloadCsv
              {...{
                reportType: "profitAndLoss",
                path: "/api/generic/profitability/profitAndLoss",
                mid: store?.merchantId,
                params: {
                  fromDate: currentRange.fromDate,
                  priorFromDate: currentRange.priorFromDate,
                  toDate: currentRange.toDate,
                  priorToDate: currentRange.priorToDate,
                  timezone: currentRange.timezone,
                  interval: currentRange.interval,
                  currentCurrency,
                  currentFilter,
                  currencyRates,
                  includeTax,
                  groupByMonth,
                  showComparison: isComparison,
                  shopName: store?.storeName,
                  marketplaceName: useMarketplace(),
                  countryCode: store?.marketplaceCountry,
                  expandRows,
                },
              }}
            />
          </Box>
        )}
      </PanelHeader>
      <PanelContent>
        {fetching ||
        isEmpty(profitAndLoss) ||
        expandedProfitIsLoading ||
        (isComparison && isEmpty(profitAndLossPrior)) ? (
          <PanelLoading />
        ) : (
          <ProfitAndLossTable
            current={profitAndLoss}
            breakdown={breakdown}
            totalBreakdown={totalBreakdown}
            prior={profitAndLossPrior}
            total={profitAndLossTotal}
            groupByMonth={groupByMonth}
            showComparison={showComparison}
            expandRows={expandRows && !expandedProfitIsLoading}
            condensed={condensed}
            currentCurrency={currentCurrency}
            report={report}
            isComparison={isComparison}
          />
        )}
      </PanelContent>
      {footerLink && (
        <PanelFooter>
          <Typography color="primary" variant="body1" align="center">
            <Link component={RouterLink} to={footerLink.url}>
              {footerLink.label}
            </Link>
          </Typography>
        </PanelFooter>
      )}
    </PanelWrapper>
  );
});

export default ProfitAndLoss;
