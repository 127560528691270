/* eslint-disable no-magic-numbers */
import { formatCurrency, intFormatterRounded } from "~/utils/currencyUtils";

import ACOSCell from "~/components/table/cells/acosCell";
import ROASCell from "~/components/table/cells/roasCell";
import { ValueAndTextCell } from "~/components/table/cells/valueAndTextCell";
import { ValueCell } from "~/components/table/cells/valueCell";
import { isEmpty } from "lodash";
import moment from "moment-timezone";

export const ENTITY_TYPE = {
  campaign: "campaign",
  adGroup: "adGroup",
  keyword: "keyword",
  productAd: "productAd",
};

export const formatAdType = (adType, t) =>
  adType === "SD"
    ? t("advertisingDashboardWidget.adType.sponsoredDisplay")
    : adType === "SP"
    ? t("advertisingDashboardWidget.adType.sponsoredProducts")
    : adType === "SB"
    ? t("advertisingDashboardWidget.adType.sponsoredBrands")
    : adType === "SBV"
    ? t("advertisingDashboardWidget.adType.sponsoredBrandsVideo")
    : adType === "DSP Order"
    ? t("advertisingDashboardWidget.adType.dspOrder")
    : !isEmpty(adType)
    ? adType.replaceAll("_", " ")
    : t("advertisingDashboardWidget.adType.unknown");

export const formatMatchType = (matchType) =>
  matchType.charAt(0).toUpperCase() + matchType.substr(1).toLowerCase();

export const getPerformanceColumns = (
  entityType,
  currencyRates,
  homeCurrency,
  currentCurrency,
  t,
  includeNtbFields = true
) => [
  {
    Header: t("advertisingDashboardWidget.adTable.salesColumn"),
    id: "attributed_sales",
    accessor: (row) => ({
      value: row.attributed_sales
        ? formatCurrency(
            row.attributed_sales,
            currencyRates,
            homeCurrency,
            currentCurrency
          )
        : "-",
      text:
        row.cost_type === "VCPM"
          ? t("advertisingDashboardWidget.adTable.viewsAndClicks")
          : "",
    }),
    align: "right",
    Cell: ValueAndTextCell,
    isVisible: true,
  },
  {
    Header: t("advertisingDashboardWidget.adTable.spendColumn"),
    id: "cost",
    accessor: (row) =>
      row.cost
        ? formatCurrency(row.cost, currencyRates, homeCurrency, currentCurrency)
        : "-",
    align: "right",
    Cell: ValueCell,
    isVisible: true,
  },
  {
    Header: t("advertisingDashboardWidget.adTable.acosColumn"),
    id: "acos",
    accessor: (row) => ({
      value: row.acos ? `${row.acos.toFixed(2)}%` : "-",
    }),
    align: "right",
    Cell: ACOSCell,
    isVisible: true,
    sortDescFirst: true,
  },
  {
    Header: t("advertisingDashboardWidget.adTable.impressionsColumn"),
    id: "impressions",
    accessor: (row) =>
      row.impressions ? intFormatterRounded.format(row.impressions) : "-",
    align: "right",
    Cell: ValueCell,
    isVisible: true,
  },
  {
    Header: t("advertisingDashboardWidget.adTable.clicksColumn"),
    id: "clicks",
    accessor: (row) =>
      row.clicks ? intFormatterRounded.format(row.clicks) : "-",
    align: "right",
    Cell: ValueCell,
    isVisible: true,
  },
  {
    Header: t("advertisingDashboardWidget.adTable.clickThroughRateColumn"),
    id: "click_through_rate",
    accessor: (row) =>
      row.click_through_rate ? `${row.click_through_rate.toFixed(4)}%` : "-",
    align: "right",
    Cell: ValueCell,
    isVisible: true,
  },
  {
    Header: t("advertisingDashboardWidget.adTable.costPerClickColumn"),
    id: "cost_per_click",
    accessor: (row) =>
      row.cost_per_click
        ? formatCurrency(
            row.cost_per_click,
            currencyRates,
            homeCurrency,
            currentCurrency
          )
        : "-",
    align: "right",
    Cell: ValueCell,
    isVisible: true,
  },
  {
    Header: t("advertisingDashboardWidget.adTable.detailPageViewClick"),
    id: "attributed_detail_page_views_clicks",
    accessor: (row) =>
      row.attributed_detail_page_views_clicks
        ? intFormatterRounded.format(row.attributed_detail_page_views_clicks)
        : "-",
    align: "right",
    Cell: ValueCell,
    isVisible: true,
  },
  {
    Header: t("advertisingDashboardWidget.adTable.conversionColumn"),
    id: "conversion_rate",
    accessor: (row) => ({
      value: row.conversion_rate ? `${row.conversion_rate.toFixed(2)}%` : "-",
      text:
        row.cost_type === "VCPM"
          ? t("advertisingDashboardWidget.adTable.viewsAndClicks")
          : "",
    }),
    align: "right",
    Cell: ValueAndTextCell,
    isVisible: true,
  },
  {
    Header: t("advertisingDashboardWidget.adTable.ordersColumn"),
    id: "attributed_conversions",
    accessor: (row) => ({
      value: row.attributed_conversions
        ? intFormatterRounded.format(row.attributed_conversions)
        : "-",
      text:
        row.cost_type === "VCPM"
          ? t("advertisingDashboardWidget.adTable.viewsAndClicks")
          : "",
    }),
    align: "right",
    Cell: ValueAndTextCell,
    isVisible: true,
  },
  {
    Header: t("advertisingDashboardWidget.adTable.unitsColumn"),
    id: "attributed_units_ordered",
    accessor: (row) => ({
      value: row.attributed_units_ordered
        ? intFormatterRounded.format(row.attributed_units_ordered)
        : "-",
      text:
        row.cost_type === "VCPM"
          ? t("advertisingDashboardWidget.adTable.viewsAndClicks")
          : "",
    }),
    align: "right",
    Cell: ValueAndTextCell,
    isVisible: false,
  },
  ...(entityType === ENTITY_TYPE.campaign
    ? [
        {
          Header: t("advertisingDashboardWidget.adTable.startColumn"),
          id: "start_date_string",
          accessor: (row) =>
            row.start_date_string
              ? moment(row.start_date_string)
                  .format("ll")
                  .replace(" ", "\u00A0")
              : "-",
          Cell: ValueCell,
          isVisible: true,
        },
        {
          Header: t("advertisingDashboardWidget.adTable.endColumn"),
          id: "end_date_string",
          accessor: (row) =>
            row.end_date_string
              ? moment(row.end_date_string).format("ll").replace(" ", "\u00A0")
              : "-",
          Cell: ValueCell,
          isVisible: true,
        },
      ]
    : []),
  {
    Header: t(
      "advertisingDashboardWidget.adTable.returnOnAdvertisingSpendColumn"
    ),
    id: "roas",
    accessor: (row) => ({
      value: row.roas ? `${row.roas.toFixed(2)}` : "-",
    }),
    align: "right",
    Cell: ROASCell,
    isVisible: false,
  },
  ...(includeNtbFields
    ? [
        {
          Header: t("advertisingDashboardWidget.adTable.ntbSalesColumn"),
          id: "attributed_sales_ntb",
          accessor: (row) => ({
            value: row.attributed_sales_ntb
              ? formatCurrency(
                  row.attributed_sales_ntb,
                  currencyRates,
                  homeCurrency,
                  currentCurrency
                )
              : "-",
            text:
              row.cost_type === "VCPM"
                ? t("advertisingDashboardWidget.adTable.viewsAndClicks")
                : "",
          }),
          align: "right",
          Cell: ValueAndTextCell,
          isVisible: false,
        },
        {
          Header: t(
            "advertisingDashboardWidget.adTable.ntbSalesPercentageColumn"
          ),
          id: "attributed_sales_ntb_percentage",
          accessor: (row) => ({
            value: row.attributed_sales_ntb_percentage
              ? `${row.attributed_sales_ntb_percentage.toFixed(2)}%`
              : "-",
            text:
              row.cost_type === "VCPM"
                ? t("advertisingDashboardWidget.adTable.viewsAndClicks")
                : "",
          }),
          align: "right",
          Cell: ValueAndTextCell,
          isVisible: false,
        },
        {
          Header: t("advertisingDashboardWidget.adTable.ntbOrdersColumn"),
          id: "attributed_orders_ntb",
          accessor: (row) => ({
            value: row.attributed_orders_ntb
              ? intFormatterRounded.format(row.attributed_orders_ntb)
              : "-",
            text:
              row.cost_type === "VCPM"
                ? t("advertisingDashboardWidget.adTable.viewsAndClicks")
                : "",
          }),
          align: "right",
          Cell: ValueAndTextCell,
          isVisible: false,
        },
        {
          Header: t(
            "advertisingDashboardWidget.adTable.ntbOrdersPercentageColumn"
          ),
          id: "attributed_orders_ntb_percentage",
          accessor: (row) => ({
            value: row.attributed_orders_ntb_percentage
              ? `${row.attributed_orders_ntb_percentage.toFixed(2)}%`
              : "-",
            text:
              row.cost_type === "VCPM"
                ? t("advertisingDashboardWidget.adTable.viewsAndClicks")
                : "",
          }),
          align: "right",
          Cell: ValueAndTextCell,
          isVisible: false,
        },
        {
          Header: t("advertisingDashboardWidget.adTable.ntbUnitsColumn"),
          id: "attributed_units_ordered_ntb",
          accessor: (row) => ({
            value: row.attributed_orders_ntb
              ? intFormatterRounded.format(row.attributed_units_ordered_ntb)
              : "-",
            text:
              row.cost_type === "VCPM"
                ? t("advertisingDashboardWidget.adTable.viewsAndClicks")
                : "",
          }),
          align: "right",
          Cell: ValueAndTextCell,
          isVisible: false,
        },
        {
          Header: t(
            "advertisingDashboardWidget.adTable.ntbUnitsPercentageColumn"
          ),
          id: "attributed_units_ordered_ntb_percentage",
          accessor: (row) => ({
            value: row.attributed_units_ordered_ntb_percentage
              ? `${row.attributed_units_ordered_ntb_percentage.toFixed(2)}%`
              : "-",
            text:
              row.cost_type === "VCPM"
                ? t("advertisingDashboardWidget.adTable.viewsAndClicks")
                : "",
          }),
          align: "right",
          Cell: ValueAndTextCell,
          isVisible: false,
        },
      ]
    : []),
];
