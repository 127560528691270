import {
  Box,
  Grid,
  GridJustification,
  PropTypes,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { DownArrow, UpArrow } from "~/icons/percentageChangeArrows";
import React, { memo, useEffect, useRef, useState } from "react";

import { InfoOutlined } from "@material-ui/icons";
import LargeNumber from "./largeNumber";
import { LightHeading } from "./styledTypography";
import StatusText from "../typography/status";
import { Variant } from "@material-ui/core/styles/createTypography";
import { getPercentageDifference } from "~/utils/salesUtils";
import { numberWithCommas } from "~/utils/utils";
import styled from "styled-components";
import { useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export const InlineIconButton = styled(InfoOutlined)`
  cursor: pointer;
  font-size: 1rem;
  margin-left: 5px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

interface GenericTotalProps {
  number: number;
  secondNumber?: string;
  title: string;
  tooltip?: string;
  condensed?: boolean;
  comparison?: {
    prior: number;
  };
  lockLayout?: boolean;
  textAlign?: PropTypes.Alignment;
  condensedComparison?: boolean;
  percentage?: boolean;
  conditionalFormatting?: boolean;
  customSize?: number;
  titleVariant?: "inherit" | Variant | undefined;
  reverseFormatting?: boolean;
  comparisonSubtitle?: string;
  currentSubtitle?: string;
}

export const getGrowthStatus = (
  growth?: number | string,
  reverseFormatting?: boolean
): undefined | "error" | "success" => {
  if (!growth || growth === "N/A" || growth === "-") {
    return undefined;
  }
  if (reverseFormatting) {
    return (growth as number) > 0 ? "error" : "success";
  } else {
    return (growth as number) < 0 ? "error" : "success";
  }
};

export const GenericTotal = memo<GenericTotalProps>(function GenericTotal({
  number,
  secondNumber,
  title,
  tooltip,
  condensed,
  comparison,
  lockLayout,
  condensedComparison,
  textAlign,
  percentage,
  conditionalFormatting,
  customSize,
  titleVariant,
  reverseFormatting,
  comparisonSubtitle,
  currentSubtitle,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const [growth, setGrowth] = useState<string | number>("N/A");

  const headingContainerRef = useRef<HTMLDivElement>(null);
  const lastPeriodHeadingContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (comparison) {
      const lastPeriod = comparison.prior || "-";

      const growthValue = getPercentageDifference(
        number,
        lastPeriod,
        percentage
      );

      setGrowth(growthValue);
    }
  }, [number, comparison]);

  const status =
    conditionalFormatting === false
      ? undefined
      : getGrowthStatus(growth, reverseFormatting);

  const value = Number.isFinite(number)
    ? `${numberWithCommas(number.toFixed(percentage ? 1 : 0))}${
        percentage ? " %" : ""
      }`
    : "N/A";

  const lastPeriodValue =
    comparison && Number.isFinite(comparison.prior)
      ? `${numberWithCommas(comparison.prior.toFixed(percentage ? 1 : 0))}${
          percentage ? " %" : ""
        }`
      : "N/A";

  return (
    <Grid
      item
      container
      alignItems="center"
      justifyContent={smDown ? "center" : "flex-start"}
      spacing={condensed ? 1 : 2}
      id="total-sales-wrapper"
    >
      <Grid
        container
        item
        wrap="wrap"
        alignItems="center"
        justifyContent={textAlign as GridJustification}
        xs={lockLayout ? 12 : 6}
        md={12}
      >
        <Box mr="5px">
          <Typography
            variant={titleVariant ? titleVariant : "h3"}
            align={textAlign}
          >
            {title}
          </Typography>
          {currentSubtitle && (
            <Typography variant="body1" color="textSecondary">
              {currentSubtitle}
            </Typography>
          )}
        </Box>
        {tooltip && (
          <Tooltip title={tooltip}>
            <InlineIconButton aria-label="info" />
          </Tooltip>
        )}
      </Grid>
      <Grid
        container
        item
        wrap="wrap"
        alignItems="center"
        justifyContent={textAlign as GridJustification}
        xs={lockLayout ? 12 : 6}
        md={12}
        ref={headingContainerRef}
      >
        <LargeNumber
          align={textAlign}
          variant="h1"
          headingContainerRef={headingContainerRef}
          value={value}
          customSize={customSize}
        />
        {secondNumber && (
          <LightHeading variant="h3" color="textSecondary">
            {secondNumber}
          </LightHeading>
        )}
      </Grid>
      {comparison && (
        <>
          {!condensedComparison && (
            <Grid
              container
              item
              wrap="wrap"
              alignItems="center"
              xs={lockLayout ? 12 : 6}
              md={12}
            >
              <Box>
                <Typography variant="h3" color="textSecondary">
                  {t("dashboardWidget.salesPerformance.priorPeriod")}
                </Typography>
                {comparisonSubtitle && (
                  <Typography variant="body1" color="textSecondary">
                    {comparisonSubtitle}
                  </Typography>
                )}
              </Box>
            </Grid>
          )}
          <Grid
            container
            item
            wrap="wrap"
            alignItems="center"
            xs={lockLayout ? 12 : 6}
            md={12}
            ref={lastPeriodHeadingContainerRef}
          >
            {!condensedComparison && (
              <LargeNumber
                variant="h2"
                headingContainerRef={lastPeriodHeadingContainerRef}
                value={lastPeriodValue}
                customSize={customSize}
              />
            )}
            <Tooltip title="Percentage change compared to the equivalent prior period">
              <StatusText variant="h5" status={status}>
                {!isNaN(growth as number) && (growth as number) > 0 && (
                  <UpArrow fontSize="inherit" status={status} />
                )}
                {!isNaN(growth as number) && (growth as number) < 0 && (
                  <DownArrow fontSize="inherit" status={status} />
                )}
                {isNaN(growth as number) ? growth : Math.abs(growth as number)}
                {!isNaN(growth as number) ? (percentage ? "ppt" : "%") : null}
              </StatusText>
            </Tooltip>
          </Grid>
        </>
      )}
    </Grid>
  );
});

export default GenericTotal;
