import { TAG_TYPES, api } from "@store/apiSlice";
import { setError, setInfoMessage } from "../globalToast.redux";

import { Dispatch } from "redux";
import { Filter } from "~/typedef/store";
import { INTERNAL_SERVER_ERROR } from "http-status-codes";
import { INTERVAL } from "../utils/dateTimeUtils";
import { Range } from "~/typedef/store";
import { TFunction } from "react-i18next";
import axios from "axios";
import { baseUrl } from "../../configs";
import get from "lodash/get";
import { globalQueryErrorHandler } from "../utils/errorHandlerUtils";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";

export const AUTHORISE_ADVERTISING_REQUEST = "AUTHORISE_ADVERTISING_REQUEST";
export const AUTHORISE_ADVERTISING_SUCCESS = "AUTHORISE_ADVERTISING_SUCCESS";
export const AUTHORISE_ADVERTISING_ERROR = "AUTHORISE_ADVERTISING_ERROR";

export const FETCH_PROFILES_SUCCESS = "FETCH_PROFILES_SUCCESS";
export const FETCH_ADVERTISERS_SUCCESS = "FETCH_ADVERTISERS_SUCCESS";

export const FETCH_MARKETING_CAMPAIGNS_REQUEST =
  "FETCH_MARKETING_CAMPAIGNS_REQUEST";
export const FETCH_MARKETING_CAMPAIGNS_SUCCESS =
  "FETCH_MARKETING_CAMPAIGNS_SUCCESS";
export const FETCH_MARKETING_CAMPAIGNS_ERROR =
  "FETCH_MARKETING_CAMPAIGNS_ERROR";

export const FETCH_MARKETING_AD_GROUPS_REQUEST =
  "FETCH_MARKETING_AD_GROUPS_REQUEST";
export const FETCH_MARKETING_AD_GROUPS_SUCCESS =
  "FETCH_MARKETING_AD_GROUPS_SUCCESS";
export const FETCH_MARKETING_AD_GROUPS_ERROR =
  "FETCH_MARKETING_AD_GROUPS_ERROR";

export const FETCH_MARKETING_KEYWORDS_REQUEST =
  "FETCH_MARKETING_KEYWORDS_REQUEST";
export const FETCH_MARKETING_KEYWORDS_SUCCESS =
  "FETCH_MARKETING_KEYWORDS_SUCCESS";
export const FETCH_MARKETING_KEYWORDS_ERROR = "FETCH_MARKETING_KEYWORDS_ERROR";

export const FETCH_MARKETING_NEGATIVE_KEYWORDS_REQUEST =
  "FETCH_MARKETING_NEGATIVE_KEYWORDS_REQUEST";
export const FETCH_MARKETING_NEGATIVE_KEYWORDS_SUCCESS =
  "FETCH_MARKETING_NEGATIVE_KEYWORDS_SUCCESS";
export const FETCH_MARKETING_NEGATIVE_KEYWORDS_ERROR =
  "FETCH_MARKETING_NEGATIVE_KEYWORDS_ERROR";

export const FETCH_MARKETING_CAMPAIGN_NEGATIVE_KEYWORDS_REQUEST =
  "FETCH_MARKETING_CAMPAIGN_NEGATIVE_KEYWORDS_REQUEST";
export const FETCH_MARKETING_CAMPAIGN_NEGATIVE_KEYWORDS_SUCCESS =
  "FETCH_MARKETING_CAMPAIGN_NEGATIVE_KEYWORDS_SUCCESS";
export const FETCH_MARKETING_CAMPAIGN_NEGATIVE_KEYWORDS_ERROR =
  "FETCH_MARKETING_CAMPAIGN_NEGATIVE_KEYWORDS_ERROR";

export const FETCH_MARKETING_PRODUCT_ADS_REQUEST =
  "FETCH_MARKETING_PRODUCT_ADS_REQUEST";
export const FETCH_MARKETING_PRODUCT_ADS_SUCCESS =
  "FETCH_MARKETING_PRODUCT_ADS_SUCCESS";
export const FETCH_MARKETING_PRODUCT_ADS_ERROR =
  "FETCH_MARKETING_PRODUCT_ADS_ERROR";

export const FETCH_MARKETING_CAMPAIGN_CHART_REQUEST =
  "FETCH_MARKETING_CAMPAIGN_CHART_REQUEST";
export const FETCH_MARKETING_CAMPAIGN_CHART_SUCCESS =
  "FETCH_MARKETING_CAMPAIGN_CHART_SUCCESS";
export const FETCH_MARKETING_CAMPAIGN_CHART_ERROR =
  "FETCH_MARKETING_CAMPAIGN_CHART_ERROR";

export const FETCH_MARKETING_CAMPAIGN_TOTALS_REQUEST =
  "FETCH_MARKETING_CAMPAIGN_TOTALS_REQUEST";
export const FETCH_MARKETING_CAMPAIGN_TOTALS_SUCCESS =
  "FETCH_MARKETING_CAMPAIGN_TOTALS_SUCCESS";

export const FETCH_MARKETING_AD_GROUP_CHART_REQUEST =
  "FETCH_MARKETING_AD_GROUP_CHART_REQUEST";
export const FETCH_MARKETING_AD_GROUP_CHART_SUCCESS =
  "FETCH_MARKETING_AD_GROUP_CHART_SUCCESS";
export const FETCH_MARKETING_AD_GROUP_CHART_ERROR =
  "FETCH_MARKETING_AD_GROUP_CHART_ERROR";

export const FETCH_MARKETING_AD_GROUP_TOTALS_REQUEST =
  "FETCH_MARKETING_AD_GROUP_TOTALS_REQUEST";
export const FETCH_MARKETING_AD_GROUP_TOTALS_SUCCESS =
  "FETCH_MARKETING_AD_GROUP_TOTALS_SUCCESS";

export const FETCH_MARKETING_CAMPAIGN_TYPES_CHART_REQUEST =
  "FETCH_MARKETING_CAMPAIGN_TYPES_CHART_REQUEST";
export const FETCH_MARKETING_CAMPAIGN_TYPES_CHART_SUCCESS =
  "FETCH_MARKETING_CAMPAIGN_TYPES_CHART_SUCCESS";
export const FETCH_MARKETING_CAMPAIGN_TYPES_CHART_ERROR =
  "FETCH_MARKETING_CAMPAIGN_TYPES_CHART_ERROR";

export interface AdvertisingProfile {
  profileId: string;
  countryCode: string;
  accountInfo: {
    id: string;
    name: string;
  };
}

export interface MarketingState {
  // TODO: Define proper types for the attributes
  // grouped under this state
  // or better yet, migrate them to RTK Query
  loading: boolean;
  profiles: AdvertisingProfile[];
  advertisers: any[];
  chartLoading: boolean;
  chartData: any[];
  campaigns: any;
  adGroups: any;
  productAds: any;
  keywords: any;
  negativeKeywords: any;
  campaignNegativeKeywords: any;
  campaignTypes: any;
  totals: {
    loading: boolean;
    data: any;
  };
}

const initState: MarketingState = {
  loading: true,
  profiles: [],
  advertisers: [],
  chartLoading: true,
  chartData: [],
  campaigns: {
    loading: false,
  },
  adGroups: {
    loading: false,
  },
  negativeKeywords: {
    loading: false,
  },
  keywords: {
    loading: false,
  },
  campaignNegativeKeywords: { loading: false },
  productAds: { loading: false },
  campaignTypes: { loading: false },
  totals: {
    loading: true,
    data: null,
  },
};

export const marketing = (
  state = initState,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case AUTHORISE_ADVERTISING_REQUEST:
    case FETCH_MARKETING_CAMPAIGNS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_MARKETING_AD_GROUPS_REQUEST:
      return {
        ...state,
        error: null,
        adGroups: { ...state.adGroups, loading: true },
      };
    case FETCH_MARKETING_NEGATIVE_KEYWORDS_REQUEST:
      return {
        ...state,
        error: null,
        negativeKeywords: {
          ...state.negativeKeywords,
          loading: true,
        },
      };
    case FETCH_MARKETING_KEYWORDS_REQUEST:
      return {
        ...state,
        error: null,
        keywords: { ...state.keywords, loading: true },
      };
    case FETCH_MARKETING_CAMPAIGN_NEGATIVE_KEYWORDS_REQUEST:
      return {
        ...state,
        error: null,
        campaignNegativeKeywords: {
          ...state.campaignNegativeKeywords,
          loading: true,
        },
      };
    case FETCH_MARKETING_PRODUCT_ADS_REQUEST:
      return {
        ...state,
        error: null,
        productAds: {
          ...state.productAds,
          loading: true,
        },
      };
    case FETCH_MARKETING_CAMPAIGN_CHART_REQUEST:
    case FETCH_MARKETING_AD_GROUP_CHART_REQUEST:
      return {
        ...state,
        chartLoading: true,
        chartError: null,
      };
    case FETCH_MARKETING_CAMPAIGN_TYPES_CHART_REQUEST:
      return {
        ...state,
        error: null,
        campaignTypes: {
          ...state.campaignTypes,
          loading: true,
        },
      };
    case AUTHORISE_ADVERTISING_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case FETCH_PROFILES_SUCCESS:
      return {
        ...state,
        profiles: action.payload,
      };
    case FETCH_ADVERTISERS_SUCCESS:
      return {
        ...state,
        advertisers: action.payload,
      };
    case FETCH_MARKETING_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        loading: false,
        campaigns: action.payload,
      };
    case FETCH_MARKETING_AD_GROUPS_SUCCESS:
      return {
        ...state,
        adGroups: { ...action.payload, loading: false },
      };
    case FETCH_MARKETING_KEYWORDS_SUCCESS:
      return {
        ...state,
        keywords: {
          ...action.payload,
          loading: false,
        },
      };
    case FETCH_MARKETING_NEGATIVE_KEYWORDS_SUCCESS:
      return {
        ...state,
        negativeKeywords: { ...action.payload, loading: false },
      };
    case FETCH_MARKETING_CAMPAIGN_NEGATIVE_KEYWORDS_SUCCESS:
      return {
        ...state,
        campaignNegativeKeywords: {
          ...action.payload,
          loading: false,
        },
      };
    case FETCH_MARKETING_PRODUCT_ADS_SUCCESS:
      return {
        ...state,
        productAds: { ...action.payload, loading: false },
      };
    case FETCH_MARKETING_CAMPAIGN_CHART_SUCCESS:
    case FETCH_MARKETING_AD_GROUP_CHART_SUCCESS:
      return {
        ...state,
        chartLoading: false,
        chartData: action.payload,
      };
    case FETCH_MARKETING_CAMPAIGN_TYPES_CHART_SUCCESS:
      return {
        ...state,
        campaignTypes: { ...action.payload, loading: false },
      };

    case AUTHORISE_ADVERTISING_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_MARKETING_CAMPAIGNS_ERROR:
      return {
        ...state,
        loading: false,
        campaigns: null,
        error: action.payload,
      };
    case FETCH_MARKETING_AD_GROUPS_ERROR:
      return {
        ...state,
        error: action.payload,
        adGroups: { ...state.adGroups, loading: false },
      };
    case FETCH_MARKETING_KEYWORDS_ERROR:
      return {
        ...state,
        error: action.payload,
        keywords: { ...state.keywords, loading: false },
      };
    case FETCH_MARKETING_NEGATIVE_KEYWORDS_ERROR:
      return {
        ...state,
        error: action.payload,
        negativeKeywords: {
          ...state.negativeKeywords,
          loading: false,
        },
      };
    case FETCH_MARKETING_CAMPAIGN_NEGATIVE_KEYWORDS_ERROR:
      return {
        ...state,
        error: action.payload,
        campaignNegativeKeywords: {
          ...state.campaignNegativeKeywords,
          loading: false,
        },
      };
    case FETCH_MARKETING_PRODUCT_ADS_ERROR:
      return {
        ...state,
        error: action.payload,
        productAds: { ...state.productAds, loading: false },
      };
    case FETCH_MARKETING_CAMPAIGN_CHART_ERROR:
    case FETCH_MARKETING_AD_GROUP_CHART_ERROR:
      return {
        ...state,
        chartLoading: false,
        chartError: action.payload,
        chartData: [],
      };
    case FETCH_MARKETING_CAMPAIGN_TYPES_CHART_ERROR:
      return {
        ...state,
        error: action.payload,
        campaignTypes: { ...state.campaignTypes, loading: false },
      };

    case FETCH_MARKETING_CAMPAIGN_TOTALS_REQUEST:
    case FETCH_MARKETING_AD_GROUP_TOTALS_REQUEST:
      return {
        ...state,
        totals: {
          loading: true,
          data: null,
        },
      };

    case FETCH_MARKETING_CAMPAIGN_TOTALS_SUCCESS:
    case FETCH_MARKETING_AD_GROUP_TOTALS_SUCCESS:
      return {
        ...state,
        totals: {
          loading: false,
          data: action.payload.summaryData || null,
        },
      };

    default:
      return state;
  }
};

export const authoriseAdvertising =
  (
    authCode: string,
    region: string,
    mid: string,
    countryCode: string,
    sellerId: string | null,
    isReconnect: boolean | undefined,
    marketplace: string,
    t: TFunction
  ) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: AUTHORISE_ADVERTISING_REQUEST });
    try {
      const res = await axios.post(
        marketplace === "amazon"
          ? `${baseUrl}/auth/amazon/advertising/connect`
          : marketplace === "amazon_vendor"
          ? `${baseUrl}/auth/amazon/vendor/advertising/connect`
          : `${baseUrl}/auth/amazon/${marketplace}/advertising/connect`,
        {
          authCode,
          region,
          mid,
          countryCode,
          sellerId,
          isReconnect,
        }
      );

      if (isHttpResponseValid(res.status)) {
        if (res.data.errMsg) {
          setError(dispatch, t(res.data.errMsg), INTERNAL_SERVER_ERROR);
          dispatch({
            type: AUTHORISE_ADVERTISING_ERROR,
            payload: t(res.data.errMsg),
          });
          throw new Error("Error authorising Amazon Advertising connection");
        } else {
          if (!sellerId) {
            return dispatch({
              type: FETCH_PROFILES_SUCCESS,
              payload: res.data.profiles,
            });
          } else {
            setInfoMessage(dispatch, t("advertisingConnectLabel.authorised"));

            return dispatch({
              type: AUTHORISE_ADVERTISING_SUCCESS,
            });
          }
        }
      }

      setError(dispatch, res.data.errMsg, res.status);
    } catch (err) {
      setError(
        dispatch,
        t("advertisingConnectLabel.generalError"),
        INTERNAL_SERVER_ERROR
      );
      dispatch({
        type: AUTHORISE_ADVERTISING_ERROR,
        payload: get(err, "response.data"),
      });
      throw new Error(
        "General error authorising Amazon Advertising connection"
      );
    }

    return dispatch({
      type: AUTHORISE_ADVERTISING_ERROR,
    });
  };

export const fetchAllMarketingCampaigns =
  (
    customerId: string,
    mid: string,
    countryCode: string,
    marketplaceType: string,
    marketplaceSubtype: string,
    searchText: string,
    groupId?: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_MARKETING_CAMPAIGNS_REQUEST });

    try {
      const { data, status } = await axios.get(
        `${baseUrl}/api/advertising-runtime-service/api/advertising/campaign/all`,
        {
          params: {
            customerId,
            mid,
            countryCode,
            marketplaceType,
            marketplaceSubtype,
            searchText,
            groupId,
            limit: 100,
          },
        }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: FETCH_MARKETING_CAMPAIGNS_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: FETCH_MARKETING_CAMPAIGNS_ERROR,
        });
        setError(dispatch, data.errMsg, status);
      }
    } catch (err) {
      setError(dispatch, get(err, "response.data"));
      return dispatch({
        type: FETCH_MARKETING_CAMPAIGNS_ERROR,
        payload: get(err, "response.data"),
      });
    }
    setError(dispatch);
    return dispatch({
      type: FETCH_MARKETING_CAMPAIGNS_ERROR,
    });
  };

export const fetchMarketingCampaignNegativeKeywords =
  (
    customerId: string,
    mid: string,
    countryCode: string,
    marketplaceType: string,
    marketplaceSubtype: string,
    searchText: string,
    reportDate: string,
    campaignId: string,
    paginationParams: CampaignPaginationParams
  ) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: FETCH_MARKETING_CAMPAIGN_NEGATIVE_KEYWORDS_REQUEST,
    });

    try {
      const { data, status } = await axios.get(
        `${baseUrl}/api/advertising-runtime-service/api/advertising/campaign/${campaignId}/negativeKeyword`,
        {
          params: {
            customerId,
            mid,
            countryCode,
            marketplaceType,
            marketplaceSubtype,
            searchText,
            reportDate,
            ...paginationParams,
          },
        }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: FETCH_MARKETING_CAMPAIGN_NEGATIVE_KEYWORDS_SUCCESS,
          payload: data,
        });
      } else {
        setError(dispatch, data.errMsg, status);
      }
    } catch (err) {
      return dispatch({
        type: FETCH_MARKETING_CAMPAIGN_NEGATIVE_KEYWORDS_ERROR,
        payload: get(err, "response.data"),
      });
    }

    return dispatch({
      type: FETCH_MARKETING_CAMPAIGN_NEGATIVE_KEYWORDS_ERROR,
    });
  };

export const fetchMarketingAdGroups =
  (
    customerId: string,
    mid: string,
    countryCode: string,
    marketplaceType: string,
    marketplaceSubtype: string,
    searchText: string,
    reportDateFrom: number,
    reportDateTo: number,
    campaignId: string,
    paginationParams: CampaignPaginationParams
  ) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_MARKETING_AD_GROUPS_REQUEST });

    try {
      const { data, status } = await axios.get(
        campaignId
          ? `${baseUrl}/api/advertising-runtime-service/api/advertising/campaign/${campaignId}/adGroup`
          : `${baseUrl}/api/advertising-runtime-service/api/advertising/adGroup`,
        {
          params: {
            customerId,
            mid,
            countryCode,
            marketplaceType,
            marketplaceSubtype,
            searchText,
            reportDateFrom,
            reportDateTo,
            ...paginationParams,
          },
        }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: FETCH_MARKETING_AD_GROUPS_SUCCESS,
          payload: data,
        });
      } else {
        setError(dispatch, data.errMsg, status);
      }
    } catch (err) {
      return dispatch({
        type: FETCH_MARKETING_AD_GROUPS_ERROR,
        payload: get(err, "response.data"),
      });
    }

    return dispatch({
      type: FETCH_MARKETING_AD_GROUPS_ERROR,
    });
  };

export const fetchMarketingKeywords =
  (
    customerId: string,
    mid: string,
    countryCode: string,
    marketplaceType: string,
    marketplaceSubtype: string,
    searchText: string,
    reportDateFrom: number,
    reportDateTo: number,
    adGroupId: string | null | undefined,
    paginationParams: CampaignPaginationParams
  ) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_MARKETING_KEYWORDS_REQUEST });

    try {
      const { data, status } = await axios.get(
        adGroupId
          ? `${baseUrl}/api/advertising-runtime-service/api/advertising/adGroup/${adGroupId}/keyword`
          : `${baseUrl}/api/advertising-runtime-service/api/advertising/keyword`,
        {
          params: {
            customerId,
            mid,
            countryCode,
            marketplaceType,
            marketplaceSubtype,
            searchText,
            reportDateFrom,
            reportDateTo,
            ...paginationParams,
          },
        }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: FETCH_MARKETING_KEYWORDS_SUCCESS,
          payload: data,
        });
      } else {
        setError(dispatch, data.errMsg, status);
      }
    } catch (err) {
      return dispatch({
        type: FETCH_MARKETING_KEYWORDS_ERROR,
        payload: get(err, "response.data"),
      });
    }

    return dispatch({
      type: FETCH_MARKETING_KEYWORDS_ERROR,
    });
  };

export const fetchMarketingNegativeKeywords =
  (
    customerId: string,
    mid: string,
    countryCode: string,
    marketplaceType: string,
    marketplaceSubtype: string,
    searchText: string,
    reportDate: number,
    adGroupId: string | null | undefined,
    paginationParams: CampaignPaginationParams
  ) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_MARKETING_NEGATIVE_KEYWORDS_REQUEST });

    try {
      const { data, status } = await axios.get(
        `${baseUrl}/api/advertising-runtime-service/api/advertising/adGroup/${adGroupId}/negativeKeyword`,
        {
          params: {
            customerId,
            mid,
            countryCode,
            marketplaceType,
            marketplaceSubtype,
            reportDate,
            searchText,
            ...paginationParams,
          },
        }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: FETCH_MARKETING_NEGATIVE_KEYWORDS_SUCCESS,
          payload: data,
        });
      } else {
        setError(dispatch, data.errMsg, status);
      }
    } catch (err) {
      return dispatch({
        type: FETCH_MARKETING_NEGATIVE_KEYWORDS_ERROR,
        payload: get(err, "response.data"),
      });
    }

    return dispatch({
      type: FETCH_MARKETING_NEGATIVE_KEYWORDS_ERROR,
    });
  };

export const fetchMarketingProductAds =
  (
    customerId: string,
    mid: string,
    countryCode: string,
    marketplaceType: string,
    marketplaceSubtype: string | undefined,
    searchText: string,
    reportDateFrom: number,
    reportDateTo: number,
    adGroupId: string | null | undefined,
    paginationParams: CampaignPaginationParams
  ) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_MARKETING_PRODUCT_ADS_REQUEST });

    try {
      const { data, status } = await axios.get(
        adGroupId
          ? `${baseUrl}/api/advertising-runtime-service/api/advertising/adGroup/${adGroupId}/productAd`
          : `${baseUrl}/api/advertising-runtime-service/api/advertising/productAd`,
        {
          params: {
            customerId,
            mid,
            countryCode,
            marketplaceType,
            marketplaceSubtype,
            searchText,
            reportDateFrom,
            reportDateTo,
            ...paginationParams,
          },
        }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: FETCH_MARKETING_PRODUCT_ADS_SUCCESS,
          payload: data,
        });
      } else {
        setError(dispatch, data.errMsg, status);
      }
    } catch (err) {
      return dispatch({
        type: FETCH_MARKETING_PRODUCT_ADS_ERROR,
        payload: get(err, "response.data"),
      });
    }

    return dispatch({
      type: FETCH_MARKETING_PRODUCT_ADS_ERROR,
    });
  };

export const fetchMarketingCampaignTypesChart =
  (
    customerId: string,
    mid: string,
    countryCode: string,
    marketplaceType: string,
    marketplaceSubtype: string | undefined,
    reportDateFrom: number,
    reportDateTo: number,
    timezone: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_MARKETING_CAMPAIGN_TYPES_CHART_REQUEST });

    try {
      const { data, status } = await axios.get(
        `${baseUrl}/api/advertising-runtime-service/api/advertising/chart/campaign/types`,
        {
          params: {
            customerId,
            mid,
            countryCode,
            marketplaceType,
            marketplaceSubtype,
            reportDateFrom,
            reportDateTo,
            timezone,
          },
        }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: FETCH_MARKETING_CAMPAIGN_TYPES_CHART_SUCCESS,
          payload: data,
        });
      } else {
        setError(dispatch, data.errMsg, status);
      }
    } catch (err) {
      return dispatch({
        type: FETCH_MARKETING_CAMPAIGN_TYPES_CHART_ERROR,
        payload: get(err, "response.data"),
      });
    }

    return dispatch({
      type: FETCH_MARKETING_CAMPAIGN_TYPES_CHART_ERROR,
    });
  };

export const fetchMarketingCampaignChart =
  (
    customerId: string,
    mid: string,
    countryCode: string,
    marketplaceType: string,
    marketplaceSubtype: string,
    reportDateFrom: number,
    reportDateTo: number,
    priorFromDate: number,
    priorToDate: number,
    campaignId: string,
    timezone: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_MARKETING_CAMPAIGN_CHART_REQUEST });

    try {
      const { data, status } = await axios.get(
        `${baseUrl}/api/advertising-runtime-service/api/advertising/chart/campaign/${campaignId}`,
        {
          params: {
            customerId,
            mid,
            countryCode,
            marketplaceType,
            marketplaceSubtype,
            reportDateFrom,
            reportDateTo,
            priorFromDate,
            priorToDate,
            timezone,
          },
        }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: FETCH_MARKETING_CAMPAIGN_CHART_SUCCESS,
          payload: data,
        });
      } else {
        setError(dispatch, data.errMsg, status);
      }
    } catch (err) {
      return dispatch({
        type: FETCH_MARKETING_CAMPAIGN_CHART_ERROR,
        payload: get(err, "response.data"),
      });
    }

    return dispatch({
      type: FETCH_MARKETING_CAMPAIGN_CHART_ERROR,
    });
  };

export const fetchMarketingCampaignTotals =
  (
    customerId: string,
    mid: string,
    countryCode: string,
    marketplaceType: string,
    marketplaceSubtype: string,
    reportDateFrom: number,
    reportDateTo: number,
    priorFromDate: number,
    priorToDate: number,
    campaignId: string,
    timezone: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_MARKETING_CAMPAIGN_TOTALS_REQUEST });

    try {
      const { data, status } = await axios.get(
        `${baseUrl}/api/advertising-runtime-service/api/advertising/totals/campaign/${campaignId}`,
        {
          params: {
            customerId,
            mid,
            countryCode,
            marketplaceType,
            marketplaceSubtype,
            reportDateFrom,
            reportDateTo,
            priorFromDate,
            priorToDate,
            timezone,
          },
        }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: FETCH_MARKETING_CAMPAIGN_TOTALS_SUCCESS,
          payload: data,
        });
      } else {
        return setError(dispatch, data.errMsg, status);
      }
    } catch (e) {
      const err = get(e, "response.data.errMsg");
      const msg = get(err, "data.error");
      const statusText = get(err, "statusText");
      return setError(dispatch, msg || statusText, get(err, "status"));
    }
  };

export const fetchMarketingAdGroupChart =
  (
    customerId: string,
    mid: string,
    countryCode: string,
    marketplaceType: string,
    marketplaceSubtype: string,
    reportDateFrom: number,
    reportDateTo: number,
    priorFromDate: number,
    priorToDate: number,
    adGroupId: string | null | undefined,
    timezone: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_MARKETING_AD_GROUP_CHART_REQUEST });

    try {
      const { data, status } = await axios.get(
        `${baseUrl}/api/advertising-runtime-service/api/advertising/chart/adGroup/${adGroupId}`,
        {
          params: {
            customerId,
            mid,
            countryCode,
            marketplaceType,
            marketplaceSubtype,
            reportDateFrom,
            priorFromDate,
            priorToDate,
            reportDateTo,
            timezone,
          },
        }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: FETCH_MARKETING_AD_GROUP_CHART_SUCCESS,
          payload: data,
        });
      } else {
        setError(dispatch, data.errMsg, status);
      }
    } catch (err) {
      return dispatch({
        type: FETCH_MARKETING_AD_GROUP_CHART_ERROR,
        payload: get(err, "response.data"),
      });
    }

    return dispatch({
      type: FETCH_MARKETING_AD_GROUP_CHART_ERROR,
    });
  };

export const fetchMarketingAdGroupTotals =
  (
    customerId: string,
    mid: string,
    countryCode: string,
    marketplaceType: string,
    marketplaceSubtype: string,
    reportDateFrom: number,
    reportDateTo: number,
    priorFromDate: number,
    priorToDate: number,
    adGroupId: string | null | undefined,
    timezone: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_MARKETING_AD_GROUP_TOTALS_REQUEST });

    try {
      const { data, status } = await axios.get(
        `${baseUrl}/api/advertising-runtime-service/api/advertising/totals/adGroup/${adGroupId}`,
        {
          params: {
            customerId,
            mid,
            countryCode,
            marketplaceType,
            marketplaceSubtype,
            reportDateFrom,
            priorFromDate,
            priorToDate,
            reportDateTo,
            timezone,
          },
        }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: FETCH_MARKETING_AD_GROUP_TOTALS_SUCCESS,
          payload: data,
        });
      } else {
        return setError(dispatch, data.errMsg, status);
      }
    } catch (e) {
      const err = get(e, "response.data.errMsg");
      const msg = get(err, "data.error");
      const statusText = get(err, "statusText");
      return setError(dispatch, msg || statusText, get(err, "status"));
    }
  };

export interface CampaignPaginationParams {
  pageSize: number;
  pageIndex: number;
  sortKey: string;
  sortDesc: boolean;
}

interface FetchMarketingCampaignsArgs {
  customerId: string;
  mid: string;
  countryCode: string;
  marketplaceType: string;
  marketplaceSubtype?: string;
  searchText: string;
  reportDateFrom: number;
  reportDateTo: number;
  pageSize: number;
  pageIndex: number;
  sortKey: string;
  sortDesc: boolean;
  groupId?: string | null;
}

interface FetchMarketingCampaignsResponse {
  data: {
    campaign_name: string;
    campaign_id: string;
    ad_type: string;
    campaign_status: string;
    targeting_type: string;
    bidding_strategy: string;
    attributed_sales: number;
    cost?: number;
    acos?: number;
    impressions?: number;
    clicks?: number;
    click_through_rate?: number;
    cost_per_click?: number;
    attributed_detail_page_views_clicks?: number;
    conversion_rate?: number;
    attributed_conversions?: number;
    attributed_units_ordered?: number;
    start_date_string?: string;
    end_date_string?: string;
    roas?: number;
    attributed_sales_ntb?: number;
    attributed_sales_ntb_percentage?: number;
    attributed_orders_ntb?: number;
    attributed_orders_ntb_percentage?: number;
    attributed_units_ordered_ntb?: number;
    attributed_units_ordered_ntb_percentage?: number;
  }[];
  count: number;
}

interface FetchMarketingAllCampaignsDataParams {
  customerId: string;
  mid: string;
  countryCode: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  priorFromDate: number;
  priorToDate: number;
  reportDateTo: number;
  reportDateFrom: number;
  interval: INTERVAL;
  timezone: string;
  groupId?: string;
}

interface ChartResponseEntry {
  report_date: Date;
  cost: number;
  attributed_sales: number;
  acos: number;
  impressions: number;
  clicks: number;
  attributed_conversions: number;
  attributed_units_ordered: number;
  attributed_units_ordered_ntb: number;
}

export interface FetchMarketingAllCampaignsDataResponse {
  current: ChartResponseEntry;
  prior: ChartResponseEntry;
}

interface FetchOrganicSalesArgs {
  mid: string;
  currentPeriod: string;
  currentRange: Range;
  filter: Filter;
  includeTax: boolean;
  includeVendor: boolean;
  currency: string;
}

interface FetchMarketingAllCampaignsTotals {
  customerId: string;
  mid: string;
  countryCode: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  reportDateFrom: number;
  reportDateTo: number;
  priorFromDate: number;
  priorToDate: number;
  timezone: string;
  includeTax: boolean;
  groupId?: string;
}

interface AdvertisingSummaryData {
  totalCost: number;
  totalAdvertisingSales: number;
  impressions: number;
  clicks: number;
  orders: number;
  units: number;
  acos: number;
  roas: number;
  costPerClick: number;
  conversionRate: number;
  ntbUnits: number;
  ntbUnitsPercentage: number;
  tacos: number;
  troas: number;
}

interface FetchMarketingAllCampaignTotalsResponse {
  summaryData: {
    current: AdvertisingSummaryData;
    prior: AdvertisingSummaryData;
  };
}

interface FetchOrganicSalesResponse {
  chartData: {
    current: {
      sales: number;
      orders: number;
      unitsSold: number;
      avgOrderValue: number;
      startTime: number;
      endTime: number;
    };
    prior: {
      sales: number;
      orders: number;
      unitsSold: number;
      avgOrderValue: number;
      startTime: number;
      endTime: number;
    };
  }[];
}

export interface FetchMarketingProductsReq {
  mid: string;
  countryCode: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  searchText: string;
  pageSize: number;
  pageIndex: number;
  sortKey: string;
  sortDesc: boolean;
  reportDateFrom: number;
  reportDateTo: number;
}

interface FetchMarketingProductsData {
  seller_id: string;
  marketplace_id: string;
  report_date: string;
  sku: string;
  asin: string;
  cost_type: string | null;
  impressions: number;
  clicks: number;
  cost: number;
  attributed_sales: number;
  attributed_sales_same_sku: number;
  attributed_conversions: number;
  attributed_conversions_same_sku: number;
  attributed_units_ordered: number;
  attributed_units_ordered_same_sku: number;
  acos: number;
  click_through_rate: number;
  cost_per_click: number;
  conversion_rate: number;
  roas: number;
  product_sku: string;
  product_title: string;
  image_url: string;
  link_url: string;
}

interface FetchMarketingProductsRes {
  data: FetchMarketingProductsData[];
  count: number;
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    marketingCampaigns: build.query<
      FetchMarketingCampaignsResponse,
      FetchMarketingCampaignsArgs
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/api/advertising-runtime-service/api/advertising/campaign`,
          method: "GET",
          params,
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
      providesTags: [TAG_TYPES.MarketingCampaigns],
      transformResponse: ({ data, count }) => ({
        data,
        count: parseInt(count),
      }),
    }),
    marketingAllCampaignsChart: build.query<
      FetchMarketingAllCampaignsDataResponse[],
      FetchMarketingAllCampaignsDataParams
    >({
      onQueryStarted: globalQueryErrorHandler(),
      providesTags: [TAG_TYPES.MarketingCampaigns],
      query: (params) => {
        return {
          url: `${baseUrl}/api/advertising-runtime-service/api/advertising/chart/campaign`,
          method: "GET",
          params,
        };
      },
    }),
    marketingProducts: build.query<
      FetchMarketingProductsRes,
      FetchMarketingProductsReq
    >({
      query: (params) => ({
        url: `${baseUrl}/api/advertising-runtime-service/api/advertising/product`,
        method: "GET",
        params,
      }),
      onQueryStarted: globalQueryErrorHandler(),
    }),
    organicSales: build.query<FetchOrganicSalesResponse, FetchOrganicSalesArgs>(
      {
        query: (params) => {
          const { currentRange, ...rest } = params;

          return {
            url: `${baseUrl}/api/generic-mws-service/api/salesPerformance`,
            method: "POST",
            data: {
              ...rest,
              ...currentRange,
            },
          };
        },
        onQueryStarted: globalQueryErrorHandler(),
      }
    ),
    marketingAllCampaignsTotals: build.query<
      FetchMarketingAllCampaignTotalsResponse,
      FetchMarketingAllCampaignsTotals
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/api/advertising-runtime-service/api/advertising/totals/campaign`,
          method: "GET",
          params,
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
    }),
  }),
});

export const {
  useMarketingCampaignsQuery,
  useMarketingAllCampaignsChartQuery,
  useOrganicSalesQuery,
  useMarketingAllCampaignsTotalsQuery,
  useMarketingProductsQuery,
} = extendedApiSlice;
